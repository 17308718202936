import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarContent,
} from "../../../components";

import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../hooks";
import { date, renderJobSalary } from "../../../utils";
import { ROUTER } from "../../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { ISidebar } from "@types";

export const JobEditSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { toast } = useToast();

  const {
    job: { data: job, isLoading },
  } = useAppSelector((state) => state.job);

  const jobId = job?.id;

  const { minSalary = 0, maxSalary = 0, salaryDependsOnExperience } = job || {};

  const { modal, setModal } = useModal();

  const handleDetail = (jobId?: string) => {
    if (!jobId) return;

    redirect(ROUTER.JOB_DETAIL(jobId));
  };

  const handleDelete = (props) => {
    setModal({
      ...modal,
      type: ModalType.JOB_DELETE,
      props,
      redirectTo: ROUTER.JOB_ROOT,
    });
  };

  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection heading="Salary Range" className="border-none pb-[0px]">
          <span className="text-lg font-semibold">
            {renderJobSalary({
              minSalary,
              maxSalary,
              salaryDependsOnExperience,
            })}
          </span>
        </SidebarSection>

        <SidebarSection heading="Applicants" className="border-none pb-[0px]">
          <span className="text-lg font-semibold">
            {job?.applications?.length || 0}
          </span>
        </SidebarSection>

        <SidebarSection heading="Posted On" className="border-none pb-[0px]">
          <span className="text-lg font-semibold">
            {date(job?.createdAt).format("YYYY-MM-DD")}
          </span>
        </SidebarSection>

        <SidebarSection className="border-none">
          <div className="flex flex-col gap-y-[10px]">
            <Button
              color="primary"
              variant="outlined"
              disabled={isLoading}
              onClick={() => handleDetail(jobId)}
            >
              View Details
            </Button>
            <Button
              color="danger"
              variant="outlined"
              disabled={isLoading}
              onClick={() => handleDelete(job)}
            >
              Delete Job
            </Button>
          </div>
        </SidebarSection>

        <SidebarSection heading="About Employeer">
          <div className="flex flex-col">
            <span className="font-semibold">Google</span>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Ullamcorper libero aenean sed et feugiat pulvinar pretium.
            </p>
          </div>
        </SidebarSection>
        <SidebarSection heading="My Jobs">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper
            libero aenean sed et feugiat pulvinar pretium.
          </p>
        </SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};
