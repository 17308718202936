import React from "react";

import { CardList } from "../../components";

import { useAppSelector } from "../../hooks";
import { VerificationRequestActionLogCard } from "./";

export const VerificationRequestActionLogList = ({ data, loading }) => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const isAuthor = (id: string) => profileId === id || false;

  return (
    <CardList className="mt-[20px] gap-y-[15px]" loading={loading}>
      {data?.map((action) => (
        <VerificationRequestActionLogCard
          key={action?.id}
          {...action}
          isAuthor={isAuthor(action?.createdById)}
        />
      ))}
    </CardList>
  );
};
