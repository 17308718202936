import React from "react";

import {
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

interface ICheckboxPros {
  id?: string;
  name?: string;
  label?: string;
  value?: string | null;
  checked?: boolean;
  className?: string;
  onChange?: () => void;
}

export const Checkbox: React.FC<ICheckboxPros> = ({
  id,
  name,
  label,
  value,
  checked,
  className,
  onChange,
  ...props
}) => (
  <div
    className={`flex flex-row justify-start items-center text-secondary text-base font-medium`}
  >
    <MuiCheckbox
      id={id}
      name={name}
      className={`${className} text-secondary p-[0px] mr-[7.5px]`}
      value={value}
      checked={checked}
      onChange={onChange}
      disableRipple
      {...props}
    />
    {label && (
      <label
        htmlFor={id}
        className="cursor-pointer py-[3px] select-none"
        onClick={() => onChange}
      >
        {label}
      </label>
    )}
  </div>
);

Checkbox.defaultProps = {
  id: "",
  label: "",
  value: null,
  checked: false,
  onChange: () => {},
};
