import React from "react";
import { IconBaseProps } from "react-icons/lib";

import { VscChromeClose } from "react-icons/vsc";

export const CloseIcon: React.FC<IconBaseProps> = (props) => (
  <VscChromeClose {...props} />
);

// export const CloseIcon = (props) => (
//   <SVGIcon {...props}>
//     <path
//       d="M35.9283 35.9283L28.7028 28.7028L21.557 21.557"
//       stroke="#7887A4"
//       stroke-width="1.4"
//       stroke-linecap="round"
//     />
//     <path
//       d="M35.9283 21.557L28.7427 28.7427L21.557 35.9283"
//       stroke="#7887A4"
//       stroke-width="1.4"
//       stroke-linecap="round"
//     />
//   </SVGIcon>
// );
