import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { BusinessVerificationDetailContainer } = lazyload(
  () => import("../../features")
);

export const AdminBusinessVerificationDetailPage = () => (
  <MainLayout>
    <BusinessVerificationDetailContainer />
  </MainLayout>
);
