import React, { useEffect, useState } from "react";

import { IProfile } from "../../shared";
import { ActionMenu, Avatar } from "../../components";
import { Action } from "../../shared";
import { date } from "../../utils";
import { useAppDispatch, useLoading } from "../../hooks";
import { removeComment } from "../../store";
import { PostCommentEditForm } from "./forms";

interface IPostCommentProps {
  id: string;
  details?: string;
  createdBy?: IProfile;
  createdAt?: Date;
  isAuthor?: boolean;
  loading?: boolean;
  editing?: boolean;

  onDelete?: (id: string) => void;
}

export const PostCommentCard: React.FC<IPostCommentProps> = ({
  id,
  createdBy,
  createdAt,
  isAuthor,
  onDelete,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const [details, setDetails] = useState<string>();
  const [editing, setEditing] = useState(false);

  const [loading, setLoading] = useLoading();

  const handleEdit = () => {
    setEditing(true);
  };

  const handleEditSave = (data) => {
    if (!data) return;

    setDetails(data?.details);
    setEditing(false);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDelete = async () => {
    if (!id) return;

    setLoading(true);

    const comment = await dispatch(removeComment(id));
    if (!comment?.id) {
      return setLoading(false);
    }

    setLoading(false);

    if (onDelete) {
      onDelete(id);
    }
  };

  useEffect(() => {
    setDetails(props?.details);
  }, [props?.details]);

  return (
    <div className="w-full flex flex-row justify-start items-start gap-x-[12px]">
      <div>
        <Avatar size={40} src={createdBy?.avatar}>
          {createdBy?.firstName}
        </Avatar>
      </div>
      <div className="w-full rounded-[8px] p-[12px] bg-primaryMid">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-y-[3px]">
            <span className="text-sm text-white font-semibold leading-none">
              {createdBy?.firstName} {createdBy?.lastName}
            </span>
            <span className="text-xs text-secondary leading-none">
              {date(createdAt).format("DD MMM YYYY")}
            </span>
          </div>
          <div>
            {isAuthor && !editing && (
              <ActionMenu
                loading={loading}
                actions={[Action.Edit, Action.Delete]}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </div>
        </div>
        <div className="mt-[10px] text-white text-base">
          {editing ? (
            <PostCommentEditForm
              id={id}
              details={details}
              onSave={handleEditSave}
              onClose={handleEditClose}
            />
          ) : (
            <p>{details}</p>
          )}
        </div>
      </div>
    </div>
  );
};

PostCommentCard.defaultProps = {
  details: "",
  isAuthor: false,
  loading: false,
  createdAt: new Date(),
};
