import React from "react";

interface ISvgIconProps {
  size?: number;
  width?: number;
  height?: number;
  viewBox?: string;
}

export const SVGIcon: React.FC<ISvgIconProps> = ({
  children,
  size,
  width,
  height,
  viewBox,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox={viewBox ? viewBox : `0 0 ${size} ${size}`}
    className="flex justify-center items-centerz"
    {...props}
  >
    {children}
  </svg>
);

SVGIcon.defaultProps = {
  size: 28,
};
