import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsChatDotsFill as ChatIcon } from "react-icons/bs";

import { useLoading, useToast } from "../../hooks";
import { date } from "../../utils";
import { CardList, Link } from "../../components";
import { ROUTER } from "../../routes";
import { CompanyActivityType } from "../../shared";

export const CompanyActivityCard = ({
  type,
  job,
  jobId,
  jobApplication,
  jobApplicationId,
  user,
  company,
  createdAt,
}) => {
  let activityTypeLabel, activityTypeClassName;

  switch (type) {
    case CompanyActivityType.JobApply:
      activityTypeLabel = "Applied To";
      activityTypeClassName = "text-success";
      break;
    case CompanyActivityType.JobWithdraw:
      activityTypeLabel = "Withdrew To";
      activityTypeClassName = "text-danger";
      break;
    default:
      break;
  }

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col text-secondary font-medium">
        <span className={`${activityTypeClassName} text-sm`}>
          {activityTypeLabel}
        </span>
        <div className="flex flex-row justify-between items-center">
          <Link to={ROUTER.JOB_APPLICATION_DETAIL(jobId, jobApplicationId)}>
            <h4 className="text-white text-base font-semibold leading-none my-[3px] py-[0px]">
              {job?.title}
            </h4>
          </Link>
        </div>

        <span className="text-xs">
          {date(createdAt).format("MMM DD, YYYY HH:mm")}
        </span>
      </div>
    </div>
  );
};
