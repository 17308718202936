import React from "react";

import { MainLayout } from "../../layouts";
import { RBAC } from "../../components";
import { lazyload } from "../../utils";
import { Role } from "../../shared";
import { JobSearchFilterSidebar } from "../../features";

const { JobSearch, BusinessHomeContainer } = lazyload(
  () => import("../../features")
);

export const JobRootPage = () => (
  <MainLayout leftSidebar={JobSearchFilterSidebar}>
    <RBAC roles={[Role.Admin, Role.TeamLead, Role.Employee]}>
      <BusinessHomeContainer />
    </RBAC>
    <RBAC roles={[Role.User]}>
      <JobSearch />
    </RBAC>
  </MainLayout>
);
