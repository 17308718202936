import React from "react";

import { IFilterTag } from "@types";

import { textformat } from "../../utils";
import { Tag } from "./../../components";

interface IFilterTagProps extends IFilterTag {
  className?: string;
  onDelete: (id: string) => void;
}

export const FilterTag: React.FC<IFilterTagProps> = ({
  id,
  label,
  value,
  className,
  onDelete,
}) => (
  <Tag
    className={`
      ${className ? className : "bg-primaryLight"}
      h-[35px] flex flex-row justify-center items-center rounded-lg px-[8px] capitalize box-border
    `}
    onDelete={onDelete}
  >
    {textformat.shorten(label ? label : value, 25)}
  </Tag>
);
