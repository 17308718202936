import React from "react";
import { ROUTER } from "../../routes";

import {
  Button,
  Card,
  Avatar,
  VerificationStatusBadge,
  CardBody,
  Link,
} from "../../components";
import { date } from "../../utils";

export const CompanyEmployeeCard = ({
  id,
  firstName,
  lastName,
  avatar,
  role,
}) => (
  <Card>
    <CardBody>
      <div className="flex flex-row justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-col">
            <div className="flex flex-row justify-start items-start gap-x-[10px]">
              <Avatar size={40} src={avatar}>
                {firstName}
              </Avatar>
              <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                <Link to={ROUTER.USER_DETAIL(id)}>
                  <h3 className="text-base font-semibold text-white leading-none">
                    {firstName || ""} {lastName || ""}
                  </h3>
                </Link>
                <span className="text-xs text-secondary leading-none">
                  Offline
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center gap-x-[10px]">
          <Button
            variant="outlined"
            color="primary"
            to={ROUTER.USER_DETAIL(id)}
          >
            Details
          </Button>
          <Button
            variant="outlined"
            color="success"
            // onClick={handleMessage}
          >
            Message
          </Button>
        </div>
      </div>
    </CardBody>
  </Card>
);

CompanyEmployeeCard.defaultProps = {
  firstName: "'*****'",
  lastName: "*****",
  photo: "",
  education: [],
  experience: [],
};
