import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "../../../components";
import { CompanyCommentList } from "./../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useAuthor,
  useLoading,
  useToast,
} from "../../../hooks";
import { date } from "../../../utils";
import {
  getCompanyCommentsByCompanyId,
  removeCompanyCommentById,
} from "../../../store";
import { IChildComponentUpdateHelpers } from "@types";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

interface ICompanyCommentLogWidget {
  companyId?: string;
  where?: {
    userId?: string;
  };
}

export const CompanyCommentLogWidget: React.FC<ICompanyCommentLogWidget> = ({
  companyId,
  where,
}) => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const [commentLoading, setCommentLoading] = useLoading();
  const [comments, setComments] = useState<any[]>([]);

  const {
    companyComments: { data: companyComments },
  } = useAppSelector((state) => state.company);

  const handleCommentFetch = async () => {
    if (!companyId) return;

    setCommentLoading(true);

    const comments = await dispatch(
      getCompanyCommentsByCompanyId(companyId, {
        where,
        sort: [{ createdAt: "ASC" }],
      })
    );
    if (!comments) {
      return setCommentLoading(false);
    }

    setComments(comments);
    setCommentLoading(false);
  };

  const handleCommentDelete = async (
    commentId: string,
    { setLoading }: IChildComponentUpdateHelpers
  ): Promise<void> => {
    setLoading(true);

    const comment = await dispatch(removeCompanyCommentById(commentId));
    if (!comment?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Company Note Not Removed!",
      });

      return setLoading(false);
    }

    setComments(comments?.filter(({ id }) => id !== commentId) || []);
    setLoading(false);
  };

  useEffect(() => {
    if (!companyId) return;

    handleCommentFetch();
  }, [companyId]);

  useEffect(() => {
    setComments(companyComments || []);
  }, [companyComments]);

  return (
    <CompanyCommentList
      loading={commentLoading}
      data={comments}
      className="gap-y-[20px]"
      cardProps={{
        variant: "transparent",
      }}
      onDelete={handleCommentDelete}
    />
  );
};

CompanyCommentLogWidget.defaultProps = {};
