import React, { useEffect } from "react";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
  FiClock as ClockIcon,
} from "react-icons/fi";

import { Container, Link } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { getRecentlyPostedJobs } from "../../../store";

export const EmployeeRecentPostedJobsWIdget = () => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    recentlyPostedJobs: {
      data: recentlyPostedJobs,
      isLoading: recentlyPostedJobsLoading,
    },
  } = useAppSelector((state) => state.job);

  const profileId = account?.profileId;

  useEffect(() => {
    if (!profileId) return;

    dispatch(getRecentlyPostedJobs());
  }, [profileId]);

  return (
    <Container loading={recentlyPostedJobsLoading}>
      <div className="flex flex-col gap-y-[15px]">
        {recentlyPostedJobs?.map((job) => (
          <div className="">
            <div className="flex flex-row justify-start items-center gap-x-[5px]">
              <Link to={ROUTER.JOB_DETAIL(job?.id)}>
                <h3 className="text-white text-sm font-semibold">
                  {job?.title}
                </h3>
              </Link>
              {job?.isDraft && (
                <span className="text-secondary text-sm font-medium">
                  (Draft)
                </span>
              )}
              {job?.isDelisted && (
                <span className="text-warning text-sm font-medium">
                  (Delisted)
                </span>
              )}
            </div>

            <div className="flex flex-row flex-wrap justify-start items-center capitalize gap-x-[10px] gap-y-[3px] text-xs text-secondary font-medium mt-[5px]">
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <MonitorIcon />
                <span>{job?.type}</span>
              </div>
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <MapIcon />
                <span>
                  {job?.city}, {job?.country}
                </span>
              </div>
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <UsersIcon />
                <span>{job?.positions} Positions</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
