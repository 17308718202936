import React from "react";

export const FallbackError: React.FC = () => (
  <div className="w-full h-screen flex flex-row justify-center items-center bg-primaryDark text-white">
    <div className="text-white">
      <h1 className="text-xl font-semibold text-center mb-[10px]">Error 😥</h1>
      <span>Something went wrong. Please try it again.</span>
    </div>
  </div>
);

FallbackError.defaultProps = {};
