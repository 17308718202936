import React from "react";

import { Link } from "./../../components";
import { ROUTER } from "./../../routes";

interface ILogoProps {
  className?: string;
  to?: string | null;
}

export const Logo: React.FC<ILogoProps> = ({ to, className }) => (
  <div className={`${className} h-[28px] flex flex-row items-center text-lg`}>
    <Link to={to || ROUTER.HOME}>
      <div className="h-[28px] flex flex-row justify-start items-center text-inherit font-bold leading-none cursor-pointer md:hidden xxs:flex">
        <span className="text-white -mb-[0.125em]">L</span>
        <span className="text-primaryBlue -mb-[0.125em]">B</span>
      </div>
      <div className="flex flex-row justify-start items-center text-inherit font-bold cursor-pointer md:flex xxs:hidden">
        <span className="text-white leading-[0px]">Legit</span>
        <span className="text-primaryBlue leading-[0px]">book</span>
      </div>
    </Link>
  </div>
);

Logo.defaultProps = {
  to: null,
  className: "",
};
