import React from "react";

import { IAudit, IProfile } from "../../shared";

import {
  Button,
  Card,
  Avatar,
  CardFooter,
  CardBody,
  Link,
  StatusCheckbox,
} from "../../components";
import { Status } from "../../shared";
import { ROUTER } from "../../routes";

import { date } from "../../utils";

interface IUserAuditCardProps extends IAudit {}

export const UserAuditCard: React.FC<IUserAuditCardProps> = ({
  id,
  userVerifications,
  user,
  company,
}) => {
  const isApproved = (status: string) => status === Status.Approved;

  return (
    <Card>
      <CardBody>
        <div className="flex justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-start items-center gap-x-[10px]">
                <Avatar
                  size={40}
                  to={ROUTER.USER_DETAIL(user?.id)}
                  src={user?.avatar}
                >
                  {user?.firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-center items-start gap-y-[3px] mt-[6px]">
                  <Link to={ROUTER.USER_DETAIL(user?.id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {user?.firstName || ""} {user?.lastName || ""}
                    </h3>
                  </Link>
                  <span className="text-xs leading-none">********</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-x-5">
            {/* <div className="flex flex-row items-center gap-x-[10px]">
            <span className="text-xs font-normal text-secondary">
              Assigned to:
            </span>
            <Avatar size={30} />
            <span className="text-white">{assignedTo?.name}</span>
          </div> */}
            <Button variant="outlined" to={ROUTER.BUSINESS_AUDIT_DETAIL(id)}>
              Details
            </Button>
          </div>
        </div>

        <div className="mt-[20px]">
          <span className="text-xs font-medium text-white">
            Recent Verifications
          </span>
          <div className="flex flex-col">
            {userVerifications?.map(
              ({
                id,
                createdAt,
                updatedAt,
                status,
                experience,
                experienceId,
                education,
                educationId,
                createdBy,
                approvedBy,
                rejectedBy,
              }) => {
                const user: IProfile = experienceId
                  ? experience?.profile
                  : educationId
                  ? education?.profile
                  : null;

                const description = experienceId
                  ? `${experience?.company}, ${experience?.role}`
                  : educationId
                  ? `${education?.university}, ${education?.degree}`
                  : null;

                return (
                  <div
                    key={id}
                    className="flex flex-row justify-between items-center gap-x-[15px] box-border py-[10px] border-t border-primaryMid first:border-t-0"
                  >
                    <div className="basis-4/12 flex flex-row items-center gap-x-[10px]">
                      <Avatar
                        size={30}
                        to={ROUTER.USER_DETAIL(user?.id)}
                        src={user?.avatar}
                      />
                      <div className="flex flex-col items-start gap-y-[3px]">
                        <Link
                          to={ROUTER.USER_DETAIL(user?.id)}
                          // className="-mb-[0.5px]"
                        >
                          <span className="text-xs font-semibold text-white leading-none">
                            {user?.firstName} {user?.lastName}
                          </span>
                        </Link>
                        <span className="text-xxs font-medium text-secondary leading-none">
                          {description || "********"}
                        </span>
                      </div>
                    </div>
                    <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                      <span className="text-xs font-semibold text-white">
                        Experience
                      </span>
                      <span className="text-xxs font-medium text-secondary">
                        {date(experience?.startedAt).format("MMM YYYY")} -{" "}
                        {date(experience?.finishedAt).format("MMM YYYY")}
                      </span>
                    </div>
                    <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                      <span className="text-xs font-semibold text-white">
                        Requester
                      </span>
                      {/* <Link
                      to={ROUTER.USER_DETAIL(createdBy?.id)}
                      className="bg-red-500 leading-none py-[0px] my-[0px] h-[10px]"
                    > */}
                      <span className="text-xxs font-medium text-secondary">
                        {createdBy?.firstName} {createdBy?.lastName}
                      </span>
                      {/* </Link> */}
                    </div>
                    <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                      <span className="text-xs font-semibold text-white">
                        Requested
                      </span>
                      <span className="text-xxs font-medium text-secondary">
                        {date(createdAt).format("MMM DD, HH:mm")}
                      </span>
                    </div>
                    <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                      <span className="text-xs font-semibold text-white ">
                        Updated
                      </span>
                      <span className="text-xxs font-medium text-secondary">
                        {date(createdAt).format("MMM DD, HH:mm")}
                      </span>
                    </div>

                    <div className="flex flex-row justify-center items-center">
                      <StatusCheckbox status={status} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </CardBody>
      {/* <CardFooter>
      <div className="flex flex-row justify-center items-center">
        <Button variant="text" className="capitalize text-white">
          Add Quick Note
        </Button>
      </div>
    </CardFooter> */}
    </Card>
  );
};

UserAuditCard.defaultProps = {};
