import React, { useEffect } from "react";

import { BusinessAuditList } from "./../../features";
import { AuditType, VerificationStatus } from "../../shared";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAudits } from "../../store";

export const AdminBusinessAuditListContainer = () => {
  const dispatch = useAppDispatch();

  const {
    audits: { data: audits, isLoading: auditsLoading },
  } = useAppSelector((state) => state.audit);

  useEffect(() => {
    dispatch(
      getAudits({
        where: {
          type: AuditType.BusinessVerification,
          companyId: null,
          $businessVerification$: {
            status: { $ne: VerificationStatus.Pending },
          },
        },
      })
    );
  }, []);

  return <BusinessAuditList loading={auditsLoading} data={audits} />;
};
