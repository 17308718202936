import React from "react";

import { EmployeeSearchFilterSidebar } from "../../features";
import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { EmployeeSearch } = lazyload(() => import("../../features"));

export const EmployeeListPage = () => (
  <MainLayout leftSidebar={EmployeeSearchFilterSidebar}>
    <EmployeeSearch />
  </MainLayout>
);
