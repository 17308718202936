import React from "react";

import {
  ResumeEducationSectionDetail,
  ResumeEducationSectionSelect,
} from "../../..";

export const ResumeEducationSection = ({ loading, editing, data }) => {
  return editing ? (
    // @ts-ignore
    <ResumeEducationSectionSelect />
  ) : (
    <ResumeEducationSectionDetail loading={loading} data={data} />
  );
};

ResumeEducationSection.defaultProps = {};
