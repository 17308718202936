import React from "react";

import { AuthLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { InviteDetail } = lazyload(() => import("../../features"));

export const InviteDetailPage = () => (
  <AuthLayout>
    <InviteDetail />
  </AuthLayout>
);
