import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CardList } from "../../components";
import { BusinessVerificationCard } from "./../../features";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCompanyRegistrations } from "../../store";

export const AdminBusinessVerificationListContainer = () => {
  const dispatch = useAppDispatch();

  const {
    companyVerifications: {
      data: verifications,
      isLoading: verificationsLoading,
    },
  } = useAppSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyRegistrations());
  }, []);

  return (
    <CardList loading={verificationsLoading}>
      {!!verifications?.length ? (
        verifications?.map((verification) => (
          <BusinessVerificationCard key={verification?.id} {...verification} />
        ))
      ) : (
        <span>No verifications found.</span>
      )}
    </CardList>
  );
};
