import React, { ChangeEvent, useEffect, useRef } from "react";

// #DEPENDENCY_ISSUE, #STYLE_ISSUE
import ReactPinInput from "react-pin-input";
import { InputError } from "..";

interface IPinInputProps {
  className?: string;
  initialValue?: string | null;
  value?: string | number | null;
  error?: string | null;
  type?: string;
  inputMode?: string;
  length?: number;
  secret?: boolean;
  style?: React.CSSProperties;
  onChange?: (value?: string) => void;
  onComplete?: (values?: any) => void;
}

export const PinInput: React.FC<IPinInputProps> = ({
  className,
  value,
  error,
  type,
  inputMode,
  length,
  secret,
  style,
  onChange,
  onComplete,
}) => {
  const pinRef = useRef<HTMLFormElement>(null);

  const isError = (error && error?.length >= 1) || false;

  const handlePaste = () => {
    // @ts-ignore
    const elements: any[] = pinRef?.current?.elements || [];

    // @ts-ignore
    if (!!elements?.length) {
      elements?.map((element) => {
        element.onPaste = (e: ClipboardEvent) => {
          const clipboard = e?.clipboardData?.getData("text");

          const value =
            clipboard
              ?.toString()
              ?.split("")
              ?.slice(0, elements?.length || 0)
              ?.join("") || "";

          const chars = value?.split("");

          if (!value || !chars?.length) return;

          elements?.map((element, key) => {
            if (!element?.state) return;

            const value = chars[key];

            // @ts-ignore
            element.state.value = value;
          });

          if (onChange && onComplete) {
            (async () => {
              await onChange(value);
              await onComplete();
            })();
          }
        };
      });
    }
  };

  useEffect(() => {
    if (!pinRef?.current) return;

    handlePaste();
  }, [pinRef]);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <ReactPinInput
        // @ts-ignore
        ref={pinRef}
        initialValue={value || ""}
        inputStyle={{
          color: "#FFF",
          width: 70,
          height: 70,
          borderRadius: 6,
          borderColor: isError ? "#EB5757" : "#342F3E",
          background: "#221F28",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          margin: "0px auto",
          ...style,
        }}
        onChange={onChange}
        onComplete={onComplete}
        {...{ type, inputMode, length, secret }}
      />
      <InputError
        className="w-full flex flex-row justify-center mt-[5px]"
        error={error}
      />
    </div>
  );
};

PinInput.defaultProps = {
  className: "",
  value: null,
  type: "numeric",
  inputMode: "number",
  length: 4,
  onComplete: () => {},
};
