import React, { useEffect, useRef } from "react";

import Calendar, { CalendarOptions } from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import { ModalType } from "../../shared";
import { useModal } from "../../hooks";
import { Generator } from "../../utils";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

interface IFullCalendarProps {}

const events = [
  {
    id: Generator.uuid(),
    title: "Event1",
    start: "2022-11-01",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat eget sed etiam tortor mattis sodales diam aliquam, elementum.",
    // allDay: false,
  },
  {
    id: Generator.uuid(),
    title: "Event1",
    start: "2022-11-02",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat eget sed etiam tortor mattis sodales diam aliquam, elementum.",
    // allDay: false,
  },
  {
    id: Generator.uuid(),
    title: "Event2",
    start: "2022-11-02",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat eget sed etiam tortor mattis sodales diam aliquam, elementum.",
    // allDay: false,
  },
  {
    id: Generator.uuid(),
    title: "Event1",
    start: "2022-11-03",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat eget sed etiam tortor mattis sodales diam aliquam, elementum.",

    // allDay: false,
  },
];

export const FullCalendar: React.FC<IFullCalendarProps> = () => {
  // const searchParams = new URLSearchParams(useLocation().search);

  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get("view");

  const calendarRef = useRef<Calendar>(null);
  const calendar = calendarRef.current ? calendarRef.current.getApi() : null;

  const { setModal } = useModal();

  const handleEventShow = (event) => {
    setModal({ type: ModalType.EVENT_DETAIL, props: { ...event } });
  };

  const handleEventCreate = ({ startedAt }) => {
    setModal({ type: ModalType.EVENT_CREATE, props: { startedAt } });
  };

  const calendarConfig: CalendarOptions = {
    firstDay: 1,
    titleFormat: { year: "numeric", month: "short", day: "numeric" },
    slotDuration: "00:30:00",
    selectable: true,
    allDaySlot: false,
    nowIndicator: true,
    events,
    dateClick: ({ date, allDay }) => {
      console.log("Date Clicked: ", { date, allDay });

      handleEventCreate({ startedAt: date });
    },
    datesSet: ({ view }) => {
      setSearchParams({ view: view.type });
    },
    eventClick: ({ event }) => {
      const { id, title, start } = event;

      console.log({ event, title });

      handleEventShow({ id, title, startedAt: start });
    },
    select: ({ start, end, allDay }) => {
      console.log("Selecting..", { start, end, allDay });
    },
  };

  useEffect(() => {
    setTimeout(() => {
      if (!view) return;

      calendarRef.current?.getApi()?.changeView(view);
    });
  }, [view]);

  useEffect(() => {
    if (!view) {
      setSearchParams({ view: "dayGridMonth" });
    }
  }, [view]);

  return (
    <Calendar
      ref={calendarRef}
      initialView={view || "dayGridMonth"}
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
      headerToolbar={{
        left: "title",
        right: "timeGridDay,timeGridWeek,dayGridMonth prev,next",
      }}
      buttonText={{
        today: "Today",
        day: "Day",
        week: "Week",
        month: "Month",
        list: "List",
      }}
      {...calendarConfig}
    />
  );
};

FullCalendar.defaultProps = {};
