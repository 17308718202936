import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { profileCoverSlice } from "./profile-cover.slice";

export const appendProfileCovers =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(profileCoverSlice.actions.appendProfileCovers(payload));

export const popProfileCovers =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(profileCoverSlice.actions.popProfileCovers(payload));

export const getProfileCovers =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      const { where, include } = options || {};

      dispatch(profileCoverSlice.actions.getProfileCoversRequest());

      const response = await ApiService.post("resume-covers/query", {
        where,
      });
      const { data } = response.data;

      dispatch(profileCoverSlice.actions.getProfileCoversSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        profileCoverSlice.actions.getProfileCoversError(e.response?.data?.error)
      );
    }
  };

export const getProfileCover =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileCoverSlice.actions.getProfileCoverRequest());

      const response = await ApiService.get(`resume-covers/${id}`);
      const { data } = response.data;

      dispatch(profileCoverSlice.actions.getProfileCoverSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        profileCoverSlice.actions.getProfileCoverError(e.response?.data?.error)
      );
    }
  };

export const createProfileCover =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileCoverSlice.actions.createProfileCoverRequest());

      const response = await ApiService.post("resume-covers", {
        isArchived: true,
        ...payload,
      });
      const { data } = response.data;

      dispatch(profileCoverSlice.actions.createProfileCoverSuccess(data));
      dispatch(profileCoverSlice.actions.appendProfileCovers(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        profileCoverSlice.actions.createProfileCoverError(
          e.response?.data?.error
        )
      );
    }
  };

export const updateProfileCover =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileCoverSlice.actions.updateProfileCoverRequest());

      const response = await ApiService.put(`resume-covers/${id}`, payload);
      const { data } = response.data;

      dispatch(profileCoverSlice.actions.updateProfileCoverSuccess(data));
      dispatch(profileCoverSlice.actions.appendProfileCovers(payload));

      return id;
    } catch (e: any) {
      dispatch(
        profileCoverSlice.actions.updateProfileCoverError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeProfileCover =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileCoverSlice.actions.removeProfileCoverRequest());

      const response = await ApiService.delete(`resume-covers/${id}`);
      const { data } = response.data;

      await dispatch(profileCoverSlice.actions.removeProfileCoverSuccess(data));
      await dispatch(profileCoverSlice.actions.popProfileCovers({ id }));

      return id;
    } catch (e: any) {
      console.log("error", { e });
      dispatch(
        profileCoverSlice.actions.removeProfileCoverError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeProfileCoverFromResume =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileCoverSlice.actions.updateProfileCoverRequest());

      const response = await ApiService.put(`resume-covers/${id}`, {
        resumeId: null,
      });
      const { data } = response.data;

      await dispatch(profileCoverSlice.actions.updateProfileCoverSuccess(data));
      await dispatch(profileCoverSlice.actions.popProfileCovers({ id }));

      return id;
    } catch (e: any) {
      dispatch(
        profileCoverSlice.actions.updateProfileCoverError(
          e.response?.data?.error
        )
      );
    }
  };
