import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";
import { getAccount } from "../account";

import { companySlice } from "./company.slice";

export const getCompanies =
  (query?: object) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompaniesRequest());

      const response = await ApiService.post(`companies/query`, query || {});
      const { data, results } = response.data;

      dispatch(companySlice.actions.getCompaniesSuccess({ data, results }));

      console.log({ data });

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompaniesError(e.response?.data?.error));
    }
  };

export const getCompany = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(companySlice.actions.getCompanyRequest());

    const response = await ApiService.get(`companies/${id}`);
    const { data } = response.data;

    dispatch(companySlice.actions.getCompanySuccess(data));

    console.log({ data });

    return data;
  } catch (e: any) {
    dispatch(companySlice.actions.getCompanyError(e.response?.data?.error));
  }
};

export const getCompanyById =
  (companyId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyRequest());

      const response = await ApiService.get(`companies/${companyId}`);
      const data = response.data;

      console.log({ data });

      dispatch(companySlice.actions.getCompanySuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyError(e.response?.data?.error));
    }
  };

export const getCompanyByAlias =
  (alias: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyRequest());

      const response = await ApiService.get(`companies/@${alias}`);
      const data = response.data;

      dispatch(companySlice.actions.getCompanySuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyError(e.response?.data?.error));
    }
  };

export const createCompany =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.createCompanyRequest());

      const response = await ApiService.post("companies", payload);
      const { data } = response.data;

      dispatch(companySlice.actions.createCompanySuccess(data));

      // TODO
      // dispatch(companySlice.actions.appendCompanies(data));

      return data;
    } catch (e: any) {
      dispatch(
        companySlice.actions.createCompanyError(e.response?.data?.error)
      );
    }
  };

export const updateCompany =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.updateCompanyRequest());

      // Updating
      const response = await ApiService.put(`companies/${id}`, payload);
      const { data } = response.data;

      // Refreshing
      // const refreshed = await dispatch(getCompany(id));

      dispatch(companySlice.actions.updateCompanySuccess(data));
      // dispatch(companySlice.actions.appendCompanies(refreshed));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        companySlice.actions.updateCompanyError(e.response?.data?.error)
      );
    }
  };

export const removeCompany = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(companySlice.actions.removeCompanyRequest());

    const response = await ApiService.delete(`companies/${id}`);
    const { data } = response.data;

    dispatch(companySlice.actions.removeCompanySuccess(data));

    // TODO
    // dispatch(companySlice.actions.popCompanies({ id }));

    return id;
  } catch (e: any) {
    dispatch(companySlice.actions.removeCompanyError(e.response?.data?.error));
  }
};

export const getCompanyRegistrations =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyRegistrationsRequest());

      const response = await ApiService.post(
        `companies/registrations/query`,
        options
      );
      const { results, data } = response.data;

      dispatch(
        companySlice.actions.getCompanyRegistrationsSuccess({ results, data })
      );

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyRegistrationsError(e));
    }
  };

export const getCompanyRegistration =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyRegistrationRequest());

      const response = await ApiService.get(`companies/registrations/${id}`);
      const { data } = response.data;

      dispatch(companySlice.actions.getCompanyRegistrationSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyRegistrationError(e));
    }
  };

export const registerCompany =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.registerCompanyRequest());

      const response = await ApiService.post(
        `companies/registrations`,
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      const { data } = response.data;

      await dispatch(companySlice.actions.registerCompanySuccess(data));
      await dispatch(getAccount());

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.registerCompanyError(e));
    }
  };

export const approveCompanyRegistration =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.approveCompanyRegistrationRequest());

      const response = await ApiService.put(
        `companies/registrations/${id}/approve`
      );
      const { data } = response.data;

      dispatch(companySlice.actions.approveCompanyRegistrationSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.approveCompanyRegistrationError(e));
    }
  };

export const rejectCompanyRegistration =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.rejectCompanyRegistrationRequest());

      const response = await ApiService.put(
        `companies/registrations/${id}/reject`
      );
      const { data } = response.data;

      dispatch(companySlice.actions.rejectCompanyRegistrationSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.rejectCompanyRegistrationError(e));
    }
  };

export const getCompanyActivities =
  (id: string, options: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyActivitiesRequest());

      const response = await ApiService.post(
        `companies/${id}/activities/query`,
        options
      );
      const { results, data } = response.data;

      dispatch(
        companySlice.actions.getCompanyActivitiesSuccess({ results, data })
      );

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyActivitiesError(e));
    }
  };

export const getCompanyCommentsByCompanyId =
  (companyId: string, options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyCommentsByCompanyIdRequest());

      const response = await ApiService.post(
        `companies/${companyId}/comments/query`,
        options
      );
      const { results, data } = response.data;

      dispatch(
        companySlice.actions.getCompanyCommentsByCompanyIdSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyCommentsByCompanyIdError(e));
    }
  };

export const appendCompanyComment =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(companySlice.actions.appendCompanyComment(payload));

export const insertCompanyComment =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(companySlice.actions.insertCompanyComment(payload));

export const pullCompanyComment =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(companySlice.actions.pullCompanyComment(payload));

export const createCompanyComment =
  (companyId: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.createCompanyCommentRequest());

      const response = await ApiService.post(
        `companies/${companyId}/comments`,
        payload
      );
      const { data } = response.data;

      console.log({ data });

      dispatch(companySlice.actions.createCompanyCommentSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.createCompanyCommentError(e));
    }
  };

export const updateCompanyComment =
  (options: any, payload: any) => async (dispatch: AppDispatch) => {
    try {
      const { id, companyId } = options || {};

      dispatch(companySlice.actions.updateCompanyCommentRequest());

      const response = await ApiService.put(
        `companies/${companyId}/comments/${id}`,
        payload
      );
      const { data } = response.data;

      dispatch(
        companySlice.actions.updateCompanyCommentSuccess({
          ...payload,
          id,
          companyId,
        })
      );

      return { id };
    } catch (e: any) {
      dispatch(companySlice.actions.updateCompanyCommentError(e));
    }
  };

export const removeCompanyCommentById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.removeCompanyCommentRequest());

      await ApiService.delete(`company-comments/${id}`);

      dispatch(companySlice.actions.removeCompanyCommentSuccess({ id }));
      dispatch(pullCompanyComment({ id }));

      return { id };
    } catch (e: any) {
      dispatch(companySlice.actions.removeCompanyCommentError(e));
    }
  };

export const removeCompanyComment =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      const { id, jobApplicationId } = payload || {};

      dispatch(companySlice.actions.removeCompanyCommentRequest());

      await ApiService.delete(`companies/${jobApplicationId}/comments/${id}`);

      dispatch(companySlice.actions.removeCompanyCommentSuccess({ id }));
      dispatch(pullCompanyComment({ id }));

      return { id };
    } catch (e: any) {
      dispatch(companySlice.actions.removeCompanyCommentError(e));
    }
  };

export const inviteEmployee =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      const { companyId } = payload || {};

      dispatch(companySlice.actions.inviteEmployeeRequest());

      const response = await ApiService.post(
        `companies/${companyId}/invites`,
        payload
      );
      const { data } = response.data;

      dispatch(companySlice.actions.inviteEmployeeSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.inviteEmployeeError(e));
    }
  };

export const acceptCompanyEmployeeInvite =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      const { token, inviteId, companyId } = payload || {};

      dispatch(companySlice.actions.acceptCompanyEmployeeInviteRequest());

      const response = await ApiService.put(
        `companies/${companyId}/invites/${inviteId}/accept?token=${token}`
      );
      const { data } = response.data;

      dispatch(companySlice.actions.acceptCompanyEmployeeInviteSuccess(data));
      dispatch(getAccount());

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.acceptCompanyEmployeeInviteError(e));
    }
  };

export const rejectCompanyEmployeeInvite =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      const { token, inviteId, companyId } = payload || {};

      dispatch(companySlice.actions.rejectCompanyEmployeeInviteRequest());

      const response = await ApiService.put(
        `companies/${companyId}/invites/${inviteId}/reject?token=${token}`
      );
      const { data } = response.data;

      dispatch(companySlice.actions.rejectCompanyEmployeeInviteSuccess(data));
      dispatch(getAccount());

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.rejectCompanyEmployeeInviteError(e));
    }
  };

export const getEmployeesByCompanyId =
  (companyId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyEmployeesRequest());

      const response = await ApiService.get(`companies/${companyId}/employees`);
      const { data, results } = response.data;

      dispatch(
        companySlice.actions.getCompanyEmployeesSuccess({
          data,
          results,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyEmployeesError(e));
    }
  };

export const getCompanyAuditsByCompanyId =
  (companyId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(companySlice.actions.getCompanyAuditsRequest());

      const response = await ApiService.post(`audits/query`, {
        where: { companyId },
      });
      const { data, results } = response.data;

      dispatch(
        companySlice.actions.getCompanyAuditsSuccess({
          data,
          results,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(companySlice.actions.getCompanyAuditsError(e));
    }
  };
