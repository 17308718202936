import { ProfileType } from "../../../shared";
import { ApiService, EventService } from "../../../services";
import { AppDispatch } from "../../store";

import { notificationSlice } from "./notification.slice";

export const setNotifications = (payload: any) =>
  notificationSlice.actions.setNotifications(payload);

export const getNotifications = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(notificationSlice.actions.getNotificationsRequest());

    const response = await ApiService.get("auth/account");
    const { data, results, refreshedAt } = response.data;

    dispatch(
      notificationSlice.actions.getNotificationsSuccess({
        data,
        results,
        refreshedAt,
      })
    );

    return data;
  } catch (e: any) {
    dispatch(
      notificationSlice.actions.getNotificationsError(e.response?.data?.error)
    );
  }
};
