import {} from "react-redux";
import { useAppSelector } from "./useRedux";

export const useAuthor = () => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const checkAuthor = (authorId: string) => authorId === profileId || false;

  return { checkAuthor };
};
