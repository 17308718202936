export enum CompanySize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum CompanyActivityType {
  JobCreate = 'job-create',
  JobApply = 'job-apply',
  JobWithdraw = 'job-withdraw',
  Comment = 'comment',
}
