import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
  FiEdit as EditIcon,
} from "react-icons/fi";

import {
  Avatar,
  Button,
  Card,
  Container,
  InfoCard,
  Link,
  PageHeader,
  VerifiedAccountBadge,
} from "../../components";
import { ChatMessageButton, PostList } from "..";
import { ROUTER } from "../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";
import {
  addFriend,
  removeFriend,
  getPosts,
  getProfileById,
  updateUserAvatar,
  createChat,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";

export const UserDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { userId } = useParams();

  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    profile: { data: profile, isLoading: profileLoading },
  } = useAppSelector((state) => state.profile);

  const [editLoading, setEditLoading] = useLoading();
  const [avatarLoading, setAvatarLoading] = useLoading();
  const [friendLoading, setFriendLoading] = useLoading();
  const [messageLoading, setMessageLoading] = useLoading();

  const [editMode, setEditMode] = useState(false);
  const [isFriend, setFriend] = useState(false);
  const [isFriendRequest, setFriendRequest] = useState(false);
  const [isFollowing, setFollowing] = useState(false);
  const [friendCount, setFriendCount] = useState(0);

  const {
    firstName,
    lastName,
    avatar,
    bio,
    country,
    state,
    city,
    chatId,
    isChat,
  } = profile || {};

  const profileId = account?.profileId;
  const isMyProfile = profileId === userId || false;
  const isBusinessProfile = profile?.isBusiness || false;
  const employment =
    profile?.account?.ownership?.profile ||
    profile?.account?.employment?.profile;

  const handleProfileSync = () => {
    if (!userId) return;

    dispatch(getProfileById(userId));
    dispatch(getPosts({ where: { createdById: userId } }));
  };

  const handleEditModeSwitch = async () => {
    if (editMode) {
      setEditLoading(true);

      return setTimeout(() => {
        setEditMode(!editMode);
        setEditLoading(false);

        handleProfileSync();
      }, 500);
    }

    setEditMode(!editMode);
  };

  const handleAddFriend = async () => {
    if (!userId) return;

    setFriendLoading(true);

    const friend = await dispatch(addFriend(userId));
    if (!friend?.id) {
      return setFriendLoading(false);
    }

    setFriendRequest(true);
    setFriendLoading(false);
  };

  const handleRemoveFriend = async () => {
    if (!userId) return;

    setFriendLoading(true);

    const friend = await dispatch(removeFriend(userId));
    if (!friend?.id) {
      return setFriendLoading(false);
    }

    setFriend(false);
    setFriendRequest(false);
    setFriendLoading(false);
  };

  const handleAvatarUpload = async (e) => {
    if (!profileId) return;

    const file = e.target?.files?.[0];
    if (!file) return;

    const data = new FormData();

    data.append("avatar", file);

    setAvatarLoading(true);

    // Uploading Avatar
    const result = await dispatch(updateUserAvatar(profileId, data));
    if (!result) {
      setAvatarLoading(false);
    }

    setTimeout(() => {
      setAvatarLoading(false);
      dispatch(getPosts({ where: { createdById: userId } }));
    }, 500);
  };

  useEffect(() => {
    setFriend(profile?.isFriend || false);
  }, [profile?.isFriend]);

  useEffect(() => {
    setFriendRequest(profile?.isFriendRequest || false);
  }, [profile?.isFriendRequest]);

  useEffect(() => {
    if (!profile?.friendCount) return;

    setFriendCount(profile?.friendCount);
  }, [profile?.friendCount]);

  useEffect(() => {
    if (!profile?.id || (!isMyProfile && !isFriend)) return;

    dispatch(getPosts({ where: { createdById: profile?.id } }));
  }, [profile]);

  useEffect(() => {
    if (!userId) return;

    dispatch(getProfileById(userId));
  }, [userId]);

  return (
    <Container
      className="w-full flex flex-col gap-y-[20px] py-[15px]"
      loading={profileLoading}
    >
      <div className="relative w-full h-[325px] flex flex-col justify-end rounded-lg overflow-hidden">
        <div className="absolute w-full h-full bg-gray-500 z-10">
          {editMode && (
            <>
              <div className="z-10 absolute w-full h-full bg-black opacity-40"></div>
              <div className="z-20 absolute top-[20px] right-[20px] w-[48px] h-[48px] flex flex-row justify-center items-center bg-primaryLight text-white rounded-full cursor-pointer">
                <EditIcon size={18} />
              </div>
            </>
          )}
        </div>

        <div className="relative z-30 w-full flex flex-col justify-end px-[25px]">
          <div className="w-full h-[80px] flex flex-row items-center box-border text-white text-xl font-bold">
            <Avatar
              loading={avatarLoading}
              size={128}
              src={avatar}
              className="border-[0px] border-white text-xl"
              onChange={handleAvatarUpload}
              editing={editMode}
            >
              {firstName}
            </Avatar>
            <div className="flex flex-col items-start ml-[18px]">
              <div className="flex flex-row justify-start items-center gap-x-[5px]">
                <h2>
                  {firstName || ""} {lastName || ""}
                </h2>
                {isBusinessProfile && <VerifiedAccountBadge />}
              </div>

              <span className="text-white text-base font-medium">
                {isBusinessProfile
                  ? `Business User at ${employment?.name}`
                  : "********"}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full min-h-[55px] z-20 flex flex-row justify-between items-center bg-primaryLight box-border px-[20px] py-[15px]">
          <div></div>
          <div className="flex flex-row gap-x-[15px]">
            <Container
              hidden={!profile?.id}
              className="flex flex-row items-center gap-[15px] min-h-[40px]"
            >
              {isMyProfile ? (
                editMode ? (
                  <Button
                    color="success"
                    loading={editLoading}
                    onClick={handleEditModeSwitch}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button color="secondary" onClick={handleEditModeSwitch}>
                    Edit Profile
                  </Button>
                )
              ) : !isBusinessProfile ? (
                isFriend ? (
                  <>
                    <ChatMessageButton userId={userId} />
                    <Button
                      color="secondary"
                      className="w-[130px]"
                      loading={friendLoading}
                      onClick={handleRemoveFriend}
                    >
                      Remove Friend
                    </Button>
                  </>
                ) : isFriendRequest ? (
                  <Button
                    color="secondary"
                    className="w-[135px]"
                    loading={friendLoading}
                    onClick={handleRemoveFriend}
                  >
                    Cancel Request
                  </Button>
                ) : (
                  <Button
                    color="warning"
                    className="w-[130px]"
                    loading={friendLoading}
                    onClick={handleAddFriend}
                  >
                    Add Friend
                  </Button>
                )
              ) : (
                <></>
              )}
            </Container>
          </div>
        </div>
      </div>

      {isMyProfile || isFriend ? (
        <div className="w-full h-full flex flex-row gap-x-[20px] gap-y-[20px] md:flex-row xxs:flex-col">
          <div className="basis-4/12 w-full flex flex-col justify-start gap-y-[20px]">
            <InfoCard>
              <div className="flex flex-row justify-center items-center gap-x-[20px]">
                <Link
                  to={
                    isMyProfile ? ROUTER.FRIENDS : ROUTER.USER_FRIENDS(userId)
                  }
                >
                  <div className="flex flex-col justify-center items-center gap-y-[2.5px]">
                    <span className="text-xl text-white font-bold">
                      {friendCount}
                    </span>
                    <span className="text-sm text-secondary font-medium">
                      Friends
                    </span>
                  </div>
                </Link>

                <div className="w-[0.5px] h-full bg-primaryMid mx-[30px]"></div>
                <div className="flex flex-col justify-center items-center gap-y-[2.5px]">
                  <span className="text-xl text-white font-bold">0</span>
                  <span className="text-sm text-secondary font-medium">
                    Followers
                  </span>
                </div>
              </div>
            </InfoCard>

            <InfoCard heading="About">
              {country && (
                <div className="flex flex-row justify-start items-center gap-x-[10px] font-medium">
                  <MapIcon size={20} className="text-secondary" />
                  <span>
                    {[country, state, city]?.filter((i) => i)?.join(", ")}
                  </span>
                </div>
              )}
            </InfoCard>

            {/* <InfoCard
              heading="Digital Portfolio"
              className="px-[0px] pb-[0px] md:flex xxs:hidden"
              headingClassName="px-[25px]"
            >
              <div className="flex flex-row flex-wrap">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i, key) => (
                  <div
                    key={key}
                    className={`basis-3/12 flex flex-row justify-center items-center h-[80px] bg-primaryMid border-[1px] border-primaryLight cursor-pointer transition-all hover:bg-gray-700`}
                  ></div>
                ))}
              </div>
            </InfoCard> */}

            <InfoCard
              heading="Gallery"
              className="px-[0px] pb-[0px] md:flex xxs:hidden"
              headingClassName="px-[25px]"
              renderOnRight={
                <Link
                  to={ROUTER.USER_GALLERY(userId)}
                  className="text-xs text-primary font-semibold"
                >
                  View All
                </Link>
              }
            >
              <div className="flex flex-row flex-wrap">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i, key) => (
                  <div
                    key={key}
                    className={`basis-3/12 flex flex-row justify-center items-center h-[80px] bg-primaryMid border-[1px] border-primaryLight cursor-pointer transition-all hover:bg-gray-700`}
                  ></div>
                ))}
              </div>
            </InfoCard>
          </div>

          <div className="basis-8/12">
            <PostList />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};
