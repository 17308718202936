export enum EducationType {
  Degree = 'degree',
  Certification = 'certification',
  Course = 'course',
}

export enum EducationDegree {
  NA = 'na',
  Secondary = 'secondary',
  Associates = 'associates',
  Bachelors = 'bachelors',
  Masters = 'masters',
  Doctorates = 'doctorates',
}
