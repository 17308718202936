import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Form,
  TextInput,
  StarRating,
  Switch,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useEmployee,
  useLoading,
  useToast,
  useURL,
} from "../../../hooks";
import { addLineBreaks } from "../../../utils";
import { appendCompanyComment, createCompanyComment } from "../../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

interface ICompanyCommentCreateForm {
  className?: string;
  inputClassName?: string;
  companyId?: string | null;
  jobApplicationId?: string;
  userId?: string;
  onCreate?: (value: any) => void;
  onClose?: (value?: any) => void;
}

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .required("Comment is a required field"),
  rating: Yup.number().notRequired().default(0),
  isPrivate: Yup.boolean().notRequired().default(false),
});

export const CompanyCommentCreateForm: React.FC<ICompanyCommentCreateForm> = ({
  className,
  inputClassName,
  jobApplicationId,
  userId,
  onCreate,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const { company } = useEmployee();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const companyId = company?.id;
  const createdById = profile?.id;

  const handleClose = () => {
    resetForm();

    if (onClose) onClose();
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      details: "",
      rating: 0,
      isPrivate: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!companyId || !createdById) return;

      const { details } = values;

      const data = {
        ...values,
        details: addLineBreaks(details),
        companyId,
        userId,
        jobApplicationId,
        createdById,
      };

      setLoading(true);

      const created = await dispatch(createCompanyComment(companyId, data));
      if (!created?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Note Not Added!",
        });
      }

      if (onCreate) onCreate(created);

      dispatch(appendCompanyComment(created));

      setLoading(false);
      resetForm();
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        id="details"
        type="text"
        variant="standard"
        placeholder="Writing a note.."
        className={`${inputClassName}`}
        controlClassName="basis-full"
        labelClassName="text-secondary"
        required
        multiline
        minRows={2}
        maxRows={8}
        value={values?.details}
        error={touched?.details ? errors?.details : null}
        onChange={handleChange}
      />

      <div className="mt-[8px] xxs:hidden md:flex">
        <StarRating
          value={values?.rating}
          onChange={(value) => setFieldValue("rating", value, true)}
        />
      </div>

      <div className="flex mt-[15px] gap-[20px] xxs:flex-col-reverse md:flex-row md:justify-start md:items-center">
        <div className="flex justify-end gap-[10px]">
          <Button
            type="submit"
            variant="outlined"
            loading={loading}
            color="primary"
            className="min-w-[125px] w-full"
          >
            Add Note
          </Button>
        </div>
        <div className="flex xxs:flex-row xxs:justify-between">
          <div className="xxs:flex md:hidden">
            <StarRating
              value={values?.rating}
              onChange={(value) => setFieldValue("rating", value, true)}
            />
          </div>
          <Switch
            leftLabel="Public"
            rightLabel="Private"
            value={values?.isPrivate}
            onChange={(value) => setFieldValue("isPrivate", value, true)}
          />
        </div>
      </div>
    </Form>
  );
};

CompanyCommentCreateForm.defaultProps = {
  inputClassName: "!bg-primaryDark !text-white",
};
