import React from "react";

interface ICardFooterProps {
  className?: string;
}

export const CardFooter: React.FC<ICardFooterProps> = ({
  children,
  className,
}) => (
  <div
    className={`${className} w-full h-auto min-h-[50px] px-[20px] box-border flex flex-row justify-center items-center border-primaryMid border-t`}
  >
    {children}
  </div>
);
