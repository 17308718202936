import React from "react";

import { v4 as uuid } from "uuid";

import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
} from "@mui/material";

import { MdExpandMore as ExpandMoreIcon } from "react-icons/md";

import { TextInput, InputError } from "../../components";

interface IMultipleSelectProps {
  className?: string;
  labelClassName?: string;
  controlClassName?: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  options?: { id: string | number; value: string }[];
  name?: string;
  defaultValue?: string | number | null;
  value?: string | number | null;
  error?: string | null;
  id?: string | null;
  label?: string | null;
  labelId?: string;
  required?: boolean;
  setValue?: (value: any) => void;
}

export const MultipleSelect: React.FC<IMultipleSelectProps> = ({
  className,
  controlClassName,
  placeholder,
  variant,
  options,
  name,
  defaultValue,
  value,
  error,
  setValue,
  label,
  labelClassName,
  required,
  ...props
}) => {
  const standardVariant = variant === "standard";

  const labelId = uuid();
  const labelHidden = !label || label?.length <= 0 || false;

  const inputProps = {
    className: standardVariant
      ? "w-full h-full flex flex-row justify-start items-center rounded-lg px-[10px] box-border"
      : "",
  };

  const handleChange = (e) => {
    if (setValue) setValue(e.target.value);
  };

  return (
    <FormControl variant={variant} className={`${controlClassName} w-full`}>
      {!labelHidden && (
        <InputLabel id={labelId} className="text-secondary" shrink={!!value}>
          {label}
        </InputLabel>
      )}

      <Select
        labelId={label ? labelId : ""}
        name={name}
        defaultValue={defaultValue}
        value={value}
        renderValue={
          value
            ? undefined
            : () => (
                <div
                  className={
                    labelClassName ? labelClassName : "text-base text-secondary"
                  }
                >
                  {placeholder}
                </div>
              )
        }
        className={`
            ${Boolean(error?.toString()?.length) ? "border border-danger" : ""}
            ${className ? className : "bg-primaryLight text-white"}
            w-full min-h-[40px] bg-primaryLight text-white rounded-lg
        `}
        inputProps={{ ...inputProps }}
        MenuProps={{
          PaperProps: {
            style: { width: "inherit", maxHeight: 280 },
            className: "text-white bg-primaryBlack",
          },
        }}
        IconComponent={({ className }) => (
          <ExpandMoreIcon
            size={20}
            className={`${className} flex h-[100%] top-0 right-[8px] text-secondary`}
          />
        )}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        // {...props}
      >
        {options?.map((option) => (
          <MenuItem key={option?.id} value={option?.id}>
            {option?.value}
          </MenuItem>
        ))}
      </Select>
      <InputError error={error} className="pt-[5px]" />
    </FormControl>
  );
};

MultipleSelect.defaultProps = {
  id: null,
  label: null,
  labelId: "",
  variant: "filled",
  placeholder: "",
  controlClassName: "",
  value: null,
  options: [],
};
