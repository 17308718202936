import React from "react";

interface IFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  loading?: boolean;
  autoComplete?: "on" | "off";
}

export const Form: React.FC<IFormProps> = ({
  method,
  children,
  className,
  loading,
  hidden,
  autoComplete,
  onSubmit,
}) => {
  const handleSubmit = (e, ...args) => {
    e.preventDefault();

    if (!onSubmit) return;

    onSubmit(e);
  };

  return (
    <form
      method={method}
      className={`${className} w-full`}
      {...{ hidden, autoComplete }}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};

Form.defaultProps = {
  method: "POST",
  loading: false,
  hidden: false,
  autoComplete: "off",
  onSubmit: () => {},
};
