import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { BusinessAuditDetailContainer } = lazyload(
  () => import("../../features")
);

export const BusinessAuditDetailPage = () => (
  <MainLayout>
    <BusinessAuditDetailContainer />
  </MainLayout>
);
