import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

import { Loader } from "../Loader/Loader";

interface IFallbackLoaderProps {
  className?: string;
}

export const FallbackLoader: React.FC<IFallbackLoaderProps> = ({
  className,
}) => (
  <div
    className={`${className} absolute w-screen h-screen flex flex-row justify-center items-center z-50 text-white bg-primaryDark text-primary`}
    style={{ overflow: "hidden" }}
  >
    <Loader className="text-inherit" />
  </div>
);

FallbackLoader.defaultProps = {
  className: "text-primary",
};
