import { createSlice } from "@reduxjs/toolkit";

import { ICompany } from "../../../shared";

import {
  appendToStore,
  AppReducerState,
  popFromStore,
  StoreManager,
} from "../../../store";

interface ICompanyState {
  companies: AppReducerState<ICompany[]>;
  company: AppReducerState<ICompany>;
  companyEmployees: AppReducerState<any[]>;
  companyActivities: AppReducerState<any[]>;
  companyComments: AppReducerState<any[]>;
  companyVerification: AppReducerState<any>;
  companyVerifications: AppReducerState<any[]>;
  companyAudit: AppReducerState<any>;
  companyAudits: AppReducerState<any[]>;
}

const initialState: ICompanyState = {
  companies: {
    isLoading: false,
    error: null,
    data: [],
  },
  company: {
    isLoading: false,
    error: null,
    data: {},
  },
  companyEmployees: {
    isLoading: false,
    error: null,
    results: 0,
    data: [],
  },
  companyActivities: {
    isLoading: false,
    error: null,
    results: 0,
    data: [],
  },
  companyComments: {
    isLoading: false,
    error: null,
    results: 0,
    data: [],
  },
  companyVerification: {
    isLoading: false,
    error: null,
    data: {},
  },
  companyVerifications: {
    isLoading: false,
    error: null,
    results: 0,
    data: [],
  },
  companyAudit: {
    isLoading: false,
    error: null,
    data: {},
  },
  companyAudits: {
    isLoading: false,
    error: null,
    results: 0,
    data: [],
  },
};

export const companySlice = createSlice({
  name: "Company",
  initialState,
  reducers: {
    appendCompanys: appendToStore("companies"),
    popCompanys: popFromStore("companies"),

    getCompaniesRequest: (state) => {
      state.companies.results = 0;
      state.companies.data = [];
      state.companies.isLoading = true;
    },
    getCompaniesSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.companies.isLoading = false;
      state.companies.data = data;
      state.companies.results = results;
    },
    getCompaniesError: (state, action) => {
      state.companies.error = action.payload;
      state.companies.isLoading = false;
    },

    getCompanyRequest: (state) => {
      state.company.isLoading = true;
    },
    getCompanySuccess: (state, action) => {
      state.company.isLoading = false;
      state.company.data = action.payload;
    },
    getCompanyError: (state, action) => {
      state.company.isLoading = false;
      state.company.error = action.payload;
    },

    createCompanyRequest: (state) => {
      state.company.isLoading = true;
    },
    createCompanySuccess: (state, action) => {
      state.company.isLoading = false;
    },
    createCompanyError: (state, action) => {
      state.company.isLoading = false;
      state.company.error = action.payload;
    },

    updateCompanyRequest: (state) => {
      state.company.isUpdating = true;
    },
    updateCompanySuccess: (state, action) => {
      state.company.isUpdating = false;
    },
    updateCompanyError: (state, action) => {
      state.company.isUpdating = false;
      state.company.error = action.payload;
    },

    removeCompanyRequest: (state) => {
      state.company.isLoading = true;
    },
    removeCompanySuccess: (state, action) => {
      state.company.data = {};
      state.company.isLoading = false;
    },
    removeCompanyError: (state, action) => {
      state.company.error = action.payload;
      state.company.isLoading = false;
    },

    registerCompanyRequest: () => {},
    registerCompanySuccess: (state, action) => {},
    registerCompanyError: (state, action) => {},

    getCompanyRegistrationsRequest: (state) => {
      state.companyVerifications.data = [];
      state.companyVerifications.isLoading = true;
    },
    getCompanyRegistrationsSuccess: (state, action) => {
      const { results, data } = action.payload;

      state.companyVerifications.results = results;
      state.companyVerifications.data = data;
      state.companyVerifications.isLoading = false;
    },
    getCompanyRegistrationsError: (state, action) => {
      state.companyVerifications.error = action.payload;
      state.companyVerifications.isLoading = false;
    },

    getCompanyRegistrationRequest: (state) => {
      state.companyVerification.data = null;
      state.companyVerification.isLoading = true;
    },
    getCompanyRegistrationSuccess: (state, action) => {
      state.companyVerification.data = action.payload;
      state.companyVerification.isLoading = false;
    },
    getCompanyRegistrationError: (state, action) => {
      state.companyVerification.error = action.payload;
      state.companyVerification.isLoading = false;
    },

    approveCompanyRegistrationRequest: (state) => {},
    approveCompanyRegistrationSuccess: (state, action) => {},
    approveCompanyRegistrationError: (state, action) => {},

    rejectCompanyRegistrationRequest: (state) => {},
    rejectCompanyRegistrationSuccess: (state, action) => {},
    rejectCompanyRegistrationError: (state, action) => {},

    getCompanyActivitiesRequest: (state) => {
      state.companyActivities.data = [];
      state.companyActivities.isLoading = true;
    },
    getCompanyActivitiesSuccess: (state, action) => {
      const { results, data } = action.payload;

      state.companyActivities.results = results;
      state.companyActivities.data = data;
      state.companyActivities.isLoading = false;
    },
    getCompanyActivitiesError: (state, action) => {
      state.companyActivities.error = action.payload;
      state.companyActivities.isLoading = false;
    },

    getCompanyCommentsByCompanyIdRequest: (state) => {
      state.companyComments.data = [];
      state.companyComments.isLoading = true;
    },
    getCompanyCommentsByCompanyIdSuccess: (state, action) => {
      const { results, data } = action.payload;

      state.companyComments.results = results;
      state.companyComments.data = data;
      state.companyComments.isLoading = false;
    },
    getCompanyCommentsByCompanyIdError: (state, action) => {
      state.companyComments.error = action.payload;
      state.companyComments.isLoading = false;
    },

    appendCompanyComment: (state, action) => {
      const data = state.companyComments.data || [];

      data.push(action.payload);

      state.companyComments.data = data;
    },

    insertCompanyComment: (state, action) => {
      const { id } = action.payload || {};

      const items = state.companyComments.data?.map(({ id }) => id);
      const index = items?.indexOf(id);

      state.companyComments.data[index] = action.payload;
    },

    pullCompanyComment: (state, action) => {
      const { id } = action.payload || {};

      const data = state.companyComments.data?.filter((i) => i?.id !== id);

      state.companyComments.data = data;
    },

    createCompanyCommentRequest: () => {},
    createCompanyCommentSuccess: (state, action) => {},
    createCompanyCommentError: (state, action) => {},

    updateCompanyCommentRequest: () => {},
    updateCompanyCommentSuccess: (state, action) => {},
    updateCompanyCommentError: (state, action) => {},

    removeCompanyCommentRequest: () => {},
    removeCompanyCommentSuccess: (state, action) => {},
    removeCompanyCommentError: (state, action) => {},

    inviteEmployeeRequest: () => {},
    inviteEmployeeSuccess: (state, action) => {},
    inviteEmployeeError: (state, action) => {},

    acceptCompanyEmployeeInviteRequest: () => {},
    acceptCompanyEmployeeInviteSuccess: (state, action) => {},
    acceptCompanyEmployeeInviteError: (state, action) => {},

    rejectCompanyEmployeeInviteRequest: () => {},
    rejectCompanyEmployeeInviteSuccess: (state, action) => {},
    rejectCompanyEmployeeInviteError: (state, action) => {},

    getCompanyEmployeesRequest: (state) => {
      state.companyEmployees.isLoading = true;
      state.companyEmployees.data = [];
      state.companyEmployees.results = 0;
    },
    getCompanyEmployeesSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.companyEmployees.data = data;
      state.companyEmployees.results = results;
      state.companyEmployees.isLoading = false;
    },
    getCompanyEmployeesError: (state, action) => {
      state.companyEmployees.error = action.payload;
      state.companyEmployees.isLoading = false;
    },

    getCompanyAuditsRequest: (state) => {
      state.companyAudits.isLoading = true;
      state.companyAudits.data = [];
      state.companyAudits.results = 0;
    },
    getCompanyAuditsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.companyAudits.data = data;
      state.companyAudits.results = results;
      state.companyAudits.isLoading = false;
    },
    getCompanyAuditsError: (state, action) => {
      const error = action.payload;

      state.companyAudits.error = error;
      state.companyAudits.isLoading = false;
    },

    getCompanyAuditRequest: (state) => {
      state.companyAudit.isLoading = true;
      state.companyAudit.data = null;
    },
    getCompanyAuditSuccess: (state, action) => {
      const data = action.payload;

      state.companyAudit.data = data;
      state.companyAudit.isLoading = false;
    },
    getCompanyAuditError: (state, action) => {
      const error = action.payload;

      state.companyAudit.error = error;
      state.companyAudit.isLoading = false;
    },
  },
});

export const companyReducer = companySlice.reducer;
