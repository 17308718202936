import React, { useEffect } from "react";
import { useParams } from "react-router";

import { FiUpload as UploadIcon } from "react-icons/fi";

import { Button, Container, Link, PageHeader } from "../../components";
import { ROUTER } from "../../routes";
import { useModal } from "../../hooks";
import { ModalType } from "../../shared";

export const UserGalleryContainer = () => {
  const { userId } = useParams();

  const { setModal } = useModal();

  const handleUploadClick = () => {
    setModal({ type: ModalType.GALLERY_UPLOAD });
  };

  // useEffect(() => {
  //   setModal({ type: ModalType.GALLERY_UPLOAD });
  // }, []);

  return (
    <Container>
      <PageHeader
        backTo={ROUTER.USER_DETAIL(userId)}
        renderOnRight={
          <Button
            color="secondary"
            icon={<UploadIcon />}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        }
      >
        Gallery
      </PageHeader>
      <Container>
        <div className="flex flex-row flex-wrap">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]?.map(
            (i, key) => (
              <div
                key={key}
                className={`
                  relative flex flex-row justify-center items-center bg-primaryMid border-[1px] border-primaryLight cursor-pointer transition-all hover:bg-gray-700
                  xxs:basis-full xxs:h-[180px] md:basis-3/12
              `}
              >
                <Link
                  to={ROUTER.USER_GALLERY_ITEM_DETAIL(userId, `${i}`)}
                  className="absolute w-full h-full"
                />
              </div>
            )
          )}
        </div>
      </Container>
    </Container>
  );
};
