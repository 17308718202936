import React from "react";

import {
  Avatar,
  BulletPointList,
  VerificationStatusBadge,
} from "../../components";
import { date, textformat } from "../../utils";

export const ExperienceListItem = ({
  id,
  role,
  company,
  details,
  verification,
  profileId,
  isWorking,
  isDraft,
  shorten,
  startedAt,
  finishedAt,
}) => {
  const isTimestamp = !!startedAt || !!finishedAt;

  return (
    <div className="flex flex-col justify-start">
      <div className="flex flex-row justify-start items-start gap-x-[10px] break-words">
        <Avatar size={30}>{company}</Avatar>
        <div
          className={`flex flex-col items-start justify-center gap-y-[5px] mt-[2px]`}
        >
          <div className="flex flex-row justify-start items-center gap-x-[10px]">
            <div className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] gap-y-[3px]">
              <h3 className="flex flex-row justify-start items-center flex-wrap gap-x-[5px] gap-y-[3px] text-sm text-white break-words font-semibold leading-none">
                <span>{role} at </span>
                <span className="text-primary">{company}</span>
              </h3>

              {isDraft ? (
                <span className="text-sm text-secondary">(Draft)</span>
              ) : (
                <VerificationStatusBadge
                  className="text-xs"
                  status={verification?.status}
                  experience={{ id, role, company, profileId }}
                />
              )}
            </div>
          </div>

          {isTimestamp && (
            <span className="text-xs font-medium text-secondary leading-none">
              {startedAt && finishedAt ? (
                `${date(startedAt).format("MMM YYYY")} - ${
                  isWorking ? "Present" : date(finishedAt).format("MMM YYYY")
                }`
              ) : (
                <>********</>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="text-xs text-secondary font-medium mt-[8px] ml-[40px] break-words">
        <p>{textformat.shorten(details, 75)}</p>
      </div>
    </div>
  );
};

ExperienceListItem.defaultProps = {
  shorten: false,
  isWorking: false,
  isVerified: false,
};
