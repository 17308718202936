import React from "react";

export const lazyload = <T extends {}, U extends keyof T>(
  loader: (x?: string) => Promise<T>
) =>
  new Proxy({} as unknown as T, {
    get: (target, componentName: string | symbol) => {
      if (typeof componentName === "string") {
        return React.lazy(() =>
          loader(componentName).then((x) => {
            console.log("Component: ", componentName);

            return {
              default: x[componentName as U] as any as React.ComponentType<any>,
            };
          })
        );
      }
    },
  });
