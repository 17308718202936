import React from "react";

interface IFormSectionProps {
  heading?: string | null;
  className?: string;
}

export const FormSection: React.FC<IFormSectionProps> = ({
  children,
  heading,
  className,
}) => (
  <div
    className={`${className} w-full flex flex-col justify-start items-start py-[15px]`}
  >
    {heading && (
      <h2 className="text-white text-base font-semibold mb-[10px]">
        {heading}
      </h2>
    )}
    <div className="w-full h-auto">{children}</div>
  </div>
);

FormSection.defaultProps = {
  heading: null,
  className: "",
};
