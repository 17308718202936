import { useEffect, useState } from "react";

import { closeModal, updateModal } from "../store";
import { useAppDispatch, useAppSelector } from "./useRedux";

interface IUseModalProps {
  type?: string;
}

export const useModal = (data?: IUseModalProps) => {
  const dispatch = useAppDispatch();

  const { modal } = useAppSelector((state) => state.app);

  const [isOpen, setOpen] = useState(false);

  const setModal = (state: any) => {
    dispatch(updateModal(state));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    setOpen(modal?.type === data?.type || false);
  }, [modal]);

  return { modal, isOpen, setModal, closeModal: handleClose };
};
