import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsChatDotsFill as ChatIcon } from "react-icons/bs";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarAccordion,
  Avatar,
  FormSection,
  RBAC,
  SidebarContent,
  IconButton,
  CloseIcon,
} from "../../../components";
import {
  CompanyCommentList,
  CompanyActivityList,
  CompanyCommentCreateForm,
  CompanyCommentLogWidget,
  CompanyActivityLogWidget,
} from "./../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useAuthor,
  useModal,
  useToast,
} from "../../../hooks";
import { date } from "../../../utils";
import {
  getCompanyActivities,
  getCompanyCommentsByCompanyId,
  getJobApplications,
  removeJob,
  updateJob,
} from "../../../store";
import { ROUTER } from "../../../routes";
import { ModalType, Role } from "../../../shared";
import { ISidebar } from "@types";

export const JobApplicationDetailRightSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [commentForm, setCommentForm] = useState(false);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    jobApplication: { data: jobApplication },
  } = useAppSelector((state) => state.jobApplication);

  const {
    companyComments: { results: companyCommentsCount },
  } = useAppSelector((state) => state.company);

  const { id: jobApplicationId, job, createdBy } = jobApplication || {};

  const companyId = job?.companyId;
  const userId = createdBy?.id;

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  return (
    <Sidebar {...props}>
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
        ]}
      >
        <SidebarContent
          className={`${commentForm ? "pb-[270px]" : "pb-[90px]"}`}
        >
          <RBAC roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}>
            <SidebarSection heading="Activity">
              <CompanyActivityLogWidget
                companyId={companyId}
                where={{ userId }}
              />
            </SidebarSection>
            <SidebarSection
              heading={`Company Notes ${
                companyCommentsCount && `(${companyCommentsCount})`
              }`}
            >
              <CompanyCommentLogWidget
                companyId={companyId}
                where={{ userId }}
              />
            </SidebarSection>
          </RBAC>
        </SidebarContent>

        <RBAC roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}>
          <SidebarSection
            heading={commentForm ? "Add Note" : null}
            navbar={
              commentForm ? (
                <IconButton
                  className="transition-all bg-primaryMid text-white box-border p-[5px] rounded-full cursor-pointer hover:bg-primaryBlack"
                  icon={CloseIcon}
                  iconProps={{ size: 12 }}
                  onClick={() => setCommentForm(false)}
                />
              ) : null
            }
            className="w-full absolute left-0 right-0 bottom-0 bg-primaryDark"
            headerClassName="mb-[0px]"
          >
            {commentForm ? (
              <FormSection>
                <CompanyCommentCreateForm
                  inputClassName="bg-primaryMid"
                  {...{ jobApplicationId, userId, companyId }}
                />
              </FormSection>
            ) : (
              <div className="flex flex-row justify-start items-center gap-x-[10px]">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setCommentForm(true)}
                >
                  Add Note
                </Button>
              </div>
            )}
          </SidebarSection>
        </RBAC>
      </RBAC>
    </Sidebar>
  );
};
