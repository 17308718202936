import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FiCheckCircle as CheckIcon } from "react-icons/fi";

import { ROUTER } from "../../../routes";
import {
  Checkbox,
  Form,
  TextInput,
  Button,
  FormSection,
  VerifiedBadgeIcon,
} from "../../../components";
import { useAppDispatch, useLoading, useToast } from "../../../hooks";
import { login, resetPassword } from "../../../store";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is incorrect")
    .required("Email is a required field"),
});

export const PasswordResetEmail = () => {
  const [loading, setLoading] = useLoading(false);

  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const { values, errors, touched, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const { email } = values;

        setLoading(true);

        const reset = await dispatch(resetPassword(values));
        if (!reset) {
          setFieldError("email", `User With Email ${email} Not Found!`);
          return setLoading(false);
        }

        setLoading(false);
        setSubmitted(true);
      },
    });

  return (
    <div className="w-full flex flex-col text-white">
      {submitted ? (
        <PasswordResetEmailSuccess />
      ) : (
        <>
          <div className="w-full flex flex-col items-center">
            <h1 className="text-2xl font-semibold mb-[20px]">Reset Password</h1>
            <span>
              Please specify your email, we'll send you the reset token.
            </span>
          </div>
          <FormSection>
            <Form onSubmit={handleSubmit} className="mt-[15px] text-black">
              <div className="w-full flex flex-col justify-start items-start gap-y-3">
                <div className="w-full flex flex-col gap-y-3">
                  <TextInput
                    id="email"
                    label="Email"
                    type="text"
                    variant="filled"
                    className="!bg-white !text-black"
                    value={values?.email}
                    error={touched?.email ? errors?.email : null}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full">
                  <Button
                    type="submit"
                    loading={loading}
                    color="primary"
                    variant="contained"
                    className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </Form>
          </FormSection>
        </>
      )}
    </div>
  );
};

export const PasswordResetEmailSuccess = () => (
  <div className="w-full flex flex-col items-center">
    <div className="flex flex-row justify-center items-center mb-[10px] h-[100px]">
      <CheckIcon size={72} className="text-success" />
    </div>
    <h1 className="text-2xl font-semibold mb-[20px]">
      We sent you the reset link.
    </h1>
    <span className="text-center">
      Please check your email. We've just sent you the reset link which you can
      use to set a new password for your account.
    </span>
    <div className="mt-[30px]">
      <Button
        type="submit"
        color="secondary"
        variant="outlined"
        className="min-w-[150px]"
        to={ROUTER.LOGIN}
      >
        Go to Login
      </Button>
    </div>
  </div>
);
