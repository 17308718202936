import React from "react";

import { useNavigate } from "react-router";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useModal, useToast } from "../../../hooks";
import { removeChat, removeJob } from "../../../store";

import { Button, Modal } from "../../../components";

export const ChatDeleteModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.CHAT_DELETE,
  });

  const { loading, props, redirectTo } = modal;
  const { chatId, onSuccess } = props || {};

  const handleDelete = async () => {
    setModal({ loading: { delete: true } });

    const chat = await dispatch(removeChat(chatId));
    if (!chat?.id) {
      setModal({ loading: { delete: false } });
      closeModal();
      return toast({
        status: NotificationStatus.Error,
        message: "Chat Not Deleted!",
      });
    }

    if (redirectTo) redirect(redirectTo);

    setModal({ loading: { delete: false } });
    closeModal();

    if (onSuccess) {
      onSuccess();
    }

    toast({
      status: NotificationStatus.Success,
      message: "Chat Deleted!",
    });
  };

  const handleClose = () => {
    console.log("Closing");
    closeModal();
    console.log("Closing");
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title={`Delete`}
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
      onClose={handleClose}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>Are you sure you want to delete chat?</p>
        </div>
        <div className="flex flex-row justify-between items-center gap-x-[10px] mt-5">
          <div>
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row gap-x-[10px]">
            <Button
              variant="outlined"
              color="danger"
              loading={loading?.delete}
              onClick={handleDelete}
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
