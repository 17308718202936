import React from "react";

interface ITagProps {
  className?: string;
  onDelete?: (tags: any) => void;
}

export const Tag: React.FC<ITagProps> = ({ className, children, onDelete }) => {
  const handleOnRemove = (e) => {
    e.stopPropagation();

    if (onDelete) onDelete(children);
  };

  return (
    <span
      className={`${className} flex flex-row justify-between items-center gap-x-[5px] h-[23px] text-secondary text-sm border-[0px]`}
    >
      <span>{children}</span>
      {onDelete && (
        <button
          type="button"
          className="flex flex-row justify-center items-center text-secondary font-medium text-sm overflow-hidden"
          onClick={handleOnRemove}
        >
          <span className="">&#10005;</span>
        </button>
      )}
    </span>
  );
};
