import * as React from "react";

import { Button } from "../../components";
import { ROUTER } from "../../routes";

export const NotFoundError = () => (
  <div className="w-full h-full flex justify-center items-center">
    <div className="w-full max-w-[300px] flex flex-col justify-center items-center">
      <div className="flex flex-col items-center text-secondary">
        <h1 className="text-xl font-semibold text-white">Page Not Found 😢</h1>
        <p className="mt-[10px] text-center">
          Oops, seems like the page you were looking for doesn't exist.
        </p>
      </div>
      <div className="w-full flex flex-col mt-[20px]">
        <Button to={ROUTER.HOME} color="secondary">
          Go to home page
        </Button>
      </div>
    </div>
  </div>
);
