import React from "react";

import { Dialog, DialogTitle } from "@mui/material";
import { Button } from "./../../components";

interface IModalTitleProps {
  className?: string;
  title?: string;
  description?: string;
}

export const ModalTitle: React.FC<IModalTitleProps> = ({
  title,
  description,
  className,
}) => (
  <div className="pb-[15px] flex flex-col gap-y-[5px] break-words">
    <h2
      className={`${className} text-base text-white font-semibold leading-none`}
    >
      {title}
    </h2>
    {description && (
      <span className="text-xs text-secondary font-medium">{description}</span>
    )}
  </div>
);

ModalTitle.defaultProps = {
  className: "",
  title: "Modal",
};
