import { createSlice } from "@reduxjs/toolkit";

import { IChat } from "../../../shared";
import { AppReducerState } from "../../store";

interface IChatState {
  chats: AppReducerState<IChat[]>;
  chat: AppReducerState<IChat>;
}

const initialState: IChatState = {
  chats: {
    data: [],
    results: 0,
    isLoading: false,
    error: null,
  },
  chat: {
    data: {},
    isLoading: false,
    error: null,
  },
};

export const chatSlice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setChats: (state, action) => {
      const { data, results } = action.payload;

      state.chats.data = data;
      state.chats.results = results;
    },

    setChat: (state, action) => {
      state.chat.data = action.payload;
    },

    getChatsRequest: (state) => {
      state.chats.isLoading = true;
      state.chats.results = 0;
      state.chats.data = [];
    },
    getChatsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.chats.isLoading = false;
      state.chats.results = results;
      state.chats.data = data;
    },
    getChatsError: (state, action) => {
      state.chats.isLoading = false;
      state.chats.results = 0;
      state.chats.data = [];
      state.chats.error = action.payload;
    },

    getChatRequest: (state) => {
      state.chat.isLoading = true;
      state.chat.data = {};
    },
    getChatSuccess: (state, action) => {
      state.chat.data = action.payload;
      state.chat.isLoading = false;
    },
    getChatError: (state, action) => {
      state.chat.data = {};
      state.chat.error = action.payload;
      state.chat.isLoading = false;
    },

    createChatRequest: (state) => {},
    createChatSuccess: (state, action) => {},
    createChatError: (state, action) => {},

    removeChatRequest: (state) => {},
    removeChatSuccess: (state, action) => {},
    removeChatError: (state, action) => {},

    sendChatMessage: () => {},
    sendChatMessageSuccess: (state, action) => {},
    sendChatMessageError: (state, action) => {},

    checkChatWithUserRequest: () => {},
    checkChatWithUserSuccess: () => {},
    checkChatWithUserError: () => {},

    startChatWithUserRequest: () => {},
    startChatWithUserSuccess: () => {},
    startChatWithUserError: () => {},
  },
});

export const chatReducer = chatSlice.reducer;
