import React from "react";

import { MainLayout } from "../../layouts";
import { JobEditSidebar } from "../../features";
import { lazyload } from "../../utils";

const { JobEditContainer } = lazyload(() => import("../../features"));

export const JobEditPage = () => (
  <MainLayout rightSidebar={JobEditSidebar}>
    <JobEditContainer />
  </MainLayout>
);
