import React, { useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { IconButton, Container, PageHeader } from "../../components";

import {
  ResumeCoverSection,
  ResumeEducationSection,
  ResumeExperienceSection,
} from "../../features";

import {
  getExperiences,
  getEducations,
  updateResumeState,
  getProfileCovers,
} from "../../store";

import { useAppDispatch, useAppSelector, useURL } from "../../hooks";

export const ResumeBuilderTemplateAll = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [URL, setURL] = useURL();

  const {
    resume: { data: resume },
  } = useAppSelector((state) => state.resume);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const { profileCovers } = useAppSelector((state) => state.profileCover);
  const { experiences } = useAppSelector((state) => state.experience);
  const { educations } = useAppSelector((state) => state.education);

  const isLoading =
    profileCovers?.isLoading || educations?.isLoading || experiences?.isLoading;

  const profile = account?.profile;
  const profileId = profile?.id;

  useEffect(() => {
    if (!profileId) return;

    console.log("rendering history?");

    dispatch(getProfileCovers({ where: { profileId, isArchived: true } }));
    dispatch(getExperiences({ where: { profileId, isArchived: true } }));
    dispatch(getEducations({ where: { profileId, isArchived: true } }));
  }, [profileId]);

  useEffect(() => {
    dispatch(updateResumeState(null));
  }, []);

  return (
    <Container loading={isLoading}>
      <PageHeader back={false}>Resume Builder / All Work History</PageHeader>

      <div className="flex flex-col gap-y-[20px]">
        {/*  @ts-ignore */}
        <ResumeCoverSection
          data={profileCovers?.data}
          loading={profileCovers?.isLoading}
        />

        {/*  @ts-ignore */}
        <ResumeExperienceSection
          data={experiences?.data}
          loading={experiences?.isLoading}
        />

        {/*  @ts-ignore */}
        <ResumeEducationSection
          data={educations?.data}
          loading={educations?.isLoading}
        />
      </div>

      <div className="flex flex-row justify-end items-center mt-[15px]"></div>
    </Container>
  );
};
