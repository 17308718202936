import React, { useState, useEffect } from "react";

import { useURL } from "../../hooks";

interface IPageSwitchProps {
  pages: {
    path: string;
    component: JSX.Element;
  }[];
  defaultPage?: {
    path: string;
    component: JSX.Element;
  };
}

export const PageSwitch: React.FC<IPageSwitchProps> = ({
  pages,
  defaultPage,
}) => {
  const [URL, setUrl] = useURL();

  const [page, setPage] = useState(defaultPage);

  useEffect(() => {
    const page = pages?.find(({ path }) => path === URL?.path) || defaultPage;

    setPage(page);
  }, [URL]);

  return page?.component || <></>;
};

PageSwitch.defaultProps = {};
