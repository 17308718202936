import React from "react";

import { useNavigate } from "react-router";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useLoading,
  useModal,
  useToast,
  useURL,
} from "../../../hooks";
import { ROUTER } from "../../../routes";
import { createVerificationRequest } from "../../../store";
import { Button, Modal } from "../../../components";

export const VerificationRequestCreateModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();

  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.VERIFICATION_REQUEST_CREATE,
  });

  const { props } = modal;
  const { id, experience, education, onVerify } = props || {};

  const handleVerify = async () => {
    const userId = experience
      ? experience?.profileId
      : education?.profileId || null;

    const data = {
      experienceId: experience?.id,
      educationId: education?.id,
      userId,
    };

    setLoading(true);

    // Creating Verification Request
    const verification = await dispatch(createVerificationRequest(data));
    if (!verification?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Verification Failed",
      });
      closeModal();
      return setLoading(false);
    }

    onVerify();
    closeModal();
    setLoading(false);
    toast({
      status: NotificationStatus.Success,
      message: "Verification Request Has Been Sent!",
    });
  };

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  const name = experience
    ? `${experience?.role} at ${experience?.company}`
    : education
    ? `${education?.degree} at ${education?.university}`
    : "";

  return (
    <Modal
      title="Verify"
      description="Please read below before taking action"
      open={isOpen}
      onClose={handleClose}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to verify{" "}
            {name ? <span className="text-white font-medium">{name}</span> : ""}
            ? Please confirm.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center gap-x-[10px] mt-[25px]">
          <div>
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row gap-x-[10px]">
            <Button
              variant="outlined"
              color="success"
              loading={loading}
              onClick={handleVerify}
            >
              Yes, Verify
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
