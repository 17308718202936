export enum JobType {
  OnSite = 'on-site',
  Remote = 'remote',
  Hybrid = 'hybrid',
  Travel10 = 'travel-10',
  Travel25 = 'travel-25',
  Travel50 = 'travel-50',
  Travel75 = 'travel-75',
}

export enum EmploymentType {
  FullTime = 'full-time',
  PartTime = 'part-time',
  Contractor = 'contractor',
  Temporary = 'temporary',
  Internship = 'internship',
}

export enum SkillLevel {
  Entry = 'entry',
  Junior = 'junior',
  Middle = 'middle',
  Senior = 'senior',
  Executive = 'executive',
}
