import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { FiClock as ClockIcon } from "react-icons/fi";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { removeChat, removeJob } from "../../../store";

import {
  BasicDatePicker,
  Button,
  Container,
  Form,
  FormSection,
  Modal,
  TextInput,
} from "../../../components";
import { date, FormValidator } from "../../../utils";

export const EventDetailModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.EVENT_DETAIL,
  });

  const [loading, setLoading] = useLoading();

  const { props, redirectTo } = modal;
  const { id, title, startedAt, onSuccess } = props || {};

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <Modal title={title || "Event"} open={isOpen} onClose={handleClose}>
      <Container loading={loading} className="text-secondary text-sm">
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat
            eget sed etiam tortor mattis sodales diam aliquam, elementum.
          </p>

          <div className="flex flex-row justify-start items-center text-sm font-medium mt-[20px] gap-x-[10px]">
            <ClockIcon size={22} className="text-secondary" />
            <span className="text-white">
              {date(startedAt).format("ddd, MMM DD")}{" "}
              <span className="text-warning">
                {date(startedAt).format("hh:mm A")}
              </span>
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-end items-center mt-[15px] gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <Button
            color="secondary"
            variant="outlined"
            className="min-w-[125px] md:w-auto xxs:w-full"
            onClick={handleCancel}
          >
            Edit
          </Button>
          {/* <Button
            color="success"
            variant="outlined"
            type="submit"
            className="min-w-[125px] md:w-auto xxs:w-full"
            loading={loading}
          >
            Create Event
          </Button> */}
        </div>
      </Container>
    </Modal>
  );
};
