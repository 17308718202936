import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Form, PinInput, Container } from "../../../components";
import { useAppDispatch, useLoading, useToast, useURL } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { confirmEmail, verifyEmail } from "../../../store";
import { useContext } from "react";
import { RegisterContext } from "../../../context";
import { FormValidator } from "../../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

interface IRegisterEmailConfirmForm {
  token?: string;
}

const validationSchema = Yup.object().shape({
  token: Yup.string().required("Token is a required field"),
});

export const RegisterEmailConfirm = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);
  const { email } = registerState;

  const [URL, setURL] = useURL();
  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [emailSentCount, setEmailSentCount] = useState(0);

  const form = useFormik<IRegisterEmailConfirmForm>({
    validationSchema,
    initialValues: {
      token: "",
    },
    onSubmit: async (values, { setFieldError }) => {
      const { email } = registerState;
      const { token } = values;

      setLoading(true);

      // Checking Confirmation Token
      const verification = await dispatch(confirmEmail({ email, token }));
      if (!verification) {
        setLoading(false);
        return setFieldError("token", "Token expired or invalid");
      }

      setLoading(false);
      setRegisterState({ ...registerState, isEmailVerified: true });
      setURL({ path: ROUTER.REGISTER_PASSWORD });
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleSubmit,
  } = form;

  const validator = new FormValidator(form);

  const handleResendToken = async () => {
    if (emailSentCount >= 2)
      return toast({
        status: NotificationStatus.Error,
        message: "You've already got 3 tokens! Please use them.",
      });
    if (!email)
      return toast({
        status: NotificationStatus.Error,
        message: "Invalid Email! Please change and try it again.",
      });

    const verification = await dispatch(verifyEmail(email));
    if (!verification) {
      return toast({
        status: NotificationStatus.Error,
        message: "Verification Token Not Sent!",
      });
    }

    toast({
      status: NotificationStatus.Success,
      message: "We sent you a new verification token!",
    });

    setEmailSentCount(emailSentCount + 1);
  };

  return (
    <Container className="w-full min-h-[250px] py-[50px] text-secondary">
      <div className="w-full text-center mb-[25px]">
        <h1 className="text-2xl font-semibold text-white mb-[18px]">
          Email Verification
        </h1>
        <div className="flex flex-row justify-center items-center text-lg font-medium">
          <span>
            We’ve just sent a verification token to{" "}
            <span className="text-primaryBlue font-semibold">{email}</span>
          </span>
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col justify-start items-start mx-auto max-w-[495px] gap-y-3">
          <PinInput
            type="numeric"
            className="!bg-white !text-black"
            length={6}
            value={values?.token}
            error={validator.isInputValid("token")}
            onChange={(value) => setFieldValue("token", value, false)}
            onComplete={() => handleSubmit()}
          />
          <div className="w-full flex flex-row justify-between items-center mt-[10px] mb-[18px]">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
              loading={loading}
            >
              Verify
            </Button>
          </div>
          <div className="w-full flex flex-row justify-center items-center">
            <span className="text-base font-medium">
              Didn't get the email? Click here to{" "}
              <span
                className="text-primaryBlue font-semibold cursor-pointer"
                onClick={handleResendToken}
              >
                resend
              </span>
            </span>
          </div>
        </div>
      </Form>
    </Container>
  );
};
