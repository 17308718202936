import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  FiUser as UserIcon,
  FiUsers as UsersIcon,
  FiCheckCircle as CheckIcon,
} from "react-icons/fi";

import {
  Button,
  Card,
  Avatar,
  VerificationStatusBadge,
  Link,
  ActionMenu,
  CardBody,
} from "../../components";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
  IJob,
  Action,
} from "../../shared";

import { ROUTER } from "../../routes";
import { date, textformat } from "../../utils";
import { useAppDispatch, useModal, useToast } from "../../hooks";
import { updateJob } from "../../store";

interface ICompanyJobCardProps extends IJob {
  actions?: string[];
}

export const CompanyJobCard: React.FC<ICompanyJobCardProps> = ({
  id,
  title,
  type,
  employmentType,
  requirements,
  description,
  country,
  city,
  timezone,
  positions,
  yearsOfExperience,
  skillLevel,
  minSalary,
  maxSalary,
  degree,
  applications,
  applicationCount,
  company,
  createdBy,
  createdAt,
  actions,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { setModal } = useModal();
  const { toast } = useToast();

  const [isDraft, setDraft] = useState(false);
  const [isDelisted, setDelisted] = useState(false);

  const handleEdit = (id?: string) => {
    if (!id) return;

    redirect(ROUTER.JOB_EDIT(id));
  };

  const handleDelete = (props) => {
    setModal({
      type: ModalType.JOB_DELETE,
      props,
    });
  };

  const handleList = async (id?: string) => {
    if (!id) return;

    const listed = await dispatch(updateJob(id, { isDelisted: false }));
    if (!listed) {
      return toast({
        status: NotificationStatus.Error,
        message: "Job Not Listed!",
      });
    }

    setDelisted(false);
    toast({
      status: NotificationStatus.Success,
      message: "Job Successfully Listed!",
    });
  };

  const handleDelist = async (id?: string) => {
    if (!id) return;

    const delisted = await dispatch(updateJob(id, { isDelisted: true }));
    if (!delisted) {
      return toast({
        status: NotificationStatus.Error,
        message: "Job Not Delisted!",
      });
    }

    setDelisted(true);
    toast({
      status: NotificationStatus.Success,
      message: "Job Successfully Delisted!",
    });
  };

  useEffect(() => {
    setDraft(props?.isDraft || false);
  }, [props?.isDraft]);

  useEffect(() => {
    setDelisted(props?.isDelisted || false);
  }, [props?.isDelisted]);

  return (
    <Card>
      <CardBody>
        <div className="w-full flex flex-row justify-between items-start">
          <div className="w-full flex flex-col">
            <div className="flex flex-row justify-start items-start gap-x-[10px]">
              <Avatar
                to={
                  company?.alias
                    ? ROUTER.COMPANY_DETAIL_ALIAS(company?.alias)
                    : ROUTER.COMPANY_DETAIL(company?.id)
                }
                size={40}
              >
                {company?.profile?.name}
              </Avatar>
              <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                <div className="flex flex-row justify-start items-center gap-x-[5px]">
                  <Link to={ROUTER.JOB_DETAIL(id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {title}
                    </h3>
                  </Link>
                  {isDraft && (
                    <span className="text-secondary text-sm font-medium">
                      (Draft)
                    </span>
                  )}
                  {isDelisted && (
                    <span className="text-warning text-sm font-medium">
                      (Delisted)
                    </span>
                  )}
                </div>
                <span className="text-xs leading-none">
                  {date(createdAt).fromNow()}
                </span>
              </div>
            </div>
          </div>
          <ActionMenu
            actions={[
              Action.Edit,
              Action.Delete,
              isDelisted ? Action.List : Action.Delist,
            ]}
            {...{
              onEdit: () => handleEdit(id),
              onDelete: () => handleDelete(id),
              onList: () => handleList(id),
              onDelist: () => handleDelist(id),
            }}
          />
        </div>
        <div className="mt-[20px] text-base text-white font-light overflow-hidden">
          <p>{textformat.shorten(description, 180)}</p>
        </div>
        <div className="flex flex-row justify-start gap-x-[20px] gap-y-[10px] mt-[20px] font-medium md:flex-row xxs:flex-col">
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <UserIcon size={18} className="-mt-[3px]" />
            <span>
              Posted By:{" "}
              <Link
                to="#"
                className="text-primaryBlue border-b border-primaryBlue"
              >
                {createdBy?.firstName}
              </Link>
            </span>
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <UsersIcon size={18} className="-mt-[3px]" />
            <span>
              Applicants:{" "}
              <Link
                to={ROUTER.JOB_APPLICATION_LIST(id)}
                className="text-primaryGreen border-b border-primaryGreen"
              >
                {applicationCount || 0}
              </Link>
            </span>
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <CheckIcon size={18} className="-mt-[3px]" />
            <span>
              Available Positions:{" "}
              <Link
                to="#"
                className="text-primaryYellow border-b border-primaryYellow"
              >
                {positions}
              </Link>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

CompanyJobCard.defaultProps = {
  title: "",
  requirements: "",
  description: "",
  country: "",
  city: "",
  timezone: "",
  positions: 0,
  yearsOfExperience: 0,
  minSalary: 0,
  maxSalary: 0,
};
