import React from "react";

import { ResumeCoverSelectSection, ResumeCoverSectionDetail } from "../../..";

export const ResumeCoverSection = ({ loading, editing, data }) => {
  return editing ? (
    // @ts-ignore
    <ResumeCoverSelectSection />
  ) : (
    <ResumeCoverSectionDetail loading={loading} data={data} />
  );
};

ResumeCoverSection.defaultProps = {};
