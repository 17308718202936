import React from "react";

import { FiClock as ClockIcon } from "react-icons/fi";

import { IProfile } from "../../shared";

import { Avatar } from "../../components";
import { date } from "../../utils";
import { ROUTER } from "../../routes";

interface IChatMessageProps {
  ref?: React.Ref<any>;
  className?: string;
  id?: string;
  text?: string;
  authorId?: string;
  author?: IProfile;
  isLatest?: boolean;
  isIncoming?: boolean;
  createdAt?: Date;
}

export const ChatMessage: React.FC<IChatMessageProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLDivElement>) => {
    const {
      className,
      id,
      text,
      authorId,
      author,
      createdAt,
      isLatest,
      isIncoming,
    } = props;

    const timestamp = date(createdAt).isToday()
      ? date(createdAt).format("hh:mm A")
      : date(createdAt).format("DD/MM hh:mm A");

    return (
      <div
        ref={ref}
        data-id={id}
        className={`${className} ${
          isIncoming ? "justify-start" : "justify-end"
        } relative w-full flex flex-row items-center`}
      >
        <div
          className={`${
            isIncoming ? "justify-start" : "justify-end"
          } flex flex-row items-start basis-6/12 gap-x-[10px]`}
        >
          {isIncoming && (
            <div>
              <Avatar
                size={32}
                src={author?.avatar}
                to={ROUTER.USER_DETAIL(author?.id)}
              >
                {author?.firstName}
              </Avatar>
            </div>
          )}
          <div
            className={`
              ${isIncoming ? "items-start" : "items-end"}
              flex flex-col gap-y-[5px]
            `}
          >
            {createdAt ? (
              <div className="flex flex-row justify-start items-center">
                <span className="text-secondary text-xxs">{timestamp}</span>
              </div>
            ) : (
              <div className="flex flex-row justify-start items-center gap-x-[2px] text-secondary text-xxs">
                <ClockIcon size={6} />
                <span>Pending</span>
              </div>
            )}
            <div
              className={`
              ${isLatest ? "mb-[20px]" : ""} 
              ${isIncoming ? "bg-primaryLight" : "bg-primaryMid"}
              w-full min-w-[75px] rounded-lg text-white text-sm font-medium box-border p-[12px]`}
            >
              <p className="break-all">{text}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ChatMessage.defaultProps = {
  isIncoming: true,
};
