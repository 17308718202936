import React, { Suspense } from "react";

import { ModalType } from "../../shared";

import { useAppSelector } from "../../hooks";
import { lazyload } from "../../utils";

const {
  JobApplicantionMessageModal,
  ResumeCoverDeleteModal,
  ResumeEducationDeleteModal,
  ResumeExperienceDeleteModal,
  ResumeDeleteModal,
  JobApplyModal,
  JobDeleteModal,
  JobWithdrawModal,
  VerificationRequestCreateModal,
  InviteEmployeeModal,
  ChatDeleteModal,
  ChatMessageModal,
  EventCreateModal,
  EventDetailModal,
  GalleryUploadModal,
  TicketCreateModal,
} = lazyload(() => import("./../../features"));

export const ModalProvider: React.FC = () => {
  const { modal } = useAppSelector((state) => state.app);

  const modals = [
    // Company
    {
      type: ModalType.COMPANY_EMPLOYEE_INVITE,
      component: <InviteEmployeeModal />,
    },

    // Resume
    {
      type: ModalType.RESUME_TEMPLATE_DELETE,
      component: <ResumeDeleteModal />,
    },
    {
      type: ModalType.RESUME_COVER_DELETE,
      component: <ResumeCoverDeleteModal />,
    },
    {
      type: ModalType.RESUME_EDUCATION_DELETE,
      component: <ResumeEducationDeleteModal />,
    },
    {
      type: ModalType.RESUME_EXPERIENCE_DELETE,
      component: <ResumeExperienceDeleteModal />,
    },

    // Job
    {
      type: ModalType.JOB_APPLY,
      component: <JobApplyModal />,
    },
    {
      type: ModalType.JOB_WITHDRAW,
      component: <JobWithdrawModal />,
    },
    {
      type: ModalType.JOB_DELETE,
      component: <JobDeleteModal />,
    },
    {
      type: ModalType.JOB_APPLICATION_MESSAGE,
      component: <JobApplicantionMessageModal />,
    },

    // Verification
    {
      type: ModalType.VERIFICATION_REQUEST_CREATE,
      component: <VerificationRequestCreateModal />,
    },

    // Chat
    {
      type: ModalType.CHAT_MESSAGE,
      component: <ChatMessageModal />,
    },
    {
      type: ModalType.CHAT_DELETE,
      component: <ChatDeleteModal />,
    },

    // Event
    {
      type: ModalType.EVENT_DETAIL,
      component: <EventDetailModal />,
    },
    {
      type: ModalType.EVENT_CREATE,
      component: <EventCreateModal />,
    },

    // Gallery
    {
      type: ModalType.GALLERY_UPLOAD,
      component: <GalleryUploadModal />,
    },

    // Ticket
    {
      type: ModalType.TICKET_CREATE,
      component: <TicketCreateModal />,
    },
  ];

  const render = () => {
    const element = modals.find(({ type }) => modal.type === type);

    if (!element) return <></>;

    console.log({ element, test: Date.now() });

    return <Suspense fallback={<></>}>{element.component}</Suspense>;
  };

  return render();
};
