import React, { useEffect, useState } from "react";

import {
  Avatar,
  VerificationStatusBadge,
  ActionMenu,
  BulletPointList,
} from "../../../../components";
import { Action, Status } from "../../../../shared";
import { date } from "../../../../utils";

export const ResumeExperienceCard = ({
  id,
  company,
  role,
  details,
  verification,
  actions,
  startedAt,
  finishedAt,
  isWorking,
  isDraft,
  profileId,
  onEdit,
  onDelete,
}) => {
  const [isBlocked, setBlocked] = useState(false);

  const isTimestamp = !!startedAt || !!finishedAt;

  const handleVerify = () => {
    setBlocked(true);
  };

  useEffect(() => {
    setBlocked(
      !!verification?.status && verification?.status !== Status.Rejected
    );
  }, [verification]);

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-row justify-start items-start gap-x-[10px] break-words">
          <Avatar size={40}>{company}</Avatar>
          <div className="h-auto flex flex-col items-start justify-center gap-y-[3px] mt-[5px]">
            <div className="flex flex-row justify-start items-center gap-x-[10px]">
              <h3 className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] text-base text-white font-semibold leading-none">
                <div>
                  <span className="break-words">{role} at </span>
                  <span className="text-primary">{company}</span>
                </div>
                {isDraft && (
                  <span className="text-sm text-secondary">(Draft)</span>
                )}
                {!isDraft && (
                  <VerificationStatusBadge
                    status={verification?.status}
                    experience={{ id, role, company, profileId }}
                    onVerify={handleVerify}
                  />
                )}
              </h3>
            </div>

            <span className="text-sm font-medium text-secondary leading-none">
              {isTimestamp ? (
                `${date(startedAt).format("MMM YYYY")} - ${
                  isWorking ? "Present" : date(finishedAt).format("MMM YYYY")
                }`
              ) : (
                <>********</>
              )}
            </span>
          </div>
        </div>
        {!isBlocked && (
          <ActionMenu
            actions={[Action.Edit, Action.Delete]}
            {...{ onEdit, onDelete }}
          />
        )}
      </div>
      <div className="mt-[15px] overflow-hidden break-words text-secondary text-base font-medium">
        <BulletPointList data={details} className="md:ml-[50px] xxs:mx-[0px]" />
      </div>
    </div>
  );
};

ResumeExperienceCard.defaultProps = {
  company: "",
  role: "",
  isDraft: false,
  isVerified: false,
  startedAt: new Date(),
  finishedAt: new Date(),
};
