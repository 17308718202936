import React, { useEffect, useReducer, useState } from "react";

import { PageSwitch } from "../../components";
import {
  ResumeBuilderTemplateAll,
  ResumeDetail,
  ResumeBuilderTemplateEdit,
} from "./../../features";

import { ROUTER } from "../../routes";

import {
  appendResumes,
  getDefaultResume,
  getResumes,
  setURL,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useScreen,
  useURL,
} from "../../hooks";
import { DrawerType } from "../../shared";

export const ResumeBuilder = () => {
  const dispatch = useAppDispatch();

  const [URL, setURL] = useURL();
  const { setDrawer, resetDrawer } = useDrawer();
  const { breakpoint, width } = useScreen();

  const {
    account: { data: account },
  } = useAppSelector((state) => state?.account);

  const {
    resumes: { data: resumes },
  } = useAppSelector((state) => state?.resume);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = profile?.resumeId;

  const pages = [
    {
      path: ROUTER.RESUME_BUILDER_TEMPLATE_ALL,
      component: <ResumeBuilderTemplateAll />,
    },
    {
      path: ROUTER.RESUME_BUILDER_TEMPLATE_DETAIL(),
      component: <ResumeDetail />,
    },
    {
      path: ROUTER.RESUME_BUILDER_TEMPLATE_EDIT(),
      component: <ResumeBuilderTemplateEdit />,
    },
  ];

  useEffect(() => {
    if (!profileId) return;

    dispatch(getResumes({ where: { isApplied: false, profileId } }));
  }, [profileId]);

  useEffect(() => {
    if (!resumeId) return;

    setURL({
      path: ROUTER.RESUME_BUILDER_TEMPLATE_DETAIL(),
      params: { id: resumeId },
    });
  }, [resumeId]);

  useEffect(() => {
    setDrawer({ type: DrawerType.RESUME_BUILDER, open: false });

    return () => resetDrawer();
  }, []);

  return <PageSwitch pages={pages} />;
};

export default ResumeBuilder;
