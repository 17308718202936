import {} from "react-redux";
import { useAppSelector } from "./useRedux";

export const useProfile = () => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    notifications: { data: notifications },
  } = useAppSelector((state) => state.notification);

  const profile = account?.profile;

  const notificationCount =
    notifications?.filter((i) => !i.isViewed)?.length || 0;

  const hasNotifications =
    notifications?.filter((i) => !i.isViewed)?.length >= 1;

  const chatMessageCount = 0;

  return { profile, hasNotifications, notificationCount, chatMessageCount };
};
