import React, { useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Form, TextInput, Container } from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../../hooks";
import { ROUTER } from "../../../routes";
import { RegisterContext } from "../../../context";
import { register } from "../../../store";
import { FormValidator } from "../../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

interface IRegisterPasswordConfirmationForm {
  password?: string;
  passwordConfirmation?: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must consist of 8 characters minimum")
    .trim()
    .required("Password is a required field"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .trim()
    .required("Password Repeat is a required field"),
});

export const RegisterComplete = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);
  const { account } = useAppSelector((state) => state.account);

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const accountId = account?.data?.id;

  const form = useFormik<IRegisterPasswordConfirmationForm>({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const data = {
        ...registerState,
        ...values,
      };

      // Creating Account
      const account = await dispatch(register(data));
      if (!account?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Account Not Created!",
        });
      }

      redirect(ROUTER.HOME);

      setLoading(false);
      toast({
        status: NotificationStatus.Success,
        icon: "🎉",
        title: "Welcome!",
        message: "You've just created the account",
      });
    },
  });

  const { values, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  useEffect(() => {
    if (accountId) redirect(ROUTER.HOME);
  }, [accountId]);

  return (
    <Container className="w-full min-h-[250px] py-[50px]">
      <div className="w-full text-center mb-[50px]">
        <h1 className="text-2xl font-semibold">Set your Password</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col justify-start items-start gap-y-3 text-black">
          <TextInput
            id="password"
            label="Password"
            type="password"
            variant="filled"
            className="!bg-white !text-black"
            value={values?.password}
            error={validator.isInputValid("password")}
            onChange={handleChange}
          />
          <TextInput
            id="passwordConfirmation"
            label="Repeat Password"
            type="password"
            variant="filled"
            className="!bg-white !text-black"
            value={values?.passwordConfirmation}
            error={validator.isInputValid("passwordConfirmation")}
            onChange={handleChange}
          />
          <div className="w-full flex flex-row justify-between items-center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
              loading={loading}
            >
              Proceed to Profile
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};
