import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useFormik } from "formik";

import {
  CardList,
  Container,
  Form,
  PageHeader,
  Searchbar,
} from "../../components";
import { CompanyCard, UserCard } from "..";
import { useAppDispatch, useAppSelector, useLoading } from "../../hooks";
import { getCompanies, getProfiles } from "../../store";

interface ISearchForm {
  search?: string | null;
}

export const SearchContainer = () => {
  const dispatch = useAppDispatch();

  const {
    profiles: {
      data: profiles,
      isLoading: profilesLoading,
      results: profilesCount,
    },
  } = useAppSelector((state) => state.profile);

  const {
    companies: {
      data: companies,
      isLoading: companiesLoading,
      results: companiesCount,
    },
  } = useAppSelector((state) => state.company);

  const [search, setSearch] = useState<string | null>(null);
  const [results, setResults] = useState<any[]>([]);
  const resultsCount = (profilesCount || 0) + (companiesCount || 0);

  const [loading, setLoading] = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get("q");

  const isEmpty = (q: string | null): boolean => !q?.trim()?.length;

  const form = useFormik<ISearchForm>({
    initialValues: {},
    onSubmit: (values) => {
      const q = values?.search?.trimStart().trimEnd() || "";

      // if (isEmpty(q)) return;

      setSearchParams({ q });
    },
  });

  const { values, errors, setFieldValue, handleChange, handleSubmit } = form;

  const sortByCreatedAt = (a, b) =>
    new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime();

  const handleFetch = async (query: string) => {
    setLoading(true);

    const profiles = await dispatch(
      getProfiles({
        where: {
          isBusiness: false,
          isLegitbook: false,
          $or: [
            { lastName: { $like: `%${query}%` } },
            { firstName: { $like: `%${query}%` } },

            {
              firstName: {
                $or: query
                  ?.split(" ")
                  .map((query) => ({ $like: `%${query}%` })),
              },
            },
            {
              lastName: {
                $or: query
                  ?.split(" ")
                  .map((query) => ({ $like: `%${query}%` })),
              },
            },
          ],
        },
      })
    );

    const companies = await dispatch(
      getCompanies({
        where: {
          $companyProfile$: {
            $or: [
              {
                name: { $like: `%${query}%` },
              },
              {
                name: {
                  $or: query
                    ?.split(" ")
                    .map((query) => ({ $like: `%${query}%` })),
                },
              },
            ],
          },
        },
      })
    );

    setResults([
      ...(profiles?.map((i) => ({ ...i, type: "user" })) || []),
      ...(companies?.map((i) => ({ ...i, type: "company" })) || []),
    ]);

    setLoading(false);
  };

  useEffect(() => {
    if (!search) return;

    handleFetch(search);
    setFieldValue("search", search);
  }, [search]);

  useEffect(() => {
    if (!query) return;

    setSearch(query);
  }, [query]);

  return (
    <Container>
      <div className="flex flex-row justify-between items-center">
        <PageHeader back={false}>Search</PageHeader>
        <span className="text-base text-secondary font-medium">
          {loading ? "Searching.." : `Results: ${resultsCount || 0}`}
        </span>
      </div>

      <div>
        <Form onSubmit={handleSubmit}>
          <Searchbar
            placeholder="Type something to find.."
            name="search"
            value={values?.search}
            error={errors?.search}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Form>
      </div>

      <Container
        loading={loading}
        loadingClassName="mt-[25px]"
        className="mt-[25px]"
      >
        <CardList className="">
          {results?.sort(sortByCreatedAt)?.map((result) => {
            switch (result?.type) {
              case "user":
                return <UserCard key={result?.id} {...result} />;
              case "company":
                return <CompanyCard key={result?.id} {...result} />;
              default:
                return <></>;
            }
          })}
        </CardList>
      </Container>
    </Container>
  );
};
