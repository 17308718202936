import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { IURLSwitch, useURL } from "../../hooks";

interface ILinkProps {
  className?: string;
  to?: string | null;
  href?: string;
  switchURL?: IURLSwitch;
}

export const Link: React.FC<ILinkProps> = ({
  children,
  className,
  to,
  href,
  switchURL,
  ...props
}) => {
  const redirect = useNavigate();
  const location = useLocation();

  const [URL, setURL] = useURL();

  const { pathname } = location;
  const isSwitch = Object.keys(switchURL || {})?.length >= 1 || false;

  const handleSwitch = () => {
    if (!switchURL) return;

    setURL(switchURL);
  };

  return isSwitch ? (
    <div className={className} onClick={handleSwitch}>
      {children}
    </div>
  ) : to ? (
    <NavLink
      to={to}
      state={{ previous: pathname }}
      className={`${className} leading-none`}
      {...props}
    >
      {children}
    </NavLink>
  ) : (
    <a className={className} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

Link.defaultProps = {
  className: "",
  to: null,
  switchURL: {},
};
