import React from "react";

import { Card } from "./../../components";

interface IInfoCardProps {
  heading?: string | null;
  className?: string;
  headingClassName?: string;
  contentClassName?: string;
  renderOnRight?: JSX.Element;
}

export const InfoCard: React.FC<IInfoCardProps> = ({
  children,
  heading,
  className,
  headingClassName,
  contentClassName,
  renderOnRight,
}) => (
  <Card
    className={`${className} w-full flex flex-col justify-start bg-primaryLight border-[0px] rounded-lg overflow-hidden box-border p-[25px]`}
  >
    {heading && (
      <div
        className={`${headingClassName} flex flex-row justify-between items-center`}
      >
        <div className="text-white text-md font-semibold">{heading}</div>
        {renderOnRight && <div>{renderOnRight}</div>}
      </div>
    )}

    <div
      className={`${contentClassName} ${
        heading ? "mt-[20px]" : ""
      } flex flex-col gap-y-[20px] text-sm text-white`}
    >
      {children}
    </div>
  </Card>
);

InfoCard.defaultProps = {
  heading: null,
  className: "",
  headingClassName: "",
  contentClassName: "",
};
