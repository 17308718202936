import React from "react";

interface ICardProps {
  className?: string;
}

export const Card: React.FC<ICardProps> = ({ children, className }) => (
  <div
    className={`${className} w-full h-auto flex flex-col font-secondary bg-primaryLight text-secondary rounded-lg overflow-hidden`}
  >
    {children}
  </div>
);

Card.defaultProps = { className: "" };
