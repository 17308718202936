import React, { useEffect, useState } from "react";

import { HiMenuAlt1 as MenuIcon } from "react-icons/hi";

import { Container } from "../../../components";
import { ISidebar } from "@types";

export interface ISidebarProps extends ISidebar {}

export const Sidebar: React.FC<ISidebarProps> = ({
  children,
  className,
  loading,
  position,
  expanded: isExpanded,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`
      ${
        isExpanded
          ? expanded
            ? `xl:!w-[260px] 2xl:!w-[350px]`
            : `xl:!w-[260px] 2xl:!w-[260px]`
          : className
      }
      ${className}
      transition-all relative w-auto h-screen flex flex-col box-border-[1px]
      `}
    >
      {loading ? (
        <Container
          loading={loading}
          className="w-full h-full px-[20px] py-[20px]"
        ></Container>
      ) : (
        <div className="relative w-full h-full">
          {isExpanded && (
            <div
              className={`absolute top-[95px] w-[30px] h-[30px] flex flex-row justify-center items-center bg-primaryLight text-secondary cursor-pointer rounded-full z-30 border-[1px] border-primaryMid transition-all hover:bg-primaryMid ${
                position === "left" ? "-right-[15px]" : "-left-[15px]"
              }`}
              onClick={handleExpand}
            >
              <MenuIcon size={18} />
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

Sidebar.defaultProps = {
  position: "left",
  className: "",
  loading: false,
  expanded: false,
};
