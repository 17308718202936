import { FormikErrors } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { FiUpload as UploadIcon, FiX as CloseIcon } from "react-icons/fi";

import { IconButton, InputError } from "../../components";
import { HTMLFileTypes } from "../../shared";

interface IFilePickerProps {
  className?: string;
  id?: string;
  name?: string;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  extensions?: HTMLFileTypes[];
  onChange?: (file?: File | null) => void;
}

export const FilePicker: React.FC<IFilePickerProps> = ({
  className,
  id,
  name,
  error,
  extensions,
  onChange,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const filePickerRef = useRef<HTMLInputElement>(null);

  const styles = file ? "" : "cursor-pointer hover:brightness-110";

  const accept = extensions?.join(",") || "";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];

    if (!file) return;

    setFile(file);
  };

  const handleBrowse = () => {
    if (!filePickerRef.current) return;

    filePickerRef.current.value = "";
    filePickerRef.current?.click();
  };

  const handleReset = () => {
    setFile(null);
  };

  useEffect(() => {
    if (onChange) {
      onChange(file);
    }
  }, [file]);

  return (
    <div>
      <input
        id={id}
        name={name}
        type="file"
        accept={accept}
        ref={filePickerRef}
        onChange={handleChange}
        hidden
      />
      <div
        className={`
          ${className} ${styles}
          ${Boolean(error?.toString()?.length) ? "border border-danger" : ""}
          transition-all w-auto h-[45px] flex flex-row justify-between items-center bg-primaryLight text-secondary text-sm font-medium rounded-lg box-border px-[15px]
        `}
        onClick={file ? () => {} : handleBrowse}
      >
        <span>
          {file
            ? `${file?.name} (${(file?.size / 1024)?.toFixed(0)} KB)`
            : "Browse files"}
        </span>
        {file ? (
          <IconButton
            icon={CloseIcon}
            iconProps={{ size: 18 }}
            onClick={handleReset}
          />
        ) : (
          <IconButton
            icon={UploadIcon}
            iconProps={{ size: 18 }}
            onClick={handleBrowse}
          />
        )}
      </div>
      <InputError error={error?.toString()} className="mt-[5px]" />
    </div>
  );
};

FilePicker.defaultProps = {
  id: "",
  name: "",
  className: "",
  extensions: [HTMLFileTypes.jpg, HTMLFileTypes.png],
};
