import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { CardList, Container, PageHeader } from "../../components";
import { PostList, PostCreateForm } from "..";

import {
  closeModal,
  getJobs,
  getPosts,
  removeJob,
  updateJob,
  postSlice,
  getPostFeed,
} from "../../store";
import { Action, DrawerType, ModalType } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useModal,
  useToast,
} from "../../hooks";
import { ROUTER } from "../../routes";
import { date } from "../../utils";

export const UserHomeContainer = () => {
  const dispatch = useAppDispatch();

  const { setDrawer, resetDrawer } = useDrawer();

  const {
    posts: { isLoading: postLoading },
  } = useAppSelector((state) => state.post);

  useEffect(() => {
    dispatch(getPostFeed());

    setDrawer({ type: DrawerType.HOME, open: false });

    return () => resetDrawer();
  }, []);

  return (
    <Container loading={postLoading}>
      <PageHeader back={false}>Home</PageHeader>
      <PostCreateForm />

      <div className="mt-[15px]">
        <PostList />
      </div>
    </Container>
  );
};
