import React from "react";

interface IAvatarStatusProps {
  status: "success" | "warning" | "danger";
}

export const AvatarStatus: React.FC<IAvatarStatusProps> = ({ status }) => {
  let className;

  switch (status) {
    case "success":
      className = "bg-success";
      break;
    case "danger":
      className = "bg-danger";
      break;
    case "warning":
      className = "bg-warning";
      break;
    default:
      className = "bg-secondary";
      break;
  }

  return (
    <div
      className={`${className} rounded-full w-[10px] h-[10px] border-[1px] border-gray-800`}
    ></div>
  );
};
