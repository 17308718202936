import React from "react";

interface ICardBodyProps {
  className?: string;
}

export const CardBody: React.FC<ICardBodyProps> = ({ children, className }) => (
  <div className={`${className} px-[20px] py-[20px]`}>{children}</div>
);

CardBody.defaultProps = { className: "" };
