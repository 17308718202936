import { IApiError } from "../../../shared";

import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { auditSlice } from "./audit.slice";

export const getAudits = (query?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(auditSlice.actions.getAuditsRequest());

    const response = await ApiService.post("audits/query", query);
    const { results, data } = response.data;

    dispatch(
      auditSlice.actions.getAuditsSuccess({
        results,
        data,
      })
    );

    return data;
  } catch (e: any) {
    const error: IApiError = e.response.data;

    dispatch(auditSlice.actions.getAuditsError(error));

    return { error };
  }
};

export const getAuditsByCompanyId =
  (companyId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(auditSlice.actions.getAuditsRequest());

      const response = await ApiService.post("audits/query", {
        where: { companyId },
      });
      const { results, data } = response.data;

      dispatch(
        auditSlice.actions.getAuditsSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(auditSlice.actions.getAuditsError(e.response?.data?.error));
    }
  };

export const getAuditById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(auditSlice.actions.getAuditRequest());

    const response = await ApiService.get(`audits/${id}`);
    const { data } = response.data;

    dispatch(auditSlice.actions.getAuditSuccess(data));

    return data;
  } catch (e: any) {
    const error: IApiError = e.response.data;

    dispatch(auditSlice.actions.getAuditError(error));

    return { error };
  }
};

// TODO
export const getAuditsByUserId =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      const { where, include } = options || {};

      dispatch(auditSlice.actions.getAuditRequest());

      const response = await ApiService.post("verifications/query", {
        where,
      });
      const { results, data } = response.data;

      dispatch(
        auditSlice.actions.getAuditSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(auditSlice.actions.getAuditError(e.response?.data?.error));
    }
  };

// TODO
