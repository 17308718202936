import React from "react";

import { Register } from "../../features";
import { AuthLayout } from "../../layouts";

export const RegisterPage = () => (
  <AuthLayout>
    <Register />
  </AuthLayout>
);
