import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";
import { Role } from "../../shared";
import { RBACSwitch } from "../../components";

const { AdminHomeContainer } = lazyload(() => import("../../features"));

export const AdminHomePage = () => (
  <MainLayout>
    {RBACSwitch({
      elements: [
        {
          roles: [Role.God, Role.Admin],
          component: () => <AdminHomeContainer />,
        },
      ],
      error: true,
    })}
  </MainLayout>
);
