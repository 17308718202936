import React, { useEffect, useState } from "react";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { MdExpandMore as MdExpandMoreIcon } from "react-icons/md";

interface ISidebarAccordionProps {
  className?: string;
  summary?: string | null;
  details?: JSX.Element | string | null;
  expandIcon?: JSX.Element;
  expanded?: boolean;
  defaultExpanded?: boolean;
}

export const SidebarAccordion: React.FC<ISidebarAccordionProps> = ({
  children,
  expanded: isExpanded,
  defaultExpanded,
  summary,
  details,
  className,
  expandIcon,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded || false);

  const handleExpandToggle = () => setExpanded(!expanded);

  useEffect(() => {
    setExpanded(isExpanded || false);
  }, [isExpanded]);

  return (
    <Accordion
      className={`${className} w-full text-secondary bg-inherit border-b border-primaryLight rounded-none shadow-none`}
      disableGutters
      expanded={expanded}
      defaultExpanded={false}
    >
      <AccordionSummary
        className="w-full px-[0px] text-white"
        expandIcon={expandIcon}
        onClick={handleExpandToggle}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className="px-[0px]">{children}</AccordionDetails>
    </Accordion>
  );
};

SidebarAccordion.defaultProps = {
  summary: "Section",
  expandIcon: <MdExpandMoreIcon className="text-secondary" />,
  expanded: false,
  defaultExpanded: false,
};
