import React, { useEffect } from "react";

import { CardList, Container } from "../../components";
import { PostCard, PostCreateForm } from "../../features";

import {
  closeModal,
  getJobs,
  getPosts,
  removeJob,
  updateJob,
  postSlice,
} from "../../store";
import { Action, ModalType } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../hooks";
import { useNavigate } from "react-router";
import { ROUTER } from "../../routes";
import { date } from "../../utils";

export const PostList = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const {
    posts: { data, isLoading },
  } = useAppSelector((state) => state.post);

  return (
    <Container loading={isLoading}>
      <CardList>
        {data?.map((post) => (
          <PostCard
            key={post?.id}
            {...post}
            actions={[Action.Edit, Action.Delete]}
          />
        ))}
      </CardList>
    </Container>
  );
};
