import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { educationSlice } from "./education.slice";

export const appendEducations =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(educationSlice.actions.appendEducations(payload));

export const popEducations = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(educationSlice.actions.popEducations(payload));

export const getEducations =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      const { where } = options || {};

      dispatch(educationSlice.actions.getEducationsRequest());

      const response = await ApiService.post("educations/query", {
        where,
      });
      const { data } = response.data;

      dispatch(educationSlice.actions.getEducationsSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        educationSlice.actions.getEducationsError(e.response?.data?.error)
      );
    }
  };

export const getEducation = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(educationSlice.actions.getEducationRequest());

    const response = await ApiService.get(`educations/${id}`);
    const { data } = response.data;

    dispatch(educationSlice.actions.getEducationSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(educationSlice.actions.getEducationError(e.response?.data?.error));
  }
};

export const createEducation =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(educationSlice.actions.createEducationRequest());

      const response = await ApiService.post("educations", {
        isArchived: true,
        ...payload,
      });
      const { data } = response.data;

      console.log({ data });

      dispatch(educationSlice.actions.createEducationSuccess(data));
      dispatch(educationSlice.actions.appendEducations(data));

      return data;
    } catch (e: any) {
      dispatch(
        educationSlice.actions.createEducationError(e.response?.data?.error)
      );
    }
  };

export const updateEducation =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(educationSlice.actions.updateEducationRequest());

      const response = await ApiService.put(`educations/${id}`, payload);
      const { data } = response.data;

      dispatch(educationSlice.actions.updateEducationSuccess(data));
      dispatch(educationSlice.actions.appendEducations(payload));

      return id;
    } catch (e: any) {
      dispatch(
        educationSlice.actions.updateEducationError(e.response?.data?.error)
      );
    }
  };

export const removeEducation =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(educationSlice.actions.removeEducationRequest());

      const response = await ApiService.delete(`educations/${id}`);
      const { data } = response.data;

      dispatch(educationSlice.actions.removeEducationSuccess(data));
      dispatch(educationSlice.actions.popEducations({ id }));

      return id;
    } catch (e: any) {
      dispatch(
        educationSlice.actions.removeEducationError(e.response?.data?.error)
      );
    }
  };
