import React from "react";

import { Sidebar, SidebarSection, SidebarAccordion } from "../../../components";
import { ISidebar } from "@types";
import { AuditLocationFilterForm } from "../forms";

export const AuditRequestSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarSection heading="Filters">
      <SidebarAccordion
        summary="Locations"
        details={<AuditLocationFilterForm />}
      />
      <SidebarAccordion summary="Experience" details={<h2>Details</h2>} />
      <SidebarAccordion summary="Education" details={<h2>Details</h2>} />
    </SidebarSection>
  </Sidebar>
);
