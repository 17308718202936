import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { inviteSlice } from "./invite.slice";

export const getInvite = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(inviteSlice.actions.getInviteRequest());

    const response = await ApiService.get(`invites/${id}`);
    const { data } = response.data;

    dispatch(inviteSlice.actions.getInviteSuccess(data));

    console.log({ invite: data });

    return data;
  } catch (e: any) {
    dispatch(inviteSlice.actions.getInviteError(e.response?.data?.error));
  }
};
