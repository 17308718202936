import React from "react";

import { PageHeader } from "../../components";
import { MainLayout } from "./../../layouts";

export const EducationPage = () => (
  <MainLayout>
    <PageHeader back={false}>Education Page Coming Soon!</PageHeader>
  </MainLayout>
);
