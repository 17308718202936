import { createSlice } from "@reduxjs/toolkit";

import {
  appendToStore,
  AppReducerState,
  popFromStore,
  StoreManager,
} from "../..";

interface IInviteState {
  invites: AppReducerState<any[]>;
  invite: AppReducerState<any>;
}

const initialState: IInviteState = {
  invites: {
    isLoading: false,
    data: [],
    error: null,
  },
  invite: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const inviteSlice = createSlice({
  name: "Invite",
  initialState,
  reducers: {
    getInviteRequest: (state) => {
      state.invite.isLoading = true;
    },
    getInviteSuccess: (state, action) => {
      state.invite.isLoading = false;
      state.invite.data = action.payload;
    },
    getInviteError: (state, action) => {
      state.invite.isLoading = false;
      state.invite.error = action.payload;
    },
  },
});

export const inviteReducer = inviteSlice.reducer;
