import React, { useEffect, useState } from "react";

import {
  VerificationStatusBadge,
  ActionMenu,
  Avatar,
} from "../../../../components";
import { Action, Status } from "../../../../shared";
import { date } from "../../../../utils";

export const ResumeEducationCard = ({
  id,
  university,
  degree,
  details,
  verification,
  profileId,
  isDraft,
  isEnrolled,
  isVerified,
  startedAt,
  finishedAt,
  actions,
  onEdit,
  onDelete,
}) => {
  const [isBlocked, setBlocked] = useState(false);

  const isTimestamp = !!startedAt || !!finishedAt;

  const handleVerify = () => {
    setBlocked(true);
  };

  useEffect(() => {
    setBlocked(
      !!verification?.status && verification?.status !== Status.Rejected
    );
  }, [verification]);

  return (
    <div className="w-full">
      <div className="flex flex-col justify-start items-start">
        <div className="w-full flex flex-row justify-between items-start">
          <div className="flex flex-row items-start items-center gap-x-[10px] break-words">
            <Avatar size={40}>{university}</Avatar>

            <div className="flex flex-col items-start justify-center gap-y-[3px] mt-[3px]">
              <div className="flex flex-row justify-start items-center gap-x-[10px]">
                <h3 className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] text-base text-white font-semibold leading-none">
                  <div>
                    <span className="break-words">{degree} at </span>
                    <span className="text-primary">{university}</span>
                  </div>
                  {isDraft && (
                    <span className="text-sm text-secondary">(Draft)</span>
                  )}
                  {!isDraft && (
                    <VerificationStatusBadge
                      status={verification?.status}
                      education={{ id, degree, university, profileId }}
                      onVerify={handleVerify}
                    />
                  )}
                </h3>
              </div>
              <span className="text-sm font-medium text-secondary leading-none">
                {isTimestamp ? (
                  `${date(startedAt).format("MMM YYYY")} - ${
                    isEnrolled ? "Present" : date(finishedAt).format("MMM YYYY")
                  }`
                ) : (
                  <>********</>
                )}
              </span>
            </div>
          </div>
          {!isBlocked && (
            <ActionMenu
              actions={[Action.Edit, Action.Delete]}
              {...{ onEdit, onDelete }}
            />
          )}
        </div>
      </div>
      {/* <div className="mx-7 overflow-hidden overflow-hidden break-words text-white text-base">
        <p>{details}</p>
      </div> */}
    </div>
  );
};

ResumeEducationCard.defaultProps = {
  university: "",
  degree: "",
  isDraft: false,
  isVerified: false,
  startedAt: new Date(),
  finishedAt: new Date(),
};
