import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  RegisterPassportScan,
  RegisterPassportData,
  RegisterEmail,
  RegisterEmailConfirm,
  RegisterComplete,
} from "./../../../features";

import { ROUTER } from "./../../../routes";
import { PageSwitch } from "../../../components";
import { RegisterContext } from "../../../context";
import { useAppSelector } from "../../../hooks";

export const Register = () => {
  const redirect = useNavigate();

  const [registerState, setRegisterState] = useState({
    email: "",
    password: "",
    passport: {},
    profile: {},
    isPassportVerified: false,
    isEmailVerified: false,
  });

  const pages = [
    {
      path: ROUTER.REGISTER_PASSPORT_SCAN,
      component: <RegisterPassportScan />,
    },
    {
      path: ROUTER.REGISTER_PASSPORT_DATA,
      component: <RegisterPassportData />,
    },
    {
      path: ROUTER.REGISTER_EMAIL,
      component: <RegisterEmail />,
    },
    {
      path: ROUTER.REGISTER_EMAIL_CONFIRM,
      component: <RegisterEmailConfirm />,
    },
    {
      path: ROUTER.REGISTER_PASSWORD,
      component: <RegisterComplete />,
    },
  ];

  return (
    // @ts-ignore
    <RegisterContext.Provider value={{ registerState, setRegisterState }}>
      <div className="w-full max-w-[640px] text-white flex flex-col gap-y-5">
        <PageSwitch pages={pages} defaultPage={pages[0]} />
      </div>
    </RegisterContext.Provider>
  );
};
