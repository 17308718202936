import * as React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { VideoCallContainer, DemoVideoCallContainer } = lazyload(
  () => import("./../../features")
);

export const VideoCallPage = () => (
  <MainLayout>
    <DemoVideoCallContainer />
  </MainLayout>
);
