import React, { Suspense } from "react";

import { StyledEngineProvider } from "@mui/material";

import { FallbackLoader, ErrorBoundary } from "./../../components";
import { useLoading } from "./../../hooks";

export const RootProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useLoading(false);

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        {loading ? <FallbackLoader /> : children}
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
