import * as React from "react";

import { Card } from "../../components";
import { INotification } from "../../shared";
import { date, textformat } from "../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

interface INotificationCardProps extends INotification {}

export const NotificationCard: React.FC<INotificationCardProps> = ({
  id,
  type,
  status,
  title,
  details,
  isViewed,
  createdAt,
}) => (
  <Card className="flex flex-col box-border p-[20px]">
    <div className="flex flex-col items-start">
      <div className="flex flex-row justify-start items-center gap-x-[5px]">
        <NotificationCardStatusIcon status={status} />
        <span className="text-base text-white font-semibold capitalize">
          {title}
        </span>
        {!isViewed && "(New)"}
      </div>

      <span className="text-sm text-secondary font-medium">
        {details ? textformat.shortenByChars(details, 200) : ""}
      </span>
    </div>
    <div className="mt-[10px]">
      <span className="text-xs text-secondary font-medium">
        {date(createdAt).fromNow()}
      </span>
    </div>
  </Card>
);

NotificationCard.defaultProps = {};

interface INotificationCardStatusIconProps {
  className?: string;
  status?: NotificationStatus;
}

export const NotificationCardStatusIcon: React.FC<
  INotificationCardStatusIconProps
> = ({ className, status }) => {
  let icon;

  switch (status) {
    case "info":
      icon = "ℹ️";
      break;
    case "success":
      icon = "✅";
      break;
    case "warning":
      icon = "⚠️";
      break;
    case "error":
      icon = "❌";
      break;
    default:
      break;
  }

  return <span className={`${className} text-xs leading-none`}>{icon}</span>;
};

NotificationCardStatusIcon.defaultProps = {
  className: "",
  status: NotificationStatus.Info,
};
