import React from "react";

import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";

import { cssTransition, ToastContainer } from "react-toastify";

const Fade = cssTransition({
  enter: "animate__animated animate__fadeInDown animate__faster",
  exit: "animate__animated animate__fadeOutRight animate__faster",
});

export const ToastProvider = () => (
  <ToastContainer transition={Fade} newestOnTop={true} limit={5} />
);
