import { FormikState } from "formik";

export class FormValidator<T> {
  public form: FormikState<T>;

  constructor(form: FormikState<T>) {
    this.form = form;
  }

  isInputValid(field: keyof T) {
    if (!field || !this.form) return null;

    const error = this.form.errors?.[field]?.toString();

    const isError = !!this.form.errors?.[field];
    const isTouched = !!this.form.touched?.[field];

    const result = isTouched ? (isError ? error : null) : null;

    return result;
  }
}
