import React, { useEffect } from "react";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
} from "react-icons/fi";

import {
  BulletPointList,
  BulletPointListItem,
  Button,
  CardList,
  Container,
  IconButton,
  PageHeader,
  RBAC,
  RBACSwitch,
  VerificationStatusBadge,
} from "../../components";
import {
  CompanyActivityLogWidget,
  CompanyCommentCreateForm,
  CompanyCommentLogWidget,
  EducationListItem,
  ExperienceListItem,
} from "..";
import { getJob, getJobs, getResume, removeJob, updateJob } from "../../store";
import { useNavigate, useParams } from "react-router";
import { ROUTER } from "../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useRBAC,
  useToast,
} from "../../hooks";
import { date, renderTextAsList } from "../../utils";
import { Role } from "../../shared";

export const EmployeeDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { employeeId: resumeId } = useParams();

  const {
    resume: { data: resume, isLoading: resumeLoading },
  } = useAppSelector((state) => state.resume);

  const {
    jobApplication: { data: jobApplication },
  } = useAppSelector((state) => state.jobApplication);

  const companyId = jobApplication?.job?.companyId;
  const userId = resume?.profileId;

  const { profile } = resume || {};

  useEffect(() => {
    if (!resumeId) return;

    dispatch(getResume(resumeId));
  }, [resumeId]);

  return (
    <Container
      loading={resumeLoading}
      className="w-full flex flex-col gap-y-[40px] py-[15px]"
    >
      <div className="flex flex-row justify-between">
        <PageHeader>
          {profile?.firstName} {profile?.lastName}
        </PageHeader>
        <div className="flex flex-row justify-start items-center gap-x-[10px]">
          <Button color="secondary" variant="outlined">
            Share
          </Button>
        </div>
      </div>

      <CardList className="w-full flex flex-col gap-y-[20px]">
        {resume?.covers?.map((cover) => (
          <div key={cover?.id}>
            <h3 className="text-white text-base font-semibold mb-[10px]">
              {cover?.title}
            </h3>
            <p className="text-sm text-white font-medium">{cover?.details}</p>
          </div>
        ))}
      </CardList>

      <div>
        <h2 className="text-white text-base font-semibold">Employer Data</h2>
        <CardList className="mt-[25px] gap-y-[50px]">
          {resume?.experiences?.map((experience) => (
            <ExperienceListItem key={experience.id} {...experience} />
          ))}
        </CardList>
      </div>

      <div>
        <h2 className="text-white text-base font-semibold">
          Education/Certifications
        </h2>
        <CardList className="flex flex-col gap-y-[25px] mt-[25px]">
          {resume?.educations?.map((education) => (
            <EducationListItem key={education?.id} {...education} />
          ))}
        </CardList>
      </div>

      {/* <Container className="flex flex-col md:hidden">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-white text-lg font-semibold">Activity</h2>
        </div>
        <div className="mt-[20px]">
          <CompanyActivityLogWidget companyId={companyId} where={{ userId }} />
        </div>
      </Container>

      <Container className="flex flex-col md:hidden">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-white text-lg font-semibold">Company Notes</h2>
          <Button>Add Note</Button>
        </div>
        <div className="mt-[20px]">
          <CompanyCommentLogWidget companyId={companyId} where={{ userId }} />
        </div>

        <div className="mt-[20px]">
          <CompanyCommentCreateForm
            inputClassName="bg-primaryLight"
            {...{ userId, companyId }}
          />
        </div>
      </Container> */}
    </Container>
  );
};
