export enum ModalType {
  EDIT = "EDIT",
  DELETE = "DELETE",

  // Company
  COMPANY_EMPLOYEE_INVITE = "COMPANY_EMPLOYEE_INVITE",

  // Resume
  RESUME_TEMPLATE_DELETE = "RESUME_TEMPLATE_DELETE",
  RESUME_COVER_DELETE = "RESUME_COVER_DELETE",
  RESUME_EDUCATION_DELETE = "RESUME_EDUCATION_DELETE",
  RESUME_EXPERIENCE_DELETE = "RESUME_EXPERIENCE_DELETE",

  // Job
  JOB_CREATE = "JOB_DELETE",
  JOB_DELETE = "JOB_DELETE",
  JOB_APPLY = "JOB_APPLY",
  JOB_WITHDRAW = "JOB_WITHDRAW",

  // Job / Application
  JOB_APPLICATION_MESSAGE = "JOB_APPLICATION_MESSAGE",
  JOB_APPLICATION_CREATE = "",
  JOB_APPLICATION_REMOVE = "",

  // Verification
  VERIFICATION_REQUEST_CREATE = "VERIFICATION_REQUEST_CREATE",

  // Chat
  CHAT_MESSAGE = "CHAT_MESSAGE",
  CHAT_DELETE = "CHAT_DELETE",

  // Event
  EVENT_DETAIL = "EVENT_DETAIL",
  EVENT_CREATE = "EVENT_CREATE",

  // Gallery
  GALLERY_UPLOAD = "GALLERY_UPLOAD",

  TICKET_CREATE = "TICKET_CREATE",
}
