import React, { useEffect } from "react";

import { FiPlus as PlusIcon, FiArrowUp as ArrowTopIcon } from "react-icons/fi";

import { Container, IconButton, PageHeader } from "../../components";
import { CompanyJobList } from "./../../features";

import { closeModal, getJobs, removeJob, updateJob } from "../../store";
import { Action, ModalType } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useScroll,
  useToast,
} from "../../hooks";
import { useNavigate } from "react-router";
import { ROUTER } from "../../routes";

export const CompanyJobSearch = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { scrollTop } = useScroll();

  const {
    jobs: { data: jobs, isLoading: jobLoading, results: jobCount },
  } = useAppSelector((state) => state.job);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profileId = account?.profile?.id;
  const companyId = account?.ownership?.id;

  useEffect(() => {
    dispatch(
      getJobs({
        where: {
          // isDraft: false,
          // isDelisted: false,
          companyId,
        },
        pagination: {
          page: 1,
          limit: 10,
        },
        sort: [{ createdAt: -1 }],
      })
    );
  }, []);

  return (
    <>
      <div className="fixed z-50 bottom-[95px] right-[20px] flex flex-col gap-y-[8px] md:hidden xxs:flex">
        <IconButton
          icon={ArrowTopIcon}
          iconProps={{ size: 20 }}
          className="w-[40px] h-[40px] rounded-full bg-primaryMid text-white hover:bg-primaryBlack"
          onClick={() => scrollTop()}
        />
        <IconButton
          icon={PlusIcon}
          iconProps={{ size: 20 }}
          className="w-[40px] h-[40px] rounded-full bg-primary text-white hover:bg-primaryHover"
          onClick={() => redirect(ROUTER.JOB_CREATE)}
        />
      </div>
      <Container loading={jobLoading}>
        <CompanyJobList data={jobs} loading={jobLoading} />
      </Container>
    </>
  );
};
