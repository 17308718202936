import React from "react";

import { RBAC, RBACSwitch } from "../../components";
import { Role } from "../../shared";
import { MainLayout, ProfileLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { CompanyDetailContainer } = lazyload(() => import("./../../features"));

export const CompanyDetailPage = () => (
  <ProfileLayout>
    <CompanyDetailContainer />
  </ProfileLayout>
);
