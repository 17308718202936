import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "../../../components";
import { CompanyActivityList, CompanyCommentList } from "./../../../features";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { date } from "../../../utils";
import { getCompanyActivities } from "../../../store";

interface ICompanyCommentLogWidget {
  companyId?: string;
  where?: {
    userId?: string;
  };
}

export const CompanyActivityLogWidget: React.FC<ICompanyCommentLogWidget> = ({
  companyId,
  where,
}) => {
  const dispatch = useAppDispatch();

  const { companyActivities } = useAppSelector((state) => state.company);

  useEffect(() => {
    if (!companyId) return;

    dispatch(getCompanyActivities(companyId, where));
  }, [companyId]);

  return (
    <CompanyActivityList
      loading={companyActivities?.isLoading}
      data={companyActivities?.data}
    />
  );
};

CompanyActivityLogWidget.defaultProps = {};
