import React, { useEffect } from "react";

import { CardList, Container } from "../../components";
import { EmployeeCard } from "..";

interface IEmployeeListProps {
  className?: string;
  data?: any;
  loading?: boolean;
}

export const EmployeeList: React.FC<IEmployeeListProps> = ({
  data,
  loading,
  className,
}) => {
  return (
    <CardList {...{ loading, className }}>
      {data?.map((employee) => (
        <EmployeeCard key={employee?.id} {...employee} />
      ))}
    </CardList>
  );
};

EmployeeList.defaultProps = {
  className: "",
  data: [],
  loading: false,
};
