import React from "react";
import { useState } from "react";

import { CardList } from "../../components";
import { useAppSelector } from "../../hooks";
import { PostCommentCard } from "./../../features";

interface IPostCommentListProps {
  postId?: string;
  loading?: boolean;
  hidden?: boolean;
  data?: any[];
  className?: string;
  onDelete?: (id: string) => Promise<void>;
}

export const PostCommentList: React.FC<IPostCommentListProps> = ({
  loading,
  hidden,
  data,
  className,
  onDelete,
}) => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const isAuthor = (id: string) => account?.profileId === id || false;

  return (
    <CardList
      loading={loading}
      hidden={hidden}
      className={`${className} gap-y-[15px]`}
    >
      {data?.map((comment: any) => (
        <PostCommentCard
          key={comment?.id}
          {...{
            ...comment,
            isAuthor: isAuthor(comment?.createdById),
            onDelete,
          }}
        />
      ))}
    </CardList>
  );
};

PostCommentList.defaultProps = { loading: false, data: [] };
