import React, { useState } from "react";

import { useModal } from "../../hooks";

import { Button, PageContainerSwitch } from "../../components";
import {
  BusinessEmployeeListContainer,
  BusinessVerificationListContainer,
  BusinessAuditListContainer,
} from "..";
import { ModalType } from "../../shared";

export const BusinessAdministrationContainer = () => {
  const { modal, setModal } = useModal();

  const tabs = [
    {
      label: "Employees",
      component: BusinessEmployeeListContainer,
      onRight: (
        <div className="md:flex xxs:hidden">
          <Button
            onClick={() =>
              setModal({ type: ModalType.COMPANY_EMPLOYEE_INVITE })
            }
          >
            Invite Member
          </Button>
        </div>
      ),
    },
    {
      label: "Verification",
      component: BusinessVerificationListContainer,
    },
    {
      label: "Audit",
      component: BusinessAuditListContainer,
      // onRight: <Button color="success">Bulk Assign</Button>,
    },
  ];

  return <PageContainerSwitch tabs={tabs} />;
};
