import React from "react";
import { SVGIcon } from "./SVGIcon";

export const StatisticIcon = (props) => (
  <SVGIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.803848 3C0 4.3923 0 6.26154 0 10C0 13.7385 0 15.6077 0.803848 17C1.33046 17.9121 2.08788 18.6695 3 19.1962C4.3923 20 6.26154 20 10 20C13.7385 20 15.6077 20 17 19.1962C17.9121 18.6695 18.6695 17.9121 19.1962 17C20 15.6077 20 13.7385 20 10C20 6.26154 20 4.3923 19.1962 3C18.6695 2.08788 17.9121 1.33046 17 0.803848C15.6077 0 13.7385 0 10 0C6.26154 0 4.3923 0 3 0.803848C2.08788 1.33046 1.33046 2.08788 0.803848 3ZM8.94813 8.46224C8.67 8.23047 8.26103 8.24902 8.00502 8.50502L5.50503 11.005C5.23166 11.2784 5.23166 11.7216 5.50503 11.995C5.77839 12.2683 6.22161 12.2683 6.49497 11.995L8.54296 9.94699L11.0519 12.0378C11.33 12.2695 11.739 12.251 11.995 11.995L14.495 9.49498C14.7683 9.22161 14.7683 8.77839 14.495 8.50502C14.2216 8.23166 13.7784 8.23166 13.505 8.50502L11.457 10.553L8.94813 8.46224Z"
    />
  </SVGIcon>
);
