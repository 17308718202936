import React from "react";

import { PageHeader } from "../../components";
import { MainLayout } from "../../layouts";

export const CreatorsPage = () => (
  <MainLayout>
    <PageHeader back={false}> Creators Page Coming Soon! </PageHeader>
  </MainLayout>
);
