import React, { Suspense } from "react";

import { Loader } from "../../components";
import { ILayout } from "@types";

interface IAuthLayoutProps extends ILayout {}

export const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => (
  <div className="w-full flex flex-col justify-center items-center min-h-screen h-auto bg-primaryDark overflow-x-hidden box-border md:p-[0px] xxs:p-[20px]">
    <Suspense fallback={<Loader />}>{children}</Suspense>
  </div>
);

AuthLayout.defaultProps = {};
