import React, { useEffect, useState } from "react";

import {
  ActionMenu,
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  FormSection,
  Link,
  StarRating,
} from "../../components";
import {
  ExperienceListItem,
  EducationListItem,
  CompanyCommentCreateForm,
  CompanyCommentList,
  ChatMessageButton,
} from "..";
import { Action, ModalType, NotificationStatus } from "../../shared";
import { ROUTER } from "../../routes";
import { useAppDispatch, useLoading, useModal, useToast } from "../../hooks";

import {
  getCompanyCommentsByCompanyId,
  removeCompanyCommentById,
} from "../../store";
import { IChildComponentUpdateHelpers } from "@types";

interface IJobApplicationCardProps {
  id: string;
  companyCommentCount: number;
  resume: any;
  job: any;
  createdBy: any;
  jobId: string;
  actions: any[];
  onEdit: () => void;
  onDelete: () => void;
}

export const JobApplicationCard: React.FC<IJobApplicationCardProps> = ({
  id,
  resume,
  job,
  companyCommentCount,
  createdBy,
  jobId,
  actions,
  onEdit,
  onDelete,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const { modal, setModal } = useModal();
  const { toast } = useToast();

  const [comments, setComments] = useState<any[]>([]);
  const [commentCount, setCommentCount] = useState<number>(
    companyCommentCount || 0
  );

  const [commentLoading, setCommentLoading] = useLoading();
  const [commentSection, setCommentSection] = useState(false);

  const companyId = job?.companyId;
  const userId = resume?.profileId;
  const jobApplicationId = id;

  const { firstName, lastName, avatar, country, state, city } = createdBy || {};

  const isLocation = [country, state, city]?.some((i) => i && i?.length >= 1);

  const handleCommentAppend = (comment) => {
    setComments([comment, ...comments]);
    setCommentCount(commentCount + 1);
  };

  const handleCommentDelete = async (
    commentId: string,
    { setLoading }: IChildComponentUpdateHelpers
  ): Promise<void> => {
    setLoading(true);

    const comment = await dispatch(removeCompanyCommentById(commentId));
    if (!comment?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Company Note Not Removed!",
      });

      return setLoading(false);
    }

    setComments(comments?.filter(({ id }) => id !== commentId) || []);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!commentSection) return;

      setCommentLoading(true);

      const data = await dispatch(
        getCompanyCommentsByCompanyId(companyId, {
          where: { jobApplicationId, userId },
          sort: [{ createdAt: -1 }],
        })
      );
      if (!data) {
        setCommentLoading(false);
      }

      setCommentLoading(false);
      setComments(data);
    })();
  }, [commentSection]);
  return (
    <Card>
      <CardBody>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-start gap-x-[10px]">
            <Avatar size={40} src={avatar}>
              {firstName}
            </Avatar>
            <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
              <Link>
                <h3 className="text-base font-semibold text-white leading-none">
                  {firstName || ""} {lastName || ""}
                </h3>
              </Link>
              <span className="text-xs leading-none">
                {isLocation
                  ? [country, state, city]
                      ?.filter((i) => i && i?.length >= 1)
                      ?.join(", ")
                  : "********"}
              </span>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center gap-x-[10px]">
            <div className="flex flex-row justify-between items-center gap-x-[10px] md:flex xxs:hidden">
              <Button
                variant="outlined"
                color="primary"
                to={ROUTER.JOB_APPLICATION_DETAIL(jobId, id)}
              >
                View Application
              </Button>
              <ChatMessageButton userId={userId} />
            </div>
            <div className="ml-[10px]">
              <ActionMenu
                actions={[Action.Edit, Action.Delete]}
                {...{ onEdit, onDelete }}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-start mt-[15px] ml-[50px] gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
          <div className="w-full flex flex-col basis-6/12 overflow-hidden">
            <div>
              <span className="text-sm font-medium text-secondary">
                Works at:
              </span>
            </div>
            <div className="flex flex-col gap-y-[15px] mt-[10px]">
              {resume?.experiences?.map((workExperience) => (
                <ExperienceListItem
                  key={workExperience?.id}
                  {...workExperience}
                  shorten
                />
              ))}
            </div>
          </div>

          <div className="w-full flex flex-col basis-6/12 overflow-hidden">
            <div>
              <span className="text-sm font-medium text-secondary">
                Education/Certifications:
              </span>
            </div>
            <div className="flex flex-col gap-y-[15px] mt-[10px]">
              {resume?.educations?.map((education) => (
                <EducationListItem key={education?.id} {...education} />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-x-[10px] gap-y-[10px] mt-[25px] md:hidden xxs:flex">
          <Button
            variant="outlined"
            color="primary"
            to={ROUTER.JOB_APPLICATION_DETAIL(jobId, id)}
          >
            View Application
          </Button>
          <ChatMessageButton userId={userId} />
        </div>
      </CardBody>

      {commentSection ? (
        <>
          <CardFooter>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setCommentSection(false)}
            >
              Close Notes
            </Button>
          </CardFooter>
          <CardFooter className="py-[15px]">
            <FormSection heading="Add Note">
              <CompanyCommentCreateForm
                {...{ jobApplicationId, userId, companyId }}
                onCreate={handleCommentAppend}
              />
            </FormSection>
          </CardFooter>

          <CardFooter className="py-[30px]">
            <CompanyCommentList
              loading={commentLoading}
              data={comments}
              onDelete={handleCommentDelete}
            />
          </CardFooter>
        </>
      ) : (
        <CardFooter>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setCommentSection(true)}
          >
            Show Notes ({commentCount || 0})
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

JobApplicationCard.defaultProps = {
  companyCommentCount: 0,
};
