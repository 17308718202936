import React from "react";
import colors from "tailwindcss/colors";

import { Rating } from "@mui/material";

interface IStarRatingProps {
  className?: string;
  value?: number;
  size?: number;
  max?: number;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: number) => void;
}

export const StarRating: React.FC<IStarRatingProps> = ({
  className,
  value,
  size,
  max,
  disabled,
  readOnly,
  onChange,
}) => {
  return (
    <Rating
      className={`${className} flex flex-row items-center text-red-yellow`}
      sx={{
        "& .MuiRating-icon": {
          fontSize: size,
        },
        "& .MuiRating-iconEmpty": {
          color: colors.gray[600],
        },
      }}
      onChange={(e, value) => (onChange ? onChange(Number(value)) : {})}
      {...{ value, max, disabled, readOnly }}
    />
  );
};

StarRating.defaultProps = {
  className: "",
  max: 5,
  value: 0,
  size: 20,
  disabled: false,
  readOnly: false,
};
