import React, { useEffect } from "react";

import { Container, CardList } from "../../components";
import { CompanyJobCard } from "./../../features";

import { getJobs, updateJob } from "../../store";
import {
  Action,
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../hooks";
import { useNavigate } from "react-router";
import { ROUTER } from "../../routes";

interface ICompanyJobListProps {
  className?: string;
  data?: any;
  loading?: boolean;
}

export const CompanyJobList: React.FC<ICompanyJobListProps> = ({
  data,
  loading,
  className,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, setModal } = useModal();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    jobs: { data: jobs, isLoading: jobsLoading },
  } = useAppSelector((state) => state.job);

  return (
    <CardList {...{ loading, className }}>
      {data?.map((job) => (
        <CompanyJobCard key={job?.id} {...job} />
      ))}
    </CardList>
  );
};

CompanyJobList.defaultProps = {
  className: "",
  data: [],
  loading: false,
};
