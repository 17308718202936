import { createSlice } from "@reduxjs/toolkit";
import { appendToStore, AppReducerState, popFromStore } from "../../../store";

interface IExperienceState {
  experiences: AppReducerState<any[]>;
  experience: AppReducerState<any>;
}

const initialState: IExperienceState = {
  experiences: {
    isLoading: false,
    data: [],
    error: null,
  },
  experience: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const experienceSlice = createSlice({
  name: "Experience",
  initialState,
  reducers: {
    appendExperiences: appendToStore("experiences"),
    popExperiences: popFromStore("experiences"),

    getExperiencesRequest: (state) => {
      state.experiences.isLoading = true;
      state.experiences.data = [];
    },
    getExperiencesSuccess: (state, action) => {
      state.experiences.data = action.payload;
      state.experiences.isLoading = false;
    },
    getExperiencesError: (state, action) => {
      state.experiences.error = action.payload;
      state.experiences.isLoading = false;
    },

    getExperienceRequest: (state) => {
      state.experience.isLoading = true;
    },
    getExperienceSuccess: (state, action) => {
      state.experience.isLoading = false;
      state.experience.data = action.payload;
    },
    getExperienceError: (state, action) => {
      state.experience.isLoading = false;
      state.experience.error = action.payload;
    },

    createExperienceRequest: (state) => {
      state.experience.isLoading = true;
    },
    createExperienceSuccess: (state, action) => {
      state.experience.isLoading = false;
      state.experience.data = action.payload;
    },
    createExperienceError: (state, action) => {
      state.experience.isLoading = false;
      state.experience.error = action.payload;
    },

    updateExperienceRequest: (state) => {
      state.experience.isLoading = true;
    },
    updateExperienceSuccess: (state, action) => {
      state.experience.isLoading = false;
      // state.experience.data = action.payload;
    },
    updateExperienceError: (state, action) => {
      state.experience.isLoading = false;
      state.experience.error = action.payload;
    },

    removeExperienceRequest: (state) => {
      state.experience.isLoading = true;
    },
    removeExperienceSuccess: (state, action) => {
      state.experience.isLoading = false;
      state.experience.data = null;
    },
    removeExperienceError: (state, action) => {
      state.experience.isLoading = false;
      state.experience.error = action.payload;
    },
  },
});

export const experienceReducer = experienceSlice.reducer;
