import React, { useState, useEffect } from "react";

import config from "tailwindcss/defaultConfig";

const screens = { ...config.theme?.screens, xs: "480px", xxs: "320px" };

type breakpointType = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | string;

export const useScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [breakpoint, setBreakpoint] = useState<string | null>(null);

  const handleResize = () => {
    const { innerWidth, innerHeight } = window;

    setWidth(innerWidth);
    setHeight(innerHeight);

    handleBreakpoint();
  };

  const handleBreakpoint = () => {
    const { innerWidth, innerHeight } = window;

    const breakpoints = Object.entries(screens)
      ?.map(([k, v]) => [k, parseInt(v?.toString())])
      ?.sort(([x, a], [y, b]) => Number(a) - Number(b));

    let breakpoint: string | null = "";

    for (let i = 0; i <= breakpoints?.length; i++) {
      const [screen, size] = breakpoints[i] || [];
      const [prevScreen, prevSize] = breakpoints[i - 1] || [];

      if (!prevSize) {
        breakpoint = screen?.toString() || null;
        continue;
      }

      if (innerWidth >= prevSize && innerWidth <= size) {
        breakpoint = screen?.toString() || null;
        break;
      }

      continue;
    }

    setBreakpoint(breakpoint);
  };

  const isBreakpoint = (sizes: breakpointType[]) =>
    breakpoint ? sizes.includes(breakpoint) : false;

  const isMobile = isBreakpoint(["xxs", "xs", "sm", "md"]);
  const isDesktop = isBreakpoint(["lg", "xl", "2xl"]);

  useEffect(() => {
    handleBreakpoint();
  }, [window.innerWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { breakpoint, width, height, isBreakpoint, isMobile, isDesktop };
};
