import React from "react";

import { MainLayout, ProfileLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { EventsContainer } = lazyload(() => import("../../features"));

export const EventsPage = () => (
  <ProfileLayout>
    <EventsContainer />
  </ProfileLayout>
);
