import React from "react";

import { RBAC, RBACSwitch } from "../../components";
import { Role } from "../../shared";
import { MainLayout, ProfileLayout } from "../../layouts";
import { lazyload } from "../../utils";
import { JobApplicationDetailRightSidebar } from "../../features";

const {
  UserDetailContainer,
  UserResumeDetailContainer,
  JobApplicationDetailSidebar,
} = lazyload(() => import("../../features"));

export const UserDetailPage = () =>
  RBACSwitch({
    elements: [
      {
        roles: [Role.Admin, Role.User],
        component: () => (
          <ProfileLayout>
            <UserDetailContainer />
          </ProfileLayout>
        ),
      },
      {
        roles: [Role.Owner, Role.TeamLead, Role.Employee],
        component: () => (
          <MainLayout
            leftSidebar={JobApplicationDetailSidebar}
            rightSidebar={JobApplicationDetailRightSidebar}
          >
            <UserResumeDetailContainer />
          </MainLayout>
        ),
      },
    ],
  });
