import React, { useEffect } from "react";
import {} from "react-redux";

import { CardList } from "../../components";
import { useAppSelector } from "../../hooks";
import { JobCard } from "./../../features";

interface IJobListProps {
  className?: string;
  data?: any;
  loading?: boolean;
}

export const JobList: React.FC<IJobListProps> = ({
  className,
  data,
  loading,
}) => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profileId = account?.profile?.id;

  return (
    <CardList {...{ loading, className }}>
      {data?.map((job) => (
        <JobCard key={job?.id} {...{ ...job, profileId }} />
      ))}
    </CardList>
  );
};

JobList.defaultProps = {
  className: "",
  data: [],
  loading: false,
};
