import { createSlice } from "@reduxjs/toolkit";
import { appendToStore, AppReducerState, popFromStore } from "../../../store";

interface IEducationState {
  educations: AppReducerState<any[]>;
  education: AppReducerState<any>;
}

const initialState: IEducationState = {
  educations: {
    isLoading: false,
    data: [],
    error: null,
  },
  education: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const educationSlice = createSlice({
  name: "Education",
  initialState,
  reducers: {
    appendEducations: appendToStore("educations"),
    popEducations: popFromStore("educations"),

    getEducationsRequest: (state) => {
      state.educations.isLoading = true;
    },
    getEducationsSuccess: (state, action) => {
      state.educations.isLoading = false;
      state.educations.data = action.payload;
    },
    getEducationsError: (state, action) => {
      state.educations.isLoading = false;
      state.educations.error = action.payload;
    },

    getEducationRequest: (state) => {
      state.education.isLoading = true;
    },
    getEducationSuccess: (state, action) => {
      state.education.isLoading = false;
      state.education.data = action.payload;
    },
    getEducationError: (state, action) => {
      state.education.isLoading = false;
      state.education.error = action.payload;
    },

    createEducationRequest: (state) => {
      state.education.isLoading = true;
    },
    createEducationSuccess: (state, action) => {
      state.education.isLoading = false;
      // state.education.data = action.payload;
    },
    createEducationError: (state, action) => {
      state.education.isLoading = false;
      state.education.error = action.payload;
    },

    updateEducationRequest: (state) => {
      state.education.isLoading = true;
    },
    updateEducationSuccess: (state, action) => {
      state.education.isLoading = false;
      // state.education.data = action.payload;
    },
    updateEducationError: (state, action) => {
      state.education.isLoading = false;
      state.education.error = action.payload;
    },

    removeEducationRequest: (state) => {
      state.education.isLoading = true;
    },
    removeEducationSuccess: (state, action) => {
      state.education.isLoading = false;
      state.education.data = null;
    },
    removeEducationError: (state, action) => {
      state.education.isLoading = false;
      state.education.error = action.payload;
    },
  },
});

export const educationReducer = educationSlice.reducer;
