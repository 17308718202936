import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { applyJob, getResumes } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  Radio,
  FilePicker,
  FileDragDropPicker,
  CloseIcon,
  IconButton,
  Loader,
  TextInput,
} from "./../../../components";
import { ROUTER } from "../../../routes";
import { FormValidator, toBase64 } from "../../../utils";

interface IGalleryUploadForm {
  title?: string;
  file?: string;
  description?: string;
}

export const GalleryUploadModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.GALLERY_UPLOAD,
  });

  const [loading, setLoading] = useLoading();

  const [files, setFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);

  const form = useFormik<IGalleryUploadForm>({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        handleReset();
        handleClose();
      }, 1000);
    },
  });

  const { values, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  const handleFileChange = (files?: File[]) => {
    if (!files?.length) return;

    setFiles(files);
  };

  const handleFilePreviews = async (files?: File[]) => {
    if (files?.length) {
      const previews: string[] = [];

      for await (let file of files) {
        const buffer = await toBase64(file);
        const preview = buffer?.toString();

        if (!preview) continue;

        previews.push(preview);
      }

      setFilePreviews(previews);
    }
  };

  const handleReset = () => {
    setFiles([]);
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    handleFilePreviews(files);

    console.log({ files });
  }, [files]);

  return (
    <Modal
      title="Upload"
      description="Please select a file you want to upload."
      open={isOpen}
      onClose={handleClose}
    >
      <Container>
        {!files?.length ? (
          <Form onSubmit={handleSubmit}>
            <FileDragDropPicker
              className="h-[240px]"
              onChange={handleFileChange}
            />
          </Form>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row flex-wrap gap-x-[5px] gap-y-[5px] mt-[5px]">
              {filePreviews?.map((preview, index) => (
                <div
                  className={`
                        ${
                          !!filePreviews?.length && filePreviews?.length === 1
                            ? "basis-full h-auto"
                            : "basis-2/12 h-[75px]"
                        }
                        relative flex flex-row rounded-lg overflow-hidden select-none
                    `}
                >
                  {/* <div className="absolute w-full h-full opacity-0 hover:opacity-100">
                    <IconButton
                      className="absolute top-[5px] right-[5px] w-[12px] h-[12px] bg-primaryDark text-white rounded-lg"
                      icon={CloseIcon}
                    />
                  </div> */}
                  {!!preview ? (
                    <img
                      className="w-[150%] h-auto select-none pointer-events-none"
                      src={preview}
                      alt="File Preview"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-y-[10px] mt-[20px]">
              <TextInput
                id="title"
                label="Title"
                type="text"
                variant="filled"
                className="!bg-primaryDark !text-white"
                controlClassName="basis-1/2"
                labelClassName="text-secondary"
                inputClassName="text-inherit"
                required
                value={values?.title}
                error={validator.isInputValid("title")}
                onChange={handleChange}
              />
              <TextInput
                id="description"
                label="Description"
                type="text"
                variant="filled"
                className="!bg-primaryDark !text-white"
                controlClassName="basis-1/2"
                labelClassName="text-secondary"
                inputClassName="text-inherit"
                minRows={2}
                maxRows={5}
                multiline
                value={values?.description}
                error={validator.isInputValid("description")}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-row justify-end items-center gap-x-[10px] mt-[20px]">
              <Button color="secondary" onClick={handleReset}>
                Change
              </Button>
              <Button
                color="success"
                className="min-w-[150px]"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                Upload
              </Button>
            </div>
          </div>
        )}
      </Container>
    </Modal>
  );
};
