import React from "react";

import { CardList } from "../../components";
import { UserAuditCard } from "..";

interface IUserAuditListProps {
  loading: boolean;
  data: any[];
}

export const UserAuditList: React.FC<IUserAuditListProps> = ({
  loading,
  data,
}) => {
  return (
    <CardList loading={loading}>
      {!!data?.length ? (
        data?.map((request) => <UserAuditCard key={request?.id} {...request} />)
      ) : (
        <span>No audits found.</span>
      )}
    </CardList>
  );
};

UserAuditList.defaultProps = {
  loading: false,
  data: [],
};
