import { createSlice } from "@reduxjs/toolkit";
import { appendToStore, AppReducerState, popFromStore } from "../../../store";

interface IProfileCoverState {
  profileCovers: AppReducerState<any[]>;
  profileCover: AppReducerState<any>;
}

const initialState: IProfileCoverState = {
  profileCovers: {
    isLoading: false,
    data: [],
    error: null,
  },
  profileCover: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const profileCoverSlice = createSlice({
  name: "ProfileCover",
  initialState,
  reducers: {
    appendProfileCovers: appendToStore("profileCovers"),
    popProfileCovers: popFromStore("profileCovers"),

    getProfileCoversRequest: (state) => {
      state.profileCovers.isLoading = true;
      state.profileCovers.data = [];
    },
    getProfileCoversSuccess: (state, action) => {
      state.profileCovers.data = action.payload;
      state.profileCovers.isLoading = false;
    },
    getProfileCoversError: (state, action) => {
      state.profileCovers.error = action.payload;
      state.profileCovers.isLoading = false;
    },

    getProfileCoverRequest: (state) => {
      state.profileCover.isLoading = true;
    },
    getProfileCoverSuccess: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.data = action.payload;
    },
    getProfileCoverError: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.error = action.payload;
    },

    createProfileCoverRequest: (state) => {
      state.profileCover.isLoading = true;
    },
    createProfileCoverSuccess: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.data = action.payload;
    },
    createProfileCoverError: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.error = action.payload;
    },

    updateProfileCoverRequest: (state) => {
      state.profileCover.isLoading = true;
    },
    updateProfileCoverSuccess: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.data = action.payload;
    },
    updateProfileCoverError: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.error = action.payload;
    },

    removeProfileCoverRequest: (state) => {
      state.profileCover.isLoading = true;
    },
    removeProfileCoverSuccess: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.data = {};
    },
    removeProfileCoverError: (state, action) => {
      state.profileCover.isLoading = false;
      state.profileCover.error = action.payload;
    },
  },
});

export const profileCoverReducer = profileCoverSlice.reducer;
