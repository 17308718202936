import * as React from "react";

import { MdMenu as BurgerMenuIcon } from "react-icons/md";

import { IconButton } from "./../../components";

interface IBurgerIconButtonProps {
  iconClassName?: string;
  onClick?: () => void;
}

export const BurgerIconButton: React.FC<IBurgerIconButtonProps> = ({
  iconClassName,
  onClick,
}) => (
  <IconButton
    icon={BurgerMenuIcon}
    iconProps={{ size: 24 }}
    className="md:hidden xxs:flex"
    iconClassName={`${iconClassName} fill-secondary`}
    onClick={onClick || (() => {})}
  />
);

BurgerIconButton.defaultProps = {
  iconClassName: "",
};
