import React from "react";

import { IChildComponentUpdateHelpers } from "@types";
import { CardList } from "../../components";
import { CompanyCommentCard } from "../../features";
import { useAppSelector } from "../../hooks";

interface ICompanyCommentListProps {
  data?: any[];
  loading?: boolean;
  hidden?: boolean;
  postId?: string;
  className?: string;
  cardProps?: {
    variant?: "standard" | "transparent";
  };
  onEdit?: (id: string, helpers: IChildComponentUpdateHelpers) => void;
  onDelete?: (id: string, helpers: IChildComponentUpdateHelpers) => void;
}

export const CompanyCommentList: React.FC<ICompanyCommentListProps> = ({
  className,
  loading,
  data,
  hidden,
  cardProps,
  onEdit,
  onDelete,
}) => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profileId = account?.profileId;

  const isAuthor = (id: string) => id === profileId;

  return (
    <CardList
      loading={loading}
      hidden={hidden}
      className={`${className} gap-y-[15px]`}
    >
      {data?.map((comment: any) => (
        <CompanyCommentCard
          key={comment?.id}
          {...comment}
          {...cardProps}
          isAuthor={isAuthor(comment?.createdById)}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </CardList>
  );
};

CompanyCommentList.defaultProps = {
  data: [],
};
