import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { videoCallSlice } from "./video-call.slice";

export const generateVideoCallToken = () => async (dispatch: AppDispatch) => {
  try {
    const response = await ApiService.post("video-calls");
    const { data } = response.data;

    return data;
  } catch (e: any) {}
};
