import React, { useEffect, useRef, useState } from "react";

import { FiDownload as DownloadIcon } from "react-icons/fi";

import { ROUTER } from "../../routes";

import {
  Avatar,
  Button,
  Container,
  IconButton,
  Link,
  PageHeader,
  StatusBadge,
} from "../../components";
import {
  approveCompanyRegistration,
  getCompanyRegistration,
  rejectCompanyRegistration,
} from "../../store";
import { date, textformat } from "../../utils";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";
import { useNavigate, useParams } from "react-router";
import { Status } from "../../shared";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";

export const BusinessVerificationDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { id } = useParams();

  const { toast } = useToast();
  const documentRef = useRef<HTMLObjectElement>(null);

  const [approveLoading, setApproveLoading] = useLoading();
  const [rejectLoading, setRejectLoading] = useLoading();

  const {
    companyVerification: {
      data: companyVerification,
      isLoading: companyVerificationLoading,
    },
  } = useAppSelector((state) => state.company);

  const [status, setStatus] = useState<string>();

  const {
    name,
    tin,
    email,
    phone,
    size,
    country,
    state,
    city,
    document,
    ownerId,
    requestedBy,
    createdAt,
  } = companyVerification || {};

  const isPending = status === Status.Pending;
  const isApproved = status === Status.Approved;
  const isRejected = status === Status.Rejected;

  const handleApprove = async () => {
    if (!id) return;

    setApproveLoading(true);

    const approved = await dispatch(approveCompanyRegistration(id));
    if (!approved?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Company Verification Failed!",
      });

      return setApproveLoading(false);
    }

    toast({
      status: NotificationStatus.Success,
      message: "Company Has Been Approved!",
    });

    setStatus(Status.Approved);
    setApproveLoading(false);
  };

  const handleReject = async () => {
    if (!id) return;

    setRejectLoading(true);

    const rejected = await dispatch(rejectCompanyRegistration(id));
    if (!rejected?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Company Verification Not Rejected!",
      });

      return setRejectLoading(false);
    }

    toast({
      status: NotificationStatus.Success,
      message: "Company Has Been Rejected!",
    });

    setStatus(Status.Rejected);
    setRejectLoading(false);
  };

  useEffect(() => {
    setStatus(companyVerification?.status);
  }, [companyVerification?.status]);

  useEffect(() => {
    if (!id) return;

    dispatch(getCompanyRegistration(id));
  }, [id]);

  useEffect(() => {
    console.log({ documentRef, data: documentRef?.current?.dataset });
  }, [documentRef]);

  return (
    <Container loading={companyVerificationLoading} data={companyVerification}>
      <PageHeader backTo={ROUTER.ADMIN}>
        <span className="">
          {textformat.shortenByChars(name, 50)}’s Verification Request
        </span>
      </PageHeader>

      <div className="flex flex-row">
        <div className="w-full flex flex-col gap-y-[30px]">
          <div className="flex flex-col pb-[30px] border-b-[1px] border-solid border-primaryLight">
            <h2 className="text-white text-md font-semibold mb-[20px]">Info</h2>
            <div
              className="flex
                        xxs:flex-col md:flex-row
                        xxs:gap-x-[10px] xxs:gap-y-[30px] md:gap-x-[30px]
            "
            >
              <div className="flex flex-row justify-center items-start">
                <div
                  className="flex flex-col justify-center items-center bg-primaryLight hover:bg-primaryMid transition-all rounded-xl
                                xxs:w-full xxs:h-[125px] md:w-[145px] md:h-[145px]
                "
                >
                  <Avatar size={42}>{name}</Avatar>
                  <span className="mt-[8px] text-white text-sm font-semibold capitalize">
                    {textformat.shortenByChars(name, 10)}
                  </span>
                </div>
              </div>

              <div className="flex basis-4/12 flex-col gap-y-[10px]">
                <DetailBlock label="Company Name">
                  {textformat.shortenByChars(name, 50)}
                </DetailBlock>
                <DetailBlock label="Company Size">{size}</DetailBlock>
                <DetailBlock label="Company Location">
                  {[country, state, city]?.filter((i) => !!i)?.join(", ")}
                </DetailBlock>
              </div>

              <div className="flex basis-4/12 flex-col gap-y-[10px]">
                <DetailBlock label="Tax Number">{tin}</DetailBlock>
                <DetailBlock label="Creation Date">
                  {date(createdAt).format("DD MMM, YYYY")}
                </DetailBlock>
                <DetailBlock label="Status">
                  <StatusBadge status={status} />
                </DetailBlock>
              </div>
            </div>
          </div>

          <div className="flex flex-col pb-[30px] border-b-[1px] border-solid border-primaryLight">
            <h2 className="text-white text-md font-semibold mb-[20px]">
              Contacts
            </h2>
            <div className="flex gap-x-[10px] gap-y-[10px] xxs:flex-col md:flex-row">
              {requestedBy && (
                <DetailBlock label="Requested By" className="flex basis-6/12">
                  <Link to={ROUTER.USER_DETAIL(requestedBy?.id)}>
                    <span className="capitalize">
                      {requestedBy?.firstName?.slice(0, 1)}.{" "}
                      {requestedBy?.lastName}
                    </span>
                  </Link>
                </DetailBlock>
              )}
              <DetailBlock label="Email" className="flex basis-6/12">
                <span className="lowercase">{email}</span>
              </DetailBlock>

              <DetailBlock label="Phone Number" className="flex basis-6/12">
                {phone}
              </DetailBlock>
            </div>
          </div>

          <div className="flex flex-col">
            <h2 className="text-white text-md font-semibold mb-[20px]">
              Documents
            </h2>
            {document ? (
              <div className="w-full h-auto rounded-lg overflow-hidden outline-none py-[5px]">
                <object
                  ref={documentRef}
                  data={document || null}
                  className="w-full min-h-[400px]"
                >
                  Document
                </object>
              </div>
            ) : (
              <span>No documents attached.</span>
            )}
          </div>

          <div
            className="flex justify-end gap-x-[10px] gap-y-[10px]
                      xxs:flex-col-reverse md:flex-row
          "
          >
            {requestedBy ? (
              isPending ? (
                <>
                  <Button
                    color="danger"
                    variant="text"
                    loading={rejectLoading}
                    onClick={handleReject}
                  >
                    Reject
                  </Button>
                  <Button
                    color="success"
                    variant="outlined"
                    className="min-w-[150px]"
                    loading={approveLoading}
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                </>
              ) : !isApproved ? (
                <>
                  {!isRejected && (
                    <Button
                      color="danger"
                      variant="text"
                      loading={rejectLoading}
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  )}
                  <Button
                    color="success"
                    variant="outlined"
                    className="min-w-[150px]"
                    loading={approveLoading}
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="danger"
                    variant="outlined"
                    loading={rejectLoading}
                    className="min-w-[150px]"
                    onClick={handleReject}
                  >
                    Reject
                  </Button>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

const DetailBlock = ({ className, label, children }) => (
  <div className={`${className} flex`}>
    <div className="flex flex-col justify-center gap-y-[5px]">
      <span className="text-secondary text-xs font-medium">{label}</span>
      <span className="text-white text-sm font-medium capitalize">
        {children ? children : <></>}
      </span>
    </div>
  </div>
);

DetailBlock.defaultProps = {
  className: "",
};
