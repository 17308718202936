import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { UserAuditList } from "./../../features";
import { getAuditsByCompanyId } from "../../store";

export const BusinessAuditListContainer = () => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    audits: { data: audits, isLoading: auditsLoading },
  } = useAppSelector((state) => state.audit);

  const companyId = account?.employment?.id || account?.ownership?.id || null;

  useEffect(() => {
    if (!companyId) return;

    dispatch(getAuditsByCompanyId(companyId));
  }, [companyId]);

  return <UserAuditList loading={auditsLoading} data={audits} />;
};
