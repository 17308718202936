import React, { useEffect, useState } from "react";

import { FiPlus as PlusIcon, FiFilter as FilterIcon } from "react-icons/fi";

import { Container, FilterTag, IconButton, PageHeader } from "../../components";
import { JobList } from "./../../features";

import { closeModal, getJobs, removeJob, updateJob } from "../../store";
import { Action, DrawerType, ModalType } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useFilter,
  useModal,
  useToast,
} from "../../hooks";
import { useNavigate } from "react-router";
import { ROUTER } from "../../routes";
import { IFilterTag, IJobSearchFilter } from "@types";

export const JobSearch = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const {
    filters,
    filterTags,
    removeFilter,
    removeFilterTag,
    isEmpty,
    isFilterEmpty,
    buildFilterQuery,
  } = useFilter();

  const { setDrawer, resetDrawer } = useDrawer();

  const {
    jobs: { data: jobs, isLoading: jobLoading, results: jobCount },
  } = useAppSelector((state) => state.job);

  useEffect(() => {
    const {
      jobTitle,
      jobType,
      employmentType,
      minSalary,
      maxSalary,
      salaryDependsOnExperience,
      company,
      country,
      state,
      city,
    }: any = filters || {};

    const where = {
      isDraft: false,
      isDelisted: false,
      title: buildFilterQuery("jobTitle", jobTitle),
      type: jobType,
      employmentType,
      minSalary: minSalary ? { $gte: minSalary } : undefined,
      maxSalary: maxSalary ? { $lte: maxSalary } : undefined,
      salaryDependsOnExperience,
      country: buildFilterQuery("country", country),
      state: buildFilterQuery("state", state),
      city: buildFilterQuery("city", city),
      $profile$: {},
      $company$: {
        $companyProfile$: { name: buildFilterQuery("company", company) },
      },
    };

    dispatch(getJobs({ where }));
  }, [filters]);

  useEffect(() => {
    setDrawer({ type: DrawerType.JOBS, open: false });

    return () => resetDrawer();
  }, []);

  return (
    <>
      <div className="fixed z-50 bottom-[95px] right-[20px] flex flex-col gap-y-[8px] md:hidden xxs:flex">
        <IconButton
          icon={FilterIcon}
          iconProps={{ size: 20 }}
          className="w-[40px] h-[40px] rounded-full bg-primary text-white hover:bg-primaryBlack"
          onClick={() => setDrawer({ type: DrawerType.JOBS, open: true })}
        />
      </div>
      <Container>
        <PageHeader
          back={false}
          renderOnRight={
            <span className="text-secondary">{jobCount || 0} jobs found</span>
          }
        >
          Job Search
        </PageHeader>

        <div className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] gap-y-[10px]">
          {filterTags?.map((tag) => (
            <FilterTag
              key={tag.id}
              {...tag}
              onDelete={() => removeFilterTag(tag.id)}
            />
          ))}
        </div>

        <JobList data={jobs} loading={jobLoading} className="mt-[15px]" />
      </Container>
    </>
  );
};
