import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface ITicketState {}

const initialState: ITicketState = {};

export const ticketSlice = createSlice({
  name: "Ticket",
  initialState,
  reducers: {
    createTicket: (state) => {},
  },
});

export const ticketReducer = ticketSlice.reducer;
