import React from "react";

import { Link, Loader } from "..";

interface IIconButtonProps {
  className?: string;
  disabledClassName?: string;
  iconClassName?: string;
  to?: string;
  size?: number;
  loading?: boolean;
  icon?: any;
  iconProps?: {
    size?: number;
  };
  onClick?: () => void;
}

export const IconButton: React.FC<IIconButtonProps> = ({
  to,
  loading,
  className,
  disabledClassName,
  icon: Icon,
  iconClassName,
  iconProps,
  onClick,
  ...props
}) =>
  to ? (
    <Link to={to} className={` ${className}`}>
      {Icon ? <Icon className={iconClassName} {...iconProps} /> : <></>}
    </Link>
  ) : (
    <div
      className={`${className} transition-all flex justify-center min-w-[18px] min-h-[18px] items-center cursor-pointer transition-all duration-200 fill-secondary overflow-hidden
      ${
        loading
          ? `cursor-default ${disabledClassName}`
          : "only:hover:fill-white"
      } `}
      onClick={loading ? () => {} : onClick}
      {...props}
    >
      {Icon ? (
        loading ? (
          <Loader size={20} className="text-inherit" />
        ) : (
          <Icon className={iconClassName} {...iconProps} />
        )
      ) : (
        <></>
      )}
    </div>
  );

IconButton.defaultProps = {
  loading: false,
  to: "",
  icon: () => <></>,
  className: "",
  disabledClassName: "",
  iconClassName: "",
  onClick: () => {},
};
