import { createSlice } from "@reduxjs/toolkit";

interface ICommentState {}

const initialState: ICommentState = {};

export const commentSlice = createSlice({
  name: "Comment",
  initialState,
  reducers: {
    updateCommentRequest: (state) => {},
    updateCommentSuccess: (state, action) => {},
    updateCommentError: (state, action) => {},

    // TODO
    getCommentsRequest: (state) => {},
    getCommentsSuccess: (state, action) => {},
    getCommentsError: (state, action) => {},

    addCommentRequest: (state) => {},
    addCommentSuccess: (state, action) => {},
    addCommentError: (state, action) => {},

    removeCommentRequest: (state) => {},
    removeCommentSuccess: (state, action) => {},
    removeCommentError: (state, action) => {},
  },
});

export const commentReducer = commentSlice.reducer;
