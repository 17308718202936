import { useEffect } from "react";

import { setURL } from "../store";
import { useAppDispatch, useAppSelector } from "./../hooks";

interface IUseURLProps {
  path?: string | Function | null;
  params?: any | null;
  query?: string | null;
}

export interface IURLSwitch {
  path?: string | Function | null;
  params?: any | null;
  query?: string | null;
}

export const useURL = (): [IUseURLProps, (payload: IUseURLProps) => void] => {
  const dispatch = useAppDispatch();

  const URL = useAppSelector((state) => state.app.URL);

  const handleUpdate = (data: IURLSwitch): void => {
    dispatch(setURL(data));
  };

  return [URL, handleUpdate];
};
