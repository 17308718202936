import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { EmployeeDetailContainer } = lazyload(() => import("../../features"));

export const EmployeeDetailPage = () => (
  <MainLayout>
    <EmployeeDetailContainer />
  </MainLayout>
);
