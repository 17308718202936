export const FILTER_LABELS = {
  "#isVerified": "Verified",
  "#salaryDependsOnExperience": "Depends On Experience",

  "#on-site": "On Site",
  "#remote": "Remote",
  "#hybrid": "Hybrid",
  "#travel-10": "Travel 10%",
  "#travel-25": "Travel 25%",
  "#travel-50": "Travel 50%",
  "#travel-75": "Travel 75%",

  "#full-time": "Full-time",
  "#part-time": "Part-time",
  "#contractor": "Contractor",
  "#temporary": "Temporary",
  "#internship": "Internship",

  "#entry": "Entry",
  "#junior": "Junior",
  "#middle": "Middle",
  "#senior": "Senior",
  "#executive": "Executive",

  "#$0-0": "Depends on experience",
  "#$10000-15000": "$10-$15.000",
  "#$15000-20000": "$15-$20.000",
  "#$20000-25000": "$20-$25.000",
  "#$25000-30000": "$25-$30.000",
  "#$30000-45000": "$30-$45.000",
  "#$45000-50000": "$45-$50.000",
  "#$50000-60000": "$50-$60.000",
  "#$60000-70000": "$60-$70.000",
  "#$70000-80000": "$70-$80.000",
  "#$80000-90000": "$80-$90.000",
  "#$90000-100000": "$90-$100.000",
  "#$100000-250000": "$100-$250.000",
  "#$250000-250000": "$250.000+",
};
