import React from "react";

import { Login } from "../../features";
import { AuthLayout } from "../../layouts";

export const LoginPage = () => (
  <AuthLayout>
    <Login />
  </AuthLayout>
);
