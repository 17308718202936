import { CommentUpdateDto } from "./../../../shared";

import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { commentSlice } from "./comment.slice";

export const updateComment =
  (commentId: string, payload?: CommentUpdateDto) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(commentSlice.actions.updateCommentRequest());

      const response = await ApiService.put(`comments/${commentId}`, payload);
      const { data } = response.data;

      dispatch(commentSlice.actions.updateCommentSuccess(data));

      return { id: commentId };
    } catch (e: any) {
      console.log({ e });
      dispatch(
        commentSlice.actions.updateCommentError(e.response?.data?.error)
      );
    }
  };

export const removeComment =
  (commentId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(commentSlice.actions.removeCommentRequest());

      const response = await ApiService.delete(`comments/${commentId}`);
      const { data } = response.data;

      dispatch(commentSlice.actions.removeCommentSuccess(data));

      return { id: commentId };
    } catch (e: any) {
      console.log({ e });
      dispatch(
        commentSlice.actions.removeCommentError(e.response?.data?.error)
      );
    }
  };

// TODO
export const createComment =
  (postId, payload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(commentSlice.actions.addCommentRequest());

      const response = await ApiService.post(
        `posts/${postId}/comments`,
        payload
      );
      const { data } = response.data;

      dispatch(commentSlice.actions.addCommentSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(commentSlice.actions.addCommentError(e.response?.data?.error));
    }
  };
