import React, { useEffect } from "react";

import { IChat, IChatMessage, IProfile } from "../../shared";

import { Avatar, Link } from "../../components";
import { ROUTER } from "../../routes";
import { textformat } from "../../utils";

interface IChatListItemProps extends IChat {
  lastMessage?: IChatMessage;
  isOnline?: boolean;
  isActive?: boolean;
  isNewMessage?: boolean;
  user?: IProfile;
  profileId?: string;
}

export const ChatListItem: React.FC<IChatListItemProps> = ({
  id,
  user,
  lastMessage,
  isNewMessage,
  isOnline,
  isActive,
}) => {
  const { firstName, lastName, avatar } = user || {};

  return (
    <Link
      to={ROUTER.CHAT_DETAIL(id)}
      className={`
      ${isActive ? "bg-primaryMid" : "bg-primaryDark hover:bg-primaryLight"}
      w-full h-[70px] flex flex-row justify-between items-center text-secondary px-[25px] transition-all cursor-pointer`}
    >
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex flex-row justify-start items-center gap-x-[10px]">
          <Avatar size={40} src={avatar} status={isOnline ? "success" : null}>
            {firstName}
          </Avatar>
          <div className="flex flex-col items-start gap-y-[5px]">
            <span className="text-sm text-white font-semibold leading-none">
              {firstName ? firstName : ""} {lastName ? lastName : ""}
            </span>
            <span className="text-xs text-secondary font-medium leading-none">
              {lastMessage?.text
                ? textformat.shortenByChars(lastMessage?.text, 25)
                : "********"}
            </span>
          </div>
        </div>

        {isNewMessage && (
          <div className="w-[10px] h-[10px] flex flex-row justify-center items-center rounded-full bg-secondary text-xs font-medium text-white"></div>
        )}
      </div>
    </Link>
  );
};
