import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { IFilterTag } from "@types";

import { setAppFilters } from "../store";
import { Generator } from "../utils";
import { useAppDispatch, useAppSelector } from "./../hooks";
import { FILTER_LABELS as _FILTER_LABELS } from "../constants";

export type IFilters = Record<string, string | string[] | number | boolean>;
export type IFilterLabel = { key: string; value: string };

interface IUseFilterProps {}

export const useFilter = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const location = useLocation();

  const FILTER_SEPARATOR = ";";
  const FILTER_LABELS = _FILTER_LABELS;

  const [searchParams, setSearchParams] = useSearchParams();

  const { filters } = useAppSelector((state) => state.app);
  const [filterTags, setFilterTags] = useState<IFilterTag[]>();

  const [isEmpty, setEmpty] = useState(true);

  const setSearch = (filters: IFilters): void => {
    const searchParams = {};

    for (let key of Object.keys(filters)) {
      const value = filters[key];

      const isEmpty = Array.isArray(value)
        ? !value?.length
        : !value?.toString()?.length;

      if (isEmpty) continue;

      searchParams[key] = Array.isArray(value)
        ? (value as string[])?.join(FILTER_SEPARATOR)
        : value;
    }

    setSearchParams({ ...searchParams }, { replace: true });
  };

  const setFilters = (filters: IFilters): void => {
    if (!Object.keys(filters || {})?.length) return;

    dispatch(setAppFilters(filters));
    setSearch(filters);
  };

  const removeFilter = (key: any): void => {};

  const removeFilterTag = (id: string): void => {
    const tag = filterTags?.find((tag) => tag?.id === id);
    const filterId = tag?.filterId || null;

    if (!filterId) return;

    const tags = filterTags?.filter((tag) => tag?.id !== id);

    const filter = Array.isArray(filters?.[filterId])
      ? // @ts-ignore
        filters?.[filterId]?.filter((i) => i !== tag?.value) || []
      : [];

    if (!filter) return;

    setFilterTags(tags);
    setFilters({ ...filters, [filterId]: filter?.length ? filter : undefined });
  };

  const resetFilters = (): void => {
    dispatch(setAppFilters({}));
    setSearchParams({});
  };

  const isBoolean = (value: string): boolean =>
    ["true", "false"]?.some((i) => i === value?.toString()?.toLowerCase());

  const isFilterEmpty = (filterKeys: string[]) => {
    const keys = Object.keys(filters || []);

    return !filterKeys?.some((key) => {
      if (Array.isArray(filters?.[key])) {
        // @ts-ignore
        return filters?.[key].length >= 1;
      }

      return keys?.includes(key);
    });
  };

  const buildFilterQuery = (filterId: string, data: string | string[]) => {
    if (isFilterEmpty([filterId]) || !data?.length) return undefined;

    const query = {
      $or: Array.isArray(data)
        ? data?.map((i) => ({ $like: `%${i}%` }))
        : [{ $like: `%${data}%` }],
    };

    return query;
  };

  const parseFilterLabel = (key: string) => FILTER_LABELS[`#${key}`] || null;

  const parseSearchParams = (searchParams: URLSearchParams): object => {
    const filters = {};

    const keys = Array.from(searchParams.keys());
    const values = Array.from(searchParams.values());

    for (let i = 0; i <= keys.length; i++) {
      const key = keys[i] || null;
      const value = values[i] || null;
      const isEmpty = !value?.length;

      if (!key || !value || isEmpty) continue;

      filters[keys[i]] = value?.includes(FILTER_SEPARATOR)
        ? value?.split(FILTER_SEPARATOR)?.filter((i) => i?.length >= 1)
        : value;
    }

    return filters;
  };

  useEffect(() => {
    if (!searchParams) return;

    const filters = parseSearchParams(searchParams);

    dispatch(setAppFilters({ ...filters }));
  }, [searchParams]);

  useEffect(() => {
    if (!filters) return;

    const tags: IFilterTag[] = [];

    const keys = Object.keys(filters) || [];

    for (const filterId of keys) {
      if (!filterId) continue;

      const values = filters[filterId] || [];
      const value = values;

      if (Array.isArray(values)) {
        for (const value of values) {
          if (!value) continue;

          tags.push({
            id: Generator.uuid(),
            filterId,
            value,
          });
        }
      } else {
        tags.push({
          id: Generator.uuid(),
          filterId,
          label: isBoolean(value?.toString())
            ? parseFilterLabel(filterId)
            : Array.isArray(value)
            ? null
            : parseFilterLabel(value?.toString()),
          value: value?.toString(),
        });
      }
    }

    setFilterTags(tags);
  }, [filters]);

  useEffect(() => {
    console.log("Filter Rendering..");
  }, []);

  return {
    filters,
    filterTags,
    setFilters,
    removeFilter,
    removeFilterTag,
    resetFilters,
    isEmpty,
    isFilterEmpty,
    buildFilterQuery,
    searchParams,
  };
};
