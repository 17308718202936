import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Form,
  FormSection,
  TextInput,
  StarRating,
  Switch,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../../hooks";
import { addLineBreaks } from "../../../utils";
import { updateCompanyComment } from "../../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1500, "Exceeded maximum character length of 1500")
    .required("Note is a required field"),
  rating: Yup.number().required("Note is a required field"),
  isPrivate: Yup.boolean().notRequired().default(false),
});

export const CompanyCommentEditForm = ({ id, initialValues, onClose }) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!profileId) return;

      const { details, jobApplicationId } = values;

      const data = {
        ...values,
        details: addLineBreaks(details),
      };

      setLoading(true);

      const updated = await dispatch(
        updateCompanyComment({ id, jobApplicationId }, data)
      );
      if (!updated?.id) {
        setLoading(false);
        return toast({
          status: NotificationStatus.Error,
          message: "Job Applicated Note Not Updated!",
        });
      }

      toast({
        status: NotificationStatus.Success,
        message: "Job Applicated Note Updated!",
      });

      onClose();
      setLoading(false);
      resetForm();
    },
  });

  const handleCancel = () => {
    onClose();
    resetForm();
  };

  return (
    <FormSection heading="Edit Note">
      <Form onSubmit={handleSubmit}>
        <TextInput
          id="details"
          label="Note"
          type="text"
          variant="filled"
          className="border border-solid border-primaryMid"
          controlClassName="basis-full"
          labelClassName="text-secondary"
          required
          multiline
          minRows={4}
          value={values?.details}
          error={touched?.details ? errors?.details : null}
          onChange={handleChange}
        />

        <div className="flex flex-row justify-between items-center mt-[15px]">
          <StarRating
            value={values?.rating}
            // error={touched?.rating ? errors?.rating : null}
            onChange={(value) => setFieldValue("rating", value, true)}
          />
          <Switch
            leftLabel="Public"
            rightLabel="Private"
            value={values?.isPrivate}
            onChange={(value) => setFieldValue("isPrivate", value, true)}
          />
          <div className="flex flex-row justify-end gap-x-1">
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Discard
            </Button>
            <Button
              type="submit"
              variant="outlined"
              loading={loading}
              className="min-w-[100px]"
              color="success"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </FormSection>
  );
};

CompanyCommentEditForm.defaultProps = {};
