import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { applyJob, getResumes } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  Radio,
} from "./../../../components";
import { ROUTER } from "../../../routes";

export const JobApplyModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.JOB_APPLY,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [defaultResumeId, setDefaultResumeId] = useState<string>();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    resumes: { data: resumes, isLoading: resumesLoading },
  } = useAppSelector((state) => state.resume);

  const { props } = modal;
  const { jobId, onSubmit } = props || {};

  const profile = account?.profile;
  const profileId = profile?.id;

  const templates = resumes
    ?.map(({ id, name, isDefault }) => ({ id, name, isDefault }))
    ?.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
    ?.map(({ id, name, isDefault }) => ({
      id,
      name,
      isDefault,
      label: isDefault ? "Default Resume" : name,
    }));

  const { values, setFieldValue, resetForm, handleSubmit } = useFormik({
    initialValues: {
      resumeId: resumes?.[0]?.id,
    },
    onSubmit: async (values) => {
      const { resumeId } = values;

      const data = {
        resumeId,
        profileId,
      };

      setLoading(true);

      const application = await dispatch(applyJob(jobId, data));
      const jobApplicationId = application?.id;
      if (!jobApplicationId) {
        setLoading(false);
        closeModal();
        resetForm();

        return toast({
          status: NotificationStatus.Error,
          message: "Resume Not Applied!",
        });
      }

      if (onSubmit) onSubmit();

      setLoading(false);
      resetForm();
      closeModal();

      toast({ status: NotificationStatus.Success, message: "Resume Applied!" });
    },
  });

  const { resumeId } = values || {};

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    if (!defaultResumeId) return;

    setFieldValue("resumeId", defaultResumeId, false);
  }, [defaultResumeId]);

  useEffect(() => {
    if (!templates?.length) return;

    setDefaultResumeId(
      templates?.find((i) => i?.isDefault)?.id || templates?.[0]?.id
    );
  }, [templates]);

  useEffect(() => {
    if (!profileId) return;

    dispatch(getResumes({ where: { profileId, isApplied: false } }));
  }, [profileId]);

  return (
    <Modal
      title="Apply"
      description="Please select if you want to apply using existing resume templates or Form."
      open={isOpen}
      onClose={handleClose}
    >
      <Container loading={resumesLoading}>
        <Form onSubmit={handleSubmit}>
          <div className="text-sm text-secondary break-words">
            <RadioGroup
              value={values?.resumeId}
              onChange={(value) => setFieldValue("resumeId", value)}
            >
              {templates?.map(({ id, label, name, isDefault }) => (
                <Radio key={id} label={label} value={id} />
              ))}
            </RadioGroup>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-[10px] mt-5">
            <div></div>
            <div className="flex flex-row gap-x-[5px]">
              <Button variant="text" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="success"
                loading={loading}
                disabled={loading || !resumeId}
              >
                Apply Resume
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};
