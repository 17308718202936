import React, { useState } from "react";

import { Popper, Box, Avatar, Menu } from "@mui/material";
import { Link, Button } from "./../../components";

interface IDropDownMenuItemProps {
  className?: string;
  button?: any;
  to?: string;
  icon?: any;
  onClick?: () => void;
}

export const DropdownMenuItem: React.FC<IDropDownMenuItemProps> = ({
  children,
  className,
  to,
  icon,
  onClick,
}) => (
  <div
    className={`${className} h-[48px] flex flex-row  justify-start items-center gap-x-[8px] font-medium text-sm cursor-pointer hover:brightness-150 px-[15px] py-[10px]`}
    onClick={onClick}
  >
    {icon ? <span className="">{icon}</span> : <></>}
    {to ? <Link to={to}>{children}</Link> : children}
  </div>
);

DropdownMenuItem.defaultProps = {
  className: "text-secondary",
  onClick: () => {},
};

interface IDropDownItemProps {
  className?: string;
  button?: any;
  to?: string;
  icon?: any;
  iconSize?: number;
  iconClassName?: string;
  onClick?: () => void;
}

export const DropdownItem: React.FC<IDropDownItemProps> = ({
  children,
  className,
  to,
  onClick,
  icon: Icon,
  iconSize,
  iconClassName,
}) => (
  <div
    className={`${className} flex flex-row justify-start items-center gap-x-[8px] font-medium font-gilroy cursor-pointer text-secondary hover:text-white`}
    onClick={onClick}
  >
    {Icon ? <Icon size={iconSize} className={`${iconClassName}`} /> : <></>}
    {to ? (
      <Link to={to}>
        <div className="py-[2px]">{children}</div>
      </Link>
    ) : (
      <div className="py-[2px]">{children}</div>
    )}
  </div>
);

DropdownItem.defaultProps = {
  className: "text-secondary",
  iconClassName: "",
  onClick: () => {},
};
