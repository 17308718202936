import React from "react";
import { CardList } from "../../components";

import { CompanyActivityCard } from "./../../features";

export const CompanyActivityList = ({ loading, data }) => (
  <CardList loading={loading} className="gap-y-[30px]">
    {data?.map((companyActivity) => (
      <CompanyActivityCard key={companyActivity?.id} {...companyActivity} />
    ))}
  </CardList>
);
