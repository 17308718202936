import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Form, FormSection, TextInput } from "../../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../../../hooks";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../../shared";
import { createResume } from "../../../../store";
import { ROUTER } from "../../../../routes";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "50 characters maximum")
    .required("Template Name is a required field"),
});

export const ResumeCreateForm = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        name: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        console.log({ values });
        if (!profileId) return;

        const data = {
          ...values,
          profileId,
        };

        setLoading(true);

        const created = await dispatch(createResume(data));
        if (!created?.id) {
          setLoading(false);

          return toast({
            status: NotificationStatus.Error,
            message: "Resume Template Not Created!",
          });
        }

        toast({
          status: NotificationStatus.Success,
          message: "Resume Template Created!",
        });

        setLoading(false);
        onClose();

        setURL({
          path: ROUTER.RESUME_BUILDER_TEMPLATE_EDIT(),
          params: { id: created.id },
        });
      },
    });

  const handleCancel = () => {
    onClose();
    resetForm();
  };

  return (
    <FormSection heading="Create Template">
      <Form onSubmit={handleSubmit}>
        <TextInput
          id="name"
          type="text"
          variant="standard"
          placeholder="Template Name"
          className="w-full h-[55px] flex flex-row items-center rounded-lg !bg-primaryLight !text-white border-[0px] px-[10px] py-[10px] "
          maxLength={50}
          value={values?.name}
          error={touched?.name ? errors?.name : null}
          onChange={handleChange}
        />
        <div className="flex flex-row justify-end gap-x-[5px] mt-[15px]">
          <Button variant="text" color="secondary" onClick={handleCancel}>
            Discard
          </Button>
          <Button
            type="submit"
            variant="outlined"
            loading={loading}
            className="w-full max-w-[150px]"
            color="success"
          >
            Create
          </Button>
        </div>
      </Form>
    </FormSection>
  );
};

ResumeCreateForm.defaultProps = {};
