import React, { useEffect } from "react";

import { PageContainerSwitch } from "../../components";
import {
  FriendListContainer,
  FriendRequestListContainer,
} from "./../../features";

export const FriendsContainer = () => {
  const pages = [
    {
      label: "Friends",
      component: FriendListContainer,
    },
    {
      label: "Requests",
      component: FriendRequestListContainer,
    },
  ];

  return <PageContainerSwitch tabs={pages} />;
};
