import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";
import { RBACSwitch } from "../../components";
import { Role } from "../../shared";

const { BusinessAdministrationContainer } = lazyload(
  () => import("../../features")
);

export const BusinessAdministrationPage = () => (
  <MainLayout>
    {RBACSwitch({
      elements: [
        {
          roles: [Role.Owner, Role.TeamLead, Role.Employee],
          component: () => <BusinessAdministrationContainer />,
        },
      ],
      error: true,
    })}
  </MainLayout>
);
