import React, { useState } from "react";

import { Popper } from "@mui/material";

import { Button } from "..";

export const Dropdown = ({ children, button }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open ? "simple-popper" : undefined;

  const handleClick = (e) => {
    // @ts-ignore
    const isClickedOutside = !e.currentTarget?.id === id;

    if (isClickedOutside) {
      console.log("Clicked Outside");
    }

    setAnchorEl(anchorEl ? null : e?.currentTarget);
    setOpen(!open);
  };

  return (
    <div>
      <div id={id} onClick={handleClick}>
        {button}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        style={{ zIndex: 100 }}
        // @ts-ignore
        disableEnforceFocus
      >
        <div className="relative min-w-[170px] min-h-[50px] bg-primaryBlack text-white box-border mt-[10px] px-[15px] py-[10px] rounded-lg">
          {children}
        </div>
      </Popper>
    </div>
  );
};

Dropdown.defaultProps = {
  iconSize: 18,
  iconClassName: "",
  button: () => <Button>Menu</Button>,
};
