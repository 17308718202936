import { ActionType } from "./action.enum";

// export const PermissionAction = { ...ActionType };

// export const PermissionObject = {
//   All: "all",
//   Account: "account",
//   Passport: "passport",
//   Profile: "profile",
//   Role: "role",
//   Permission: "permission",
//   Company: "company",
//   Employee: "employee",
//   Job: "job",
//   JobApplication: "job-application",
//   Post: "post",
//   Resume: "resume",
//   Education: "education",
//   Experience: "experience",
//   Verification: "verification",
//   Audit: "audit",
// };

export enum PermissionAction {
  All = "all",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export enum PermissionObject {
  All = "all",
  Account = "account",
  Passport = "passport",
  Profile = "profile",
  Company = "company",
  Employee = "employee",
  Job = "job",
  Post = "post",
  Resume = "resume",
  Education = "education",
  Experience = "experience",
}
