import React, { useEffect, useMemo } from "react";

import { Button, Checkbox } from "../../../../components";
import {
  ResumeExperienceCard,
  ResumeSectionCard,
  ResumeSectionCardBody,
} from "../../..";
import {
  getEducations,
  getExperiences,
  getResumeCovers,
} from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

export const ResumeExperienceSectionSelect = ({
  selected,
  setSelected,
  isChecked,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);
  const { experiences } = useAppSelector((state) => state.experience);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;
  const resumeExperiences = resume?.data?.experiences || [];

  const archivedExperiences = experiences?.data?.filter(
    ({ id }) =>
      !resumeExperiences
        ?.map(({ originId }) => originId)
        ?.filter((originId: string) => originId)
        ?.includes(id)
  );
  const data = [...resumeExperiences, ...archivedExperiences];

  useEffect(() => {
    setSelected(resumeExperiences?.map(({ id }) => id));
  }, [resumeExperiences]);

  useEffect(() => {
    if (!profileId || !resumeId) return;
    dispatch(getExperiences({ where: { profileId, isArchived: true } }));
  }, []);

  return (
    <ResumeSectionCard
      heading="Work Experience"
      // navbar={
      //   <Button color="success" variant="text">
      //     Verify
      //   </Button>
      // }
    >
      <ResumeSectionCardBody loading={experiences?.isLoading}>
        {data?.map((experience) => (
          <div
            key={experience?.id}
            className="flex flex-row justify-start items-start gap-x-[10px]"
          >
            <Checkbox
              value={experience?.id}
              checked={isChecked(experience?.id)}
              onChange={onChange}
            />
            <ResumeExperienceCard isActionMenu={false} {...experience} />
          </div>
        ))}
      </ResumeSectionCardBody>
    </ResumeSectionCard>
  );
};

ResumeExperienceSectionSelect.defaultProps = {};
