import React, { Suspense, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  FiDownload as ExportIcon,
  FiFileText as DelistIcon,
  FiUserPlus as InviteIcon,
  FiEdit3 as EditIcon,
  FiTrash2 as DeleteIcon,
} from "react-icons/fi";
import { MdSend as SendIcon } from "react-icons/md";

import { EventService } from "../../services";
import {
  ActionMenu,
  Avatar,
  BackButton,
  Button,
  Container,
  Form,
  Link,
  Loader,
  PageHeader,
  Searchbar,
  TextInput,
} from "../../components";
import { ChatList, ChatMessage, ChatMessageForm } from "./../../features";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useScroll,
} from "../../hooks";
import { ModalType, WebsocketEvents, IChat, IChatMessage } from "../../shared";
import { ROUTER } from "../../routes";
import { getChatById, setChat, setChats } from "../../store";

export const ChatContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { chatId } = useParams();

  const { scrollToRef } = useScroll();
  const { setModal } = useModal();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    chat: { data: chat, isLoading: chatLoading },
    chats: { data: chats, results: chatResults },
  } = useAppSelector((state) => state.chat);

  const inputRef = useRef<HTMLInputElement>(null);
  const latestMessageRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<IChatMessage[]>([]);

  const [newMessage, setNewMessage] = useState<IChatMessage | null>();
  const [latestMessage, setLatestMessage] = useState<IChatMessage | null>();

  const [scrolled, setScrolled] = useState(false);

  const { isOnline, userA, userB } = chat || {};

  const profileId = account?.profileId;
  const chatUser = chat?.user
    ? chat.user
    : [userA, userB]?.find((i) => i?.id !== profileId);

  const isLoading = !!chatLoading || !scrolled;
  const isChatSelected = !!chatId;
  const isChatEmpty = !messages?.length;

  const isIncomingMessage = (authorId?: string) =>
    authorId ? authorId !== profileId : false;

  const isLatestMessage = (length: number, index: number) =>
    index + 1 >= length;

  const handleMessageSend = (message?: IChatMessage) => {
    if (!message) return;

    setNewMessage(message);
  };

  const handleChatDelete = (chatId: string | undefined) => {
    if (!chatId) return;

    setModal({
      type: ModalType.CHAT_DELETE,
      props: {
        chatId,
        onSuccess: () => {
          redirect(ROUTER.CHATS);
        },
      },
    });
  };

  useEffect(() => {
    if (!newMessage) return;

    if (!newMessage?.isIncoming) {
      setTimeout(() => {
        if (latestMessageRef?.current) {
          scrollToRef(latestMessageRef);
        }
      }, 50);
    }

    setNewMessage(null);
    setMessages([...messages, newMessage]);
  }, [newMessage]);

  useEffect(() => {
    if (!latestMessage?.id) return;

    if (!scrolled) {
      setTimeout(() => {
        if (latestMessageRef?.current) {
          scrollToRef(latestMessageRef);
          setScrolled(true);
        }
      }, 50);
    }
  }, [latestMessage]);

  useEffect(() => {
    if (!chat?.id) return;

    // Updating Messages
    const messages = !!chat?.messages?.length
      ? [...chat?.messages]?.sort((a: IChatMessage, b: IChatMessage) => {
          const dateA = a?.createdAt ? new Date(a?.createdAt) : new Date();
          const dateB = b?.createdAt ? new Date(b?.createdAt) : new Date();

          return dateA?.getTime() - dateB?.getTime();
        })
      : [];

    const latestMessage = messages[messages?.length - 1];

    setMessages(messages);
    setLatestMessage(latestMessage);

    if (isChatEmpty) {
      setScrolled(true);
    }
  }, [chat]);

  useEffect(() => {
    setScrolled(false);
  }, [chatId, chat?.id]);

  useEffect(() => {
    if (!chatId) return;

    dispatch(getChatById(chatId));

    EventService.send({
      event: WebsocketEvents.SYNC_CHAT_START,
      data: { id: chatId },
    });

    return () => {
      EventService.send({
        event: WebsocketEvents.SYNC_CHAT_STOP,
        data: { id: chatId },
      });
    };
  }, [chatId]);

  useEffect(() => {
    if (inputRef.current) {
      console.log({ inputRef: inputRef.current, test: Date.now() });

      inputRef.current.innerText = "HELLO";
    }

    EventService.on("message", (message) => {
      const { event, data, results } = message || {};

      switch (event) {
        case WebsocketEvents.GET_CHATS:
          dispatch(setChats({ data, results }));
          break;
        case WebsocketEvents.GET_CHAT:
          dispatch(setChat(data));
          break;
        case WebsocketEvents.CHAT_MESSAGE_SUCCESS:
          break;
        case WebsocketEvents.CHAT_MESSAGE_ERROR:
          break;
        default:
          break;
      }
    });

    dispatch(setChat(null));
  }, []);

  return (
    <div className="flex flex-row w-full h-screen">
      <div
        className={`relative min-w-[280px] max-w-[280px] h-screen box-border flex flex-col box-border border-r border-solid border-primaryLight bg-primaryDark z-40
                  md:w-auto md:flex
                  ${chatId ? "xxs:hidden" : "xxs:w-full xxs:max-w-none"}
        `}
      >
        <div
          className="w-full h-screen overflow-y-scroll mt-[75px]
                    xxs:w-full xxs:h-auto xxs:overflow-y-auto
          "
        >
          <div className="w-full">
            <div className="w-full h-[90px] flex flex-row justify-start items-center px-[25px] border-b-none border-primaryMid">
              <PageHeader className="mb-[0px]" back={false}>
                Chats ({chatResults || 0})
              </PageHeader>
            </div>

            <ChatList />
          </div>

          {/* <span className="text-red-500">
            {JSON.stringify({
              isChatEmpty,
              isChatSelected,
              isLoading,
              scrolled,
              latestMessage,
            })}
          </span> */}
        </div>
      </div>

      <div
        className={`flex flex-row justify-between items-start w-full h-screen box-border pt-[75px]
                  ${isChatSelected ? "xxs:flex" : "xxs:hidden md:flex"}
                  
        `}
      >
        <Suspense fallback={<Loader />}>
          <Container
            className="flex flex-col w-full h-full"
            loadingClassName="!bg-primaryDark"
            hiddenLoading={isLoading}
            hidden={!isChatSelected}
            hiddenComponent={
              <div className="w-full h-full flex flex-row justify-center items-center">
                <span className="text-secondary text-base font-medium">
                  Select some chat from the list on the left.
                </span>
              </div>
            }
          >
            <Container
              loadingClassName="w-full max-h-[90px] min-h-[90px] flex flex-row justify-between items-center box-border px-[20px]"
              className="w-full max-h-[90px] min-h-[90px] flex flex-row justify-between items-center box-border px-[20px] text-white border-b-[1px] border-solid border-primaryMid"
            >
              <div className="flex flex-row justify-start items-center gap-x-[10px]">
                <div className="xxs:flex md:hidden">
                  <BackButton backTo={ROUTER.CHATS} />
                </div>
                <Avatar
                  to={ROUTER.USER_DETAIL(chatUser?.id)}
                  size={40}
                  src={chatUser?.avatar}
                >
                  {chatUser?.firstName}
                </Avatar>
                <div className="flex flex-col items-start gap-y-[8px] -mt-[3.5  px]">
                  <span className="text-white text-base font-bold leading-[0px]">
                    <Link to={ROUTER.USER_DETAIL(chatUser?.id)}>
                      {chatUser?.firstName ? chatUser?.firstName : ""}{" "}
                      {chatUser?.lastName ? chatUser?.lastName : ""}
                    </Link>
                  </span>

                  <span className="text-secondary text-xs font-medium leading-[0px]">
                    {isOnline ? (
                      <span className="text-success">Online</span>
                    ) : (
                      <span className="text-secondary">Offline</span>
                    )}
                  </span>
                </div>
              </div>
              <div>
                <ActionMenu
                  direction="horizontal"
                  iconClassName="text-secondary"
                  customActions={[
                    {
                      label: "Invite",
                      icon: <InviteIcon />,
                      onClick: () => {},
                    },
                    {
                      label: "Delete",
                      icon: <DeleteIcon />,
                      className: "!text-danger",
                      onClick: () => handleChatDelete(chatId),
                    },
                  ]}
                />
              </div>
            </Container>
            <Container
              loadingClassName="flex flex-row justify-center items-center w-full h-screen"
              className="flex flex-row justify-between items-start w-full h-screen overflow-y-scroll box-border"
            >
              <div className="w-full h-auto flex flex-col gap-y-[20px] px-[25px] pt-[25px]">
                {messages?.map((message, index) => (
                  <ChatMessage
                    key={message?.id}
                    ref={
                      isLatestMessage(messages.length, index)
                        ? latestMessageRef
                        : null
                    }
                    isIncoming={isIncomingMessage(message?.author?.id)}
                    isLatest={isLatestMessage(messages.length, index)}
                    {...message}
                  />
                ))}
              </div>
            </Container>

            <div className="w-full h-auto max-h-[220px] flex flex-row justify-between items-center text-white border-t-[1px] border-solid border-primaryMid">
              <ChatMessageForm ref={inputRef} onSubmit={handleMessageSend} />
            </div>
          </Container>
        </Suspense>
      </div>
    </div>
  );
};
