export enum NotificationStatus {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum NotificationPriority {
  Top = 'top',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum NotificationType {
  EmploymentInvite = 'employment-invite',
  FriendRequest = 'friend-request',
  VerificationRequest = 'verification-request',
}
