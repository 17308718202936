export const ROUTER = {
  TEST: "/test",

  ROOT: "/",

  LOGIN: "/login",
  REGISTER: "/register",

  REGISTER_PASSPORT_SCAN: "register",
  REGISTER_PASSPORT_DATA: "register/passport",
  REGISTER_EMAIL: "register/email",
  REGISTER_EMAIL_CONFIRM: "register/confirm",
  REGISTER_PASSWORD: "register/password",

  PASSWORD_RESET: "/password-reset",
  PASSWORD_RESET_EMAIL: "/password-reset/email",
  PASSWORD_RESET_CONFIRM: "/password-reset/confirm",

  INVITE_DETAIL: (inviteId?: string) => `/invites/${inviteId || ":inviteId"}`,

  // Company
  COMPANY_DETAIL_ALIAS: (companyAlias?: string) =>
    `/${companyAlias || ":companyAlias"}`,
  COMPANY_DETAIL: (companyId?: string) =>
    `/companies/${companyId || ":companyId"}`,

  JOB_ROOT: "/jobs",
  JOB_SEARCH: "/job-search",
  JOB_CREATE: "/jobs/create",
  JOB_DETAIL: (id?: string) => `/jobs/${id || ":id"}`,
  JOB_EDIT: (id?: string) => `/jobs/${id || ":id"}/edit`,
  JOB_APPLICATION_LIST: (jobId?: string) =>
    `/jobs/${jobId || ":id"}/applications`,
  JOB_APPLICATION_DETAIL: (jobId?: string, jobApplicationId?: string) =>
    `/jobs/${jobId || ":id"}/applications/${
      jobApplicationId || ":jobApplicationId"
    }`,

  HOME: "/home",
  NEWS: "/news",
  EDUCATION: "/education",
  CREATORS: "/creators",

  POST_ROOT: "/posts",
  POST_DETAIL: (id?: string) => `/posts/${id || ":id"}`,
  POST_EDIT: (id?: string) => `/posts/${id || ":id"}/edit`,

  // Events
  EVENTS: "/schedule",

  // Admin
  ADMIN: "/admin",
  ADMIN_BUSINESS_VERIFICATION_DETAIL: (id?: string) =>
    `/admin/business-verifications/${id || ":id"}`,
  ADMIN_BUSINESS_AUDIT_DETAIL: (auditId?: string) =>
    `/admin/business-audits/${auditId || ":auditId"}`,

  // Business
  BUSINESS_ADMINISTRATION: "/administration",
  BUSINESS_AUDIT_DETAIL: (auditId?: string) =>
    `/administration/audits/${auditId || ":auditId"}`,

  // Audit
  AUDITS: "/audits",

  // Users
  USERS_ROOT: "/users",
  FRIENDS: "/friends",
  USER_DETAIL: (userId?: string) => `/users/${userId || ":userId"}`,
  USER_FRIENDS: (userId?: string) => `/users/${userId || ":userId"}/friends`,
  USER_GALLERY: (userId?: string) => `/users/${userId || ":userId"}/gallery`,
  USER_GALLERY_ITEM_DETAIL: (userId?: string, itemId?: string) =>
    `/users/${userId || ":userId"}/gallery/${itemId || ":itemId"}`,

  // Employee
  EMPLOYEE_SEARCH: "/employees",
  EMPLOYEE_DETAIL: (employeeId?: string) =>
    `/employees/${employeeId || ":employeeId"}`,

  // Resumes
  RESUME_ROOT: "/resumes",
  RESUME_DETAIL: (id?: string) => `/resumes/${id || ":id"}`,
  RESUME_BUILDER_ROOT: "/resume-builder",
  RESUME_BUILDER_TEMPLATE_DEFAULT: "/resume/builder/default",
  RESUME_BUILDER_TEMPLATE_ALL: `/resume-builder/all`,
  RESUME_BUILDER_TEMPLATE_DETAIL: (id?: string) =>
    `/resume-builder/${id || ":id"}`,
  RESUME_BUILDER_TEMPLATE_EDIT: (id?: string) =>
    `/resume-builder/${id || ":id"}/edit`,

  BUSINESS_REGISTRATION: "/register-business",

  // Search
  SEARCH: (query?: string) => `/search${query ? `?q=${query}` : ""}`,

  // Notifications
  NOTIFICATIONS: "/notifications",

  // Meet
  MEET: "/meet",

  // Chats
  CHATS: "/chats",
  CHAT_DETAIL: (chatId?: string) => `/chats/${chatId || ":chatId"}`,
};
