export class StoreManager {
  static parse = (data: any) => JSON.parse(JSON.stringify(data));

  static sort = (data: any[]) =>
    data?.sort(
      (a: any, b: any) =>
        new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
    );

  static appendChild =
    (keys: any, options?: any) => (state: any, action: any) => {
      const { payload } = action;

      const { parent, child, parentKey } = keys || {};
      const { sort } = options || {};

      if (!parent) return;

      const id = payload?.[parentKey];
      const elements = state?.[parent]?.data?.map(this.parse);

      const currentElement = elements?.find((el: any) => el?.id === id);
      const parentIndex = elements?.findIndex((el: any) => el?.id === id);

      const children = this.sort([...currentElement[child], payload]);

      const result = { ...currentElement, [child]: children };

      state[parent].data[parentIndex] = result;
    };

  static insertChild =
    (keys: any, options?: any) => (state: any, action: any) => {
      const { payload } = action;

      const { parent, child, parentKey } = keys || {};
      const { sort } = options || {};

      if (!parent) return;

      const id = payload?.[parentKey];
      const elements = state?.[parent]?.data?.map(this.parse);

      const currentElement = elements?.find((el: any) => el?.id === id);
      const parentIndex = elements?.findIndex((el: any) => el?.id === id);

      const children = this.sort([...currentElement[child], payload]);
      const childIndex = children?.findIndex(
        (el: any) => el?.id === payload?.id
      );

      children[childIndex] = payload;

      const result = { ...currentElement, [child]: children };

      state[parent].data[parentIndex] = result;
    };

  static removeChild =
    (keys: any, options?: any) => (state: any, action: any) => {
      const { payload } = action;

      console.log({ payload, keys, options });

      const { parent, child, parentKey } = keys || {};
      const { sort } = options || {};

      if (!parent) return;

      const id = payload?.[parentKey];

      const elements = state?.[parent]?.data?.map(this.parse);

      const parentElement = elements?.find((el: any) => el?.id === id);
      const parentIndex = elements?.findIndex((el: any) => el?.id === id);

      const children = parentElement[child]?.filter(
        ({ id }: any) => id !== payload?.id
      );
      const childIndex = children?.findIndex(
        (el: any) => el?.id === payload?.id
      );

      const result = { ...parentElement, [child]: children };

      console.log({ children, childIndex, result });

      state[parent].data[parentIndex] = result;
    };

  static append(state: any, payload: any) {
    state = [...state, payload];
  }

  static insert(state: any, payload: any) {
    const id = payload.id;

    const elements = state?.map(({ id }: any) => id) || [];
    const index = elements.indexOf(id);

    state[index] = payload;
  }

  static pop(state: any, id: any) {
    state = state.filter((el: any) => el?.id !== id);
  }
}

const sortByCreatedAt = (a: any, b: any) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

export const appendToStore =
  (key: any, options?: any) => (state: any, action: any) => {
    const { payload } = action;

    const entity = key?.toString();
    if (!entity) return;

    const data = [
      ...state[entity]?.data?.filter(({ id }: any) => id !== payload?.id),
      payload,
    ].sort(options?.sort || sortByCreatedAt);

    state[entity] = { ...state[entity], data };
  };

export const popFromStore = (key: any) => (state: any, action: any) => {
  console.log("popping");

  const entity = key?.toString();
  if (!entity) return;

  const { id } = action.payload || {};

  const results = state[entity]?.results;
  const data = state[entity].data?.filter((el: any) => el?.id !== id);

  console.log({ data });

  state[entity] = {
    ...state[entity],
    results: results ? results - 1 : results || 0,
    data: data ? data : [],
  };
};

export const appendToStoreEntity =
  (entity: any, key: any, options?: any) => (state: any, action: any) => {
    const { payload } = action;

    const keys = {
      entity: entity?.toString(),
      key: key?.toString(),
    };
    if (!keys?.entity || !keys?.key) return;

    const data = [
      ...state[entity]?.data?.[key]?.filter((i: any) => i?.id !== payload?.id),
      payload,
    ].sort(options?.sort || sortByCreatedAt);

    console.log(`${entity}.data.${key}`, data);

    state[entity].data[key] = data;
  };

export const popFromStoreEntity =
  (entity: any, key: any, options?: any) => (state: any, action: any) => {
    const { payload: id } = action;

    const keys = {
      entity: entity?.toString(),
      key: key?.toString(),
    };

    if (!keys?.entity || !keys?.key) return;

    const data = [
      ...state[entity]?.data?.[key]?.filter((el: any) => el?.id !== id),
    ];

    state[entity].data[key] = data;
  };
