import { IDrawer } from "../../../types";
import { AppDispatch } from "../../store";

import { appSlice } from "./app.slice";

export const setURL = (payload: any) => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.setURL(payload));
};

export const closeModal = () => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.closeModal());
};

export const updateModal = (data: any) => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.updateModal(data));
};

export const setDrawer = (data: IDrawer) => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.setDrawer(data));
};

export const setAppFilters = (data: any) => (dispatch: AppDispatch) => {
  dispatch(appSlice.actions.setFilters(data));
};
