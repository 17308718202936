import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(relativeTime);

export const date = dayjs;

// date.ago = (d = new Date()) => {
//   let result = 0;
//   let unit = "minutes";

//   const diff = Math.abs(date(d).diff(new Date(), "minutes"));

//   if (diff > 0) {
//     result = Math.abs(date(d).diff(new Date(), "minutes"));
//     unit = "minutes";
//   }

//   if (diff >= 60) {
//     result = Math.abs(date(d).diff(new Date(), "hours"));
//     unit = "hours";
//   }

//   if (diff >= 1440) {
//     result = Math.abs(date(d).diff(new Date(), "days"));
//     unit = "days";
//   }

//   return result > 0 ? `${result} ${unit} ago` : "now";
// };
