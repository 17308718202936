import { createSlice } from "@reduxjs/toolkit";

import { INotification } from "../../../shared";

import { AppReducerState } from "../../store";

interface INotificationState {
  notifications: AppReducerState<INotification[]>;
}

const initialState: INotificationState = {
  notifications: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const notificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },

    getNotificationsRequest: (state) => {
      state.notifications.isLoading = true;
      state.notifications.data = [];
      state.notifications.results = 0;
    },
    getNotificationsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.notifications.data = data;
      state.notifications.results = results;
      state.notifications.isLoading = false;
    },
    getNotificationsError: (state, action) => {
      state.notifications.isLoading = false;
      state.notifications.error = action.payload;
    },
  },
});

export const notificationReducer = notificationSlice.reducer;
