import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarAccordion,
} from "../../../components";

import { useAppDispatch, useModal, useToast } from "../../../hooks";
import { date } from "../../../utils";
import { removeJob, updateJob } from "../../../store";
import { ROUTER } from "../../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { ISidebar } from "@types";

export const JobApplicationFilterSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();

  return (
    <Sidebar {...props}>
      <SidebarSection heading="Filters" className="border-none">
        <SidebarAccordion summary="Locations" />
        <SidebarAccordion summary="Experience" />
        <SidebarAccordion summary="Education" />
        <SidebarAccordion summary="Companies" />
        <SidebarAccordion summary="Industry" />
        <SidebarAccordion summary="Certifications" />
        <SidebarAccordion summary="Rating" />
        <SidebarAccordion summary="Bookmarked Applicants" />
      </SidebarSection>
    </Sidebar>
  );
};
