import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { postSlice } from "./post.slice";

export const appendPosts = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(postSlice.actions.appendPosts(payload));

export const popPosts = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(postSlice.actions.popPosts(payload));

export const getPosts = (options?: any) => async (dispatch: AppDispatch) => {
  try {
    const { where } = options || {};

    dispatch(postSlice.actions.getPostsRequest());

    const response = await ApiService.post("posts/query", {
      where,
    });
    const { data, results } = response.data;

    dispatch(postSlice.actions.getPostsSuccess({ data, results }));

    return data;
  } catch (e: any) {
    console.log({ e });
    dispatch(postSlice.actions.getPostsError(e.response?.data?.error));
  }
};

export const getPostFeed = (options?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.getPostFeedRequest());

    const response = await ApiService.post("posts/feed", options);
    const { data, results } = response.data;

    dispatch(postSlice.actions.getPostFeedSuccess({ data, results }));

    return data;
  } catch (e: any) {
    console.log({ e });
    dispatch(postSlice.actions.getPostFeedError(e.response?.data?.error));
  }
};

export const getPost = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.getPostRequest());

    const response = await ApiService.get(`posts/${id}`);
    const { data } = response.data;

    dispatch(postSlice.actions.getPostSuccess(data));

    console.log({ data });

    return data;
  } catch (e: any) {
    dispatch(postSlice.actions.getPostError(e.response?.data?.error));
  }
};

export const createPost = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.createPostRequest());

    const response = await ApiService.post("posts", payload, {
      headers: { "Content-Type": "multipart/form-data;" },
    });
    const { data } = response.data;

    console.log(data);
    console.log({ id: data?.id });

    dispatch(postSlice.actions.createPostSuccess(data));
    dispatch(postSlice.actions.appendPosts(data));

    return data;
  } catch (e: any) {
    dispatch(postSlice.actions.createPostError(e.response?.data?.error));
  }
};

export const updatePost =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(postSlice.actions.updatePostRequest());

      const response = await ApiService.put(`posts/${id}`, payload);
      const { data } = response.data;

      dispatch(postSlice.actions.updatePostSuccess(data));

      return { id };
    } catch (e: any) {
      console.log({ e });
      dispatch(postSlice.actions.updatePostError(e.response?.data?.error));
    }
  };

export const removePost = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.removePostRequest());

    const response = await ApiService.delete(`posts/${id}`);
    const { data } = response.data;

    dispatch(postSlice.actions.removePostSuccess(data));
    dispatch(postSlice.actions.popPosts({ id }));

    return { id };
  } catch (e: any) {
    console.log({ e });
    dispatch(postSlice.actions.removePostError(e.response?.data?.error));
  }
};

export const likePost = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.likePostRequest());

    const response = await ApiService.put(`posts/${id}/likes`);
    const { data } = response.data;

    dispatch(postSlice.actions.likePostSuccess(data));

    return { id };
  } catch (e: any) {
    console.log({ e });
    dispatch(postSlice.actions.likePostError(e.response?.data?.error));
  }
};

export const dislikePost = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postSlice.actions.dislikePostRequest());

    const response = await ApiService.delete(`posts/${id}/likes`);
    const { data } = response.data;

    dispatch(postSlice.actions.dislikePostSuccess(data));

    return { id };
  } catch (e: any) {
    console.log({ e });
    dispatch(postSlice.actions.dislikePostError(e.response?.data?.error));
  }
};

export const getPostComments =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(postSlice.actions.getPostCommentsRequest());

      const response = await ApiService.get(`posts/${id}/comments`);
      const { data } = response.data;

      dispatch(postSlice.actions.getPostCommentsSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(postSlice.actions.getPostCommentsError(e.response?.data?.error));
    }
  };

export const addPostComment =
  (postId, payload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(postSlice.actions.addPostCommentRequest());

      const response = await ApiService.post(
        `posts/${postId}/comments`,
        payload
      );
      const { data } = response.data;

      dispatch(postSlice.actions.addPostCommentSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(postSlice.actions.addPostCommentError(e.response?.data?.error));
    }
  };

// export const removePostComment =
//   (postId, commentId: string) => async (dispatch: AppDispatch) => {
//     try {
//       dispatch(postSlice.actions.removePostCommentRequest());

//       const response = await ApiService.delete(
//         `posts/${postId}/comments/${commentId}`
//       );
//       const { data } = response.data;

//       dispatch(postSlice.actions.removePostCommentSuccess(data));

//       return { id: commentId };
//     } catch (e: any) {
//       console.log({ e });
//       dispatch(
//         postSlice.actions.removePostCommentError(e.response?.data?.error)
//       );
//     }
//   };
