import React from "react";

import { MainLayout } from "../../layouts";
import {
  JobApplicationDetailSidebar,
  JobApplicationDetailRightSidebar,
} from "../../features";
import { lazyload } from "../../utils";

const { JobApplicationDetailContainer } = lazyload(
  () => import("../../features")
);

export const JobApplicationDetailPage = () => (
  <MainLayout
    leftSidebar={JobApplicationDetailSidebar}
    rightSidebar={JobApplicationDetailRightSidebar}
  >
    <JobApplicationDetailContainer />
  </MainLayout>
);
