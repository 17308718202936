import React, { useEffect, useState } from "react";

import { Tag, TextInput, InputError } from "./../../../components";

interface ITagInputProps {
  value?: string[] | null;
  error?: string | string[] | null;
  setError?: (error: string) => void;
  separators?: string[];
  onExisting?: (e?: any) => void;
  onRemove?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  [x: string]: any;
}

export const TagInput: React.FC<ITagInputProps> = ({
  value,
  error,
  setError,
  separators,
  onExisting,
  onRemove,
  onChange,
  onBlur,
  ...props
}) => {
  const [tags, setTags] = useState<string[]>(value || []);
  const [currentValue, setCurrentValue] = useState("");

  const isEmpty = (value) => value?.length <= 0 || false;
  const isDuplicated = (tag) =>
    tags
      ?.map((i) => i?.toLowerCase()?.trim())
      ?.includes(tag?.toLowerCase()?.trim()) || false;

  const handleOnKeyUp = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const code = e.keyCode;
    const value = e.key;

    const isBackspace = value === "Backspace";
    const isSeparated = separators?.includes(value);
    const isValid = code >= 48 && code <= 90 && !isBackspace && !isSeparated;

    if (e.ctrlKey) return;

    if (isValid) {
      setCurrentValue((currentValue + value)?.trim());
    }

    if (isBackspace) {
      setCurrentValue(currentValue.slice(0, currentValue.length - 1));
    }

    if (isSeparated) {
      if (!isDuplicated(currentValue) && !isEmpty(currentValue)) {
        setTags([...tags, currentValue]);
      }

      setCurrentValue("");
    }
  };

  const handleDelete = (text: string) => {
    setTags(tags?.filter((tag) => tag !== text));

    if (onRemove) {
      onRemove(text);
    }
  };

  useEffect(() => {
    if (onChange) onChange(tags);
  }, [tags]);

  useEffect(() => {
    setTags(value || []);
  }, [value]);

  return (
    <div>
      <div
        className={`${
          !tags?.length
            ? Boolean(error?.toString()?.length)
              ? "border border-danger rounded-lg"
              : ""
            : ""
        }`}
      >
        <TextInput
          variant="filled"
          className={`
            bg-primaryLight h-auto text-white
        `}
          labelClassName="text-secondary"
          value={currentValue}
          error={null}
          onKeyDown={handleOnKeyUp}
          onBlur={onBlur}
          {...props}
        />
        {tags?.length ? (
          <div
            className={`flex flex-wrap justify-start items-center gap-x-[5px] gap-y-[5px] px-[10px] py-[10px] w-full bg-primaryLight rounded-b-lg`}
          >
            {tags?.map((tag, key) => (
              <Tag
                key={`${tag}-${key}`}
                className="flex flex-row justify-center items-center h-[35px] px-[8px] bg-primaryMid box-border rounded-lg h-[20px] text-xs font-medium capitalize"
                onDelete={handleDelete}
              >
                {tag}
              </Tag>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      <InputError className="mt-[5px]" error={error?.toString() || null} />
    </div>
  );
};

TagInput.defaultProps = {
  value: [],
  separators: ["Enter", ","],
};
