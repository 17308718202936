import React from "react";
import { SVGIcon } from "./SVGIcon";

export const NewsIcon = (props) => (
  <SVGIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8575 1.5H12.1432C14.46 1.5 15.75 2.835 15.75 5.1225V12.87C15.75 15.195 14.46 16.5 12.1432 16.5H5.8575C3.5775 16.5 2.25 15.195 2.25 12.87V5.1225C2.25 2.835 3.5775 1.5 5.8575 1.5ZM6.06 4.995V4.9875H8.30175C8.625 4.9875 8.8875 5.25 8.8875 5.57175C8.8875 5.9025 8.625 6.165 8.30175 6.165H6.06C5.73675 6.165 5.475 5.9025 5.475 5.58C5.475 5.2575 5.73675 4.995 6.06 4.995ZM6.06 9.555H11.94C12.2625 9.555 12.525 9.2925 12.525 8.97C12.525 8.6475 12.2625 8.38425 11.94 8.38425H6.06C5.73675 8.38425 5.475 8.6475 5.475 8.97C5.475 9.2925 5.73675 9.555 6.06 9.555ZM6.06 12.9825H11.94C12.2393 12.9525 12.465 12.6968 12.465 12.3975C12.465 12.09 12.2393 11.835 11.94 11.805H6.06C5.835 11.7825 5.6175 11.8875 5.4975 12.0825C5.3775 12.27 5.3775 12.5175 5.4975 12.7125C5.6175 12.9 5.835 13.0125 6.06 12.9825Z"
    />
  </SVGIcon>
  //fill="#7887A4"
);
