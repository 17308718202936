import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { IJob, ModalType, Role } from "../../../shared";
import { Button, Container, RBAC } from "../../../components";

import { ROUTER } from "../../../routes";
import { useModal, useProfile } from "../../../hooks";

interface IJobDetailButtonsProps {
  loading?: boolean;
  job: IJob;
}

export const JobDetailButtons: React.FC<IJobDetailButtonsProps> = ({
  loading,
  job,
}) => {
  const redirect = useNavigate();

  const { setModal } = useModal();
  const { profile } = useProfile();

  const [isApplied, setApplied] = useState(false);
  const [applicationCount, setApplicationCount] = useState(0);

  const { id: jobId, jobApplication } = job || {};

  const jobApplicationId = jobApplication?.id;
  const profileId = profile?.id;

  const handleEdit = () => {
    if (!jobId) return;

    redirect(ROUTER.JOB_EDIT(jobId));
  };

  const handleDelete = () => {
    setModal({
      type: ModalType.JOB_DELETE,
      props: job,
    });
  };

  const handleApply = () => {
    setModal({
      type: ModalType.JOB_APPLY,
      props: {
        job,
        jobId,
        profileId,
        onSubmit: () => {
          setApplied(true);
          setApplicationCount(applicationCount + 1);
        },
      },
    });
  };

  const handleWithdraw = () => {
    setModal({
      type: ModalType.JOB_WITHDRAW,
      props: {
        job,
        jobId,
        profileId,
        onSubmit: () => {
          setApplied(false);
          setApplicationCount(applicationCount - 1);
        },
      },
    });
  };

  useEffect(() => {
    setApplied(job?.isApplied || false);
  }, [job?.isApplied]);

  useEffect(() => {
    setApplicationCount(job?.applicationCount || 0);
  }, [job?.applicationCount]);

  return (
    <Container loading={loading} className="flex flex-col gap-y-[10px]">
      <RBAC roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}>
        <>
          <Button
            color="secondary"
            variant="outlined"
            className="xxs:flex md:hidden"
            to={ROUTER.JOB_APPLICATION_LIST(jobId)}
          >
            View Applications
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={handleEdit}
          >
            Edit Job
          </Button>
          <Button
            color="danger"
            variant="outlined"
            disabled={loading}
            onClick={handleDelete}
          >
            Delete Job
          </Button>
        </>
      </RBAC>
      <RBAC roles={[Role.User]}>
        <>
          {isApplied ? (
            <>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleWithdraw}
              >
                Withdraw
              </Button>
              <Button
                color="primary"
                variant="outlined"
                to={ROUTER.JOB_APPLICATION_DETAIL(jobId, jobApplicationId)}
              >
                View Application
              </Button>
            </>
          ) : (
            <Button
              color="success"
              variant="outlined"
              disabled={loading}
              onClick={handleApply}
            >
              Apply Now
            </Button>
          )}
        </>
      </RBAC>
    </Container>
  );
};

JobDetailButtons.defaultProps = {
  loading: false,
};
