import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { UserFriendsContainer } = lazyload(() => import("../../features"));

export const UserFriendsPage = () => (
  <MainLayout>
    <UserFriendsContainer />
  </MainLayout>
);
