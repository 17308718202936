import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { UserGalleryContainer } = lazyload(() => import("../../features"));

export const UserGalleryPage = () => (
  <MainLayout>
    <UserGalleryContainer />
  </MainLayout>
);
