import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  TextInput,
  Form,
  BasicDatePicker,
  FormSection,
  Checkbox,
} from "../../../../components";
import { ROUTER } from "../../../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useScreen,
  useToast,
} from "../../../../hooks";
import { createEducation, createResumeEducation } from "../../../../store";
import { date, FormValidator } from "../../../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../../shared";

interface IResumeEducationCreateForm {
  university: string;
  degree: string;
  isEnrolled?: boolean;
  startedAt?: Date | null;
  finishedAt?: Date | null;
}

const schema = Yup.object().shape({
  university: Yup.string()
    .max(50, "Exceeded maximum character length of 50")
    .required("University is a required field"),
  degree: Yup.string()
    .max(50, "Exceeded maximum character length of 50")
    .required("Degree is a required field"),
  startedAt: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .max(date().add(1, "day").toDate(), "Date can't be in future")
    .required("From is a required field"),
  finishedAt: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .max(date().add(1, "day").toDate(), "Date can't be in future")
    .required("To is a required field"),
});

const draftSchema = Yup.object().shape({
  university: Yup.string()
    .max(50, "Exceeded maximum character length of 50")
    .required("University is a required field"),
  degree: Yup.string()
    .max(50, "Exceeded maximum character length of 50")
    .required("Degree is a required field"),
});

export const ResumeEducationCreateForm = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading(false);
  const { isMobile } = useScreen();
  const { toast } = useToast();
  const [validationSchema, setValidationSchema] = useState(schema);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);

  const profile = account?.profile;
  const profileId = profile?.id;

  const resumeId = resume?.data?.id;

  const form = useFormik<IResumeEducationCreateForm>({
    initialValues: {
      university: "",
      degree: "",
      isEnrolled: false,
      startedAt: null,
      finishedAt: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = { ...values, profileId };

      setLoading(true);

      const created = await dispatch(
        resumeId ? createResumeEducation(resumeId, data) : createEducation(data)
      );
      if (!created) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Education Not Created!",
        });
      }

      toast({
        status: NotificationStatus.Success,
        message: "Education Created!",
      });

      setLoading(false);
      handleCancel();
    },
  });

  const validator = new FormValidator<IResumeEducationCreateForm>(form);

  const { values, resetForm, setFieldValue, handleChange, handleSubmit } = form;

  const handleCancel = () => {
    onCancel();
    resetForm();
  };

  const handleSaveDraft = async (e) => {
    //@ts-ignore
    await setValidationSchema(draftSchema);

    setFieldValue("isDraft", true, true);
    handleSubmit();
  };

  useEffect(() => {
    setValidationSchema(schema);
  }, []);

  return (
    <FormSection heading="Create Education">
      <Form
        onSubmit={handleSubmit}
        className="w-full flex flex-col text-white mt-5 gap-y-3"
      >
        <div className="flex flex-row justify-start gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
          <TextInput
            id="university"
            label="University"
            type="text"
            variant="filled"
            className="!bg-primaryDark !text-white"
            controlClassName="basis-1/2"
            labelClassName="text-secondary"
            required
            value={values?.university}
            error={validator.isInputValid("university")}
            onChange={handleChange}
          />
          <TextInput
            id="degree"
            label="Degree"
            type="text"
            variant="filled"
            className="!bg-primaryDark !text-white"
            controlClassName="basis-1/2"
            labelClassName="text-secondary"
            inputClassName="text-inherit"
            required
            value={values?.degree}
            error={validator.isInputValid("degree")}
            onChange={handleChange}
          />
        </div>

        <div className="flex text-white h-auto gap-x-4 gap-y-[10px] md:flex-row xxs:flex-col">
          <div className="flex flex-row basis-1/2 gap-x-4 gap-y-[10px] md:flex-row xxs:flex-col">
            <BasicDatePicker
              id="startedAt"
              label="From"
              value={values?.startedAt}
              error={validator.isInputValid("startedAt")}
              className="!bg-primaryDark !text-white"
              onChange={(value) => setFieldValue("startedAt", value, true)}
              required
              disableFuture
            />
            <BasicDatePicker
              id="finishedAt"
              label="To"
              value={values?.finishedAt}
              error={validator.isInputValid("finishedAt")}
              className="!bg-primaryDark !text-white"
              onChange={(value) => setFieldValue("finishedAt", value, true)}
              required={!values?.isEnrolled}
              disabled={values?.isEnrolled}
              disableFuture
            />
          </div>
          <div className="basis-1/2 h-[55px] flex flex-row justify-start items-center">
            <Checkbox
              id="isEnrolled"
              label="Present"
              checked={values?.isEnrolled}
              onChange={() => {
                setFieldValue("isEnrolled", !values.isEnrolled, true);
                setFieldValue("finishedAt", new Date().toDateString(), true);
              }}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between items-center mt-2 gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <Button
            color="danger"
            variant={isMobile ? "outlined" : "text"}
            className="md:w-auto xxs:w-full"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <div className="flex flex-row justify-between items-center gap-x-[10px] gap-y-[10px] md:w-auto xxs:w-full md:flex-row xxs:flex-col-reverse">
            <Button
              color="secondary"
              variant={isMobile ? "outlined" : "text"}
              className="md:w-auto xxs:w-full"
              disabled={loading}
              onClick={handleSaveDraft}
            >
              Save Draft
            </Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              className="min-w-[100px] md:w-auto xxs:w-full"
              loading={loading}
            >
              Create
            </Button>
          </div>
        </div>
      </Form>
    </FormSection>
  );
};

ResumeEducationCreateForm.defaultProps = {};
