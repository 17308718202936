import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ISidebar } from "@types";
import { ModalType, Role } from "../../../shared";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  RBAC,
  Container,
  Avatar,
  SidebarContent,
} from "../../../components";
import {
  DevelopingFeatureInfoMessage,
  JobDetailButtons,
} from "../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../hooks";
import { date, renderJobSalary } from "../../../utils";
import { ROUTER } from "../../../routes";

export const JobDetailSidebar: React.FC<ISidebar> = (props) => {
  const redirect = useNavigate();

  const { setModal } = useModal();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    job: { data: job, isLoading },
  } = useAppSelector((state) => state.job);

  const [isApplied, setApplied] = useState(false);
  const [applicationCount, setApplicationCount] = useState(0);

  const profile = account?.profile;
  const profileId = profile?.id;
  const jobId = job?.id;
  const jobApplicationId = job?.jobApplication?.id;
  const companyId = job?.companyId;

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  const { minSalary = 0, maxSalary = 0, salaryDependsOnExperience } = job || {};

  const handleEdit = (jobId?: string) => {
    if (!jobId) return;

    redirect(ROUTER.JOB_EDIT(jobId));
  };

  const handleDelete = (props) => {
    setModal({
      type: ModalType.JOB_DELETE,
      props,
      redirectTo: ROUTER.JOB_ROOT,
    });
  };

  const handleApply = () => {
    setModal({
      type: ModalType.JOB_APPLY,
      props: {
        job,
        jobId,
        profileId,
        onSubmit: () => {
          setApplied(true);
          setApplicationCount(applicationCount + 1);
        },
      },
    });
  };

  const handleWithdraw = () => {
    setModal({
      type: ModalType.JOB_WITHDRAW,
      props: {
        job,
        jobId,
        profileId,
        onSubmit: () => {
          setApplied(false);
          setApplicationCount(applicationCount - 1);
        },
      },
    });
  };

  useEffect(() => {
    setApplied(job?.isApplied || false);
  }, [job?.isApplied]);

  useEffect(() => {
    setApplicationCount(job?.applicationCount || 0);
  }, [job?.applicationCount]);

  return (
    <Sidebar {...props} loading={isLoading}>
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
        ]}
      >
        <SidebarContent>
          <SidebarSection
            heading="Salary Range"
            className="border-none pb-[0px]"
          >
            <span className="text-white text-lg font-semibold">
              {renderJobSalary({
                minSalary,
                maxSalary,
                salaryDependsOnExperience,
              })}
            </span>
          </SidebarSection>

          <SidebarSection
            heading="Applicantions"
            className="border-none pb-[0px]"
          >
            <div className="flex flex-row justify-between items-center">
              <span className="text-white text-lg font-semibold">
                {applicationCount || 0}
              </span>
              <RBAC
                roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}
              >
                <Button
                  color="warning"
                  variant="outlined"
                  to={ROUTER.JOB_APPLICATION_LIST(jobId)}
                >
                  View All
                </Button>
              </RBAC>
            </div>
          </SidebarSection>

          <SidebarSection heading="Posted On" className="border-none pb-[0px]">
            <span className="text-white text-lg font-semibold">
              {date(job?.createdAt).format("YYYY-MM-DD")}
            </span>
          </SidebarSection>

          <SidebarSection className="border-none">
            <JobDetailButtons loading={isLoading} job={job} />
            {/* <Container
              loading={isLoading}
              className="flex flex-col gap-y-[10px]"
            >
              <RBAC
                roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}
              >
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => handleEdit(jobId)}
                  >
                    Edit Job
                  </Button>
                  <Button
                    color="danger"
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => handleDelete(job)}
                  >
                    Delete Job
                  </Button>
                </>
              </RBAC>
              <RBAC roles={[Role.User]}>
                <>
                  {isApplied ? (
                    <>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleWithdraw}
                      >
                        Withdraw
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        to={ROUTER.JOB_APPLICATION_DETAIL(
                          jobId,
                          jobApplicationId
                        )}
                      >
                        View Application
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="success"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={handleApply}
                    >
                      Apply Now
                    </Button>
                  )}
                </>
              </RBAC>
            </Container> */}
          </SidebarSection>

          <SidebarSection heading="About Employeer">
            <div className="flex flex-col">
              <div className="flex flex-row justify-start items-center gap-x-[8px]">
                <Avatar size={24}>{job?.company?.profile?.name}</Avatar>
                <span className="text-white text-base font-semibold">
                  {job?.company?.profile?.name}
                </span>
              </div>
              <div className="mt-[10px]">
                <DevelopingFeatureInfoMessage />
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Ullamcorper libero aenean sed et feugiat pulvinar pretium.
                </p> */}
              </div>
            </div>
          </SidebarSection>

          <RBAC roles={[Role.Owner, Role.TeamLead, Role.Employee]}>
            <SidebarSection heading="My Jobs">
              <DevelopingFeatureInfoMessage />
            </SidebarSection>
          </RBAC>

          <RBAC roles={[Role.User]}>
            <SidebarSection heading="More Openings">
              <DevelopingFeatureInfoMessage />
            </SidebarSection>
          </RBAC>
        </SidebarContent>
      </RBAC>
    </Sidebar>
  );
};
