import React from "react";

import { FiCalendar as CalendarIcon } from "react-icons/fi";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import { FormikErrors } from "formik";

import { TextInput, InputError } from "./../../components";

interface IBasicDatePickerProps {
  id?: string;
  label?: string;
  value?: Date | string | null;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  className?: string;
  disabled?: boolean;
  renderInput?: (
    props: TextFieldProps
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  required?: boolean;
  disableFuture?: boolean;
  onChange?: (
    date: string | Date | null | undefined,
    keyboardInputValue?: string | undefined
  ) => void;
}

export const BasicDatePicker: React.FC<IBasicDatePickerProps> = ({
  id,
  label,
  value,
  error,
  className,
  disabled,
  disableFuture,
  renderInput,
  onChange,
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={label}
      value={value}
      renderInput={(props: any) => (
        <TextInput
          {...props}
          variant="filled"
          error={error}
          className={className}
          controlClassName="basis-1/2"
          labelClassName="text-secondary"
        />
      )}
      components={{
        OpenPickerIcon: !disabled ? CalendarIcon : () => <></>,
      }}
      onChange={onChange ? onChange : () => {}}
      {...{ disabled, disableFuture }}
      // {...props}
    />
  </LocalizationProvider>
);

BasicDatePicker.defaultProps = {};
