import React, { useState } from "react";

import { SwipeableDrawer } from "@mui/material";

import { Button } from "./../../components";

interface IDrawerProps {
  anchor?: "top" | "bottom" | "left" | "right";
  open: boolean;
  className?: string;
  onOpen: React.ReactEventHandler<{}>;
  onClose: React.ReactEventHandler<{}>;
}

export const Drawer: React.FC<IDrawerProps> = ({
  children,
  anchor,
  open,
  onOpen,
  onClose,
}) => {
  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      className="rounded-none"
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </SwipeableDrawer>
  );
};

Drawer.defaultProps = {
  anchor: "left",
};
