export enum Events {
  USER_NOTIFICATION_CREATE = "USER_NOTIFICATION_CREATE",
  USER_NOTIFICATION_SEND = "USER_NOTIFICATION_SEND",
}

export enum WebsocketEvents {
  GET_USER = "GET_USER",
  GET_USER_STATUS = "GET_USER_STATUS",

  // GET_CHAT = "GET_CHAT",
  GET_CHAT_LOADING = "GET_CHAT_LOADING",
  GET_CHAT_ERROR = "GET_CHAT_ERROR",

  GET_CHATS = "GET_CHATS",
  SYNC_CHATS_START = "SYNC_CHATS_START",
  SYNC_CHATS_STOP = "SYNC_CHATS_STOP",

  GET_CHAT = "GET_CHAT",
  SYNC_CHAT_START = "SYNC_CHAT_START",
  SYNC_CHAT_STOP = "SYNC_CHAT_STOP",

  GET_CHAT_LIST = "GET_CHAT_LIST",
  GET_CHAT_LIST_LOADING = "GET_CHAT_LIST_LOADING",
  GET_CHAT_LIST_ERROR = "GET_CHAT_LIST_ERROR",

  SEND_CHAT_ACTION = "SEND_CHAT_ACTION",
  SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE",
  CHAT_MESSAGE_PENDING = "CHAT_MESSAGE_PENDING",
  CHAT_MESSAGE_SUCCESS = "CHAT_MESSAGE_SUCCESS",
  CHAT_MESSAGE_ERROR = "CHAT_MESSAGE_ERROR",

  // Notifications
  VIEW_USER_NOTIFICATIONS = "VIEW_USER_NOTIFICATIONS",
  GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS",
  NEW_USER_NOTIFICATION = "NEW_USER_NOTIFICATION",
}

export enum WebsocketReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
