import React from "react";

import { INavbarLinkProps, Link, NavbarLink } from "./../../components";

interface IBottomNavbarLinkProps extends INavbarLinkProps {}

export const BottomNavbarLink: React.FC<IBottomNavbarLinkProps> = (props) => (
  <NavbarLink
    {...props}
    className="flex flex-row justify-center items-center w-[40px] h-[40px]"
  />
);

BottomNavbarLink.defaultProps = {
  isActive: false,
  iconSize: 30,
};
