import React, { useEffect, useState } from "react";

import { FullCalendar, Container, PageHeader } from "../../components";

export const EventsContainer = () => {
  return (
    <Container>
      <PageHeader>Events</PageHeader>
      <FullCalendar />
    </Container>
  );
};
