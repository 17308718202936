import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { experienceSlice } from "./experience.slice";

export const appendExperiences =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(experienceSlice.actions.appendExperiences(payload));

export const popExperiences = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(experienceSlice.actions.popExperiences(payload));

export const getExperiences =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      const { where } = options || {};

      dispatch(experienceSlice.actions.getExperiencesRequest());

      const response = await ApiService.post("experiences/query", {
        where,
      });
      const { data } = response.data;

      dispatch(experienceSlice.actions.getExperiencesSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        experienceSlice.actions.getExperiencesError(e.response?.data?.error)
      );
    }
  };

export const getExperience = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(experienceSlice.actions.getExperienceRequest());

    const response = await ApiService.get(`experiences/${id}`);
    const { data } = response.data;

    dispatch(experienceSlice.actions.getExperienceSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(
      experienceSlice.actions.getExperienceError(e.response?.data?.error)
    );
  }
};

export const createExperience =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(experienceSlice.actions.createExperienceRequest());

      const response = await ApiService.post("experiences", {
        isArchived: true,
        ...payload,
      });
      const { data } = response.data;

      dispatch(experienceSlice.actions.createExperienceSuccess(data));
      dispatch(experienceSlice.actions.appendExperiences(data));

      return data;
    } catch (e: any) {
      dispatch(
        experienceSlice.actions.createExperienceError(e.response?.data?.error)
      );
    }
  };

export const updateExperience =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(experienceSlice.actions.updateExperienceRequest());

      const response = await ApiService.put(`experiences/${id}`, payload);
      const { data } = response.data;

      dispatch(experienceSlice.actions.updateExperienceSuccess(data));
      dispatch(experienceSlice.actions.appendExperiences(payload));

      return id;
    } catch (e: any) {
      dispatch(
        experienceSlice.actions.updateExperienceError(e.response?.data?.error)
      );
    }
  };

export const removeExperience =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(experienceSlice.actions.removeExperienceRequest());

      const response = await ApiService.delete(`experiences/${id}`);
      const { data } = response.data;

      dispatch(experienceSlice.actions.removeExperienceSuccess(data));
      dispatch(experienceSlice.actions.popExperiences({ id }));

      return id;
    } catch (e: any) {
      dispatch(
        experienceSlice.actions.removeExperienceError(e.response?.data?.error)
      );
    }
  };
