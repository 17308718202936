import React from "react";

import {
  SidebarSection,
  Sidebar,
  SidebarContent,
  Button,
} from "../../../components";
import { ROUTER } from "../../../routes";
import { ISidebar } from "@types";
import { RecentlyAppliedJobsWidget } from "../../Job";

export const UserFeedRightSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarContent>
      <SidebarSection heading="Resumes" className="border-none">
        <Button
          to={ROUTER.RESUME_BUILDER_ROOT}
          color="primary"
          className="w-full"
        >
          Go to Resume Builder
        </Button>
      </SidebarSection>
    </SidebarContent>
  </Sidebar>
);
