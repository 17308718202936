import React from "react";

import { MainLayout } from "../../layouts";
import { JobDetailSidebar } from "../../features";
import { lazyload } from "../../utils";

const { JobDetailContainer } = lazyload(() => import("../../features"));

export const JobDetailPage = () => (
  <MainLayout rightSidebar={JobDetailSidebar}>
    <JobDetailContainer />
  </MainLayout>
);
