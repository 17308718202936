import { createSlice } from "@reduxjs/toolkit";

import { IAudit } from "../../../shared";

import { AppReducerState } from "../../store";

interface IAuditState {
  audits: AppReducerState<IAudit[]>;
  audit: AppReducerState<IAudit>;
}

const initialState: IAuditState = {
  audits: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  audit: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const auditSlice = createSlice({
  name: "Audit",
  initialState,
  reducers: {
    getAuditsRequest: (state) => {
      state.audits.isLoading = true;
    },
    getAuditsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.audits.isLoading = false;
      state.audits.data = data;
      state.audits.results = results;
    },
    getAuditsError: (state, action) => {
      state.audits.isLoading = false;
      state.audits.error = action.payload;
    },

    getAuditRequest: (state) => {
      state.audit.isLoading = true;
    },
    getAuditSuccess: (state, action) => {
      state.audit.isLoading = false;
      state.audit.data = action.payload;
    },
    getAuditError: (state, action) => {
      state.audit.isLoading = false;
      state.audit.error = action.payload;
    },
  },
});

export const auditReducer = auditSlice.reducer;
