import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const {
  JobApplicationList,
  JobApplicationFilterSidebar,
  JobApplicationJobDetailSidebar,
} = lazyload(() => import("../../features"));

export const JobApplicationListPage = () => (
  <MainLayout
    rightSidebar={(props) => (
      <JobApplicationJobDetailSidebar expanded {...props} />
    )}
  >
    <JobApplicationList />
  </MainLayout>
);
