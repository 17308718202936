import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IJobSearchFilter, ISidebar } from "@types";

import {
  SidebarSection,
  Sidebar,
  SidebarAccordion,
  Searchbar,
  Form,
  TextInput,
  Button,
  SidebarContent,
  Checkbox,
  MultipleSelect,
  AdvancedFilterInput,
} from "../../../components";

import {
  IFilters,
  useAppDispatch,
  useFilter,
  useModal,
  useToast,
} from "../../../hooks";
import { date, FormValidator } from "../../../utils";
import { removeJob, updateJob } from "../../../store";
import { ROUTER } from "../../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { useFormik } from "formik";
import { EMPLOYMENT_TYPES, JOB_TYPES } from "../../../constants";

export const JobSearchFilterSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { filters, setFilters, resetFilters, isFilterEmpty, isEmpty } =
    useFilter();

  const filtersEmpty = Object.keys(filters || [])?.length <= 0;

  const form = useFormik<IJobSearchFilter>({
    initialValues: {},
    onSubmit: (values) => {
      setFilters({ ...values });
    },
  });

  const {
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
    resetForm,
    handleChange,
    handleSubmit,
  } = form;

  const validator = new FormValidator<IJobSearchFilter>(form);

  const handleResetFilters = () => {
    resetForm();
    resetFilters();
  };

  const handleReset = () => {
    resetForm();
    resetFilters();
  };

  useEffect(() => {
    const { minSalary, maxSalary } = values;

    if (!!minSalary || !!maxSalary) {
      setFieldValue("salaryDependsOnExperience", undefined, false);
    }
  }, [values.minSalary, values?.maxSalary]);

  useEffect(() => {
    setValues({ ...values, ...filters });
  }, [filters]);

  return (
    <Sidebar {...props}>
      <SidebarContent className="pb-[75px]">
        {/* <SidebarSection heading="Search" className="border-none">
          <Searchbar />
        </SidebarSection> */}

        <Form onSubmit={handleSubmit}>
          <SidebarSection
            heading="Filters"
            navbar={
              <div>
                {!filtersEmpty && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleResetFilters}
                  >
                    Clear All
                  </Button>
                )}
              </div>
            }
          >
            <SidebarAccordion
              summary="Location"
              expanded={!isFilterEmpty(["country", "state", "city"])}
            >
              <div className="flex flex-col gap-y-[10px]">
                <AdvancedFilterInput
                  filterId="country"
                  placeholder="Country"
                  values={values?.country}
                  error={validator.isInputValid("country")}
                  onChange={(value) => setFieldValue("country", value, false)}
                />
                <AdvancedFilterInput
                  filterId="state"
                  placeholder="State"
                  values={values?.state}
                  error={validator.isInputValid("state")}
                  onChange={(value) => setFieldValue("state", value, false)}
                />
                <AdvancedFilterInput
                  filterId="city"
                  placeholder="City"
                  values={values?.city}
                  error={validator.isInputValid("city")}
                  onChange={(value) => setFieldValue("city", value, false)}
                />
              </div>
            </SidebarAccordion>
            <SidebarAccordion
              summary="Experience"
              expanded={
                !isFilterEmpty([
                  "company",
                  "jobTitle",
                  "jobType",
                  "employmentType",
                  "minSalary",
                  "maxSalary",
                  "salaryDependsOnExperience",
                ])
              }
            >
              <div className="flex flex-col gap-y-[10px]">
                <AdvancedFilterInput
                  filterId="company"
                  placeholder="Company"
                  values={values?.company}
                  error={validator.isInputValid("company")}
                  onChange={(value) => setFieldValue("company", value, false)}
                />
                <AdvancedFilterInput
                  filterId="jobTitle"
                  placeholder="Job Title"
                  values={values?.jobTitle}
                  error={validator.isInputValid("jobTitle")}
                  onChange={(value) => setFieldValue("jobTitle", value, false)}
                />

                <MultipleSelect
                  className="w-full h-[55px]"
                  variant="standard"
                  options={JOB_TYPES}
                  placeholder="Job Type"
                  value={values?.jobType}
                  error={touched?.jobType ? errors?.jobType : null}
                  setValue={(value) => setFieldValue("jobType", value, true)}
                />
                <MultipleSelect
                  className="w-full h-[55px]"
                  variant="standard"
                  options={EMPLOYMENT_TYPES}
                  placeholder="Employment Type"
                  value={values?.employmentType}
                  error={
                    touched?.employmentType ? errors?.employmentType : null
                  }
                  setValue={(value) =>
                    setFieldValue("employmentType", value, true)
                  }
                />

                <div className="flex xl:flex-col 2xl:flex-row gap-x-[10px] gap-y-[10px]">
                  <div className="xl:basis-full 2xl:basis-6/12 flex-row">
                    <TextInput
                      id="minSalary"
                      type="number"
                      label="Min Salary"
                      className="w-full h-[55px]"
                      maxLength={6}
                      value={values?.minSalary}
                      error={validator.isInputValid("minSalary")}
                      disabled={!!values?.salaryDependsOnExperience}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="xl:basis-full 2xl:basis-6/12 flex-row">
                    <TextInput
                      id="maxSalary"
                      type="number"
                      label="Max Salary"
                      className="w-full h-[55px]"
                      maxLength={6}
                      value={values?.maxSalary}
                      error={validator.isInputValid("maxSalary")}
                      disabled={!!values?.salaryDependsOnExperience}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <Checkbox
                    id="salaryDependsOnExperience"
                    label="Depends On Experience"
                    checked={values?.salaryDependsOnExperience}
                    onChange={() => {
                      setFieldValue(
                        "salaryDependsOnExperience",
                        !values.salaryDependsOnExperience ? true : undefined,
                        true
                      );
                      setFieldValue("minSalary", undefined);
                      setFieldValue("maxSalary", undefined);
                    }}
                  />
                </div>
              </div>
            </SidebarAccordion>

            {/* <SidebarAccordion
              summary="Verification"
              className="border-none"
              expanded={expandedFilters?.verification}
            >
              <div className="flex flex-col gap-y-[10px]">
                <Checkbox
                  name="isVerified"
                  label="Only Verified"
                  checked={values?.isVerified}
                  onChange={() => {
                    setFieldValue("isVerified", !values.isVerified, false);
                  }}
                />
              </div>
            </SidebarAccordion> */}
          </SidebarSection>
        </Form>
      </SidebarContent>

      <SidebarSection className="w-full absolute h-[75px] bottom-[0px] flex flex-col justify-center bg-primaryDark">
        <div className="flex flex-row justify-between gap-x-[10px]">
          <Button
            variant="outlined"
            color="primary"
            className="w-full"
            // disabled={isEmpty}
            onClick={() => handleSubmit()}
          >
            Apply Filters
          </Button>
        </div>
      </SidebarSection>
    </Sidebar>
  );
};
