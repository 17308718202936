import React from "react";

import { Fade } from "@mui/material";

import { Loader } from "../../components";

interface IContainerProps {
  loading?: boolean;
  data?: any;
  className?: string;
  loadingClassName?: string;
  animated?: boolean;
  hidden?: boolean;
  hiddenLoading?: boolean;
  hiddenComponent?: JSX.Element;
}

export const Container: React.FC<IContainerProps> = ({
  children,
  loading,
  data,
  className,
  loadingClassName,
  animated,
  hidden,
  hiddenLoading,
  hiddenComponent,
  ...props
}) =>
  !hidden ? (
    loading ? (
      <div className={`${loadingClassName} w-full`}>
        <Loader />
      </div>
    ) : data ? (
      hiddenLoading ? (
        <div
          className={`${className} relative w-full z-10 animate__animated animate__fadeIn animate__faster`}
        >
          <div
            className={`${loadingClassName} absolute w-full h-full flex flex-row justify-center z-20 items-center bg-primaryDark`}
          >
            <Loader />
          </div>
          {children}
        </div>
      ) : (
        <div
          className={`${className} w-full animate__animated animate__fadeIn animate__faster`}
        >
          {children}
        </div>
      )
    ) : (
      <div className="flex flex-col justify-center items-start text-white">
        <span className="text-lg font-semibold">Error!</span>
        <span className="text-sm">Ooops, something went wrong 😢</span>
      </div>
    )
  ) : (
    <>{hiddenComponent}</>
  );

Container.defaultProps = {
  data: [],
  loading: false,
  hidden: false,
  hiddenLoading: false,
  hiddenComponent: <></>,
};
