export enum ActionType {
  All = "all",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Copy = "copy",
  Apply = "apply",
  Withdraw = "withdraw",
  Approve = "approve",
  Promote = "promote",
  Reject = "reject",
  Verify = "verify",
  Delist = "delist",
  Bookmark = "bookmark",
  Like = "like",
  Comment = "comment",
  Follow = "follow",
  Friend = "friend",
  Subscribe = "subscribe",
  Invite = "invite",
  Archive = "archive",
}

export enum Action {
  All = "all",
  Create = "create",
  Read = "read",
  Edit = "edit",
  Update = "update",
  Delete = "delete",
  Copy = "copy",
  Apply = "apply",
  Withdraw = "withdraw",
  Approve = "approve",
  Promote = "promote",
  Reject = "reject",
  Verify = "verify",
  List = "list",
  Delist = "delist",
  Bookmark = "bookmark",
  Like = "like",
  Comment = "comment",
  Follow = "follow",
  Friend = "friend",
  Subscribe = "subscribe",
  Invite = "invite",
  Archive = "archive",
}
