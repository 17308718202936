import React from "react";

import { PasswordResetRoot } from "../../features";
import { AuthLayout } from "../../layouts";

export const PasswordResetPage = () => (
  <AuthLayout>
    <PasswordResetRoot />
  </AuthLayout>
);
