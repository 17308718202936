import React, { ChangeEvent } from "react";

import { FiSearch as SearchIcon } from "react-icons/fi";

import { TextInput } from "./../../components";

interface ISearchbarProps {
  className?: string;
  iconClassName?: string;
  id?: string;
  name?: string;
  value?: string | null;
  error?: string;
  placeholder?: string;
  onClick?: (e?: React.MouseEvent) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void;
}

export const Searchbar: React.FC<ISearchbarProps> = ({
  className,
  iconClassName,
  id,
  name,
  value,
  error,
  placeholder,
  onClick,
  onChange,
  onSubmit,
}) => (
  <div
    className={`${className} relative w-full h-[45px] flex flex-row bg-primaryLight rounded-lg overflow-hidden`}
  >
    <TextInput
      type="text"
      variant="standard"
      className={`${className} w-full h-full min-h-[0px] flex flex-row items-center rounded-lg bg-inherit text-sm !text-white border-[0px] px-[10px] py-[10px] pr-[40px]`}
      {...{ id, name, value, error, placeholder, onClick, onChange }}
    />
    <div className="absolute w-[40px] h-[100%] bg-transparent top-[0px] right-[0px] flex flex-row justify-center items-center cursor-pointer">
      <SearchIcon
        size={16}
        className={`${iconClassName} transition-all text-secondary hover:text-white`}
        onClick={() => (onSubmit ? onSubmit() : {})}
      />
    </div>
  </div>
);

Searchbar.defaultProps = {
  placeholder: "Search something...",
};
