import React from "react";

import { Status } from "../../shared";

interface IStatusBadgeProps {
  status?: string | null;
  className?: string;
}

export const StatusBadge: React.FC<IStatusBadgeProps> = ({
  status,
  className,
}) => {
  let color;

  switch (status) {
    case Status.Pending:
      color = "warning";
      break;
    case Status.Approved:
      color = "success";
      break;
    case Status.Rejected:
      color = "danger";
      break;
    default:
      color = "secondary";
      break;
  }

  return (
    <div
      className={`${className} flex flex-row justify-center relative items-center min-w-[65px] h-[22px] px-[10px] rounded-full overflow-hidden border border-solid border-${color}`}
    >
      <div className={`absolute opacity-10 inset-0 z-0 bg-warning`}></div>
      <span className={`capitalize text-xs z-10 font-medium text-${color}`}>
        {status}
      </span>
    </div>
  );
};

StatusBadge.defaultProps = {
  status: null,
};
