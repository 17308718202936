import React, { useEffect, useState } from "react";

import { FormikErrors } from "formik";
import { FiClock } from "react-icons/fi";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { TextInput } from "./../../components";

interface IBasicTimePickerProps {
  id?: string;
  label?: string;
  value?: Date | string | null;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (
    date: string | Date | null | undefined,
    keyboardInputValue?: string | undefined
  ) => void;
}

export const BasicTimePicker: React.FC<IBasicTimePickerProps> = ({
  id,
  label,
  value,
  error,
  className,
  disabled,
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={value}
        renderInput={(props: any) => (
          <TextInput
            {...props}
            variant="filled"
            error={error}
            className={className}
            controlClassName="basis-1/2"
            labelClassName="text-secondary"
          />
        )}
        components={{
          OpenPickerIcon: !disabled ? FiClock : () => <></>,
        }}
        onChange={onChange ? onChange : () => {}}
        {...{ disabled }}
      />
    </LocalizationProvider>
  );
};

BasicTimePicker.defaultProps = {};
