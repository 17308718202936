import React, { useState, useEffect } from "react";

export const TabViews = ({ activeTab, tabs }) => {
  const [view, setView] = useState(() => <></>);

  useEffect(() => {
    if (!tabs.length || !tabs[activeTab]) return;

    const { component: Component } = tabs[activeTab];

    setView(() => <Component />);
  }, [activeTab]);

  return <div className="w-full">{view ? view : <></>}</div>;
};

TabViews.defaultProps = {
  activeTab: 0,
  tabs: [],
};
