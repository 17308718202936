import React, { useEffect } from "react";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
} from "react-icons/fi";

import { Container, Link } from "../../../components";
import { JOB_TYPES } from "../../../constants";
import { getUserRecentApplications } from "../../../store";
import { textformat } from "../../../utils";
import { ROUTER } from "../../../routes";
import { useAppDispatch, useAppSelector } from "../../../hooks";

export const RecentlyAppliedJobsWidget = () => {
  const dispatch = useAppDispatch();

  const {
    userJobApplications: {
      data: userJobApplications,
      isLoading: userJobApplicationLoading,
    },
  } = useAppSelector((state) => state.jobApplication);

  const parseJobType = (type) =>
    JOB_TYPES?.find(({ id }) => id === type)?.value || null;

  useEffect(() => {
    dispatch(getUserRecentApplications());
  }, []);

  return (
    <Container
      loading={userJobApplicationLoading}
      className="flex flex-col gap-y-[25px]"
    >
      {userJobApplications?.map(({ id: jobApplicationId, job, jobId }) => (
        <div key={jobApplicationId} className="flex flex-col gap-y-[10px]">
          <Link to={ROUTER.JOB_DETAIL(jobId)}>
            <h4 className="text-base text-white font-semibold leading-none">
              {textformat.shorten(job?.title, 30)}
            </h4>
          </Link>
          <div className="flex flex-row items-center flex-wrap gap-x-[10px] text-xs text-secondary font-medium">
            <div className="flex flex-row justify-start items-center gap-x-[5px]">
              <MonitorIcon size={14} />
              <span>{parseJobType(job?.type)}</span>
            </div>

            <div className="flex flex-row justify-start items-center gap-x-[5px]">
              <MapIcon size={14} />
              <span>{[job?.country, job?.city]?.join(", ")}</span>
            </div>
          </div>

          <div className="mt-[5px]">
            <Link
              to={ROUTER.JOB_APPLICATION_DETAIL(jobId, jobApplicationId)}
              className="text-sm text-link font-medium"
            >
              View Application
            </Link>
          </div>
        </div>
      ))}
    </Container>
  );
};
