import axios from "axios";

const { REACT_APP_API_HOST: API_HOST } = process.env;

export const ApiService = axios.create({
  baseURL: `${API_HOST}/`,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 60000,
});

ApiService.interceptors.response.use(
  (response) => response,
  (e) => {
    if (!e.response) {
      console.log("Please Check Your Internet Connection.");
    }

    return Promise.reject(e);
  }
);
