import React, { Suspense } from "react";

import { BottomNavbar, Loader, Navbar } from "../../components";
import { ILayout } from "@types";

interface IProfileLayoutProps extends ILayout {}

export const ProfileLayout: React.FC<IProfileLayoutProps> = ({ children }) => (
  <div className="flex flex-col w-full min-h-screen h-auto bg-primaryDark overflow-x-hidden font-gilroy">
    <Navbar />
    <div className="w-full max-w-[1140px] flex flex-col h-auto min-h-[500px] mt-[75px] mx-auto py-[30px] box-border text-secondary xxs:p-[20px]">
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </div>

    <div className="fixed z-50 bottom-0 w-full h-[70px] flex md:hidden xxs:flex">
      <BottomNavbar />
    </div>
    <div className="w-full h-[75px]"></div>
  </div>
);

ProfileLayout.defaultProps = {};
