import React from "react";

import { ActionMenu } from "../../../../components";
import { Action } from "../../../../shared";

export const ResumeCoverCard = ({
  title,
  details,
  isDraft,
  isArchived,
  actions,
  onEdit,
  onDelete,
}) => (
  <div className="w-full">
    <div className="w-full flex flex-row justify-between items-center mb-2">
      <div className="w-full flex flex-row justify-between items-center break-words">
        <h3 className="flex flex-row justify-start items-center basis-10/12 gap-x-[10px] text-base text-white font-semibold leading-none">
          <span className="break-words">{title}</span>
          {isDraft && <span className="text-sm text-secondary">(Draft)</span>}
        </h3>{" "}
        <div className="basis-2/12 flex flex-row justify-end">
          {actions?.length > 0 ? (
            <ActionMenu
              actions={[Action.Edit, Action.Delete]}
              {...{ onEdit, onDelete }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    <div className="mt-[15px] overflow-hidden break-words text-secondary text-base font-medium">
      <p>{details}</p>
    </div>
  </div>
);

ResumeCoverCard.defaultProps = {
  university: "",
  degree: "",
  isDraft: false,
  isVerified: false,
  startedAt: new Date(),
  finishedAt: new Date(),
};
