import React from "react";

import { Fade } from "@mui/material";
import { Loader } from "./../../components";

export interface ICardListProps {
  className?: string;
  data?: any[];
  loading?: boolean;
  hidden?: boolean;
}

export const CardList: React.FC<ICardListProps> = ({
  children,
  className,
  loading,
  hidden,
}) =>
  loading ? (
    <div className={`${className} w-full flex`}>
      <Loader />
    </div>
  ) : !hidden ? (
    <div
      className={`${className} w-full animate__animated animate__fadeIn animate__faster flex flex-col gap-y-[15px]`}
    >
      {children}
    </div>
  ) : (
    <></>
  );

CardList.defaultProps = {
  className: "",
  loading: false,
};
