import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { getEmployeesByCompanyId } from "../../store";

import { CardList, Loader } from "../../components";

import { CompanyEmployeeCard } from "./CompanyEmployeeCard";

export const BusinessEmployeeListContainer = () => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    companyEmployees: {
      data: companyEmployees,
      isLoading: companyEmployeesLoading,
    },
  } = useAppSelector((state) => state.company);

  const companyId = account?.ownership?.id || account?.employment?.id || null;

  useEffect(() => {
    if (!companyId) return;

    dispatch(getEmployeesByCompanyId(companyId));
  }, [companyId]);

  return (
    <CardList loading={companyEmployeesLoading}>
      {companyEmployees?.map((employee) => (
        <CompanyEmployeeCard key={employee?.id} {...employee} />
      ))}
    </CardList>
  );
};
