import { useEffect } from "react";

import { IAccount } from "../shared";

import { useAppSelector } from "./../hooks";
import { PermissionAction, PermissionObject, Role } from "../shared";

interface IUseRBACProps {
  roles: Role[];
  actions?: PermissionAction[];
  object?: PermissionObject | null;
  conditions?: any[];
}

export const useRBAC = (props?: IUseRBACProps) => {
  const { roles, actions, object, conditions } = props || {};

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  type checkRBACRolesProps = {
    account: IAccount;
    roles: Role[];
  };

  const checkRBACRoles = ({ account, roles }: checkRBACRolesProps): boolean =>
    roles?.includes(account?.role?.type) || false;

  type checkRBACPermissionsProps = {
    account: IAccount;
    actions: PermissionAction[];
    object: PermissionObject | null;
  };

  const checkRBACPermissions = ({
    account,
    actions,
    object,
  }: checkRBACPermissionsProps): boolean =>
    actions?.length >= 1
      ? actions?.every((action) =>
          account?.role?.permissions
            ?.filter((i) => i?.object === object)
            ?.map(({ action }) => action)
            ?.includes(action)
        ) || false
      : false;

  type checkRBACConditionsProps = {
    account: IAccount;
    conditions: any[];
  };

  const checkConditions = ({
    account,
    conditions,
  }: checkRBACConditionsProps): boolean =>
    conditions?.some(({ roles, condition }) =>
      checkRBACRoles({ account, roles }) && condition ? condition : false
    ) || false;

  type checkRBACProps = {
    roles?: Role[];
    actions?: PermissionAction[];
    object?: PermissionObject | null;
    conditions?: any[];
  };

  const checkRBAC = ({
    roles,
    actions,
    object,
    conditions,
  }: checkRBACProps): boolean => {
    if (!account || !roles) return false;

    return roles?.length > 0
      ? checkRBACRoles({ account, roles }) ||
          (!!conditions ? checkConditions({ account, conditions }) : false)
      : (actions && actions?.length >= 1 && object
          ? checkRBACPermissions({ account, actions, object })
          : false) ||
          (!!conditions ? checkConditions({ account, conditions }) : false);
  };

  type isAllowedProps = {
    roles?: Role[];
    actions?: PermissionAction[];
    object?: PermissionObject | null;
    conditions?: any[];
  };

  const isAllowed = ({ roles, actions, object, conditions }: isAllowedProps) =>
    checkRBAC({ roles, actions, object, conditions });

  return { isAllowed, checkRBAC };
};

useRBAC.defaultProps = {
  roles: [],
  actions: [],
  conditions: [],
  object: null,
};
