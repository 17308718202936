import React, { useEffect, useState } from "react";

import { ActionMenu, Avatar, StarRating } from "../../components";
import { CompanyCommentEditForm } from "../../features";
import { removeCompanyComment } from "../../store";
import { Action } from "../../shared";
import { useAppDispatch, useLoading, useToast } from "../../hooks";
import { date } from "../../utils";
import { IChildComponentUpdateHelpers } from "@types";

interface ICompanyCommentCardProps {
  variant?: "standard" | "transparent";
  id: string;
  details: string;
  rating?: number;
  isPrivate?: boolean;
  isAuthor?: boolean;
  jobApplication?: any;
  jobApplicationId?: string;
  createdBy?: any;
  createdById?: string;
  createdAt?: Date;
  actions?: any[];
  onEdit?: (id: string, helpers: IChildComponentUpdateHelpers) => void;
  onDelete?: (id: string, helpers: IChildComponentUpdateHelpers) => void;
}

interface ICompanyCommentCardVariantProps extends ICompanyCommentCardProps {
  loading?: boolean;
  editing?: boolean;
  handleEdit?: () => void;
  handleDelete?: () => void;
}

export const CompanyCommentCard: React.FC<ICompanyCommentCardProps> = (
  props
) => {
  const {
    variant,
    id,
    details,
    rating,
    isPrivate,
    isAuthor,
    jobApplication,
    jobApplicationId: _jobApplicationId,
    createdBy,
    createdById,
    createdAt,
    actions,
    onEdit,
    onDelete,
  } = props;

  const jobApplicationId = _jobApplicationId || jobApplication?.id;

  const [loading, setLoading] = useLoading();
  const [isEditing, setEditing] = useState(false);

  const handleEdit = () => (onEdit ? onEdit(id, { setLoading }) : {});
  const handleDelete = () =>
    onDelete ? onDelete(id, { setLoading }) : console.log("Deleting Failed");

  let Component: () => JSX.Element = () => <></>;

  switch (variant) {
    case "standard":
      Component = () => (
        <CompanyCommentCardStandardVariant
          {...props}
          {...{
            loading,
            handleEdit,
            handleDelete,
          }}
        />
      );
      break;
    case "transparent":
      Component = () => (
        <CompanyCommentCardTransparentVariant
          {...props}
          {...{
            loading,
            handleEdit,
            handleDelete,
          }}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="flex flex-col items-start w-full overflow-hidden break-words">
      {!isEditing ? (
        <div className="w-full flex flex-row justify-start items-start gap-x-[12px]">
          <div>
            <Avatar size={40} src={createdBy?.avatar}>
              {createdBy?.firstName}
            </Avatar>
          </div>
          <Component />
        </div>
      ) : (
        <CompanyCommentEditForm
          id={id}
          initialValues={{
            details,
            rating,
            isPrivate,
            jobApplicationId: jobApplicationId || jobApplication?.id,
            createdBy,
          }}
          onClose={() => {
            setEditing(false);
          }}
        />
      )}
    </div>
  );
};

CompanyCommentCard.defaultProps = {
  variant: "standard",
};

const CompanyCommentCardStandardVariant: React.FC<
  ICompanyCommentCardVariantProps
> = ({
  details,
  rating,
  isPrivate,
  isAuthor,
  jobApplication,
  jobApplicationId: _jobApplicationId,
  createdBy,
  createdById,
  createdAt,
  actions,
  loading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <div className="w-full rounded-[8px] p-[12px] bg-primaryMid">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col gap-y-[5px]">
              <div className="flex flex-row justify-start items-center gap-x-[5px]">
                <span className="text-sm text-white font-semibold leading-none">
                  {createdBy?.firstName} {createdBy?.lastName}
                </span>
                {isPrivate && (
                  <span className="text-warning text-xs font-medium leading-none">
                    (Private)
                  </span>
                )}
              </div>
              <span className="text-xs text-secondary leading-none">
                {date(createdAt).format("DD MMM YYYY")}
              </span>
            </div>

            {isAuthor && (
              <ActionMenu
                loading={loading}
                actions={[Action.Edit, Action.Delete]}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </div>

          {rating && rating >= 1 ? (
            <StarRating
              className="mt-[8px]"
              value={rating}
              size={10}
              readOnly
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mt-[8px] text-white text-base">
        <p>{details}</p>
      </div>
    </div>
  );
};

const CompanyCommentCardTransparentVariant: React.FC<
  ICompanyCommentCardVariantProps
> = ({
  details,
  rating,
  isPrivate,
  isAuthor,
  jobApplication,
  jobApplicationId: _jobApplicationId,
  createdBy,
  createdById,
  createdAt,
  actions,
  loading,
  handleEdit,
  handleDelete,
}) => {
  return (
    <div className="w-full bg-transparent">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col gap-y-[5px] mt-[3px]">
              <div className="flex flex-row justify-start items-center gap-x-[5px]">
                <span className="text-sm text-white font-semibold leading-none">
                  {createdBy?.firstName} {createdBy?.lastName}
                </span>
                {isPrivate && (
                  <span className="text-warning text-xs font-medium leading-none">
                    (Private)
                  </span>
                )}
              </div>
              <span className="text-xs text-secondary leading-none">
                {date(createdAt).format("DD MMM YYYY")}
              </span>
            </div>

            {isAuthor && (
              <ActionMenu
                loading={loading}
                actions={[Action.Edit, Action.Delete]}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </div>

          {rating && rating >= 1 ? (
            <StarRating
              className="mt-[8px]"
              value={rating}
              size={10}
              readOnly
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mt-[8px] text-white text-base">
        <p>{details}</p>
      </div>
    </div>
  );
};
