import { useAppSelector } from "./useRedux";

export const useEmployee = () => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const { employment, ownership } = account || {};

  const company = ownership ? ownership : employment ? employment : null;

  const checkEmployment = (companyId: string) => companyId === company?.id;

  return { company, checkEmployment };
};
