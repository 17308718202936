import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { SearchContainer } = lazyload(() => import("./../../features"));

export const SearchPage = () => (
  <MainLayout>
    <SearchContainer />
  </MainLayout>
);
