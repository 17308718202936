import React from "react";
import { SVGIcon } from "./SVGIcon";

export const EmployeeSearchIcon = (props) => (
  <SVGIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.09638 5.5H14.9041C15.786 5.5 16.5002 6.23579 16.5002 7.14419V8.29282C16.5002 8.7335 16.328 9.15667 16.0223 9.46531L11.1435 14.4003C11.0593 14.4862 10.945 14.5339 10.8269 14.5331L6.74194 14.5204C6.61762 14.5204 6.49947 14.4671 6.41453 14.374L1.93113 9.44304C1.65391 9.13838 1.50024 8.73588 1.50024 8.31827V7.14499C1.50024 6.23658 2.21453 5.5 3.09638 5.5ZM6.96049 16.0331L10.6014 16.0442C10.8284 16.045 11.0122 16.2351 11.0122 16.4682V18.5165C11.0122 18.7503 10.8794 18.9619 10.6725 19.0598L7.36744 20.6117C7.29022 20.6475 7.20837 20.665 7.12651 20.665C7.01763 20.665 6.90875 20.6332 6.81377 20.5703C6.64775 20.4606 6.54736 20.2712 6.54736 20.0684V16.4571C6.54736 16.2224 6.73269 16.0323 6.96049 16.0331Z"
    />
    <circle cx="8.5" cy="2.5" r="2.5" />
  </SVGIcon>
  //fill="#7887A4"
);
