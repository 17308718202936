import { ApiService, EventService } from "../../../services";
import { IChat, IChatMessage, WebsocketEvents } from "../../../shared";
import { AppDispatch, GetRootState } from "../../store";

import { chatSlice } from "./chat.slice";

export const setChats =
  ({ data, results }) =>
  (dispatch: AppDispatch) =>
    dispatch(chatSlice.actions.setChats({ data, results }));

export const setChat = (data: IChat | null) => (dispatch: AppDispatch) =>
  dispatch(chatSlice.actions.setChat(data));

export const setChatById =
  (chatId: string, data: IChat) => (dispatch: AppDispatch) =>
    dispatch(chatSlice.actions.setChat(data));

export const getUserChats =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const profileId = state.account.account.data.profileId;

      dispatch(chatSlice.actions.getChatsRequest());

      const response = await ApiService.post(
        `profiles/${profileId}/chats/query`
      );
      const { data } = response.data;

      console.log({ chats: data });

      dispatch(chatSlice.actions.getChatsSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(chatSlice.actions.getChatsError(e.response?.data?.error));
    }
  };

export const getChatById =
  (chatId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(chatSlice.actions.getChatRequest());

      const response = await ApiService.get(`chats/${chatId}`);
      const { data } = response.data;

      dispatch(chatSlice.actions.getChatSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(chatSlice.actions.getChatError(e.response?.data?.error));
    }
  };

export const createChat = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const { userAId, userBId } = payload || {};

    dispatch(chatSlice.actions.createChatRequest());

    const response = await ApiService.post("chats", { userAId, userBId });
    const { data } = response.data;

    dispatch(chatSlice.actions.createChatSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(chatSlice.actions.createChatError(e.response?.data?.error));
  }
};

export const removeChat = (chatId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(chatSlice.actions.removeChatRequest());

    const response = await ApiService.delete(`chats/${chatId}`);
    const { data } = response.data;

    dispatch(chatSlice.actions.removeChatSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(chatSlice.actions.removeChatError(e.response?.data?.error));
  }
};

export const sendChatMessage =
  (data: IChatMessage) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      dispatch(chatSlice.actions.sendChatMessage());

      EventService.send({ event: WebsocketEvents.SEND_CHAT_MESSAGE, data });
    } catch (e: any) {
      dispatch(chatSlice.actions.sendChatMessageError(e.response?.data?.error));
    }
  };

export const checkChatWithUser =
  (userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(chatSlice.actions.checkChatWithUserRequest());

      const response = await ApiService.get(`chats/check?userId=${userId}`);

      const data: IChat = response.data?.data;

      dispatch(chatSlice.actions.checkChatWithUserSuccess());

      return data;
    } catch (e: any) {
      console.log({ e });

      dispatch(chatSlice.actions.checkChatWithUserError());
    }
  };

export const startChatWithUser =
  (userId: string, initialMessage?: IChatMessage) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(chatSlice.actions.startChatWithUserRequest());

      const response = await ApiService.post(`chats?userId=${userId}`, {
        initialMessage,
      });

      const data: IChat = response.data?.data;

      dispatch(chatSlice.actions.startChatWithUserSuccess());

      return data;
    } catch (e: any) {
      console.log({ e });

      dispatch(chatSlice.actions.startChatWithUserError());
    }
  };
