import { createSlice } from "@reduxjs/toolkit";
import { appendToStore, AppReducerState, popFromStore } from "../../../store";

interface IPostState {
  posts: AppReducerState<any[]>;
  post: AppReducerState<any>;
}

const initialState: IPostState = {
  posts: {
    isLoading: false,
    data: [],
    results: 0,
    error: null,
  },
  post: {
    isLoading: false,
    isUpdating: false,
    data: {},
    error: null,
  },
};

export const postSlice = createSlice({
  name: "Post",
  initialState,
  reducers: {
    appendPosts: appendToStore("posts"),
    popPosts: popFromStore("posts"),

    getPostsRequest: (state) => {
      state.posts.isLoading = true;
      state.posts.data = [];
      state.posts.results = 0;
    },
    getPostsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.posts.isLoading = false;
      state.posts.data = data;
      state.posts.results = results;
    },
    getPostsError: (state, action) => {
      state.posts.isLoading = false;
      state.posts.data = [];
      state.posts.results = 0;
      state.posts.error = action.payload;
    },

    getPostFeedRequest: (state) => {
      state.posts.isLoading = true;
    },
    getPostFeedSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.posts.isLoading = false;
      state.posts.data = data;
      state.posts.results = results;
    },
    getPostFeedError: (state, action) => {
      state.posts.isLoading = false;
      state.posts.error = action.payload;
    },

    getPostRequest: (state) => {
      state.post.isLoading = true;
    },
    getPostSuccess: (state, action) => {
      state.post.isLoading = false;
      state.post.data = action.payload;
    },
    getPostError: (state, action) => {
      state.post.isLoading = false;
      state.post.error = action.payload;
    },

    createPostRequest: (state) => {
      state.post.isLoading = true;
    },
    createPostSuccess: (state, action) => {
      state.post.isLoading = false;
    },
    createPostError: (state, action) => {
      state.post.isLoading = false;
      state.post.error = action.payload;
    },

    updatePostRequest: (state) => {
      state.post.isUpdating = true;
    },
    updatePostSuccess: (state, action) => {
      state.post.isUpdating = false;
    },
    updatePostError: (state, action) => {
      state.post.isUpdating = false;
      state.post.error = action.payload;
    },

    removePostRequest: (state) => {
      state.post.isLoading = true;
    },
    removePostSuccess: (state, action) => {
      state.post.data = null;
      state.post.isLoading = false;
    },
    removePostError: (state, action) => {
      state.post.error = action.payload;
      state.post.isLoading = false;
    },

    likePostRequest: (state) => {},
    likePostSuccess: (state, action) => {},
    likePostError: (state, action) => {},

    dislikePostRequest: (state) => {},
    dislikePostSuccess: (state, action) => {},
    dislikePostError: (state, action) => {},

    getPostCommentsRequest: (state) => {},
    getPostCommentsSuccess: (state, action) => {},
    getPostCommentsError: (state, action) => {},

    addPostCommentRequest: (state) => {},
    addPostCommentSuccess: (state, action) => {},
    addPostCommentError: (state, action) => {},

    // removePostCommentRequest: (state) => {},
    // removePostCommentSuccess: (state, action) => {},
    // removePostCommentError: (state, action) => {},
  },
});

export const postReducer = postSlice.reducer;
