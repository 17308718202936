import React from "react";

import { SidebarSection, Sidebar } from "../../../components";
import { ISidebar } from "@types";

export const AuditRequestRightSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarSection heading="Company Profile"></SidebarSection>
    <SidebarSection heading="Your Jobs"></SidebarSection>
    <SidebarSection heading="My Position"></SidebarSection>
    <SidebarSection heading="Contacts"></SidebarSection>
  </Sidebar>
);
