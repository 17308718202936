import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ROUTER } from "../../../routes";
import { Checkbox, Form, TextInput, Button } from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../../hooks";
import { login } from "../../../store";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is incorrect")
    .required("Email is a required field"),
  password: Yup.string().required("Password is a required field"),
});

export const Login = () => {
  const [loading, setLoading] = useLoading(false);

  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();

  const { account } = useAppSelector((state) => state.account);

  const [rememberUser, setRememberUser] = useState(false);

  const { values, errors, touched, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        setLoading(true);

        const isLoggedIn = await dispatch(login(values));
        if (!isLoggedIn) {
          setFieldError("password", "Incorrect email or password");
          return setLoading(false);
        }

        setLoading(false);
        redirect(ROUTER.HOME);
      },
    });

  return (
    <div className="w-full max-w-[640px] text-white">
      <div className="w-full text-center mb-[50px] text-2xl font-semibold">
        <h1>Login</h1>
      </div>
      <Form onSubmit={handleSubmit} className="text-black">
        <div className="w-full flex flex-col justify-start items-start gap-y-3">
          <div className="w-full flex flex-col gap-y-3">
            <TextInput
              id="email"
              label="Email"
              type="text"
              variant="filled"
              className="!bg-white !text-black"
              value={values?.email}
              error={touched?.email ? errors?.email : null}
              onChange={handleChange}
            />
            <TextInput
              id="password"
              label="Password"
              type="password"
              variant="filled"
              className="!bg-white !text-black"
              value={values?.password}
              error={touched?.password ? errors?.password : null}
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center text-secondary font-medium"></div>
            <div>
              <Link
                to={ROUTER.PASSWORD_RESET}
                className="text-primaryBlue text-base font-medium"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div className="w-full">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
              loading={loading}
            >
              Login
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
