import * as React from "react";

import { BsPatchCheckFill as VerifiedIcon } from "react-icons/bs";

interface IVerifiedAccountBadgeProps {
  className?: string;
  iconClassName?: string;
}

export const VerifiedAccountBadge: React.FC<IVerifiedAccountBadgeProps> = ({
  className,
  iconClassName,
}) => (
  <div className="relative w-[16px] h-[16px]">
    <VerifiedIcon size={16} className={`${iconClassName} absolute z-20`} />
    <div className="absolute w-full h-full flex flex-row justify-center items-center">
      <div className={`${className} absolute w-[10px] h-[10px] z-10`}></div>
    </div>
  </div>
);

VerifiedAccountBadge.defaultProps = {
  className: "bg-blue-900",
  iconClassName: "fill-blue-200",
};
