import * as React from "react";

import { FriendCard } from "./../../features";
import { CardList } from "../../components";

interface IFriendListProps {
  className?: string;
  data?: any[];
  loading?: boolean;
}

export const FriendList: React.FC<IFriendListProps> = ({
  className,
  data,
  loading,
}) => (
  <CardList className={className} loading={loading}>
    {data?.map((friend) => (
      <FriendCard key={friend?.id} {...friend} />
    ))}
  </CardList>
);

FriendList.defaultProps = {
  className: "",
  data: [],
  loading: false,
};
