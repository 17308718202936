import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface IVerificationState {
  verifications: AppReducerState<IVerification[]>;
  verification: AppReducerState<IVerification>;
}

const initialState: IVerificationState = {
  verifications: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  verification: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const verificationSlice = createSlice({
  name: "Verification Request",
  initialState,
  reducers: {
    appendVerificationRequestsRequests: appendToStore("verifications"),
    popVerificationRequestsRequests: popFromStore("verifications"),

    getVerificationRequestsRequest: (state) => {
      state.verifications.isLoading = true;
    },
    getVerificationRequestsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.verifications.isLoading = false;
      state.verifications.data = data;
      state.verifications.results = results;
    },
    getVerificationRequestsError: (state, action) => {
      state.verifications.isLoading = false;
      state.verifications.error = action.payload;
    },

    getVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    getVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    getVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    createVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    createVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    createVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    updateVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    updateVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    updateVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    removeVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    removeVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = {};
    },
    removeVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    approveVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    approveVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    approveVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    rejectVerificationRequestRequest: (state) => {
      state.verification.isLoading = true;
    },
    rejectVerificationRequestSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    rejectVerificationRequestError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    getVerificationRequestActionsRequest: (state) => {
      state.verification.isLoading = true;
    },
    getVerificationRequestActionsSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    getVerificationRequestActionsError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    addVerificationRequestActionRequest: (state) => {
      state.verification.isLoading = true;
    },
    addVerificationRequestActionSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    addVerificationRequestActionError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },

    removeVerificationRequestActionRequest: (state) => {
      state.verification.isLoading = true;
    },
    removeVerificationRequestActionSuccess: (state, action) => {
      state.verification.isLoading = false;
      state.verification.data = action.payload;
    },
    removeVerificationRequestActionError: (state, action) => {
      state.verification.isLoading = false;
      state.verification.error = action.payload;
    },
  },
});

export const verificationReducer = verificationSlice.reducer;
