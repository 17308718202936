import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { BusinessRegistrationContainer } = lazyload(
  () => import("../../features")
);

export const BusinessRegistrationPage = () => (
  <MainLayout>
    <BusinessRegistrationContainer />
  </MainLayout>
);
