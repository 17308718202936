import React from "react";

import { Container } from "../../../../components";

export const ResumeSectionCardBody = ({ children, loading }) => (
  <div className="bg-primaryLight rounded-md">
    <Container
      loading={loading}
      loadingClassName="p-[20px]"
      className="flex flex-col gap-y-[30px] p-[30px] box-sizing break-words text-sm"
    >
      {children}
    </Container>
  </div>
);

ResumeSectionCardBody.defaultProps = { loading: false };
