import React from "react";

import { Link } from "./../../components";

interface INavbarIconLink {
  className?: string;
  to?: string | null;
  counter?: number;
  counterClassName?: string;
  counterComponent?: () => JSX.Element;
  icon: React.FunctionComponent;
  iconSize?: number;
  iconProps?: object;
  iconClassName?: string;
  isActive?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
}

export const NavbarIconLink: React.FC<INavbarIconLink> = ({
  children,
  counter,
  counterClassName,
  className,
  to,
  onClick,
  icon: Icon,
  iconSize,
  iconProps,
  iconClassName,
  isActive,
}) => (
  <div
    className={`${className} ${
      isActive ? "text-white fill-white" : "text-secondary fill-secondary"
    } relative flex flex-row justify-start items-center font-semibold text-sm cursor-pointer hover:fill-white hover:text-white`}
  >
    {to && <Link to={to} className="absolute w-full h-full z-40" />}

    <div
      className="relative flex flex-row justify-start items-center text-inherit py-[2px] px-[2px] z-30"
      onClick={onClick}
    >
      {Icon ? (
        <div className="w-[20px] h-[20px] overflow-hidden">
          <Icon
            // @ts-ignore
            size={iconSize}
            className="fill-inherit text-inherit"
            {...iconProps}
          />
        </div>
      ) : (
        <></>
      )}

      {counter && counter >= 1 ? (
        <div
          className={`${counterClassName} absolute -top-[4px] -right-[3px] w-[14px] h-[14px] flex flex-row justify-center items-center bg-coral rounded-full text-[0.65em] text-white`}
        >
          <span>
            {counter ? (counter >= 1 && counter <= 99 ? counter : "99") : ""}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

NavbarIconLink.defaultProps = {
  className: "",
  counter: 0,
  counterClassName: "",
  iconClassName: "",
  isActive: false,
};
