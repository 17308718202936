import React from "react";

import {
  ResumeExperienceDetailSection,
  ResumeExperienceSectionSelect,
} from "../../..";

export const ResumeExperienceSection = ({ loading, editing, data }) => {
  return editing ? (
    // @ts-ignore
    <ResumeExperienceSectionSelect />
  ) : (
    <ResumeExperienceDetailSection loading={loading} data={data} />
  );
};

ResumeExperienceSection.defaultProps = {};
