import * as React from "react";
import { useNavigate } from "react-router";

import { IProfile } from "../../shared";

import { Avatar, Button, Card, CardBody, Link } from "../../components";
import { useAppDispatch, useLoading, useProfile, useToast } from "../../hooks";
import { ROUTER } from "../../routes";
import { createChat } from "../../store";

import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

interface IFriendCardProps extends IProfile {}

export const FriendCard: React.FC<IFriendCardProps> = ({
  id,
  firstName,
  lastName,
  avatar,
  isChat,
  chatId,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { profile } = useProfile();
  const [messageLoading, setMessageLoading] = useLoading();
  const { toast } = useToast();

  const isMyProfile = profile?.id === id || false;

  const handleMessage = async () => {
    if (!profile?.id) return;

    if (!isChat) {
      setMessageLoading(true);

      const chat = await dispatch(
        createChat({ userAId: profile?.id, userBId: id })
      );
      if (!chat?.id) {
        toast({
          status: NotificationStatus.Error,
          message: "Chat Not Created!",
        });
        return setMessageLoading(false);
      }

      setMessageLoading(false);
      redirect(ROUTER.CHAT_DETAIL(chat?.id));

      return;
    }

    redirect(ROUTER.CHAT_DETAIL(chatId));
  };

  return (
    <Card>
      <CardBody>
        <div className="flex flex-row justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-start items-start gap-x-[10px]">
                <Avatar size={40} src={avatar} to={ROUTER.USER_DETAIL(id)}>
                  {firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                  <Link to={ROUTER.USER_DETAIL(id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {firstName || ""} {lastName || ""}
                    </h3>
                  </Link>
                  <span className="text-xs text-secondary leading-none">
                    ********{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-[10px]">
            {isMyProfile ? (
              <></>
            ) : (
              <Button
                variant="outlined"
                color="success"
                className="min-w-[100px]"
                loading={messageLoading}
                onClick={handleMessage}
              >
                Message
              </Button>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

FriendCard.defaultProps = {};
