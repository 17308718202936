export enum Status {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum VerificationStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}
