import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { withdrawJob } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  Radio,
} from "./../../../components";
import { ROUTER } from "../../../routes";

export const JobWithdrawModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.JOB_WITHDRAW,
  });

  const { props } = modal;
  const { job, jobId, profileId, onSubmit } = props || {};

  const handleWithdraw = async () => {
    const data = {
      jobId,
      profileId,
    };

    setLoading(true);

    const application = await dispatch(withdrawJob(jobId));
    const jobApplicationId = application?.id;
    if (!jobApplicationId) {
      setLoading(false);
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "Job Application Not Removed!",
      });
    }

    if (onSubmit) onSubmit();

    setLoading(false);
    closeModal();

    toast({
      status: NotificationStatus.Success,
      message: "Job Application Removed!",
    });
  };

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Withdraw"
      description="Please confirm if you want to withdraw the job."
      open={isOpen}
      onClose={handleClose}
    >
      <div className="flex flex-col justify-start items-start">
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to withdraw{" "}
            <span className="text-white font-semibold">{job?.title}</span> job?
          </p>
        </div>
        <div className="w-full flex flex-row justify-end mt-[15px] gap-x-[5px]">
          <Button variant="text" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="danger"
            loading={loading}
            disabled={loading}
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        </div>
      </div>
    </Modal>
  );
};
