import React from "react";

interface IInputProps {
  className?: string;
  error?: string | null;
}

export const InputError: React.FC<IInputProps> = ({ error, className }) =>
  error ? (
    <span
      className={`${className} flex flex-row w-full text-xs px-[13.5px] text-danger`}
    >
      {error}
    </span>
  ) : (
    <></>
  );

InputError.defaultProps = {
  className: "",
  error: null,
};
