import React from "react";

import { BiSupport as SupportIcon } from "react-icons/bi";

import { IconButton } from "../../../components";
import { useModal } from "../../../hooks";
import { ModalType } from "../../../shared";

export const TicketIconButton = () => {
  const { setModal } = useModal();

  const handleClick = () => {
    setModal({ type: ModalType.TICKET_CREATE });
  };

  return (
    <IconButton
      className="transition-all w-[48px] h-[48px] rounded-full cursor-pointer bg-primaryMid text-secondary hover:bg-secondary hover:text-white"
      icon={SupportIcon}
      iconProps={{ size: 20 }}
      onClick={handleClick}
    />
  );
};
