import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { NotificationContainer } = lazyload(() => import("../../features"));

export const NotificationsPage = () => (
  <MainLayout>
    <NotificationContainer />
  </MainLayout>
);
