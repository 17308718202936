import React from "react";

import { ChatLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { ChatContainer } = lazyload(() => import("./../../features"));

export const ChatPage = () => (
  <ChatLayout>
    <ChatContainer />
  </ChatLayout>
);
