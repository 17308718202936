import { WebsocketReadyState } from "./../shared";

const { REACT_APP_WEBSOCKET_HOST: WEBSOCKET_HOST } = process.env;

interface IWebSocketMessage {
  event?: string;
  data?: any;
}

class WebSocketAdapter extends WebSocket {
  constructor(url) {
    super(url);
  }

  send(message: any) {
    super.send(JSON.stringify(message));
  }
}

type WebsocketReadyStateType = "";

class EventManager {
  socket;
  path: WebSocket;

  events = new Map();

  constructor(options) {
    const { path } = options || {};

    this.path = path;
  }

  async connect() {
    try {
      if (!this.socket) {
        this.socket = new WebSocketAdapter(
          `${WEBSOCKET_HOST}/${this.path || ""}`
        );

        this.socket.addEventListener("open", (e) => {
          console.log("WebSocket: Connected");
        });

        this.socket.addEventListener("close", (e) => {
          console.log("WebSocket: Disconnected");

          this.socket = null;

          setTimeout(() => this.connect(), 5000);
        });
      }

      if (!this.isConnected()) return setTimeout(() => this.connect(), 1000);

      return this.socket;
    } catch (e: any) {
      console.error(e);
    }
  }

  async close() {
    try {
      this.socket.close();
    } catch (e: any) {}
  }

  isConnected() {
    return this.socket && this.socket?.readyState === 1 ? true : false;
  }

  async send(message: IWebSocketMessage) {
    try {
      if (!this.isConnected()) return setTimeout(() => this.send(message), 100);

      this.socket.send(message);
    } catch (e: any) {}
  }

  async on(event: string, callback: Function) {
    try {
      if (!this.isConnected())
        return setTimeout(() => this.on(event, callback), 100);

      switch (event) {
        case "message":
          this.socket.addEventListener(event, (message) =>
            callback(JSON.parse(message.data))
          );
          break;
        default:
          break;
      }
    } catch (e: any) {
      console.error(e);
    }
  }
}

export const EventService = new EventManager({ path: "apiws" });
