import React, { useState } from "react";

import { IVerification } from "../../shared";

import {
  Button,
  Avatar,
  MessageIcon,
  CardFooter,
  VerificationStatusBadge,
  PhoneCallIcon,
  Card,
  CardBody,
  Link,
} from "../../components";

import { VerificationRequestActionLog } from "./../../features";
import { useAppDispatch, useLoading, useToast } from "../../hooks";
import {
  approveVerificationRequest,
  rejectVerificationRequest,
} from "../../store";
import { date } from "../../utils";
import { ROUTER } from "../../routes";
import { NotificationStatus, VerificationStatus } from "../../shared";

interface IUserVerificationCardProps extends IVerification {}

export const UserVerificationCard: React.FC<IUserVerificationCardProps> = ({
  id,
  status,
  experience,
  education,
  actionLogId,
  user,
  createdBy,
  createdAt,
}) => {
  const dispatch = useAppDispatch();

  const [approveLoading, setApproveLoading] = useLoading();
  const [rejectLoading, setRejectLoading] = useLoading();

  const { toast } = useToast();

  const [actionLog, setActionLog] = useState(false);

  const handleApprove = async () => {
    if (!id) return;

    setApproveLoading(true);

    const approved = await dispatch(approveVerificationRequest(id));
    if (!approved?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Verification Not Approved!",
      });

      return setApproveLoading(false);
    }

    toast({
      status: NotificationStatus.Success,
      message: "Verification Has Been Approved!",
    });
    setApproveLoading(false);
  };

  const handleReject = async () => {
    if (!id) return;

    setRejectLoading(true);

    const rejected = await dispatch(rejectVerificationRequest(id));
    if (!rejected?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Verification Not Rejected!",
      });

      return setRejectLoading(false);
    }

    toast({
      status: NotificationStatus.Success,
      message: "Verification Has Been Rejected!",
    });
    setRejectLoading(false);
  };

  return (
    <Card>
      <CardBody>
        <div className="flex justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-row justify-between items-center">
              <div className="flex flex-row justify-start items-start gap-x-[10px]">
                <Avatar
                  to={ROUTER.USER_DETAIL(user?.id)}
                  size={40}
                  src={user?.avatar}
                >
                  {user?.firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[5px]">
                  <Link to={ROUTER.USER_DETAIL(user?.id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {user?.firstName || ""} {user?.lastName || ""}
                    </h3>
                  </Link>

                  <span className="text-xs leading-none">
                    {date(createdAt).fromNow()}
                  </span>
                </div>
                <VerificationStatusBadge
                  className="mt-[1.5px]"
                  status={status}
                  experience={experience}
                />
              </div>

              <div className="flex justify-between gap-x-[10px]">
                <Button
                  color="success"
                  loading={approveLoading}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  color="danger"
                  loading={rejectLoading}
                  onClick={handleReject}
                >
                  Reject
                </Button>
              </div>
            </div>

            <div className="flex flex-col mx-[50px] mt-[15px]">
              {experience ? (
                <div className="flex flex-col">
                  <span className="text-base text-white font-semibold">
                    {experience?.role} at {experience?.company}
                  </span>
                  <span className="text-xs">
                    {date(experience?.startedAt).format("MMM YYYY")} -{" "}
                    {date(experience?.finishedAt).format("MMM YYYY")}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col mt-[10px]">
                  <span className="text-sm text-white font-medium">
                    {education?.degree} at {education?.university}
                  </span>
                  <span className="text-xs">
                    {date(education?.startedAt).format("MMM YYYY")} -{" "}
                    {date(education?.finishedAt).format("MMM YYYY")}
                  </span>
                </div>
              )}

              {experience && (
                <div className="flex flex-col mt-[20px]">
                  <div className="flex flex-row justify-start items-center gap-x-[20px] text-white">
                    <div className="flex flex-row justify-start items-center gap-x-[5px]">
                      <span className="text-sm text-secondary font-medium">
                        Requested By:
                      </span>
                      <div className="flex flex-row justify-start items-center gap-x-[5px]">
                        <Link to={ROUTER.USER_DETAIL(createdBy?.id)}>
                          <span className="text-sm font-semibold text-white">
                            {createdBy?.firstName} {createdBy?.lastName}
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-x-[5px] text-base stroke-secondary fill-transparent">
                      <MessageIcon size={20} />
                      <span>{experience?.companyEmail}</span>
                    </div>
                    <div className="flex flex-row items-center gap-x-[5px] text-base stroke-secondary fill-transparent">
                      <PhoneCallIcon size={20} />
                      <span>{experience?.companyPhone}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardBody>

      {actionLog ? (
        <>
          <CardFooter className="w-full flex flex-col py-[20px]">
            <VerificationRequestActionLog
              verificationId={id}
              actionLogId={actionLogId}
            />
          </CardFooter>
          <CardFooter>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setActionLog(false)}
            >
              Close
            </Button>
          </CardFooter>
        </>
      ) : (
        <CardFooter>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setActionLog(true)}
          >
            Action Log
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

UserVerificationCard.defaultProps = {
  status: VerificationStatus.Pending,
  experience: {},
  createdBy: {},
};
