import React, { useEffect } from "react";
import { useParams } from "react-router";

import { Container, PageHeader } from "../../components";
import { FriendList, FriendRequestList } from "./../../features";
import { useAppDispatch, useAppSelector, useProfile } from "../../hooks";
import { getFriendRequestsByUserId, getFriendsByUserId } from "../../store";

export const FriendRequestListContainer = () => {
  const dispatch = useAppDispatch();

  const { profile } = useProfile();

  const {
    userFriendRequests: {
      data: friendRequests,
      results: friendRequestCount,
      isLoading: friendRequestLoading,
    },
  } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!profile?.id) return;

    dispatch(getFriendRequestsByUserId(profile?.id));
  }, [profile?.id]);

  return (
    <Container>
      <PageHeader back={false}>
        Friend Requests ({friendRequestCount || 0})
      </PageHeader>
      <FriendRequestList data={friendRequests} loading={friendRequestLoading} />
    </Container>
  );
};
