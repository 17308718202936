import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";

import { Button, Container, Form, PageHeader } from "../../components";

import {
  ResumeCoverSelectSection,
  ResumeExperienceSectionSelect,
  ResumeEducationSectionSelect,
} from "../../features";

import {
  getResumeCovers,
  getExperiences,
  getEducations,
  updateResume,
  getResume,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../hooks";
import { ROUTER } from "../../routes";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

export const ResumeBuilderTemplateEdit = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);
  const { profileCovers } = useAppSelector((state) => state.profileCover);
  const { experiences } = useAppSelector((state) => state.experience);
  const { educations } = useAppSelector((state) => state.education);

  const [selectedCovers, setSelectedCovers] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedEducations, setSelectedEducations] = useState([]);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = URL.params?.id;

  const isDefault = resume?.data?.isDefault;
  const isLoading = resume?.isLoading;

  const { values, errors, touched, handleSubmit, setValues, resetForm } =
    useFormik({
      initialValues: {
        covers: [],
        experiences: [],
        educations: [],
      },
      onSubmit: async (values) => {
        const { covers, educations, experiences } = values;

        const data = {
          covers,
          experiences,
          educations,
        };

        setLoading(true);

        // Updating Resume Template
        const updated = await dispatch(updateResume(resumeId, data));
        if (!updated) {
          toast({
            status: NotificationStatus.Error,
            message: "Resume Template Not Updated!",
          });

          return setLoading(false);
        }

        toast({
          status: NotificationStatus.Success,
          message: "Resume Template Updated!",
        });

        setLoading(false);

        setURL({
          path: ROUTER.RESUME_BUILDER_TEMPLATE_DETAIL(),
          params: { id: resumeId },
        });
      },
    });

  const isChecked = (selected) => (id: string) =>
    selected?.filter((i) => i === id).length >= 1;

  const handleSelect = (state, setState) => (e) => {
    const value = e?.target?.value;
    const isChecked = e?.target?.checked;

    if (!value) return;
    if (!isChecked) return setState([...state.filter((i) => i !== value)]);

    setState([...(state?.filter((i) => i !== value) || []), value]);
  };

  const handleCancel = () => {
    resetForm();
    setURL({
      path: ROUTER.RESUME_BUILDER_TEMPLATE_DETAIL(),
      params: { id: resumeId },
    });
  };

  useEffect(() => {
    setValues({
      covers: selectedCovers,
      experiences: selectedExperiences,
      educations: selectedEducations,
    });
  }, [selectedCovers, selectedExperiences, selectedEducations]);

  useEffect(() => {
    dispatch(getResume(resumeId));
  }, [resumeId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Container loading={isLoading}>
        <PageHeader back={false}>
          Resume Builder / Edit{" "}
          {isDefault ? "Default Resume" : resume?.data?.name}
        </PageHeader>

        <div className="flex flex-col gap-y-[20px]">
          <ResumeCoverSelectSection
            // @ts-ignore
            loading={profileCovers?.isLoading}
            selected={selectedCovers}
            // @ts-ignore
            setSelected={setSelectedCovers}
            isChecked={isChecked(selectedCovers)}
            onChange={handleSelect(selectedCovers, setSelectedCovers)}
          />
          <ResumeExperienceSectionSelect
            // @ts-ignore
            loading={experiences?.isLoading}
            selected={selectedExperiences}
            setSelected={setSelectedExperiences}
            isChecked={isChecked(selectedExperiences)}
            onChange={handleSelect(selectedExperiences, setSelectedExperiences)}
          />
          <ResumeEducationSectionSelect
            // @ts-ignore
            loading={educations?.isLoading}
            selected={selectedEducations}
            setSelected={setSelectedEducations}
            isChecked={isChecked(selectedEducations)}
            onChange={handleSelect(selectedEducations, setSelectedEducations)}
          />
        </div>

        <div className="flex flex-row justify-between items-center mt-[15px]">
          <div className="flex flex-row gap-x-5">
            <Button color="danger" variant="text" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <Button
            type="submit"
            loading={loading}
            color="success"
            variant="outlined"
          >
            Save Template
          </Button>
        </div>
      </Container>
    </Form>
  );
};
