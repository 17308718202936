import React, { useEffect, useReducer, useState } from "react";

import {
  Button,
  DropdownItem,
  DropdownMenuItem,
  DropdownMenu,
  IconButton,
  Checkbox,
} from "../../../../components";

import { removeResumeCover, removeProfileCover } from "../../../../store";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../../hooks";

import {
  ResumeSectionCard,
  ResumeSectionCardBody,
  ResumeSectionCardFooter,
  ResumeCoverCreateForm,
  ResumeCoverEditForm,
  ResumeCoverCard,
  ResumeDeleteModal,
} from "../../..";
import { Action, ModalType } from "../../../../shared";

export interface IResumeSectionProps {
  loading?: boolean;
  editing?: boolean;
  data?: any;
}

export const ResumeCoverSectionDetail: React.FC<IResumeSectionProps> = ({
  loading,
  editing,
  data,
}) => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();
  const { modal, setModal } = useModal();

  const { resume } = useAppSelector((state) => state.resume);

  const resumeId = resume?.data?.id;

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "START_CREATE_COVER":
        return { ...state, creating: true };
      case "STOP_CREATE_COVER":
        return { ...state, creating: false };
      case "START_EDIT_COVER":
        return {
          ...state,
          editing: [
            ...state.editing.filter(({ id }) => id !== payload.id),
            payload,
          ],
        };
      case "STOP_EDIT_COVER":
        return {
          ...state,
          editing: state.editing.filter(({ id }) => id !== payload.id),
        };
      default:
        return state;
    }
  };

  const [state, localDispatch] = useReducer(reducer, {
    data: [],
    creating: false,
    editing: [],
  });

  const isEditing = (id: string) =>
    state.editing?.filter((el) => el.id === id)?.length >= 1;

  const handleCreateStart = () => {
    localDispatch({ type: "START_CREATE_COVER" });
  };

  const handleCreateCancel = () => {
    localDispatch({ type: "STOP_CREATE_COVER" });
  };

  const handleEditStart = (id: string) => {
    localDispatch({ type: "START_EDIT_COVER", payload: { id } });
  };

  const handleEditCancel = (id: string) => {
    localDispatch({ type: "STOP_EDIT_COVER", payload: { id } });
  };

  const handleDelete = (props) => {
    setModal({
      ...modal,
      type: ModalType.RESUME_COVER_DELETE,
      props,
    });
  };

  return (
    <>
      <ResumeSectionCard heading="Cover">
        <ResumeSectionCardBody loading={loading}>
          {data?.map((cover) => (
            <div className="w-full" key={cover?.id}>
              {isEditing(cover?.id) ? (
                <ResumeCoverEditForm
                  data={cover}
                  onCancel={() => handleEditCancel(cover.id)}
                />
              ) : editing ? (
                <div className="flex flex-row justify-start items-start gap-x-[10px]">
                  <Checkbox value={cover?.id} />
                  <div className="-mt-[2px]">
                    <div className="flex flex-row justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold">{cover?.title}</h3>
                    </div>
                    <p>{cover?.details}</p>
                  </div>
                </div>
              ) : (
                <ResumeCoverCard
                  {...cover}
                  actions={[Action.Edit, Action.Delete]}
                  onEdit={() => handleEditStart(cover.id)}
                  onDelete={() => handleDelete({ ...cover, resumeId })}
                />
              )}
            </div>
          ))}
        </ResumeSectionCardBody>

        {editing ? (
          <></>
        ) : (
          <ResumeSectionCardFooter>
            {state.creating ? (
              <ResumeCoverCreateForm onCancel={handleCreateCancel} />
            ) : (
              <div className="w-full flex flex-row justify-center">
                <Button
                  variant="text"
                  className="text-white"
                  onClick={handleCreateStart}
                >
                  Add New
                </Button>
              </div>
            )}
          </ResumeSectionCardFooter>
        )}
      </ResumeSectionCard>
    </>
  );
};

ResumeCoverSectionDetail.defaultProps = {};
