import React, { useEffect, useMemo } from "react";

import { Button, Checkbox } from "../../../../components";
import {
  ResumeCoverCard,
  ResumeSectionCard,
  ResumeSectionCardBody,
} from "../../..";
import {
  getEducations,
  getExperiences,
  getProfileCovers,
  getResumeCovers,
} from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

export interface ISelectSectionProps {
  selected: any[];
  setSelected: (value?: any[]) => void;
  isChecked: (value?: any) => boolean;
  onChange?: (e?: React.ChangeEvent) => void;
}

export const ResumeCoverSelectSection: React.FC<ISelectSectionProps> = ({
  selected,
  setSelected,
  isChecked,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { profileCovers } = useAppSelector((state) => state.profileCover);

  const { resume } = useAppSelector((state) => state.resume);
  const resumeCovers = resume?.data?.covers || [];

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;

  const archivedCovers = profileCovers?.data?.filter(
    ({ id }) =>
      !resumeCovers
        ?.map(({ originId }) => originId)
        ?.filter((originId: string) => originId)
        ?.includes(id)
  );

  const data = [...resumeCovers, ...archivedCovers];

  useEffect(() => {
    setSelected(resumeCovers?.map(({ id }) => id) || []);
  }, [resumeCovers]);

  useEffect(() => {
    if (!profileId || !resumeId) return;

    dispatch(
      getProfileCovers({
        where: { profileId, isArchived: true },
      })
    );
  }, []);

  return (
    <ResumeSectionCard heading="Cover">
      <ResumeSectionCardBody loading={profileCovers?.isLoading}>
        {data?.map((cover) => (
          <div
            key={cover?.id}
            className="flex flex-row justify-start items-start gap-x-[10px]"
          >
            <Checkbox
              value={cover?.id}
              checked={isChecked(cover?.id)}
              onChange={onChange}
            />
            <ResumeCoverCard actions={[]} {...cover} />
          </div>
        ))}
      </ResumeSectionCardBody>
    </ResumeSectionCard>
  );
};

ResumeCoverSelectSection.defaultProps = {};
