import React from "react";

import { useNavigate } from "react-router";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useModal, useToast } from "../../../hooks";
import { removeJob, updateJob } from "../../../store";

import { Button, Form, TextInput, Modal } from "../../../components";
import { useFormik } from "formik";

export const JobApplicantionMessageModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { toast } = useToast();

  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.JOB_APPLICATION_MESSAGE,
  });

  const { loading, props, redirectTo } = modal;
  const { id, title, company } = props || {};

  const {
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      company: "",
      role: "",
      companyEmail: "",
      companyPhone: "",
      isWorking: false,
      details: "",
      startedAt: null,
      finishedAt: null,
    },
    // validationSchema,
    onSubmit: async (values) => {},
  });

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Send Message"
      description="Please select a message template or use custome message option to send message"
      className="bg-primaryBlack"
      open={isOpen}
      onClose={handleClose}
    >
      <div className="w-full">
        <div className="text-sm text-secondary break-words">
          <Form>
            <TextInput
              id="details"
              label="Details"
              type="text"
              variant="filled"
              className="border border-solid border-primaryMid"
              controlClassName="basis-full"
              labelClassName="text-secondary"
              required
              multiline
              minRows={4}
              value={values?.details}
              error={touched?.details ? errors?.details : null}
              onChange={handleChange}
            />
          </Form>
        </div>
        <div className="w-full flex flex-row justify-between items-center mt-[15px]">
          <div>
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row">
            <Button
              variant="outlined"
              color="primary"
              className="min-w-[100px]"
              // loading={loading?.delete}
              // onClick={handleDelete}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
