import React from "react";

import { FiChevronRight as ChevronRight } from "react-icons/fi";

export const BulletPointListItem: React.FC = ({ children }) => (
  <div className="flex flex-row justify-start items-start gap-x-[8px] min-h-[20px]">
    <div className="flex flex-row justify-center items-center w-[14px] min-w-[14px] h-[14px] bg-secondary rounded-full text-primaryDark">
      <ChevronRight size={12} />
    </div>
    <p className="-mt-[4px]">{children}</p>
  </div>
);
