import React, { useEffect } from "react";
import { useToast } from "../../hooks";

export const NotificationProvider = () => {
  const { toast } = useToast();

  useEffect(() => {
    // console.log("Notification Provider..");
    // toast({
    //   status: "info",
    //   title: "Welcome",
    //   icon: () => <span>🥳</span>,
    //   message:
    //     "Welcome to Legitbook! Your account has been successfully created. Now you have access to Legitbook features.",
    // });
    // setInterval(() => {
    //   toast({
    //     status: "info",
    //     title: "Notification",
    //     message:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //   });
    // }, 3000);
  }, []);

  return <></>;
};
