import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
  FiClock as ClockIcon,
} from "react-icons/fi";

import { Role } from "../../shared";
import {
  BulletPointList,
  BulletPointListItem,
  Button,
  Container,
  IconButton,
  PageHeader,
  RBAC,
  RBACSwitch,
  SidebarSection,
} from "../../components";
import { JobDetailButtons, NotFoundError } from "./../../features";
import { getJob, getJobs, removeJob, updateJob } from "../../store";
import { ROUTER } from "../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useRBAC,
  useToast,
} from "../../hooks";
import { date, renderJobSalary, renderTextAsList } from "../../utils";
import { TIMEZONES } from "../../constants";

export const JobDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { id } = useParams();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    job: { data: job, isLoading: jobLoading },
  } = useAppSelector((state) => state.job);

  const {
    id: jobId,
    title,
    type,
    city,
    country,
    skills,
    timezone,
    positions,
    description,
    requirements,
    desiredSkills,
    isDraft,
    isDelisted,
    isApplied,
    companyId,
    minSalary = 0,
    maxSalary = 0,
    salaryDependsOnExperience,
    applicationCount = 0,
  } = job || {};

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  const parseTimezone = (value) =>
    TIMEZONES?.find(({ id }) => id === value)?.value || null;

  useEffect(() => {
    if (!id) return;

    dispatch(getJob(id));
  }, [id]);

  return (
    <Container
      className="w-full flex flex-col gap-y-5 pt-5 pb-5 text-secondary"
      loading={jobLoading}
    >
      {jobId ? (
        <Container>
          {" "}
          <RBAC
            roles={[Role.Admin, Role.User]}
            conditions={[
              {
                roles: [Role.Owner, Role.TeamLead, Role.Employee],
                condition: isEmployed,
              },
            ]}
            error
          >
            <PageHeader
              exception={{
                from: ROUTER.JOB_APPLICATION_LIST(id),
                backTo: "/home",
              }}
            >
              Job Details
            </PageHeader>

            <div className="flex flex-row justify-between items-center gap-x-[10px]">
              <h1 className="capitalize text-2xl font-semibold text-white">
                {title}{" "}
                {isDraft && <span className="text-secondary">(Draft)</span>}{" "}
                {isDelisted && <span className="text-warning">(Delisted)</span>}
              </h1>
            </div>

            <div className="flex flex-row flex-wrap justify-start items-center capitalize gap-x-[15px] gap-y-[10px] text-base text-secondary font-medium mt-[10px]">
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <MonitorIcon />
                <span>{type}</span>
              </div>
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <MapIcon />
                <span>
                  {city}, {country}
                </span>
              </div>
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <UsersIcon />
                <span>{positions} Positions</span>
              </div>
              <div className="flex flex-row justify-between items-center gap-x-[5px]">
                <ClockIcon />
                <span>{parseTimezone(timezone)}</span>
              </div>
            </div>

            <div
              className="
          w-full mt-[15px]
          xxs:flex xl:hidden
      "
            >
              <Container
                // loading={isLoading}
                className="flex flex-col gap-y-[10px]"
              >
                <div className="flex flex-row flex-wrap justify-between items-center">
                  <SidebarSection
                    heading="Salary Range"
                    className="border-none px-[0px] py-[0px] basis-6/12"
                  >
                    <span className="text-white text-lg font-semibold">
                      {renderJobSalary({
                        minSalary,
                        maxSalary,
                        salaryDependsOnExperience,
                      })}
                    </span>
                  </SidebarSection>

                  <SidebarSection
                    heading="Posted On"
                    className="border-none px-[0px] py-[0px] basis-6/12"
                  >
                    <span className="text-white text-lg font-semibold">
                      {date(job?.createdAt).format("YYYY-MM-DD")}
                    </span>
                  </SidebarSection>

                  <SidebarSection
                    heading="Applicantions"
                    className="border-none px-[0px] py-[0px] basis-6/12"
                  >
                    <div className="flex flex-row justify-between items-center">
                      <span className="text-white text-lg font-semibold">
                        {applicationCount || 0}
                      </span>
                    </div>
                  </SidebarSection>
                </div>

                <Container className="flex flex-col gap-y-[10px] mt-[20px]">
                  <JobDetailButtons loading={jobLoading} job={job} />
                </Container>
              </Container>
            </div>

            <div className="flex flex-col gap-y-[50px] mt-[30px]">
              <DetailTextSection heading="Description">
                <p>{description}</p>
              </DetailTextSection>

              {!!skills?.length && (
                <DetailTextSection heading="Experience Required">
                  <BulletPointList
                    data={skills?.map((i) => i?.name)?.join("\n")}
                  />
                </DetailTextSection>
              )}

              {!!requirements?.length && (
                <DetailTextSection heading="Requirements">
                  <BulletPointList data={requirements} />
                </DetailTextSection>
              )}

              {!!desiredSkills?.length && (
                <DetailTextSection heading="Desired Skills">
                  <BulletPointList data={desiredSkills} />
                </DetailTextSection>
              )}
            </div>
          </RBAC>
        </Container>
      ) : (
        <>
          <NotFoundError />
        </>
      )}
    </Container>
  );
};

interface IDetailTextSectionProps {
  className?: string;
  heading?: string;
}

export const DetailTextSection: React.FC<IDetailTextSectionProps> = ({
  children,
  className,
  heading,
}) => (
  <div className="w-full flex flex-col">
    <h2 className="text-lg text-white font-semibold">{heading}</h2>
    <div
      className={`${className} flex flex-col gap-y-[15px] mt-[18px] text-base text-white font-light`}
    >
      {children}
    </div>
  </div>
);
DetailTextSection.defaultProps = {
  className: "",
};
