import React from "react";

import { SidebarSection, Sidebar, SidebarContent } from "../../../components";
import { ISidebar } from "@types";
import { RecentlyAppliedJobsWidget } from "./../../../features";

export const ResumeBuilderRightSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarContent>
      <SidebarSection heading="Recently Applied Jobs" className="border-none">
        <RecentlyAppliedJobsWidget />
      </SidebarSection>
    </SidebarContent>
  </Sidebar>
);
