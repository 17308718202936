import React from "react";

import { ActionMenu, Avatar } from "../../components";
import { Action } from "../../shared";
import { date } from "../../utils";

export const VerificationRequestActionLogCard = ({
  id,
  comment,
  isAuthor,
  createdBy,
  createdAt,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row justify-start items-center gap-x-[10px]">
          <Avatar>{createdBy?.firstName}</Avatar>
          <span className="text-white text-sm font-semibold">
            {createdBy?.firstName} {createdBy?.lastName}
          </span>
          <span className="text-xs font-medium">
            • {date(createdAt).format("dd, DD MMMM")}
          </span>
        </div>
        {isAuthor && <ActionMenu actions={[Action.Edit, Action.Delete]} />}
      </div>

      <div className="text-white text-sm font-medium ml-[30px]">
        <p className="py-[5px]">{comment}</p>
      </div>
    </div>
  );
};

VerificationRequestActionLogCard.defaultProps = {
  isAuthor: false,
};
