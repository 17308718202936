import React from "react";

import { Link } from "./../../components";

export interface INavbarLinkProps {
  className?: string;
  to?: string;
  icon?: React.FunctionComponent;
  iconSize?: number;
  iconClassName?: string;
  isActive?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
}

export const NavbarLink: React.FC<INavbarLinkProps> = ({
  children,
  className,
  iconClassName,
  to,
  onClick,
  icon: Icon,
  iconSize,
  isActive,
}) => (
  <div
    className={`${className} ${
      isActive
        ? " fill-white bg-primaryMid rounded-[20px] text-white "
        : "bg-primaryDark"
    } relative flex flex-row justify-start items-center font-semibold md:text-sm xxs:text-xs cursor-pointer hover:fill-white hover:text-white`}
  >
    {to && <Link to={to} className="absolute w-full h-full" />}

    <div
      className={`${
        children ? "justify-start" : "justify-center"
      } flex flex-row items-center gap-x-[3px] text-inherit h-[40px] md:px-[15px] xxs:px-[5px]`}
      onClick={onClick}
    >
      {Icon ? (
        <Icon
          // @ts-ignore
          size={iconSize}
          className={`flex flex-row justify-center items-center`}
          view
        />
      ) : (
        <></>
      )}
      {children}
    </div>
  </div>
);

NavbarLink.defaultProps = {
  isActive: false,
  iconSize: 30,
};
