import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MdSend as SendIcon } from "react-icons/md";

import {
  Avatar,
  Button,
  Form,
  FormSection,
  TextInput,
  IconButton,
  StarRating,
  Switch,
} from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../hooks";
import {
  addVerificationRequestAction,
  createPost,
  createResume,
} from "../../store";
import { ROUTER } from "../../routes";
import { addLineBreaks } from "../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .max(1000, "Exceeded maximum character length of 1500")
    .required("Comment is a required field"),
});

export const VerificationRequestActionLogCreateForm = ({
  verificationId,
  actionLogId,
  onCreate,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;
  const createdById = profile?.id;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!createdById) return;

      const { comment } = values;

      const data = {
        comment: addLineBreaks(comment),
        actionLogId,
        createdById,
      };

      setLoading(true);

      const created = await dispatch(
        addVerificationRequestAction(verificationId, data)
      );
      if (!created?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Action Not Created!",
        });
      }

      onCreate({ ...created, createdBy: profile });
      setLoading(false);
      resetForm();
    },
  });

  return (
    <div className="w-full flex flex-row justify-start items-start gap-x-[12px]">
      <div className="mt-[10px]">
        <Avatar size={40}>{profile?.firstName}</Avatar>
      </div>
      <div className="w-full">
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-row justify-between items-start gap-x-[12px]">
            <TextInput
              id="comment"
              type="text"
              variant="outlined"
              className="border-2 border-solid border-primaryMid bg-primaryLight text-white"
              controlClassName="basis-full"
              labelClassName="text-secondary"
              placeholder="Write a comment…"
              multiline
              maxRows={10}
              required
              value={values?.comment}
              error={touched?.comment ? errors?.comment : null}
              onChange={handleChange}
            />
            <IconButton
              loading={loading}
              icon={SendIcon}
              iconProps={{ size: 24 }}
              iconClassName="hover:fill-white"
              className="px-[15px] py-[15px] rounded-[8px] min-h-[40px]"
              onClick={() => handleSubmit()}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

VerificationRequestActionLogCreateForm.defaultProps = {};
