export enum VerificationRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum VerificationRequestType {
  Experience = 'experience',
  Education = 'education',
}
