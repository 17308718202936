import React, { useState } from "react";

import { Popper, Box, Avatar, Menu } from "@mui/material";
import { Link, Button } from "./../../components";

interface IDropDownMenuProps {
  className?: string;
  button?: any;
}

export const DropdownMenu: React.FC<IDropDownMenuProps> = ({
  children,
  className,
  button,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div onClick={handleClick}>{button}</div>
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // @ts-ignore
        disableRipple
        disableScrollLock
        PaperProps={{
          elevation: 0,
          className: `flex min-w-[30px] mt-[2px] ml-[7px] min-h-[48px] bg-primaryBlack text-white rounded-lg p-0 overflow-hidden box-border ${className}`,
          sx: {
            overflow: "visible",
            "& .MuiMenu-list": {
              padding: 0,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </Menu>
    </>
  );
};

DropdownMenu.defaultProps = {
  className: "",
};
