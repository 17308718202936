import React, { Suspense } from "react";

import { Loader, Navbar, BottomNavbar } from "../../components";
import { ILayout } from "@types";
import { TicketIconButton } from "../../features";

interface IMainLayoutProps extends ILayout {}

export const MainLayout: React.FC<IMainLayoutProps> = ({
  children,
  leftSidebar: LeftSidebar,
  rightSidebar: RightSidebar,
}) => (
  <div className="flex flex-col w-full min-h-screen h-auto bg-primaryDark overflow-x-hidden font-gilroy">
    <Navbar />

    <div
      className="
        fixed top-[0px] bottom-[0px] right-[0px] flex-col justify-end items-center p-[25px] box-border z-40
        xxs:hidden md:flex
        "
    >
      <TicketIconButton />
    </div>

    {/* <Drawer
        anchor="left"
        open={sidebar}
        onOpen={handleToggleSidebar}
        onClose={handleToggleSidebar}
      >
        <div className="w-[280px] h-screen bg-primaryDark flex flex-col">
          <div className="flex flex-row box-border p-[25px]">
            <h2 className="text-lg text-white font-medium">Menu</h2>
          </div>
        </div>
      </Drawer> */}

    {LeftSidebar && (
      <div
        className="fixed h-screen w-auto flex flex-col box-border border-r border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex 
    "
      >
        <Suspense fallback={<Loader />}>
          <LeftSidebar position="left" className="xl:w-[250px] 2xl:w-[350px]" />
        </Suspense>
      </div>
    )}

    <div className="w-full max-w-[768px] flex flex-col h-auto min-h-[500px] mt-[75px] mx-auto py-[30px] box-border text-secondary px-[20px] md:px-[0px]">
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </div>

    {RightSidebar && (
      <div
        className="fixed w-auto h-screen right-[0px] flex flex-col box-border border-l border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex          
    "
      >
        <Suspense fallback={<Loader />}>
          <RightSidebar
            position="right"
            className="xl:w-[250px] 2xl:w-[350px]"
          />
        </Suspense>
      </div>
    )}

    <div className="fixed z-50 bottom-0 w-full h-[70px] flex md:hidden xxs:flex">
      <BottomNavbar />
    </div>
    <div className="w-full h-[75px]"></div>
  </div>
);

MainLayout.defaultProps = {};
