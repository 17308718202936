import { createSlice } from "@reduxjs/toolkit";

import { IAccount } from "../../../shared";

import { AppReducerState } from "../../store";

interface IAccountState {
  account: AppReducerState<IAccount>;
}

const initialState: IAccountState = {
  account: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const accountSlice = createSlice({
  name: "Account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account.data = { ...state.account.data, ...action.payload };
    },
    setAccountProfile: (state, action) => {
      state.account.data.profile = {
        ...state.account.data?.profile,
        ...action.payload,
      };
    },

    getAccountRequest: (state) => {
      state.account.isLoading = true;
    },
    getAccountSuccess: (state, action) => {
      state.account.isLoading = false;
      state.account.data = action.payload;
    },
    getAccountError: (state, action) => {
      state.account.isLoading = false;
      state.account.error = action.payload;
    },

    syncAccountRequest: (state) => {},
    syncAccountSuccess: (state, action) => {
      state.account.data = action.payload;
    },
    syncAccountError: (state, action) => {
      state.account.data = {};
      state.account.error = action.payload;
    },

    checkAccountRequest: (state) => {},
    checkAccountSuccess: (state, action) => {},
    checkAccountError: (state) => {},

    loginRequest: (state) => {
      state.account.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.account.isLoading = false;
    },
    loginError: (state, action) => {
      state.account.isLoading = false;
      state.account.error = action.payload;
    },

    registerRequest: (state) => {
      state.account.isLoading = true;
    },
    registerSuccess: (state, action) => {
      state.account.isLoading = false;
    },
    registerError: (state, action) => {
      state.account.isLoading = false;
      state.account.error = action.payload;
    },

    checkPassportRequest: (state) => {
      state.account.isLoading = true;
    },
    checkPassportSuccess: (state) => {
      state.account.isLoading = false;
    },
    checkPassportError: (state) => {
      state.account.isLoading = false;
    },

    scanPassportRequest: (state) => {
      state.account.isLoading = true;
    },
    scanPassportSuccess: (state, action) => {
      state.account.isLoading = false;
    },
    scanPassportError: (state, action) => {
      state.account.isLoading = false;
      state.account.error = action.payload;
    },

    logoutRequest: (state) => {
      state.account.isLoading = true;
    },
    logoutSuccess: (state, action) => {
      state.account.isLoading = false;
      state.account.data = {};
    },
    logoutError: (state, action) => {
      state.account.isLoading = false;
      state.account.error = action.payload;
    },

    switchToPersonalProfileRequest: (state) => {
      state.account.isLoading = true;
    },
    switchToPersonalProfileSuccess: (state, action) => {
      state.account.isLoading = false;
    },
    switchToPersonalProfileError: (state, action) => {
      state.account.isLoading = false;
    },

    switchToBusinessProfileRequest: (state) => {
      state.account.isLoading = true;
    },
    switchToBusinessProfileSuccess: (state, action) => {
      state.account.isLoading = false;
    },
    switchToBusinessProfileError: (state, action) => {
      state.account.isLoading = false;
    },

    validateEmailRequest: () => {},
    validateEmailSuccess: () => {},
    validateEmailError: (state, action) => {
      state.account.error = action.payload;
      state.account.isLoading = false;
    },

    checkEmailRequest: () => {},
    checkEmailSuccess: () => {},
    checkEmailError: () => {},

    verifyEmailRequest: () => {},
    verifyEmailSuccess: () => {},
    verifyEmailError: () => {},

    confirmEmailRequest: () => {},
    confirmEmailSuccess: () => {},
    confirmEmailError: () => {},

    resetPasswordRequest: () => {},
    resetPasswordSuccess: () => {},
    resetPasswordError: () => {},

    updatePasswordRequest: () => {},
    updatePasswordSuccess: () => {},
    updatePasswordError: () => {},

    checkUserTokenRequest: () => {},
    checkUserTokenSuccess: () => {},
    checkUserTokenError: () => {},

    updateUserAvatarRequest: () => {},
    updateUserAvatarSuccess: (state, action) => {
      if (!state.account.data?.profile) return;

      const { avatar } = action.payload || {};

      state.account.data.profile.avatar = avatar;
    },
    updateUserAvatarError: () => {},
  },
});

export const accountReducer = accountSlice.reducer;
