import React from "react";

import { AuthLayout } from "../../layouts";

import { lazyload } from "../../utils";

const { NotFoundError } = lazyload(() => import("../../features"));

export const NotFoundPage = () => (
  <AuthLayout>
    <NotFoundError />
  </AuthLayout>
);
