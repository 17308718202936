import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { AdminBusinessAuditDetailContainer } = lazyload(
  () => import("../../features")
);

export const AdminBusinessAuditDetailPage = () => (
  <MainLayout>
    <AdminBusinessAuditDetailContainer />
  </MainLayout>
);
