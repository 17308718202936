import React from "react";

import { CircularProgress } from "@mui/material";

interface ILoaderProps {
  className?: string;
  size?: number;
}

export const Loader: React.FC<ILoaderProps> = ({ className, size }) => (
  <CircularProgress className={className} size={size} />
);

Loader.defaultProps = {
  className: "text-primary",
  size: 28,
};
