import React, { useEffect } from "react";
import { useParams } from "react-router";

import { Button, Container, PageHeader } from "../../components";
import { ROUTER } from "../../routes";

export const UserGalleryItemDetailContainer = () => {
  const { userId, itemId } = useParams();

  return (
    <Container>
      <PageHeader
        backTo={ROUTER.USER_GALLERY(userId)}
        // renderOnRight={
        //   <Button
        //     color="secondary"
        //     icon={<UploadIcon />}
        //     onClick={handleUploadClick}
        //   >
        //     Upload
        //   </Button>
        // }
      >
        Item #{itemId}
      </PageHeader>
      <Container className="">
        <div className="w-full h-[480px] bg-blue-200 rounded-lg"></div>
      </Container>
    </Container>
  );
};
