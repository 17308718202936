import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useActivePage, useDrawer, useProfile } from "../../hooks";

import { MdMenu as BurgerMenuIcon } from "react-icons/md";
import { FiSearch as SearchIcon } from "react-icons/fi";

import { Role } from "./../../shared";

import {
  CreatorsIcon,
  EducationIcon,
  Logo,
  Searchbar,
  NotificationIcon,
  CalendarIcon,
  StatisticIcon,
  MessageNotificationIcon,
  NavbarLink,
  NavbarIconLink,
  RBAC,
  RBACSwitch,
  Form,
  EmployeeSearchIcon,
  HomeIcon,
  JobSearchIcon,
  NewsIcon,
  Drawer,
  IconButton,
} from "./../../components";

import { ProfileNavbar } from "./../../features";
import { ROUTER } from "./../../routes";

export const Navbar: React.FC = () => {
  const { isActive } = useActivePage();
  const redirect = useNavigate();

  const { notificationCount } = useProfile();
  const { drawer, setDrawer } = useDrawer();

  const [search, setSearch] = useState<string | null>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target?.value;
    const isValid = query?.length >= 0;

    if (!isValid) return;

    setSearch(query);
  };

  const handleSearchSubmit = () => {
    if (!search) return;

    redirect(ROUTER.SEARCH(search));
    setSearch(null);
  };

  const handleToggleSidebar = () => {
    setDrawer({ ...drawer, open: true });
  };

  return (
    <div className="fixed w-full h-[75px] flex flex-row justify-between items-center box-border px-[25px] z-50 overflow-hidden fill-defaultNav bg-primaryDark text-secondary border-b border-primaryLight">
      <div className="w-full flex flex-row justify-between items-center gap-[25px]">
        <div className="basis-2/12 flex flex-row justify-start items-center gap-x-[20px]">
          <IconButton
            icon={BurgerMenuIcon}
            iconProps={{ size: 24 }}
            className="md:hidden xxs:flex"
            iconClassName="fill-secondary"
            onClick={handleToggleSidebar}
          />
          <Logo />
        </div>
        <div className="basis-3/12 md:flex xxs:hidden">
          <Form onSubmit={handleSearchSubmit}>
            <Searchbar
              className="min-w-[200px] max-w-[300px]"
              value={search}
              onChange={handleSearch}
              onSubmit={handleSearchSubmit}
            />
          </Form>
        </div>
        <div className="basis-7/12 flex flex-row justify-start items-center md:flex xxs:hidden md:gap-x-[10px] xxs:gap-x-[10px]">
          <NavbarLink
            to={ROUTER.HOME}
            icon={() => <HomeIcon size={20} viewBox="0 0 20 20" />}
            isActive={isActive(ROUTER.HOME)}
          >
            Home
          </NavbarLink>
          <NavbarLink
            to={ROUTER.NEWS}
            icon={() => <NewsIcon size={20} viewBox="0 0 20 20" />}
            isActive={isActive(ROUTER.NEWS)}
          >
            News
          </NavbarLink>

          <RBAC roles={[Role.Owner, Role.TeamLead, Role.Employee]}>
            <NavbarLink
              to={ROUTER.EMPLOYEE_SEARCH}
              icon={() => <EmployeeSearchIcon size={20} viewBox="-2 0 22 22" />}
              isActive={isActive(ROUTER.EMPLOYEE_SEARCH)}
            >
              Employee Search
            </NavbarLink>
          </RBAC>
          <RBAC roles={[Role.User]}>
            <NavbarLink
              to={ROUTER.JOB_ROOT}
              icon={() => <JobSearchIcon size={20} viewBox="-1.5 -1.5 20 20" />}
              isActive={isActive(ROUTER.JOB_ROOT)}
            >
              Job Search
            </NavbarLink>
          </RBAC>

          {/* <NavbarLink
            to={ROUTER.EDUCATION}
            icon={EducationIcon}
            isActive={isActive(ROUTER.EDUCATION)}
          >
            Education
          </NavbarLink>
          <NavbarLink
            to={ROUTER.CREATORS}
            icon={CreatorsIcon}
            isActive={isActive(ROUTER.CREATORS)}
          >
            Creators
          </NavbarLink> */}
        </div>

        <div
          className="basis-2/12 flex flex-row justify-end items-center
                    md:gap-x-[15px]
                    xxs:gap-x-[15px]"
        >
          <div
            className="flex flex-row
                      md:gap-x-[10px]
                      xxs:gap-x-[15px]"
          >
            <NavbarIconLink
              to={ROUTER.SEARCH()}
              icon={() => <SearchIcon size={24} />}
              className="md:hidden xxs:flex"
              isActive={isActive(ROUTER.SEARCH())}
            />

            <NavbarIconLink
              to={ROUTER.EVENTS}
              icon={() => <CalendarIcon size={20} viewBox="-0.5 2 20 20" />}
              isActive={isActive(ROUTER.EVENTS)}
            />

            {RBACSwitch({
              elements: [
                {
                  roles: [Role.Owner, Role.TeamLead, Role.Employee],
                  component: () => (
                    <NavbarIconLink
                      to={ROUTER.BUSINESS_ADMINISTRATION}
                      icon={() => (
                        <StatisticIcon size={20} viewBox="-0.5 0 22 22" />
                      )}
                      isActive={isActive(ROUTER.BUSINESS_ADMINISTRATION)}
                    />
                  ),
                },
                {
                  roles: [Role.God, Role.Admin],
                  component: () => (
                    <NavbarIconLink
                      to={ROUTER.ADMIN}
                      icon={() => (
                        <StatisticIcon size={20} viewBox="-0.5 0 22 22" />
                      )}
                      isActive={isActive(ROUTER.ADMIN)}
                    />
                  ),
                },
              ],
            })}

            <NavbarIconLink
              to={ROUTER.NOTIFICATIONS}
              icon={() => (
                <NotificationIcon
                  size={20}
                  className="-ml-[2px]"
                  viewBox="1 2 20 20"
                />
              )}
              isActive={isActive(ROUTER.NOTIFICATIONS)}
              counter={notificationCount}
              counterClassName="!right-[0.5px]"
            />
            <NavbarIconLink
              to={ROUTER.CHATS}
              icon={() => (
                <MessageNotificationIcon size={20} viewBox="0 0 20 20" />
              )}
              isActive={isActive(ROUTER.CHATS)}
              counter={0}
            />
          </div>
          <ProfileNavbar />
        </div>
      </div>
    </div>
  );
};
