import React from "react";

import { CardList } from "../../components";
import { BusinessAuditCard } from "./../../features";

interface IBusinessAuditListProps {
  loading: boolean;
  data: any[];
}

export const BusinessAuditList: React.FC<IBusinessAuditListProps> = ({
  loading,
  data,
}) => {
  return (
    <CardList loading={loading}>
      {!!data?.length ? (
        data?.map((request) => (
          <BusinessAuditCard key={request?.id} {...request} />
        ))
      ) : (
        <span>No audits found.</span>
      )}
    </CardList>
  );
};

BusinessAuditList.defaultProps = {
  loading: false,
  data: [],
};
