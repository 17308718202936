export enum Role {
  God = "god",
  Admin = "admin",
  Owner = "owner",
  TeamLead = "teamlead",
  Employee = "employee",
  User = "user",
}

export enum RoleType {
  God = "god",
  Admin = "admin",
  Owner = "owner",
  TeamLead = "teamlead",
  Employee = "employee",
  User = "user",
}

// export enum PermissionAction {
//   All = "all",
//   Create = "create",
//   Read = "read",
//   Update = "update",
//   Delete = "delete",
// }

// export enum PermissionObject {
//   All = "all",
//   Account = "account",
//   Passport = "passport",
//   Profile = "profile",
//   Company = "company",
//   Employee = "employee",
//   Job = "job",
//   Post = "post",
//   Resume = "resume",
//   Education = "education",
//   Experience = "experience",
// }
