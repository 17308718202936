import React, { useState } from "react";

import { Button, PageContainerSwitch } from "../../components";
import { AdminUserContainer, AdminBusinessContainer } from "./../../features";

export const AdminHomeContainer = () => {
  const tabs = [
    {
      label: "Users",
      component: AdminUserContainer,
      onRight: (
        <div>
          <Button color="primary" variant="outlined">
            Invite Member
          </Button>
        </div>
      ),
    },
    {
      label: "Businesses",
      component: AdminBusinessContainer,
      onRight: (
        <div>
          <Button color="primary" variant="outlined">
            Invite Member
          </Button>
        </div>
      ),
    },
  ];

  return <PageContainerSwitch tabs={tabs} />;
};
