import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarContent,
} from "../../../components";

import { useAppDispatch, useAppSelector, useToast } from "../../../hooks";
import { ISidebar } from "@types";

export const CompanyProfileSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection heading="Company Profile">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper
            libero aenean sed et feugiat pulvinar pretium.
          </p>
        </SidebarSection>
        <SidebarSection heading="Active Jobs"></SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};
