import React, { useContext, useEffect, useRef, useState } from "react";

import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";

import {
  PropsContext,
  RtcContext,
  RtcPropsInterface,
  CallbacksInterface,
  RtcConfigure,
} from "agora-react-uikit";

import { Button, Container, PageHeader } from "../../components";
import { useProfile } from "../../hooks";

export const VideoCallContainer = () => {
  const { profile } = useProfile();

  const localUserContainerRef = useRef(null);
  const remoteUserContainerRef = useRef(null);

  const [rtc, setRtc] = useState<IAgoraRTCClient>();
  const [videoCall, setVideoCall] = useState(false);

  const rtcConfig: RtcPropsInterface = {
    appId: "6777cd39c22742b6a7923568dceb5d94",
    channel: "legitbook-demo",
    token:
      "0066777cd39c22742b6a7923568dceb5d94IAAcCt0GcSsRuJce12S4LPyw3t5ZzTka+l0pCpXN9gFvW/81ekYAAAAAIgCjo1oJxpNzYwQAAQDGk3NjAgDGk3NjAwDGk3NjBADGk3Nj",
    enableAudio: false,
    enableVideo: false,
    CustomVideoPlaceholder: () => <></>,
  };

  let channelConfig = {
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null,
  };

  const callbacks: Partial<CallbacksInterface> = {
    EndCall: () => {
      setVideoCall(false);
    },
  };

  const handleJoin = () => {
    setVideoCall(true);

    // document.getElementById("join").onclick = async function ()
    // {
    //     // Join a channel.
    //     await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
    //     // Create a local audio track from the audio sampled by a microphone.
    //     channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    //     // Create a local video track from the video captured by a camera.
    //     channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    //     // Append the local video container to the page body.
    //     document.body.append(localPlayerContainer);
    //     // Publish the local audio and video tracks in the channel.
    //     await agoraEngine.publish([channelParameters.localAudioTrack, channelParameters.localVideoTrack]);
    //     // Play the local video track.
    //     channelParameters.localVideoTrack.play(localPlayerContainer);
    //     console.log("publish success!");
    // }
  };

  const handleLeave = () => {
    setVideoCall(false);

    // document.getElementById("leave").onclick = async function () {
    //   // Destroy the local audio and video tracks.
    //   channelParameters.localAudioTrack.close();
    //   channelParameters.localVideoTrack.close();
    //   // Remove the containers you created for the local video and remote video.
    //   removeVideoDiv(remotePlayerContainer.id);
    //   removeVideoDiv(localPlayerContainer.id);
    //   // Leave the channel
    //   await agoraEngine.leave();
    //   console.log("You left the channel");
    //   // Refresh the page for reuse
    //   window.location.reload();
    // };
  };

  useEffect(() => {
    if (!videoCall) return;

    const agoraRTC = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    setRtc(agoraRTC);
  }, [videoCall]);

  useEffect(() => {
    console.log({ rtc });
  }, [rtc]);

  useEffect(() => {
    const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    client.on("user-published", async (user, mediaType) => {
      await client.subscribe(user, mediaType);

      console.log("subscribe success");

      switch (mediaType) {
        case "video":
          break;
        case "audio":
          break;
        default:
          break;
      }

      if (mediaType == "video") {
        // // Retrieve the remote video track.
        // channelConfig.remoteVideoTrack = user.videoTrack;
        // // Retrieve the remote audio track.
        // channelConfig.remoteAudioTrack = user.audioTrack;
        // // Save the remote user id for reuse.
        // channelConfig.remoteUid = user.uid.toString();
        // Specify the ID of the DIV container. You can use the uid of the remote user.
        // remotePlayerContainer.id = user.uid.toString();
        // channelConfig.remoteUid = user.uid.toString();
        // remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
        // refs
        // remotePlayerContainer
        // Append the remote container to the page body.
        // document.body.append(remotePlayerContainer);
        // Play the remote video track.
        // channelConfig.remoteVideoTrack.play(remotePlayerContainer);
      }

      // Subscribe and play the remote audio track If the remote user publishes the audio track only.
      if (mediaType == "audio") {
        // // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        // channelConfig.remoteAudioTrack = user.audioTrack;
        // // Play the remote audio track. No need to pass any DOM element.
        // channelConfig.remoteAudioTrack.play();
      }
    });

    client.on("user-unpublished", (user) => {
      console.log(`User #${user.uid} has left the channel.`);
    });
  }, []);

  return (
    <Container>
      <PageHeader back={false}>
        Meet {rtc?.channelName ? `#${rtc?.channelName}` : ""}
      </PageHeader>
      <Container>
        {videoCall ? (
          <>
            <Container className="relative w-full h-[500px] bg-gray-500 rounded-lg overflow-hidden">
              <div style={{ display: "flex", width: "100%", height: "100%" }}>
                <div className="flex flex-row gap-x-[10px] p-[10px] box-border">
                  <div
                    ref={localUserContainerRef}
                    className="w-[150px] h-[150px] bg-red-900 rounded-lg"
                  ></div>
                  <div
                    ref={remoteUserContainerRef}
                    className="w-[150px] h-[150px] bg-red-900 rounded-lg"
                  ></div>
                </div>
                {/* <PropsContext.Provider value={config}>
                  <VideoCallUI />
                </PropsContext.Provider> */}
              </div>
            </Container>
            <div className="flex flex-row justify-end items-center mt-[15px]">
              <Button
                color="danger"
                className="min-w-[150px]"
                onClick={handleLeave}
              >
                Leave
              </Button>
            </div>
          </>
        ) : (
          <>
            <Container className="w-full h-[500px] bg-gray-800 rounded-lg overflow-hidden"></Container>
            <div className="flex flex-row justify-end items-center mt-[15px]">
              <Button
                color="success"
                className="min-w-[150px]"
                onClick={handleJoin}
              >
                Join
              </Button>
            </div>
          </>
        )}
      </Container>
    </Container>
  );
};
