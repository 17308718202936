import React, { Suspense, useEffect } from "react";

import { MdClose as CloseIcon } from "react-icons/md";

import { IDrawer } from "../../types";
import { DrawerType } from "../../shared";
import {
  BurgerIconButton,
  Drawer,
  IconButton,
  Link,
  Logo,
} from "./../../components";
import { useDrawer, useFilter, useURL } from "../../hooks";
import { lazyload } from "../../utils";
import {
  EmployeeSearchFilterSidebar,
  JobSearchFilterSidebar,
  ResumeBuilderSidebar,
  UserFeedRightSidebar,
} from "../../features";
import { ROUTER } from "../../routes";

export const DrawerProvider: React.FC = () => {
  const { drawer, setDrawer } = useDrawer();
  const [URL, setURL] = useURL();
  const { filters, searchParams } = useFilter();

  const drawers: IDrawer[] = [
    {
      type: DrawerType.HOME,
      position: "left",
      component: (
        <div className="w-[80vw] h-full -mt-[75px]">
          <UserFeedRightSidebar />
        </div>
      ),
    },
    {
      type: DrawerType.NEWS,
      position: "left",
      component: (
        <div className="w-[80vw] h-full -mt-[75px]">
          <h2>News</h2>
        </div>
      ),
    },
    {
      type: DrawerType.RESUME_BUILDER,
      position: "left",
      component: (
        <div className="w-[80vw] h-full -mt-[75px]">
          <ResumeBuilderSidebar />
        </div>
      ),
    },
    {
      type: DrawerType.JOBS,
      position: "bottom",
      component: (
        <div className="w-full h-[100vh] -mt-[75px]">
          <JobSearchFilterSidebar />
        </div>
      ),
    },
    {
      type: DrawerType.EMPLOYEES,
      position: "bottom",
      component: (
        <div className="w-full h-[100vh] -mt-[75px]">
          <EmployeeSearchFilterSidebar />
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setDrawer({ ...drawer, open: false });
  };

  const render = () => {
    const { position, component } =
      drawers.find(({ type }) => drawer?.type === type) || {};
    if (!component) return <></>;

    const direction =
      position === "top" || position === "bottom" ? "vertical" : "horizontal";

    return !!drawer ? (
      <Suspense fallback={<></>}>
        {
          <Drawer
            anchor={position || "left"}
            open={drawer?.open || false}
            onOpen={() => {}}
            onClose={handleClose}
          >
            {direction === "vertical" ? (
              <>
                <div className="z-40 absolute w-full h-[75px] top-[0px] left-[0px] right-[0px] flex flex-row justify-center items-center box-border bg-inherit">
                  <div className="w-[75px] h-[4px] bg-primaryMid rounded-full"></div>
                </div>
                <div className="z-40 absolute w-full h-[75px] bottom-[0px] left-[0px] right-[0px] flex flex-row justify-center items-center p-[25px] box-border bg-inherit">
                  <div className="bg-primaryLight rounded-full p-[10px] box-border">
                    <IconButton
                      icon={CloseIcon}
                      iconProps={{ size: 20 }}
                      className="text-secondary"
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="z-40 absolute h-[75px] top-[0px] left-[0px] right-[0px] flex flex-row justify-between items-center p-[25px] box-border bg-inherit ">
                <BurgerIconButton onClick={handleClose} />
              </div>
            )}

            {direction === "vertical" ? (
              <div className="z-30 w-full mt-[75px] mb-[75px] overflow-x-hidden">
                {component}
              </div>
            ) : (
              <div className="z-30 w-full mt-[75px] overflow-x-hidden">
                {component}
              </div>
            )}
          </Drawer>
        }
      </Suspense>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    handleClose();
  }, [URL, searchParams]);

  return render();
};
