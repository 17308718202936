import React, { useEffect, useState } from "react";

import { useAppDispatch, useLoading } from "../../hooks";
import { getVerificationRequestActions } from "../../store";
import {
  VerificationRequestActionLogList,
  VerificationRequestActionLogCreateForm,
} from "./";

export const VerificationRequestActionLog = ({
  verificationId,
  actionLogId,
}) => {
  const dispatch = useAppDispatch();

  const [actions, setActions] = useState<any[]>([]);
  const [loading, setLoading] = useLoading();

  useEffect(
    () =>
      // @ts-ignore
      (async () => {
        setLoading(true);

        const result = await dispatch(
          getVerificationRequestActions(verificationId)
        );
        if (!result) {
          return setLoading(false);
        }

        setActions(result);
        setLoading(false);
      })(),
    []
  );

  return (
    <div className="w-full">
      <div className="w-full">
        <h3 className="font-medium text-sm">Action Log</h3>
        <div className="mt-[20px] gap-y-[15px]">
          <VerificationRequestActionLogList loading={loading} data={actions} />
        </div>
      </div>
      <div className="w-full mt-[25px]">
        <VerificationRequestActionLogCreateForm
          verificationId={verificationId}
          actionLogId={actionLogId}
          onCreate={(data) => setActions([data, ...actions])}
        />
      </div>
    </div>
  );
};
