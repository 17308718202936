import { createSlice } from "@reduxjs/toolkit";

import { IDrawer } from "../../../types";
import { IFilters } from "../../../hooks";

interface IAppState {
  modal: {
    type: string | null;
    loading?: any;
    props?: any;
    redirectTo?: string;
  };
  drawer?: IDrawer;
  URL: {
    path: string | null;
    params: string | null;
    query: string | null;
  };
  filters?: IFilters | null;
}

const initialState: IAppState = {
  modal: { type: null, props: {} },
  URL: {
    path: null,
    params: null,
    query: null,
  },
  filters: null,
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setURL: (state, action) => {
      state.URL = action.payload;
    },

    updateModal: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },

    closeModal: (state) => {
      state.modal = { ...state.modal, type: null };
    },

    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
