import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { NewsContainer } = lazyload(() => import("../../features"));

export const NewsPage = () => (
  <MainLayout>
    <NewsContainer />
  </MainLayout>
);
