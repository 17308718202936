import React from "react";

import { IAccount } from "../shared";

import { Routes, Route, RouteProps } from "react-router-dom";
import { useAppSelector } from "../hooks";

import { MainLayout } from "./../layouts";

export const RouteProvider = ({ routes }) => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const checkRoles = ({ account, route }) =>
    route?.roles?.includes(account?.role?.type);

  const checkPermissions = ({ account, route }) =>
    route?.permissions?.every((permission) =>
      account?.role?.permissions?.find(
        ({ action, object }) =>
          permission?.action === action && permission?.object === object
      )
    );

  const isPublic = ({ roles = [], permissions = [] }) =>
    roles?.length < 1 && permissions?.length < 1;

  const isAllowed = (account: IAccount, route: RouteProps) =>
    checkRoles({ account, route }) || checkPermissions({ account, route });

  return (
    <Routes>
      {routes.map(({ component: Component, ...route }, key) => {
        return isPublic(route) ? (
          <Route key={key} {...route} element={<Component />} />
        ) : isAllowed(account, route) ? (
          <Route key={key} {...route} element={<Component />} />
        ) : (
          <Route
            key={key}
            {...route}
            element={
              <MainLayout>
                <h2 className="text-white text-base font-semibold">
                  Error! Permission Denied
                </h2>
              </MainLayout>
            }
          />
        );
      })}
    </Routes>
  );
};
