import React, { Ref, useEffect } from "react";

import { TextField } from "@mui/material";
import {
  FiUpload as UploadIcon,
  FiUser as UserIcon,
  FiLock as LockIcon,
} from "react-icons/fi";
import { InputError } from "../InputError";
import { FormikErrors } from "formik";

interface ITextFieldProps {
  ref?: React.Ref<any>;
  variant?: "standard" | "outlined" | "filled";
  value?: string | number | Date | undefined | null;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  label?: string;
  className?: string;
  controlClassName?: string;
  labelClassName?: string;
  rows?: number;
  maxRows?: number;
  minRows?: number;
  min?: number;
  max?: number;
  maxLength?: number;
  autoComplete?: "on" | "off";
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  InputProps?: any;
  InputLabelProps?: any;
  rightIcon?: () => JSX.Element;
  rightIconProps?: {
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
  };
  autocomplete?: boolean | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
}

export const TextInput: React.FC<ITextFieldProps> = React.forwardRef(
  (
    {
      value,
      error,
      label,
      variant,
      required,
      className,
      controlClassName,
      labelClassName,
      rows,
      maxRows,
      minRows,
      min,
      max,
      maxLength,
      disabled,
      autoComplete,
      placeholder,
      InputProps,
      InputLabelProps,
      rightIcon: RightIcon,
      rightIconProps,
      onChange,
      ...props
    },
    ref: React.Ref<HTMLElement>
  ) => {
    let variantStyles = "";

    switch (variant) {
      case "standard":
        variantStyles = "box-border p-[10px]";
        break;
      default:
        variantStyles = "";
        break;
    }

    return (
      <div className={`${controlClassName} w-full relative overflow-hidden`}>
        <TextField
          ref={ref}
          label={label ? `${label} ${required ? "*" : ""}` : null}
          className={`
            w-full flex flex flex-row items-center bg-primaryLight text-white rounded-lg
            ${className}
            ${
              Boolean(error?.toString()?.length)
                ? `${variantStyles} border border-solid border-danger`
                : `${variantStyles}`
            }
            ${RightIcon ? "pr-[50px]" : ""}
        `}
          InputProps={{
            className: "w-full bg-transparent text-inherit",
            disableUnderline: true,
            ...InputProps,
          }}
          InputLabelProps={{ className: labelClassName, ...InputLabelProps }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "inherit",
            },
          }}
          value={value || ""}
          autoComplete="off"
          required={false}
          onInput={(e) => {
            if (!maxLength) return;
            //@ts-ignore
            e.target.value = e.target.value?.slice(0, maxLength);
          }}
          onChange={onChange}
          {...{
            variant,
            placeholder,
            disabled,
            rows,
            maxRows,
            minRows,
            ...props,
          }}
        />

        {disabled ? (
          <div className="absolute top-[12.5px] right-[6px] w-[28px] h-[28px] flex flex-row justify-center items-center text-secondary">
            <LockIcon size={16} />
          </div>
        ) : RightIcon ? (
          <div
            className={`${
              rightIconProps?.className ||
              "flex flex-row justify-center items-center absolute top-[12.5px] right-[6px] w-[28px] h-[28px] text-secondary"
            }`}
            onClick={rightIconProps?.onClick || ((e) => {})}
          >
            <RightIcon />
          </div>
        ) : (
          <></>
        )}
        <InputError error={error?.toString() || null} className="mt-[5px]" />
      </div>
    );
  }
);

TextInput.defaultProps = {
  variant: "filled",
  controlClassName: "",
  className: "bg-primaryLight text-white",
  labelClassName: "text-secondary",
  InputProps: {},
  error: "",
  label: "",
  autoComplete: "off",
  required: false,
};
