import { ApiService } from "../../../services";
import { AppDispatch, GetRootState } from "../../store";

import { jobSlice } from "./job.slice";

export const appendJobs = (payload?: any) => async (dispatch: AppDispatch) =>
  dispatch(jobSlice.actions.appendJobs(payload));

export const popJobs = (payload?: any) => async (dispatch: AppDispatch) =>
  dispatch(jobSlice.actions.popJobs(payload));

export const getJobs = (options?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(jobSlice.actions.getJobsRequest());

    const response = await ApiService.post("jobs/query", options);
    const { data, results } = response.data;

    console.log({ data, results });

    dispatch(jobSlice.actions.getJobsSuccess({ data, results }));

    return data;
  } catch (e: any) {
    dispatch(jobSlice.actions.getJobsError(e.response?.data?.error));
  }
};

export const getJob = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(jobSlice.actions.getJobRequest());

    const response = await ApiService.get(`jobs/${id}`);
    const { data } = response.data;

    dispatch(jobSlice.actions.getJobSuccess(data));

    console.log({ data });

    return data;
  } catch (e: any) {
    dispatch(jobSlice.actions.getJobError(e.response?.data?.error));
  }
};

export const createJob = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(jobSlice.actions.createJobRequest());

    const response = await ApiService.post("jobs", payload);
    const { data } = response.data;

    dispatch(jobSlice.actions.createJobSuccess(data));
    // dispatch(jobSlice.actions.appendJobs(data));

    return data;
  } catch (e: any) {
    dispatch(jobSlice.actions.createJobError(e.response?.data?.error));
  }
};

export const updateJob =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobSlice.actions.updateJobRequest());

      // Updating
      const response = await ApiService.put(`jobs/${id}`, payload);
      const { data } = response.data;

      // Refreshing
      // const refreshed = await dispatch(getJob(id));

      dispatch(jobSlice.actions.updateJobSuccess(data));
      // dispatch(jobSlice.actions.appendJobs(refreshed));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(jobSlice.actions.updateJobError(e.response?.data?.error));
    }
  };

export const removeJob = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(jobSlice.actions.removeJobRequest());

    const response = await ApiService.delete(`jobs/${id}`);
    const { data } = response.data;

    dispatch(jobSlice.actions.removeJobSuccess(data));
    dispatch(jobSlice.actions.popJobs({ id }));

    return id;
  } catch (e: any) {
    dispatch(jobSlice.actions.removeJobError(e.response?.data?.error));
  }
};

export const applyJob =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobSlice.actions.applyJobRequest());

      const response = await ApiService.post(`jobs/${id}/apply`, payload);
      const { data } = response.data;

      console.log({ data });

      dispatch(jobSlice.actions.applyJobSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(jobSlice.actions.applyJobError(e.response?.data?.error));
    }
  };

export const withdrawJob = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(jobSlice.actions.applyJobRequest());

    const response = await ApiService.delete(`jobs/${id}/withdraw`);
    const { data } = response.data;

    console.log({ data });

    dispatch(jobSlice.actions.applyJobSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(jobSlice.actions.applyJobError(e.response?.data?.error));
  }
};

export const getRecentlyPostedJobs =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      dispatch(jobSlice.actions.getRecentlyPostedJobsRequest());

      const state = getState();
      const profileId = state.account.account.data?.profileId;

      const response = await ApiService.post("jobs/query", {
        where: {
          createdById: profileId,
        },
      });
      const { data, results } = response.data;

      dispatch(
        jobSlice.actions.getRecentlyPostedJobsSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      dispatch(
        jobSlice.actions.getRecentlyPostedJobsError(e.response?.data?.error)
      );
    }
  };
