export const renderTextAsList = (
  text: string,
  Component: React.FunctionComponent
): JSX.Element => {
  if (!text) return <></>;

  const elements: JSX.Element[] = text
    ?.split("\n")
    ?.filter((p) => p?.trim()?.length >= 1)
    ?.map((text: string, key: number) => (
      <Component key={key}>{text}</Component>
    ));

  return <>{elements?.map((Element) => Element)}</>;
};

type JobSalary = {
  minSalary: number;
  maxSalary: number;
  salaryDependsOnExperience?: boolean;
  currencySymbol?: string;
};

export const renderJobSalary = ({
  minSalary,
  maxSalary,
  salaryDependsOnExperience,
  currencySymbol,
}: JobSalary) => {
  const symbol = currencySymbol || "$";

  const isValid =
    !!salaryDependsOnExperience ||
    (!salaryDependsOnExperience && minSalary > 0 && maxSalary > 0);

  const result = isValid
    ? salaryDependsOnExperience
      ? "Depends on Experience"
      : `${symbol}${minSalary || 0}-${symbol}${maxSalary || 0}`
    : "";

  return result;
};
