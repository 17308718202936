import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ROUTER } from "../../routes";

import {
  BasicDatePicker,
  Button,
  CardList,
  Container,
  Form,
  FormSection,
  TextInput,
  MultipleSelect,
  PageHeader,
  FilePicker,
} from "../../components";

import { registerCompany } from "../../store";
import { COMPANY_SIZE } from "../../constants";
import { date, FormValidator } from "../../utils";
import { useAppDispatch, useLoading, useToast } from "../../hooks";
import {
  HTMLFileTypes,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";

interface IBusinessRegistrationForm {
  name?: string;
  tin?: string;
  email?: string;
  phone?: string;
  applicantRole?: string;
  size?: string;
  country?: string;
  state?: string;
  city?: string;
  document?: File | null;
  isWorking?: false;
  foundedAt?: Date | null;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .typeError("Company Name must be string")
    .max(50, "Exceeded maximum character length of 50")
    .required("Company Name is a required field"),
  email: Yup.string()
    .email("Company Email is incorrect")
    .max(100, "Exceeded maximum character length of 100")
    .required("Company Email is a required field"),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone"
    )
    .max(18, "Exceeded maximum character length of 18")
    .required("Company Phone is a required field"),

  tin: Yup.string()
    .typeError("Tax Number must be string")
    .max(20, "Exceeded maximum character length of 20")
    .required("Tax Number is a required field"),
  document: Yup.mixed().required("Document is a required field"),
  applicantRole: Yup.string()
    .max(50, "Exceeded maximum character length of 50")
    .required("Job Title of Applicant is a required field"),
  size: Yup.string().required("Company Size is a required field"),
  country: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Country is a required field"),
  state: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
  city: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("City is a required field"),
  foundedAt: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .max(date().add(1, "day").toDate(), "Date can't be in future")
    .required("Creation Date is a required field"),
});

export const BusinessRegistrationContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const form = useFormik<IBusinessRegistrationForm>({
    initialValues: {
      name: "",
      tin: "",
      email: "",
      phone: "",
      applicantRole: "",
      size: "",
      country: "",
      state: "",
      city: "",
      document: null,
      isWorking: false,
      foundedAt: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();

      for (const key of Object.keys(values)) {
        if (!key) continue;

        data.append(key, values[key]);
      }

      setLoading(true);

      const registration = await dispatch(registerCompany(data));
      if (!registration) {
        toast({
          status: NotificationStatus.Error,
          title: "Ooops, something went wrong.",
          message: "Company Not Registered!",
        });

        return setLoading(false);
      }

      toast({
        status: NotificationStatus.Success,
        title: "We've received your request!",
        message: "It'll take a few days to register your company.",
      });

      setLoading(false);
      redirect(ROUTER.HOME);
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    resetForm,
    handleChange,
    handleSubmit,
  } = form;

  const validator = new FormValidator(form);

  const handleCancel = () => {
    resetForm();
    redirect(ROUTER.HOME);
  };

  return (
    <Container>
      <PageHeader backTo={ROUTER.HOME}>Register As Business</PageHeader>
      <div>
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-y-[15px]">
            <FormSection>
              <div className="flex flex-col gap-y-[15px]">
                <div className="flex flex-row gap-x-[15px]">
                  <div className="basis-6/12">
                    <TextInput
                      id="name"
                      label="Company Name"
                      type="text"
                      variant="filled"
                      maxLength={50}
                      value={values?.name}
                      error={validator.isInputValid("name")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="basis-6/12">
                    <TextInput
                      id="tin"
                      label="Tax Number (TIN)"
                      type="text"
                      variant="filled"
                      maxLength={20}
                      value={values?.tin}
                      error={validator.isInputValid("tin")}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-x-[15px]">
                  <div className="basis-6/12">
                    <TextInput
                      id="applicantRole"
                      label="Job Title of Applicant"
                      type="text"
                      variant="filled"
                      value={values?.applicantRole}
                      error={validator.isInputValid("applicantRole")}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-row basis-6/12 gap-x-[15px]">
                    <div className="basis-6/12">
                      <MultipleSelect
                        name="size"
                        label="Company Size"
                        options={COMPANY_SIZE}
                        value={values?.size}
                        error={validator.isInputValid("size")}
                        setValue={(value) => setFieldValue("size", value, true)}
                      />
                    </div>

                    <div className="basis-6/12">
                      <BasicDatePicker
                        id="foundedAt"
                        label="Creation Date"
                        value={values?.foundedAt}
                        error={validator.isInputValid("foundedAt")}
                        onChange={(value) =>
                          setFieldValue("foundedAt", value, true)
                        }
                        required={!values?.isWorking}
                        disableFuture
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormSection>

            <FormSection heading="Location">
              <div className="flex flex-row gap-x-[15px]">
                <div className="basis-4/12">
                  <TextInput
                    id="country"
                    label="Country"
                    type="text"
                    variant="filled"
                    value={values?.country}
                    error={validator.isInputValid("country")}
                    onChange={handleChange}
                  />
                </div>
                <div className="basis-4/12">
                  <TextInput
                    id="state"
                    label="State"
                    type="text"
                    variant="filled"
                    value={values?.state}
                    error={validator.isInputValid("state")}
                    onChange={handleChange}
                  />
                </div>
                <div className="basis-4/12">
                  <TextInput
                    id="city"
                    label="City"
                    type="text"
                    variant="filled"
                    value={values?.city}
                    error={validator.isInputValid("city")}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </FormSection>

            <FormSection heading="Document">
              <FilePicker
                className="min-h-[56px]"
                extensions={[HTMLFileTypes.pdf, HTMLFileTypes.jpg]}
                error={validator.isInputValid("document")}
                onChange={(file) => {
                  setFieldValue("document", file, false);
                  setFieldError("document", undefined);
                }}
              />
            </FormSection>

            <FormSection heading="Contacts">
              <div className="flex flex-row gap-x-[15px]">
                <div className="basis-6/12">
                  <TextInput
                    id="email"
                    label="Company Email"
                    type="text"
                    variant="filled"
                    value={values?.email}
                    error={validator.isInputValid("email")}
                    onChange={handleChange}
                  />
                </div>
                <div className="basis-6/12">
                  <TextInput
                    id="phone"
                    label="Company Phone"
                    type="text"
                    variant="filled"
                    value={values?.phone}
                    error={validator.isInputValid("phone")}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </FormSection>

            <div className="flex flex-row justify-end gap-x-[10px]">
              <Button color="secondary" variant="text" onClick={handleCancel}>
                Cancel
              </Button>
              <div>
                <Button
                  type="submit"
                  color="success"
                  variant="outlined"
                  loading={loading}
                >
                  Register Business
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};
