import React from "react";

import { useNavigate } from "react-router";

import * as Yup from "yup";
import { useFormik } from "formik";

import { IChatMessage, ModalType, NotificationStatus } from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { removeJob, startChatWithUser, updateJob } from "../../../store";

import { Button, Form, TextInput, Modal } from "../../../components";
import { addLineBreaks, FormValidator } from "../../../utils";
import { ROUTER } from "../../../routes";

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .trim()
    .max(500, "Exceeded maximum character length of 500")
    .required("Message is a required field"),
});

interface IChatMessageForm {
  details?: string;
}

export const ChatMessageModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.CHAT_MESSAGE,
  });

  const { props } = modal || {};
  const { userId, onClose } = props || {};

  const [loading, setLoading] = useLoading();

  const form = useFormik<IChatMessageForm>({
    initialValues: {
      details: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { details } = values;

      setLoading(true);

      const data: IChatMessage = {
        text: addLineBreaks(details || ""),
      };

      const chat = await dispatch(startChatWithUser(userId, data));
      if (!chat?.id) {
        setLoading(false);

        toast({
          status: NotificationStatus.Error,
          message: "Chat Not Created!",
        });

        handleCancel();

        return;
      }

      console.log({ data, userId });

      setLoading(false);
      handleClose();

      redirect(ROUTER.CHAT_DETAIL(chat?.id));
    },
  });

  const { values, resetForm, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  const handleClose = () => {
    resetForm();
    closeModal();

    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Message"
      description="Please write something to start a chat."
      className="bg-primaryBlack"
      open={isOpen}
      onClose={handleClose}
    >
      <div className="w-full">
        <div className="text-sm text-secondary break-words">
          <Form>
            <TextInput
              id="details"
              type="text"
              variant="standard"
              placeholder="Write something.."
              className="!bg-primaryDark"
              controlClassName="basis-full"
              labelClassName="text-secondary"
              required
              multiline
              minRows={3}
              maxRows={5}
              value={values?.details}
              error={validator.isInputValid("details")}
              onChange={handleChange}
            />
          </Form>
        </div>
        <div className="w-full flex flex-row justify-between items-center mt-[15px]">
          <div>
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row">
            <Button
              variant="outlined"
              color="success"
              className="min-w-[150px]"
              loading={loading}
              onClick={() => handleSubmit()}
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
