import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
} from "react-icons/fi";

import {
  BulletPointList,
  BulletPointListItem,
  CardList,
  Container,
  IconButton,
  PageHeader,
  RBAC,
} from "../../components";
import { JobApplicationCard } from "./../../features";
import { getJob, getJobs, removeJob, updateJob } from "../../store";
import { ROUTER } from "../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../hooks";
import { renderTextAsList } from "../../utils";
import { ModalType, Role } from "../../shared";
import { getJobApplications } from "../../store/reducers/job-application/job-application.action";

export const JobApplicationList = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { id: jobId } = useParams();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    job: { data: job, isLoading: jobLoading },
  } = useAppSelector((state) => state.job);

  const {
    jobApplications: {
      data: jobApplications,
      isLoading: jobApplicationLoading,
    },
  } = useAppSelector((state) => state.jobApplication);

  const companyId = job?.companyId;
  const isLoading = jobLoading || jobApplicationLoading;

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  useEffect(() => {
    if (!jobId) return;

    dispatch(getJob(jobId));
  }, [jobId]);

  useEffect(() => {
    if (!job?.id) return;

    dispatch(getJobApplications({ where: { jobId, isWithdrawn: false } }));
  }, [job]);

  return (
    <Container
      className="w-full flex flex-col gap-y-5 pt-5 pb-5 text-secondary"
      loading={isLoading}
    >
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
        ]}
        error
      >
        <PageHeader exception={{ from: "", backTo: ROUTER.JOB_DETAIL(jobId) }}>
          Applicants for{" "}
          <span className="text-orange pl-[5px]">"{job?.title}"</span>
        </PageHeader>

        <CardList>
          {jobApplications?.map((application) => (
            <JobApplicationCard
              key={application?.id}
              jobId={jobId}
              {...application}
            />
          ))}
        </CardList>
      </RBAC>
    </Container>
  );
};
