export const addLineBreaks = (text: string) => text?.replace(/\n/g, "\n") || "";

const shorten = (string?: string, maxChars = 180) => {
  if (!string || !string?.length) return "";

  let length = 0;
  let words: string[] = [];

  for (let word of string.split(" ")) {
    length += word.length + 1;

    if (length > maxChars) break;

    words.push(word);
  }

  let isExceeded = length > maxChars ? true : false;

  const result = isExceeded
    ? `${words.join(" ").trim()}...`
    : words.join(" ").trim();

  return result;
};

const shortenByChars = (string: string, maxChars = 180) => {
  if (!string || !string?.length) return "";

  const isExceeded = string.length > maxChars || false;

  const result = isExceeded
    ? string.split("").slice(0, maxChars).join("") + "..."
    : string;

  return result;
};

export const textformat = {
  shorten,
  shortenByChars,
};
