import * as React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Form, TextInput, Button } from "../../../components";
import { FormValidator } from "../../../utils";
import { useAppDispatch, useLoading, useToast } from "../../../hooks";
import { updateComment } from "../../../store";
import { NotificationStatus } from "../../../shared";

interface IPostCommentEditFormProps {
  id: string;
  details?: string;
  onSave?: (data?: IPostCommentEditForm) => void;
  onClose?: () => void;
}

interface IPostCommentEditForm {
  details?: string;
}

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1500, "Exceeded maximum character length of 1500")
    .required("Comment is a required field"),
});

export const PostCommentEditForm: React.FC<IPostCommentEditFormProps> = ({
  id,
  details,
  onSave,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const [loading, setLoading] = useLoading();

  const form = useFormik<IPostCommentEditForm>({
    validationSchema,
    initialValues: { details },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const comment = await dispatch(updateComment(id, values));
      if (!comment?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Comment Not Updated!",
        });
      }

      if (onSave) {
        onSave(values);
      }

      setLoading(false);
      resetForm();
    },
  });

  const { values, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Form className="flex flex-col gap-y-[15px]" onSubmit={handleSubmit}>
      <TextInput
        id="details"
        type="text"
        variant="standard"
        className="h-auto min-h-[45px] flex flex-row items-center bg-primaryLight text-white box-border px-[10px] py-[0px]"
        labelClassName="text-secondary"
        placeholder="Editing a comment..."
        multiline
        maxRows={10}
        required
        value={values?.details}
        error={validator.isInputValid("details")}
        onChange={handleChange}
      />
      <div className="flex flex-row justify-end gap-x-[10px]">
        <Button color="secondary" onClick={handleCancel}>
          Discard
        </Button>
        <Button
          color="success"
          className="min-w-[150px]"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

PostCommentEditForm.defaultProps = {
  details: "",
  onClose: () => {},
};
