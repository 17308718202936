import React, { useEffect, useState } from "react";

import "animate.css/animate.min.css";

import {
  FiCheckCircle as SuccessIcon,
  FiXCircle as DangerIcon,
  FiAlertCircle as WarningIcon,
  FiInfo as InfoIcon,
  FiX as CloseIcon,
} from "react-icons/fi";

import { toast, cssTransition } from "react-toastify";

import { NotificationStatus } from "../shared";

interface IUseToastProps {
  status: NotificationStatus;
  icon?: any;
  title?: string;
  message?: string;
}

export const useToast = () => {
  const defaultOptions = {
    position: toast.POSITION.TOP_RIGHT,
    type: toast.TYPE.DEFAULT,
    className:
      "flex flex-row justify-center items-start bg-primaryBlack text-secondary",
    bodyClassName: "flex flex-row items-start w-[360px]",
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    autoClose: 3000,
    closeOnClick: false,
    closeButton: ({ closeToast }: any) => (
      <CloseIcon
        size={24}
        className="text-white m-[5px]"
        onClick={closeToast}
      />
    ),
  };

  const render = ({ status, icon, title, message }: IUseToastProps) => {
    let options = { title: "", icon };

    switch (status) {
      case "info":
        title = title || "Notification";
        options.icon = () => <InfoIcon size={24} className="text-primary" />;
        break;
      case "success":
        title = title || "Success";
        options.icon = () => <SuccessIcon size={24} className="text-success" />;
        break;
      case "error":
        title = title || "Error";
        options.icon = () => <DangerIcon size={24} className="text-danger" />;
        break;
      case "warning":
        title = title || "Warning";
        options.icon = () => <WarningIcon size={24} className="text-warning" />;
        break;
      default:
        title = title || "Notification";
        options.icon = null;
        break;
    }

    toast(
      <div className="flex flex-col text-secondary">
        <span className="font-semibold text-white text-sm">{title}</span>
        <p className="font-normal text-xs">{message}</p>
      </div>,
      {
        ...defaultOptions,
        ...options,
        icon: icon || options?.icon,
      }
    );
  };

  return { toast: render };
};
