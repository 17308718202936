import React, { useEffect } from "react";

import { IRBACElement } from "@types";

import { useRBAC } from "../../hooks";
import { ErrorAccessDenied } from "./../../components";

interface IRBACSwitchElement extends IRBACElement {
  render?: JSX.Element;
  component?: React.FC | (() => JSX.Element);
}

interface IRBACSwitchProps {
  elements: IRBACSwitchElement[];
  error?: boolean;
  fallback?: JSX.Element;
}

export const RBACSwitch = ({
  elements = [],
  fallback = <></>,
  error = false,
}: IRBACSwitchProps): JSX.Element => {
  const { checkRBAC } = useRBAC();

  const element =
    elements?.find(({ roles, actions, object, conditions }) =>
      checkRBAC({ roles, actions, object, conditions })
    ) || null;

  const Component = element?.component || (() => <></>);

  return element ? (
    element?.render ? (
      element.render
    ) : (
      <Component />
    )
  ) : error ? (
    <ErrorAccessDenied />
  ) : (
    fallback
  );
};
