import React from "react";
import { SVGIcon } from "./SVGIcon";

export const PhoneCallIcon = (props) => (
  <SVGIcon {...props}>
    <path
      d="M6.1672 4.57996C5.77788 5.12945 5.00028 5.88324 5.00028 6.5C5.00028 7.24792 5.79726 8.75403 6.83231 9.77632C7.86736 10.7986 8.74303 11.375 9.50029 11.375C10.0158 11.375 10.2749 10.1316 11.652 10.1316C12.6847 10.1316 14.0003 11.2566 14.3753 12.5C14.5619 13.1187 13.9524 13.6852 13.1708 14.117C12.2145 14.6454 11.0897 14.8118 10.0084 14.6545C8.54948 14.4423 6.57831 13.7581 4.25029 11.5526C1.32415 8.375 1.17642 6.29328 1.25029 4.26039C1.32415 2.22749 3.12529 1.25 3.73398 1.25C4.28465 1.25 5.98149 2.70388 6.29991 3.89479C6.36314 4.13126 6.30872 4.38024 6.1672 4.57996Z"
      stroke="inherit"
      fill="inherit"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SVGIcon>
);
