import { useEffect } from "react";

import { IDrawer } from "../types";
import { setDrawer } from "../store";
import { useAppDispatch, useAppSelector } from "./../hooks";

export const useDrawer = () => {
  const dispatch = useAppDispatch();

  const { drawer } = useAppSelector((state) => state.app);

  const isActive = !!drawer?.open;

  const handleSetDrawer = (payload: IDrawer) => {
    dispatch(setDrawer({ ...drawer, ...payload }));
  };

  const handleResetDrawer = () => {
    dispatch(setDrawer({ type: null, open: false }));
  };

  return {
    drawer,
    isActive,
    setDrawer: handleSetDrawer,
    resetDrawer: handleResetDrawer,
  };
};
