import React from "react";

import {
  FiDownload as ExportIcon,
  FiFileText as ListIcon,
  FiEdit3 as EditIcon,
  FiTrash2 as DeleteIcon,
} from "react-icons/fi";
import { BiDotsHorizontalRounded as DotsIcon } from "react-icons/bi";

import {
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  Loader,
} from "./../../components";
import { Action } from "../../shared";

interface IActionMenuProps {
  className?: string;
  iconClassName?: string;
  direction?: "vertical" | "horizontal";
  actions?: string[];
  loading?: boolean;
  customActions?: any[];
  onEdit?: (id?: string) => void;
  onDelete?: (id?: string) => void;
  onList?: (id?: string) => void;
  onDelist?: (id?: string) => void;
  onArchive?: (id?: string) => void;
}

export const ActionMenu: React.FC<IActionMenuProps> = ({
  className,
  iconClassName,
  direction,
  actions,
  loading,
  customActions,
  onEdit,
  onDelete,
  onList,
  onDelist,
  onArchive,
}) => {
  const isAction = (op) => actions?.includes(op);

  const directionStyles = direction === "horizontal" ? "flex-col" : "flex-row";

  return (
    <DropdownMenu
      className={`${className}`}
      button={
        <div className="w-[24] h-[24px]">
          {loading ? (
            <Loader size={22} className="text-inherit" />
          ) : (
            <IconButton
              icon={DotsIcon}
              iconProps={{ size: 24 }}
              iconClassName={iconClassName}
            />
          )}
        </div>
      }
    >
      <div className={`${directionStyles} flex`}>
        {customActions?.map(({ id, label, icon, className, onClick }, key) => (
          <DropdownMenuItem
            key={key}
            className={className}
            icon={icon}
            onClick={onClick}
          >
            {label}
          </DropdownMenuItem>
        ))}
        {isAction(Action.List) && (
          <DropdownMenuItem
            icon={<ListIcon className="text-secondary" />}
            onClick={onList}
          >
            List
          </DropdownMenuItem>
        )}
        {isAction(Action.Delist) && (
          <DropdownMenuItem
            icon={<ListIcon className="text-warning" />}
            onClick={onDelist}
          >
            Delist
          </DropdownMenuItem>
        )}

        {isAction(Action.Archive) && (
          <DropdownMenuItem
            // icon={<EditIcon className="text-warning" />}
            onClick={onArchive}
          >
            Archive
          </DropdownMenuItem>
        )}

        {isAction(Action.Edit) && (
          <DropdownMenuItem
            icon={<EditIcon className="text-warning" />}
            onClick={onEdit}
          >
            Edit
          </DropdownMenuItem>
        )}

        {isAction(Action.Delete) && (
          <DropdownMenuItem
            icon={<DeleteIcon className="text-danger" />}
            onClick={onDelete}
          >
            Delete
          </DropdownMenuItem>
        )}
      </div>
    </DropdownMenu>
  );
};

ActionMenu.defaultProps = {
  className: "",
  loading: false,
  direction: "vertical",
  actions: [],
};
