import React from "react";

import { Dialog } from "@mui/material";
import { VscChromeClose as CloseIcon } from "react-icons/vsc";

import { Button, ModalTitle, IconButton } from "./../../components";
import { closeModal } from "../../store";
import { useAppDispatch } from "../../hooks/useRedux";
import { useScreen } from "../../hooks";

interface IModalProps {
  title?: string;
  description?: string;
  open?: boolean;
  className?: string;
  dialogClassName?: string;
  titleClassName?: string;

  onClose?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  children,
  title,
  description,
  open,
  className,
  dialogClassName,
  titleClassName,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (!onClose) return dispatch(closeModal());

    dispatch(closeModal());
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        className: `
                ${dialogClassName}
                w-full max-w-[480px] bg-transparent
                xxs:w-full`,
      }}
      open={open || false}
      onClose={handleClose}
    >
      <div
        className={`${className} w-full text-sm text-secondary break-words p-[25px] box-border bg-primaryBlack text-white rounded-lg border-[2px] border-primaryLight`}
      >
        <div className="flex flex-row justify-between items-start">
          {title && (
            <ModalTitle
              {...{ title, description, className: titleClassName }}
            />
          )}
          <div className="absolute top-[15px] right-[15px] transition-all flex flex-row justify-center items-center box-border p-[5px] rounded-full cursor-pointer hover:bg-primaryLight ">
            <IconButton
              icon={CloseIcon}
              iconProps={{ size: 14 }}
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="w-full">{children}</div>
      </div>
    </Dialog>
  );
};

Modal.defaultProps = {
  open: false,
  className: "",
  dialogClassName: "",
  titleClassName: "",
};
