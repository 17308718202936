import React from "react";

import { Avatar, VerificationStatusBadge } from "../../components";
import { date } from "../../utils";

export const EducationListItem = ({
  id,
  degree,
  university,
  grade,
  verification,
  profileId,
  isDraft,
  isEnrolled,
  isVerified,
  startedAt,
  finishedAt,
}) => {
  const isTimestamp = !!startedAt || !!finishedAt;

  return (
    <div className="flex justify-start">
      <div className="w-full flex flex-col justify-start items-start text-sm text-secondary gap-x-[10px]">
        <div className="flex flex-row justify-start items-start gap-x-[10px] break-words">
          <Avatar size={30}>{university}</Avatar>
          <div className="flex flex-col items-start justify-center gap-y-[5px]">
            <div className="flex flex-row justify-start items-center gap-x-[10px]">
              <div className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] gap-y-[3px]">
                <h3 className="flex flex-row justify-start items-center flex-wrap gap-x-[5px] gap-y-[3px] text-sm text-white break-words font-semibold leading-none">
                  <span>{degree} at </span>
                  <span className="text-primary">{university}</span>
                </h3>

                {isDraft ? (
                  <span className="text-sm text-secondary">(Draft)</span>
                ) : (
                  <VerificationStatusBadge
                    className="text-xs"
                    status={verification?.status}
                    education={{ id, degree, university, profileId }}
                  />
                )}
              </div>
            </div>

            {isTimestamp && (
              <span className="text-xs font-medium text-secondary leading-none">
                {startedAt && finishedAt ? (
                  `${date(startedAt).format("MMM YYYY")} - ${
                    isEnrolled ? "Present" : date(finishedAt).format("MMM YYYY")
                  }`
                ) : (
                  <>********</>
                )}
              </span>
            )}

            {/* <span className="text-xxs font-medium text-secondary leading-none">
              {startedAt && finishedAt ? (
                `${date(startedAt).format("MMM YYYY")} - ${
                  isEnrolled ? "Present" : date(finishedAt).format("MMM YYYY")
                }`
              ) : (
                <>********</>
              )}
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

EducationListItem.defaultProps = {
  grade: 0,
  isEnrolled: false,
  isVerified: false,
};
