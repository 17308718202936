import React, { useEffect } from "react";

import { Container, PageHeader } from "../../components";
import { PostCreateForm, PostList } from "..";
import { getPosts } from "../../store";
import { useAppDispatch, useAppSelector, useDrawer } from "../../hooks";
import { DrawerType } from "../../shared";

export const NewsContainer = () => {
  const dispatch = useAppDispatch();

  const { setDrawer, resetDrawer } = useDrawer();

  const {
    posts: { results: postCount, isLoading: postLoading },
  } = useAppSelector((state) => state.post);

  useEffect(() => {
    dispatch(getPosts({ where: { isPublic: true } }));

    setDrawer({ type: DrawerType.NEWS, open: false });

    return () => resetDrawer();
  }, []);

  return (
    <Container loading={postLoading}>
      <PageHeader back={false}>News</PageHeader>
      <PostCreateForm isPublic={true} />

      <div className="mt-[15px]">
        <PostList />
      </div>
    </Container>
  );
};
