import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router";

import {
  Button,
  CardList,
  Container,
  PageHeader,
  RBAC,
} from "../../components";
import {
  ChatMessageButton,
  CompanyActivityLogWidget,
  CompanyCommentCreateForm,
  CompanyCommentLogWidget,
  EducationDetailSection,
  ExperienceDetailSection,
} from "./../../features";

import { Role } from "../../shared";
import { getJobApplication } from "../../store";
import { useAppDispatch, useAppSelector, useAuthor } from "../../hooks";

export const JobApplicationDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { id, jobApplicationId } = useParams();

  const { checkAuthor } = useAuthor();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    jobApplication: { data: jobApplication, isLoading },
  } = useAppSelector((state) => state.jobApplication);

  const { resume, createdById } = jobApplication || {};

  const companyId = jobApplication?.job?.companyId;
  const userId = resume?.profileId;

  const isAuthor = checkAuthor(createdById);
  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  useEffect(() => {
    if (!jobApplicationId) return;

    dispatch(getJobApplication(jobApplicationId));
  }, [jobApplicationId]);

  return (
    <Container className="w-full flex flex-col py-[15px]" loading={isLoading}>
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
          { roles: [Role.User], condition: isAuthor },
        ]}
        error
      >
        <div className="flex flex-row justify-between mb-[30px]">
          <PageHeader>Resumes</PageHeader>
          <div className="flex flex-row justify-start items-center gap-x-[10px]">
            <Button color="secondary" variant="outlined">
              Share
            </Button>
            <ChatMessageButton userId={userId} />
          </div>
        </div>

        <div className="flex flex-col gap-y-[50px]">
          {resume?.covers?.length >= 1 && (
            <CardList className="w-full flex flex-col gap-y-[20px]">
              {resume?.covers?.map((cover) => (
                <div key={cover?.id} className="w-full flex flex-col">
                  <h3 className="text-white text-md font-semibold mb-[10px]">
                    {cover?.title}
                  </h3>
                  <p className="text-base text-secondary font-medium">
                    {cover?.details}
                  </p>
                </div>
              ))}
            </CardList>
          )}

          {resume?.experiences?.length >= 1 && (
            <div>
              <h2 className="text-white text-lg font-semibold">
                Employer Data
              </h2>
              <CardList className="mt-[25px] gap-y-[50px]">
                {resume?.experiences?.map((experience) => (
                  <ExperienceDetailSection
                    key={experience.id}
                    {...experience}
                  />
                ))}
              </CardList>
            </div>
          )}

          {resume?.educations?.length >= 1 && (
            <div>
              <h2 className="text-white text-lg font-semibold">
                Education/Certifications
              </h2>
              <CardList className="flex flex-col gap-y-[25px] mt-[25px]">
                {resume?.educations?.map((education) => (
                  <EducationDetailSection key={education?.id} {...education} />
                ))}
              </CardList>
            </div>
          )}

          <div>
            <h2 className="text-white text-lg font-semibold">
              Digital Portfolio
            </h2>
            <CardList className="flex flex-col gap-y-[25px] mt-[25px]">
              <span>Portfolio is empty.</span>
            </CardList>
          </div>

          <Container className="flex flex-col md:hidden">
            <div className="flex flex-row justify-between items-center">
              <h2 className="text-white text-lg font-semibold">Activity</h2>
            </div>
            <div className="mt-[20px]">
              <CompanyActivityLogWidget
                companyId={companyId}
                where={{ userId }}
              />
            </div>
          </Container>

          <Container className="flex flex-col md:hidden">
            <div className="flex flex-row justify-between items-center">
              <h2 className="text-white text-lg font-semibold">
                Company Notes
              </h2>
              <Button>Add Note</Button>
            </div>
            <div className="mt-[20px]">
              <CompanyCommentLogWidget
                companyId={companyId}
                where={{ userId }}
              />
            </div>

            <div className="mt-[20px]">
              <CompanyCommentCreateForm
                inputClassName="bg-primaryLight"
                {...{ jobApplicationId, userId, companyId }}
              />
            </div>
          </Container>
        </div>
      </RBAC>
    </Container>
  );
};
