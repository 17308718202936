import {
  EmploymentType,
  JobType,
  SkillLevel,
  EducationDegree,
} from "./../shared";

export const JOB_LEVELS = [
  { id: SkillLevel.Entry, value: "Entry Level (0-1)" },
  { id: SkillLevel.Junior, value: "Junior Level (1-2)" },
  { id: SkillLevel.Middle, value: "Mid Level (2-5)" },
  { id: SkillLevel.Senior, value: "Senior Level (5-10)" },
  { id: SkillLevel.Executive, value: "Executive Level (10+)" },
];

// s

export const JOB_TYPES = [
  { id: JobType.OnSite, value: "On Site" },
  { id: JobType.Remote, value: "Remote" },
  { id: JobType.Hybrid, value: "Hybrid" },
  { id: JobType.Travel10, value: "Travel 10%" },
  { id: JobType.Travel25, value: "Travel 25%" },
  { id: JobType.Travel50, value: "Travel 50%" },
  { id: JobType.Travel75, value: "Travel 75%+" },
];

export const EMPLOYMENT_TYPES = [
  { id: EmploymentType.FullTime, value: "Full Time" },
  { id: EmploymentType.PartTime, value: "Part Time" },
  { id: EmploymentType.Contractor, value: "Contractor" },
  { id: EmploymentType.Temporary, value: "Temporary" },
  { id: EmploymentType.Internship, value: "Internship" },
];

export const EDUCATION_DEGREES = [
  { id: EducationDegree.NA, value: "N/A" },
  { id: EducationDegree.Secondary, value: "Secondary" },
  { id: EducationDegree.Associates, value: "Associates" },
  { id: EducationDegree.Bachelors, value: "Bachelors" },
  { id: EducationDegree.Masters, value: "Masters" },
  { id: EducationDegree.Doctorates, value: "Doctorates" },
];

export const TIMEZONES = [
  {
    id: "(UTC-12:00) International Date Line West",
    value: "(UTC-12:00) International Date Line West",
  },
  {
    id: "(UTC-11:00) Coordinated Universal Time-11",
    value: "(UTC-11:00) Coordinated Universal Time-11",
  },
  { id: "(UTC-10:00) Hawaii", value: "(UTC-10:00) Hawaii" },
  { id: "(UTC-09:00) Alaska", value: "(UTC-09:00) Alaska" },
  { id: "(UTC-08:00) Baja California", value: "(UTC-08:00) Baja California" },
  {
    id: "(UTC-08:00) Pacific Time (US and Canada)",
    value: "(UTC-08:00) Pacific Time (US and Canada)",
  },
  {
    id: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    value: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  },
  { id: "(UTC-07:00) Arizona", value: "(UTC-07:00) Arizona" },
  {
    id: "(UTC-07:00) Mountain Time (US and Canada)",
    value: "(UTC-07:00) Mountain Time (US and Canada)",
  },
  { id: "(UTC-06:00) Central America", value: "(UTC-06:00) Central America" },
  {
    id: "(UTC-06:00) Central Time (US and Canada)",
    value: "(UTC-06:00) Central Time (US and Canada)",
  },
  { id: "(UTC-06:00) Saskatchewan", value: "(UTC-06:00) Saskatchewan" },
  {
    id: "(UTC-06:00) Guadalajara, Mexico City, Monterey",
    value: "(UTC-06:00) Guadalajara, Mexico City, Monterey",
  },
  {
    id: "(UTC-05:00) Bogota, Lima, Quito",
    value: "(UTC-05:00) Bogota, Lima, Quito",
  },
  { id: "(UTC-05:00) Indiana (East)", value: "(UTC-05:00) Indiana (East)" },
  {
    id: "(UTC-05:00) Eastern Time (US and Canada)",
    value: "(UTC-05:00) Eastern Time (US and Canada)",
  },
  { id: "(UTC-04:30) Caracas", value: "(UTC-04:30) Caracas" },
  {
    id: "(UTC-04:00) Atlantic Time (Canada)",
    value: "(UTC-04:00) Atlantic Time (Canada)",
  },
  { id: "(UTC-04:00) Asuncion", value: "(UTC-04:00) Asuncion" },
  {
    id: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    value: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  },
  { id: "(UTC-04:00) Cuiaba", value: "(UTC-04:00) Cuiaba" },
  { id: "(UTC-04:00) Santiago", value: "(UTC-04:00) Santiago" },
  { id: "(UTC-03:30) Newfoundland", value: "(UTC-03:30) Newfoundland" },
  { id: "(UTC-03:00) Brasilia", value: "(UTC-03:00) Brasilia" },
  { id: "(UTC-03:00) Greenland", value: "(UTC-03:00) Greenland" },
  {
    id: "(UTC-03:00) Cayenne, Fortaleza",
    value: "(UTC-03:00) Cayenne, Fortaleza",
  },
  { id: "(UTC-03:00) Buenos Aires", value: "(UTC-03:00) Buenos Aires" },
  { id: "(UTC-03:00) Montevideo", value: "(UTC-03:00) Montevideo" },
  {
    id: "(UTC-02:00) Coordinated Universal Time-2",
    value: "(UTC-02:00) Coordinated Universal Time-2",
  },
  { id: "(UTC-01:00) Cape Verde", value: "(UTC-01:00) Cape Verde" },
  { id: "(UTC-01:00) Azores", value: "(UTC-01:00) Azores" },
  { id: "(UTC+00:00) Casablanca", value: "(UTC+00:00) Casablanca" },
  {
    id: "(UTC+00:00) Monrovia, Reykjavik",
    value: "(UTC+00:00) Monrovia, Reykjavik",
  },
  {
    id: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    value: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
  },
  {
    id: "(UTC+00:00) Coordinated Universal Time",
    value: "(UTC+00:00) Coordinated Universal Time",
  },
  {
    id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    id: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    id: "(UTC+01:00) West Central Africa",
    value: "(UTC+01:00) West Central Africa",
  },
  {
    id: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    id: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  { id: "(UTC+01:00) Windhoek", value: "(UTC+01:00) Windhoek" },
  {
    id: "(UTC+02:00) Athens, Bucharest, Istanbul",
    value: "(UTC+02:00) Athens, Bucharest, Istanbul",
  },
  {
    id: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    value: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  },
  { id: "(UTC+02:00) Cairo", value: "(UTC+02:00) Cairo" },
  { id: "(UTC+02:00) Damascus", value: "(UTC+02:00) Damascus" },
  { id: "(UTC+02:00) Amman", value: "(UTC+02:00) Amman" },
  { id: "(UTC+02:00) Harare, Pretoria", value: "(UTC+02:00) Harare, Pretoria" },
  { id: "(UTC+02:00) Jerusalem", value: "(UTC+02:00) Jerusalem" },
  { id: "(UTC+02:00) Beirut", value: "(UTC+02:00) Beirut" },
  { id: "(UTC+03:00) Baghdad", value: "(UTC+03:00) Baghdad" },
  { id: "(UTC+03:00) Minsk", value: "(UTC+03:00) Minsk" },
  { id: "(UTC+03:00) Kuwait, Riyadh", value: "(UTC+03:00) Kuwait, Riyadh" },
  { id: "(UTC+03:00) Nairobi", value: "(UTC+03:00) Nairobi" },
  { id: "(UTC+03:30) Tehran", value: "(UTC+03:30) Tehran" },
  {
    id: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    value: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
  },
  { id: "(UTC+04:00) Tbilisi", value: "(UTC+04:00) Tbilisi" },
  { id: "(UTC+04:00) Yerevan", value: "(UTC+04:00) Yerevan" },
  {
    id: "(UTC+04:00) Abu Dhabi, Muscat",
    value: "(UTC+04:00) Abu Dhabi, Muscat",
  },
  { id: "(UTC+04:00) Baku", value: "(UTC+04:00) Baku" },
  { id: "(UTC+04:00) Port Louis", value: "(UTC+04:00) Port Louis" },
  { id: "(UTC+04:30) Kabul", value: "(UTC+04:30) Kabul" },
  { id: "(UTC+05:00) Tashkent", value: "(UTC+05:00) Tashkent" },
  {
    id: "(UTC+05:00) Islamabad, Karachi",
    value: "(UTC+05:00) Islamabad, Karachi",
  },
  {
    id: "(UTC+05:30) Sri Jayewardenepura Kotte",
    value: "(UTC+05:30) Sri Jayewardenepura Kotte",
  },
  {
    id: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  { id: "(UTC+05:45) Kathmandu", value: "(UTC+05:45) Kathmandu" },
  { id: "(UTC+06:00) Astana", value: "(UTC+06:00) Astana" },
  { id: "(UTC+06:00) Dhaka", value: "(UTC+06:00) Dhaka" },
  { id: "(UTC+06:00) Yekaterinburg", value: "(UTC+06:00) Yekaterinburg" },
  { id: "(UTC+06:30) Yangon", value: "(UTC+06:30) Yangon" },
  {
    id: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    value: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  },
  { id: "(UTC+07:00) Novosibirsk", value: "(UTC+07:00) Novosibirsk" },
  { id: "(UTC+08:00) Krasnoyarsk", value: "(UTC+08:00) Krasnoyarsk" },
  { id: "(UTC+08:00) Ulaanbaatar", value: "(UTC+08:00) Ulaanbaatar" },
  {
    id: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    value: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  },
  { id: "(UTC+08:00) Perth", value: "(UTC+08:00) Perth" },
  {
    id: "(UTC+08:00) Kuala Lumpur, Singapore",
    value: "(UTC+08:00) Kuala Lumpur, Singapore",
  },
  { id: "(UTC+08:00) Taipei", value: "(UTC+08:00) Taipei" },
  { id: "(UTC+09:00) Irkutsk", value: "(UTC+09:00) Irkutsk" },
  { id: "(UTC+09:00) Seoul", value: "(UTC+09:00) Seoul" },
  {
    id: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    value: "(UTC+09:00) Osaka, Sapporo, Tokyo",
  },
  { id: "(UTC+09:30) Darwin", value: "(UTC+09:30) Darwin" },
  { id: "(UTC+09:30) Adelaide", value: "(UTC+09:30) Adelaide" },
  { id: "(UTC+10:00) Hobart", value: "(UTC+10:00) Hobart" },
  { id: "(UTC+10:00) Yakutsk", value: "(UTC+10:00) Yakutsk" },
  { id: "(UTC+10:00) Brisbane", value: "(UTC+10:00) Brisbane" },
  {
    id: "(UTC+10:00) Guam, Port Moresby",
    value: "(UTC+10:00) Guam, Port Moresby",
  },
  {
    id: "(UTC+10:00) Canberra, Melbourne, Sydney",
    value: "(UTC+10:00) Canberra, Melbourne, Sydney",
  },
  { id: "(UTC+11:00) Vladivostok", value: "(UTC+11:00) Vladivostok" },
  {
    id: "(UTC+11:00) Solomon Islands, New Caledonia",
    value: "(UTC+11:00) Solomon Islands, New Caledonia",
  },
  {
    id: "(UTC+12:00) Coordinated Universal Time+12",
    value: "(UTC+12:00) Coordinated Universal Time+12",
  },
  {
    id: "(UTC+12:00) Fiji, Marshall Islands",
    value: "(UTC+12:00) Fiji, Marshall Islands",
  },
  { id: "(UTC+12:00) Magadan", value: "(UTC+12:00) Magadan" },
  {
    id: "(UTC+12:00) Auckland, Wellington",
    value: "(UTC+12:00) Auckland, Wellington",
  },
  { id: "(UTC+13:00) Nuku'alofa", value: "(UTC+13:00) Nuku'alofa" },
  { id: "(UTC+13:00) Samoa", value: "(UTC+13:00) Samoa" },
];
