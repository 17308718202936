import React, { useState } from "react";

import { PageContainerSwitch } from "../../components";
import {
  AdminBusinessAuditListContainer,
  AdminBusinessVerificationListContainer,
} from "./../../features";

export const AdminBusinessContainer = () => {
  const tabs = [
    {
      label: "Verification",
      component: AdminBusinessVerificationListContainer,
    },
    {
      label: "Audit",
      component: AdminBusinessAuditListContainer,
    },
  ];

  return <PageContainerSwitch tabs={tabs} indicatorClassName="hidden" />;
};
