import React, { useEffect, useState } from "react";

import {
  ActionMenu,
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  FormSection,
  Link,
  StarRating,
} from "../../components";
import {
  ExperienceListItem,
  EducationListItem,
  CompanyCommentCreateForm,
  CompanyCommentList,
  ChatMessageButton,
} from "..";
import { ROUTER } from "../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../hooks";
import {
  getCompanyCommentsByCompanyId,
  removeCompanyComment,
  removeCompanyCommentById,
} from "../../store";
import { IChildComponentUpdateHelpers } from "@types";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

export const EmployeeCard = ({
  id,
  profile,
  profileId,
  experiences,
  educations,
  companyCommentCount,
  maxExperiences,
  maxEducations,
}) => {
  const dispatch = useAppDispatch();

  const { modal, setModal } = useModal();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const [loading, setLoading] = useLoading();
  const [likeLoading, setLikeLoading] = useLoading();

  const [commentLoading, setCommentLoading] = useLoading();
  const [commentSection, setCommentSection] = useState(false);

  const [comments, setComments] = useState<any[]>([]);
  const [commentCount, setCommentCount] = useState<number>(
    companyCommentCount || 0
  );

  const { firstName, lastName, avatar } = profile || {};
  const companyId = account.ownership?.id || account?.employment?.id || null;
  const userId = profile?.id;

  const isLocation = [profile?.country, profile?.state, profile?.city]?.some(
    (i) => i && i?.length >= 1
  );

  const handleCommentsToggle = async () => {
    if (commentSection) return setCommentSection(false);

    setCommentLoading(true);
    setCommentSection(true);

    const comments = companyId
      ? commentCount
        ? await dispatch(
            getCompanyCommentsByCompanyId(companyId, {
              where: {
                userId: profileId,
              },
              sort: [{ createdAt: -1 }],
            })
          )
        : []
      : [];
    if (!comments) {
      return setCommentLoading(false);
    }

    setComments(comments);
    setCommentLoading(false);
  };

  const handleCommentAppend = (comment: any) => {
    setComments([comment, ...comments]);
    setCommentCount(commentCount + 1);
  };

  const handleCommentDelete = async (
    commentId: string,
    { setLoading }: IChildComponentUpdateHelpers
  ): Promise<void> => {
    setLoading(true);

    console.log("Deleting Comment..");

    const comment = await dispatch(removeCompanyCommentById(commentId));
    if (!comment?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Company Note Not Removed!",
      });

      return setLoading(false);
    }

    setComments(comments?.filter(({ id }) => id !== commentId) || []);
    setLoading(false);
  };

  useEffect(() => {
    setCommentCount(companyCommentCount);
  }, [companyCommentCount]);

  return (
    <Card>
      <CardBody>
        <div className="flex flex-row justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-start items-start gap-x-[10px]">
                <Avatar size={40} src={avatar}>
                  {firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                  <Link to={ROUTER.USER_DETAIL(profileId)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {firstName || ""} {lastName || ""}
                    </h3>
                  </Link>
                  <span className="text-xs leading-none">
                    {isLocation
                      ? [profile?.country, profile?.state, profile?.city]
                          ?.filter((i) => i && i?.length >= 1)
                          ?.join(", ")
                      : "********"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center gap-x-[10px] md:flex xxs:hidden">
            <Button
              variant="outlined"
              color="primary"
              to={ROUTER.USER_DETAIL(profileId)}
            >
              Details
            </Button>
            <ChatMessageButton userId={userId} />
          </div>
        </div>

        {(experiences?.length > 0 || educations?.length > 0) && (
          <div className="flex justify-start mt-[10px] ml-[25px] gap-x-[10px] gap-y-[15px] md:flex-row xxs:flex-col">
            {experiences?.length > 0 && (
              <div className="w-full flex flex-col basis-6/12 overflow-hidden">
                <div>
                  <span className="text-sm font-medium text-secondary">
                    Works at:
                  </span>
                </div>
                <div className="flex flex-col gap-y-[15px] mt-[5px]">
                  {experiences
                    ?.slice(0, maxExperiences)
                    ?.map((workExperience) => (
                      <ExperienceListItem
                        key={workExperience?.id}
                        {...workExperience}
                        shorten
                      />
                    ))}
                </div>
              </div>
            )}

            {educations?.length > 0 && (
              <div className="w-full flex flex-col basis-6/12 overflow-hidden">
                <div>
                  <span className="text-sm font-medium text-secondary">
                    Education/Certifications:
                  </span>
                </div>
                <div className="flex flex-col gap-y-[15px] mt-[5px]">
                  {educations?.slice(0, maxEducations)?.map((education) => (
                    <EducationListItem key={education?.id} {...education} />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-y-[10px] mt-[25px] md:hidden xxs:flex">
          <Button
            variant="outlined"
            color="primary"
            to={ROUTER.USER_DETAIL(profileId)}
          >
            Details
          </Button>
          <ChatMessageButton userId={userId} />
        </div>
      </CardBody>

      <CardFooter>
        <Button variant="text" color="secondary" onClick={handleCommentsToggle}>
          {commentSection ? "Close Notes" : `Show Notes (${commentCount || 0})`}
        </Button>
      </CardFooter>

      {commentSection && (
        <>
          <CardFooter className="py-[15px]">
            <FormSection heading="Add Note">
              <CompanyCommentCreateForm
                {...{ userId, companyId }}
                onCreate={handleCommentAppend}
              />
            </FormSection>
          </CardFooter>
          <CardFooter className="py-[30px]">
            <CompanyCommentList
              loading={commentLoading}
              data={comments}
              onDelete={handleCommentDelete}
            />
          </CardFooter>
        </>
      )}
    </Card>
  );
};

EmployeeCard.defaultProps = {
  maxExperiences: 2,
  maxEducations: 2,
  commentCount: 0,
};
