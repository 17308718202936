import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { UserGalleryItemDetailContainer } = lazyload(
  () => import("../../features")
);

export const UserGalleryItemDetailPage = () => (
  <MainLayout>
    <UserGalleryItemDetailContainer />
  </MainLayout>
);
