import { IFriendRequest, IProfile } from "../../../shared";

import { ApiService } from "../../../services";

import { AppDispatch, GetRootState } from "../../store";
import { setAccount, setAccountProfile } from "../account";

import { profileSlice } from "./profile.slice";

export const getProfiles =
  (query?: object) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.getProfilesRequest());

      const response = await ApiService.post(`profiles/query`, query || {});
      const { data, results } = response.data;

      dispatch(profileSlice.actions.getProfilesSuccess({ data, results }));

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.getProfilesError(e.response?.data?.error));
    }
  };

export const getProfileById =
  (profileId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.getProfileRequest());

      const response = await ApiService.get(`profiles/${profileId}`);
      const { data } = response.data;

      dispatch(profileSlice.actions.getProfileSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.getProfileError(e.response?.data?.error));
    }
  };

export const updateUserAvatar =
  (userId: string, payload: any) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      dispatch(profileSlice.actions.updateUserAvatarRequest());

      const state = getState();
      const profileId = state.account.account.data?.profileId;
      const isMyProfile = userId === profileId;

      const response = await ApiService.post(
        `profiles/${userId}/avatar`,
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      const { data } = response.data;

      dispatch(profileSlice.actions.updateUserAvatarSuccess(data?.avatar));

      if (isMyProfile) {
        dispatch(setAccountProfile({ avatar: data.avatar }));
      }

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.updateUserAvatarError());
    }
  };

export const getFriendsByUserId =
  (userId: string) =>
  async (dispatch: AppDispatch): Promise<IProfile[]> => {
    try {
      dispatch(profileSlice.actions.getFriendsRequest());

      const response = await ApiService.post(
        `profiles/${userId}/friends/query`
      );
      const { data, results } = response.data.data;

      dispatch(profileSlice.actions.getFriendsSuccess({ data, results }));

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;
      dispatch(profileSlice.actions.getFriendsError(error));

      return [];
    }
  };

export const addFriend = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.addFriendRequest());

    const response = await ApiService.put(`profiles/${userId}/friend`);
    const { data } = response.data;

    dispatch(profileSlice.actions.addFriendSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(profileSlice.actions.addFriendError(e.response?.data?.error));
  }
};

export const removeFriend =
  (userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.removeFriendRequest());

      const response = await ApiService.put(`profiles/${userId}/unfriend`);
      const { data } = response.data;

      dispatch(profileSlice.actions.removeFriendSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.removeFriendError(e.response?.data?.error));
    }
  };

export const getUserFriendRequests =
  () =>
  async (
    dispatch: AppDispatch,
    getState: GetRootState
  ): Promise<IFriendRequest[]> => {
    try {
      dispatch(profileSlice.actions.getFriendRequestsRequest());

      const state = getState();
      const userId = state.account.account.data.profileId;

      const response = await ApiService.post(
        `profiles/${userId}/friend-requests/query`
      );

      const data: IFriendRequest[] = response.data.data.data;
      const results = response.data.data.results;

      dispatch(
        profileSlice.actions.getFriendRequestsSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;
      dispatch(profileSlice.actions.getFriendRequestsError(error));

      return [];
    }
  };

export const getFriendRequestsByUserId =
  (userId: string) =>
  async (dispatch: AppDispatch): Promise<IFriendRequest[]> => {
    try {
      dispatch(profileSlice.actions.getFriendRequestsRequest());

      const response = await ApiService.post(
        `profiles/${userId}/friend-requests/query`
      );

      const data: IFriendRequest[] = response.data.data.data;
      const results = response.data.data.results;

      dispatch(
        profileSlice.actions.getFriendRequestsSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;
      dispatch(profileSlice.actions.getFriendRequestsError(error));

      return [];
    }
  };

export const approveFriendRequest =
  (requestId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.approveFriendRequestRequest());

      const response = await ApiService.put(
        `friend-requests/${requestId}/approve`
      );

      const data: IFriendRequest = response.data.data;

      console.log({ data });

      dispatch(profileSlice.actions.approveFriendRequestSuccess());

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.approveFriendRequestError());
    }
  };

export const rejectFriendRequest =
  (requestId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.rejectFriendRequestRequest());

      const response = await ApiService.put(
        `friend-requests/${requestId}/reject`
      );

      const data: IFriendRequest = response.data.data;

      console.log({ data });

      dispatch(profileSlice.actions.rejectFriendRequestSuccess());

      return data;
    } catch (e: any) {
      dispatch(profileSlice.actions.rejectFriendRequestError());
    }
  };
