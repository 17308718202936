import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./assets/css/main.scss";

import {
  AuthProvider,
  RootProvider,
  EventProvider,
  ToastProvider,
  ModalProvider,
  NotificationProvider,
  DrawerProvider,
} from "./components";
import { RouteProvider, routes } from "./routes";

export const App = () => {
  return (
    <RootProvider>
      <Router>
        <EventProvider />
        <ToastProvider />
        <NotificationProvider />
        <ModalProvider />
        <DrawerProvider />
        <AuthProvider>
          <RouteProvider routes={routes} />
        </AuthProvider>
      </Router>
    </RootProvider>
  );
};
