import { createSlice } from "@reduxjs/toolkit";

import {
  appendToStore,
  popFromStore,
  appendToStoreEntity,
  popFromStoreEntity,
  AppReducerState,
} from "../../../store";

interface IResumeState {
  resumes: AppReducerState<any[]>;
  resume: AppReducerState<any>;
}

const initialState: IResumeState = {
  resumes: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  resume: {
    isLoading: false,
    isCreating: false,
    data: {},
    error: null,
  },
};

export const resumeSlice = createSlice({
  name: "Resume",
  initialState,
  reducers: {
    appendResumes: appendToStore("resumes"),
    popResumes: popFromStore("resumes"),

    getResumesRequest: (state) => {
      state.resumes.isLoading = true;
      state.resumes.results = 0;
      state.resumes.data = [];
    },
    getResumesSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.resumes.isLoading = false;
      state.resumes.results = results;
      state.resumes.data = data;
    },
    getResumesError: (state, action) => {
      state.resumes.isLoading = false;
      state.resumes.results = 0;
      state.resumes.data = [];
      state.resumes.error = action.payload;
    },

    getResumeRequest: (state) => {
      state.resume.isLoading = true;
      state.resume.data = null;
    },
    getResumeSuccess: (state, action) => {
      state.resume.data = action.payload;
      state.resume.isLoading = false;
    },
    getResumeError: (state, action) => {
      state.resume.error = action.payload;
      state.resume.isLoading = false;
    },

    getDefaultResumeRequest: (state) => {
      state.resume.isLoading = true;
      state.resume.data = null;
    },
    getDefaultResumeSuccess: (state, action) => {
      state.resume.data = action.payload;
      state.resume.isLoading = false;
    },
    getDefaultResumeError: (state, action) => {
      state.resume.error = action.payload;
      state.resume.isLoading = false;
    },

    createResumeRequest: (state) => {
      state.resume.isLoading = true;
    },
    createResumeSuccess: (state, action) => {
      state.resume.isLoading = false;
      // state.resume.data = action.payload;
    },
    createResumeError: (state, action) => {
      state.resume.isLoading = false;
      // state.resume.error = action.payload;
    },

    updateResume: (state, action) => {
      state.resume.data = action.payload;
    },
    updateResumeRequest: (state) => {
      state.resume.isLoading = true;
    },
    updateResumeSuccess: (state, action) => {
      state.resume.isLoading = false;
      state.resume.data = action.payload;
    },
    updateResumeError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },
    copyResumeAsDefaultRequest: (state) => {
      state.resume.isLoading = true;
    },
    copyResumeAsDefaultSuccess: (state, action) => {
      state.resume.isLoading = false;
    },
    copyResumeAsDefaultError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },

    removeResumeRequest: (state) => {
      state.resume.isLoading = true;
    },
    removeResumeSuccess: (state, action) => {
      state.resume.isLoading = false;
      state.resume.data = {};
    },
    removeResumeError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },

    // Covers
    appendResumeCover: appendToStoreEntity("resume", "covers"),
    popResumeCover: popFromStoreEntity("resume", "covers"),

    getResumeCoversRequest: (state) => {
      state.resume.isLoading = true;
      state.resume.data.covers = [];
    },
    getResumeCoversSuccess: (state, action) => {
      state.resume.isLoading = false;
      state.resume.data.covers = action.payload;
    },
    getResumeCoversError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },

    createResumeCoverRequest: (state) => {},
    createResumeCoverSuccess: (state, action) => {},
    createResumeCoverError: (state, action) => {},

    // Experiences
    appendResumeExperience: appendToStoreEntity("resume", "experiences"),
    popResumeExperience: popFromStoreEntity("resume", "experiences"),

    getResumeExperiencesRequest: (state) => {
      state.resume.isLoading = true;
    },
    getResumeExperiencesSuccess: (state, action) => {
      state.resume.isLoading = false;
      state.resume.data.experiences = action.payload;
    },
    getResumeExperiencesError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },

    createResumeExperienceRequest: (state) => {},
    createResumeExperienceSuccess: (state, action) => {},
    createResumeExperienceError: (state, action) => {},

    // Educations
    appendResumeEducation: appendToStoreEntity("resume", "educations"),
    popResumeEducation: popFromStoreEntity("resume", "educations"),
    getResumeEducationsRequest: (state) => {
      state.resume.isLoading = true;
    },
    getResumeEducationsSuccess: (state, action) => {
      state.resume.isLoading = false;
      state.resume.data.educations = action.payload;
    },
    getResumeEducationsError: (state, action) => {
      state.resume.isLoading = false;
      state.resume.error = action.payload;
    },
    createResumeEducationRequest: (state) => {},
    createResumeEducationSuccess: (state, action) => {},
    createResumeEducationError: (state, action) => {},
  },
});

export const resumeReducer = resumeSlice.reducer;
