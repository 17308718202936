import React from "react";

interface ISidebarContentProps {
  className?: string;
}

export const SidebarContent: React.FC<ISidebarContentProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`${className} relative h-screen pt-[75px] box-border`}>
      <div className="h-[100%] overflow-y-scroll">{children}</div>
    </div>
  );
};

SidebarContent.defaultProps = { className: "" };
