import React from "react";

import { RadioGroup as MuiRadioGroup } from "@mui/material";

interface IRadioGroupProps {
  className?: string;
  value?: string | null;
  defaultValue?: string | null;
  defaultChecked?: boolean;
  onChange?: (value?: string) => void;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  children,
  className,
  value,
  defaultValue,
  defaultChecked,
  onChange,
}) => (
  <MuiRadioGroup
    className={`${className} flex flex-col justify-start`}
    value={value}
    onChange={(e, value) => (onChange ? onChange(value) : {})}
  >
    {children}
  </MuiRadioGroup>
);

RadioGroup.defaultProps = {
  value: null,
  className: "",
  defaultValue: null,
  defaultChecked: false,
};
