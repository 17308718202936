import React from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useModal, useToast } from "../../../hooks";
import { removeChat, removeJob } from "../../../store";

import {
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Form,
  FormSection,
  Modal,
  TextInput,
} from "../../../components";
import { FormValidator } from "../../../utils";

interface IEventCreateForm {
  title?: string;
  description?: string;
  startedAt?: Date;
  finishedAt?: Date;

  startDate?: Date;
  startTime?: Date;
}

export const EventCreateModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.EVENT_CREATE,
  });

  const { loading, props, redirectTo } = modal;
  const { startedAt, onSuccess } = props || {};

  const form = useFormik<IEventCreateForm>({
    initialValues: { startedAt },
    // validationSchema,
    onSubmit: async (values) => {},
  });

  const validator = new FormValidator<IEventCreateForm>(form);

  const { values, resetForm, setFieldValue, handleChange, handleSubmit } = form;

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Create Event"
      description="Please create your next event using the form below."
      open={isOpen}
      onClose={handleClose}
    >
      <div>
        <FormSection>
          <Form
            onSubmit={handleSubmit}
            className="w-full flex flex-col text-white gap-y-[10px]"
          >
            <div className="flex flex-row justify-start gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
              <TextInput
                id="title"
                label="Title"
                type="text"
                variant="filled"
                className="!bg-primaryDark !text-white"
                labelClassName="text-secondary"
                inputClassName="text-inherit"
                required
                value={values?.title}
                error={validator.isInputValid("title")}
                onChange={handleChange}
              />
            </div>

            <div className="flex text-white h-auto gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
              <BasicDatePicker
                id="startedAt"
                label="Date"
                value={values?.startedAt}
                error={validator.isInputValid("startedAt")}
                className="!bg-primaryDark !text-white"
                onChange={(value) => setFieldValue("startDate", value, true)}
                required
                disableFuture
              />
              <BasicTimePicker
                id="startedAt"
                label="Time"
                value={values?.startedAt}
                error={validator.isInputValid("startedAt")}
                className="!bg-primaryDark !text-white"
                onChange={(value) => setFieldValue("startDate", value, true)}
              />
            </div>

            <div>
              <TextInput
                id="description"
                label="Description"
                type="text"
                variant="filled"
                className="!bg-primaryDark !text-white"
                controlClassName="basis-full"
                labelClassName="text-secondary"
                multiline
                minRows={2}
                maxRows={5}
                value={values?.description}
                error={validator.isInputValid("description")}
                onChange={handleChange}
              />
            </div>
          </Form>
        </FormSection>

        <div className="flex flex-row justify-end items-center mt-[15px] gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <Button
            color="secondary"
            variant="outlined"
            className="md:w-auto xxs:w-full"
            onClick={handleCancel}
          >
            Discard
          </Button>
          <Button
            color="success"
            variant="outlined"
            type="submit"
            className="min-w-[125px] md:w-auto xxs:w-full"
            loading={loading}
          >
            Create Event
          </Button>
        </div>
      </div>
    </Modal>
  );
};
