import React, { useEffect } from "react";
import { useParams } from "react-router";

import { Container, PageHeader } from "../../components";
import { FriendList } from "./../../features";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getFriendsByUserId } from "../../store";

export const UserFriendsContainer = () => {
  const dispatch = useAppDispatch();

  const { userId } = useParams();

  const {
    userFriends: {
      data: friends,
      results: friendCount,
      isLoading: friendLoading,
    },
  } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!userId) return;

    dispatch(getFriendsByUserId(userId));
  }, [userId]);

  return (
    <Container>
      <PageHeader>Friends ({friendCount || 0})</PageHeader>
      <FriendList data={friends} loading={friendLoading} />
    </Container>
  );
};
