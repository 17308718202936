import { IApiError } from "../../../shared";

import { ProfileType } from "../../../shared";
import { ApiService, EventService } from "../../../services";

import { AppDispatch } from "../../store";

import { accountSlice } from "./account.slice";

export const setAccount = (data: any) => async (dispatch: AppDispatch) =>
  dispatch(accountSlice.actions.setAccount(data));

export const setAccountProfile = (data: any) => async (dispatch: AppDispatch) =>
  dispatch(accountSlice.actions.setAccountProfile(data));

export const getAccount = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.getAccountRequest());

    const response = await ApiService.get("auth/account");
    const { data } = response.data;

    dispatch(accountSlice.actions.getAccountSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.getAccountError(e.response?.data?.error));
  }
};

export const syncAccount = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.syncAccountRequest());

    const response = await ApiService.get("auth/account");
    const { data } = response.data;

    dispatch(accountSlice.actions.syncAccountSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.syncAccountError(e.response?.data?.error));
  }
};

export const checkAccount = (options: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.checkAccountRequest());

    const response = await ApiService.post("accounts/check", options);
    const { data } = response.data;

    dispatch(accountSlice.actions.checkAccountSuccess(data));

    return true;
  } catch (e: any) {
    dispatch(accountSlice.actions.checkAccountError());
    return false;
  }
};

export const login = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.loginRequest());

    const response = await ApiService.post("auth/login", payload);
    const { data } = response.data;

    await dispatch(accountSlice.actions.loginSuccess(data));
    await dispatch(getAccount());

    return true;
  } catch (e: any) {
    dispatch(accountSlice.actions.loginError(e.response?.data?.error));
    return false;
  }
};

export const register = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.registerRequest());

    const response = await ApiService.post("auth/register", payload);
    const { data } = response.data;

    dispatch(accountSlice.actions.registerSuccess(data));
    dispatch(getAccount());

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.registerError(e.response?.data?.error));
    return null;
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.logoutRequest());

    const response = await ApiService.post("auth/logout");
    const { data } = response.data;

    await EventService.close();

    dispatch(accountSlice.actions.logoutSuccess(data));

    return true;
  } catch (e: any) {
    dispatch(accountSlice.actions.logoutError(e.response?.data?.error));
    return false;
  }
};

export const switchToPersonalProfile =
  (accountId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.switchToPersonalProfileRequest());

      const response = await ApiService.put(
        `accounts/${accountId}/switch?to=${ProfileType.Personal}`
      );
      const { data } = response.data;

      dispatch(accountSlice.actions.switchToPersonalProfileSuccess(data));
      dispatch(getAccount());

      return data;
    } catch (e: any) {
      dispatch(
        accountSlice.actions.switchToPersonalProfileError(
          e.response?.data?.error
        )
      );
    }
  };

export const switchToBusinessProfile =
  (accountId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.switchToBusinessProfileRequest());

      const response = await ApiService.put(
        `accounts/${accountId}/switch?to=${ProfileType.Business}`
      );
      const { data } = response.data;

      dispatch(accountSlice.actions.switchToBusinessProfileSuccess(data));
      dispatch(getAccount());

      return data;
    } catch (e: any) {
      dispatch(
        accountSlice.actions.switchToBusinessProfileError(
          e.response?.data?.error
        )
      );
    }
  };

export const checkPassport =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.checkPassportRequest());

      const response = await ApiService.post("passports/check", payload);
      const { data } = response.data;

      dispatch(accountSlice.actions.checkPassportSuccess());

      return data;
    } catch (e: any) {
      const error: IApiError = e.response.data;

      dispatch(accountSlice.actions.checkPassportError());

      return { error };
    }
  };

export const scanPassport =
  (payload: any, onError?: (error: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.scanPassportRequest());

      const response = await ApiService.post(
        "passport-validator/scan",
        payload,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const { data } = response.data;

      dispatch(accountSlice.actions.scanPassportSuccess(data));

      return { data };
    } catch (e: any) {
      const error: IApiError = e.response.data;

      dispatch(accountSlice.actions.scanPassportError(error));

      return { error };
    }
  };

export const checkEmail = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.checkAccountRequest());

    const response = await ApiService.post("accounts/check", {
      where: { email },
    });
    const { data } = response.data;

    dispatch(accountSlice.actions.checkAccountSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.checkAccountError());
    return false;
  }
};

export const validateEmail =
  (email: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.validateEmailRequest());

      const response = await ApiService.get(`email/validate?email=${email}`);
      const { data } = response.data;

      dispatch(accountSlice.actions.validateEmailSuccess());

      return true;
    } catch (e: any) {
      console.log(e.response?.data);

      dispatch(accountSlice.actions.validateEmailError(e.response?.data));

      return false;
    }
  };

export const verifyEmail = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.verifyEmailRequest());

    const response = await ApiService.get(`email/verify?email=${email}`);
    const { data } = response.data;

    dispatch(accountSlice.actions.verifyEmailSuccess());

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.verifyEmailError());
    return false;
  }
};

export const confirmEmail = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(accountSlice.actions.confirmEmailRequest());

    const { email, token } = payload;

    const response = await ApiService.get(
      `email/confirm?email=${email}&token=${token}`
    );
    const { data } = response.data;

    dispatch(accountSlice.actions.confirmEmailSuccess());

    return data;
  } catch (e: any) {
    dispatch(accountSlice.actions.confirmEmailError());
  }
};

export const resetPassword =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.resetPasswordRequest());

      const response = await ApiService.post(`auth/reset-password`, payload);
      const { data } = response.data;

      dispatch(accountSlice.actions.resetPasswordSuccess());

      return data;
    } catch (e: any) {
      dispatch(accountSlice.actions.resetPasswordError());
    }
  };

export const updatePassword =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.updatePasswordRequest());

      const { token } = payload;

      const response = await ApiService.put(
        `auth/reset-password?token=${token}`,
        payload
      );
      const { data } = response.data;

      console.log({ data });

      dispatch(accountSlice.actions.updatePasswordSuccess());

      return data;
    } catch (e: any) {
      dispatch(accountSlice.actions.updatePasswordError());
    }
  };

export const checkUserToken =
  (token: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountSlice.actions.checkUserTokenRequest());

      const response = await ApiService.get(`tokens/${token}`);
      const { data } = response.data;

      dispatch(accountSlice.actions.checkUserTokenSuccess());

      return data;
    } catch (e: any) {
      dispatch(accountSlice.actions.checkUserTokenError());
    }
  };
