import React, { useEffect } from "react";

import { BulletPointListItem } from "../../../components";
import { renderTextAsList } from "../../../utils";

interface IBulledPointListProps {
  className?: string;
  data?: any;
}

export const BulletPointList: React.FC<IBulledPointListProps> = ({
  children,
  className,
  data,
}) => {
  return (
    <div className={`${className} flex flex-col gap-y-[15px]`}>
      {renderTextAsList(data, BulletPointListItem)}
    </div>
  );
};

BulletPointList.defaultProps = {
  data: "",
};
