import React from "react";

import { FallbackError } from "./../../components";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });

    const timestamp = new Date();

    console.log("Error Report", { error, info, timestamp });
  };

  render() {
    // @ts-ignore
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <FallbackError /> : children;
  }
}
