import React, { useState } from "react";

import {
  FiUser as UserIcon,
  FiMapPin as MapIcon,
  FiUsers as UsersIcon,
  FiCheckCircle as CheckIcon,
} from "react-icons/fi";

import { AiOutlinePieChart as PieChartIcon } from "react-icons/ai";

import {
  Button,
  Card,
  Avatar,
  VerificationStatusBadge,
  Link,
  ActionMenu,
  CardBody,
} from "../../components";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
  IJob,
} from "../../shared";
import { useLoading, useModal, useToast } from "../../hooks";
import { ROUTER } from "../../routes";
import { date, renderJobSalary, textformat } from "../../utils";

interface IJobCardProps extends IJob {
  profileId?: string;
  actions?: any;
  onEdit?: any;
  onDelete?: any;
  onDelist?: any;
  onApply?: any;
  onWithdraw?: any;
  onArchive?: any;
}

export const JobCard: React.FC<IJobCardProps> = ({
  id,
  title,
  type,
  employmentType,
  requirements,
  description,
  country,
  state,
  city,
  timezone,
  positions,
  yearsOfExperience,
  skillLevel,
  minSalary = 0,
  maxSalary = 0,
  salaryDependsOnExperience,
  degree,
  company,
  isDraft,
  isDelisted,
  createdBy,
  createdAt,
  profileId,

  actions,
  onEdit,
  onDelete,
  onDelist,
  onApply,
  onWithdraw,
  onArchive,
  ...props
}) => {
  const [loading, setLoading] = useLoading();
  const { modal, setModal } = useModal();

  const [isApplied, setApplied] = useState(props?.isApplied);
  const [applicationCount, setApplicationCount] = useState(
    props?.applicationCount || 0
  );

  const jobId = id;
  const companyName = company?.profile?.name;
  const isLocation = [country, state, city]?.some((i) => i && i?.length >= 1);

  const handleApply = () => {
    setModal({
      type: ModalType.JOB_APPLY,
      props: {
        job: { title },
        jobId,
        profileId,
        onSubmit: () => {
          setApplicationCount(applicationCount + 1);
          setApplied(true);
        },
      },
    });
  };

  const handleWithdraw = () => {
    setModal({
      type: ModalType.JOB_WITHDRAW,
      props: {
        job: { title },
        jobId,
        profileId,
        onSubmit: () => {
          setApplied(false);
          setApplicationCount(applicationCount > 0 ? applicationCount - 1 : 0);
        },
      },
    });
  };

  return (
    <Card>
      <CardBody>
        <div className="w-full flex flex-row justify-between items-start">
          <div className="w-full flex flex-col">
            <div className="flex flex-row justify-start items-start gap-x-[10px]">
              <Avatar
                to={
                  company?.alias
                    ? ROUTER.COMPANY_DETAIL_ALIAS(company?.alias)
                    : ROUTER.COMPANY_DETAIL(company?.id)
                }
                size={40}
              >
                {company?.profile?.name}
              </Avatar>
              <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                <div className="flex flex-row justify-start items-center gap-x-[5px]">
                  <Link to={ROUTER.JOB_DETAIL(id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {title} -{" "}
                      <span className="text-primary">{companyName}</span>
                    </h3>
                  </Link>
                  {isDraft && (
                    <span className="text-secondary text-sm font-medium">
                      (Draft)
                    </span>
                  )}
                </div>
                <span className="text-xs leading-none">
                  {date(createdAt).fromNow()}
                </span>
              </div>
            </div>
          </div>
          <div className="md:flex xxs:hidden">
            {isApplied ? (
              <Button
                color="secondary"
                variant="outlined"
                loading={loading}
                onClick={handleWithdraw}
              >
                Withdraw
              </Button>
            ) : (
              <Button
                color="success"
                variant="outlined"
                loading={loading}
                onClick={handleApply}
              >
                Apply
              </Button>
            )}
          </div>
        </div>
        <div className="mt-[20px] text-base text-white font-light overflow-hidden">
          <p>{textformat.shorten(description, 180)}</p>
        </div>

        {isLocation && (
          <div className="mt-[10px]">
            <span className="text-base text-secondary font-medium">
              {[country, state, city]
                ?.filter((i) => i && i?.length >= 1)
                ?.join(", ")}
            </span>
          </div>
        )}

        <div className="flex flex-row justify-start gap-x-[20px] gap-y-[10px] mt-[20px] text-sm font-medium md:flex-row xxs:flex-col">
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <PieChartIcon size={18} className="-mt-[3px]" />
            <span>Salary Range:</span>
            <span className="text-primary">
              {renderJobSalary({
                minSalary,
                maxSalary,
                salaryDependsOnExperience,
              })}
            </span>
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <UsersIcon size={18} className="-mt-[3px]" />
            <span>Applicants:</span>
            <span className="text-green-500 border-b border-green-500">
              {applicationCount || 0}
            </span>
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <CheckIcon size={18} className="-mt-[3px]" />
            <span>Available Positions:</span>
            <Link to="#" className="text-yellow border-b border-yellow">
              {positions}
            </Link>
          </div>
        </div>

        <div className="mt-[25px] md:hidden xxs:flex">
          {isApplied ? (
            <Button
              color="secondary"
              variant="outlined"
              className="w-full"
              loading={loading}
              onClick={handleWithdraw}
            >
              Withdraw
            </Button>
          ) : (
            <Button
              color="success"
              variant="outlined"
              className="w-full"
              loading={loading}
              onClick={handleApply}
            >
              Apply
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

JobCard.defaultProps = {
  title: "",
  description: "",
  country: "",
  city: "",
  timezone: "",
  positions: 0,
  yearsOfExperience: 0,
  minSalary: 0,
  maxSalary: 0,
  isApplied: false,
};
