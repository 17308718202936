import { useAppSelector } from "./useRedux";

export const useAuth = () => {
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const isAuthenticated = account?.id ? true : false;

  return { isAuthenticated, account };
};
