import React from "react";

import { FormControlLabel, Radio as MuiRadio } from "@mui/material";

interface IRadioProps {
  className?: string;
  labelClassName?: string;
  value?: string | null;
  label?: string | JSX.Element;
  onChange?: (e?: React.ChangeEvent) => void;
}

export const Radio: React.FC<IRadioProps> = ({
  className,
  labelClassName,
  value,
  label,
  onChange,
}) => (
  <FormControlLabel
    className={`${className} flex flex-row justify-start items-center gap-x-[5px] text-sm text-secondary select-none cursor-pointer`}
    value={value}
    control={
      <MuiRadio
        className="text-inherit"
        sx={{
          "& .MuiSvgIcon-root": {
            height: 20,
            width: 20,
          },
        }}
        value={value}
        disableRipple
      />
    }
    label={<span className={`${labelClassName} text-sm`}>{label}</span>}
  />
);

Radio.defaultProps = {
  className: "",
  label: <></>,
  labelClassName: "",
};
