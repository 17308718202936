import React from "react";

import { useRBAC } from "../../hooks";
import { Role, PermissionAction, PermissionObject } from "./../../shared";
import { ErrorAccessDenied } from "./../../components";

interface IRBACProps {
  roles?: Role[];
  actions?: PermissionAction[];
  object?: PermissionObject | null;
  conditions?: any[];
  loading?: boolean;
  error?: boolean;
  fallback?: JSX.Element;
}

export const RBAC: React.FC<IRBACProps> = ({
  children,
  roles,
  actions,
  object,
  conditions,
  error,
  fallback,
}) => {
  const { isAllowed } = useRBAC();

  return isAllowed({ roles, actions, object, conditions }) ? (
    <>{children}</>
  ) : error ? (
    fallback ? (
      fallback
    ) : (
      <ErrorAccessDenied />
    )
  ) : (
    <></>
  );
};

RBAC.defaultProps = {
  actions: [],
  object: null,
  error: false,
};
