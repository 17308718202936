import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { JobCreateContainer } = lazyload(() => import("../../features"));

export const JobCreatePage = () => (
  <MainLayout>
    <JobCreateContainer />
  </MainLayout>
);
