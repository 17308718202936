import React, { useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Button, Container, PageHeader } from "../../components";

import {
  ResumeCoverSection,
  ResumeEducationSection,
  ResumeExperienceSection,
} from "../../features";

import {
  getResume,
  getResumes,
  getResumeExperiences,
  getResumeCovers,
  getResumeEducations,
  copyResumeAsDefault,
  getDefaultResume,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
  useURL,
} from "../../hooks";
import { ROUTER } from "../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";

export const ResumeDetail = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const [URL, setURL] = useURL();

  const { modal, setModal } = useModal();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);
  const { profileCovers } = useAppSelector((state) => state.profileCover);
  const { educations } = useAppSelector((state) => state.education);
  const { experiences } = useAppSelector((state) => state.experience);

  const isLoading =
    resume?.isLoading ||
    profileCovers?.isLoading ||
    educations?.isLoading ||
    experiences?.isLoading;

  const isDefault = resume?.data?.isDefault;
  const profile = account?.profile;
  const profileId = profile?.id;

  const resumeId = URL.params?.id;

  const isVerificationAvailable =
    resume?.data?.experiences?.length > 0 ||
    resume?.data?.educations?.length > 0;

  const handleEdit = () => {
    setURL({
      path: ROUTER.RESUME_BUILDER_TEMPLATE_EDIT(),
      params: { id: resumeId },
    });
  };

  const handleDelete = (props) => {
    setModal({
      type: ModalType.RESUME_TEMPLATE_DELETE,
      props,
    });
  };

  const handleSaveAsDefault = async (id: string) => {
    const updated = await dispatch(copyResumeAsDefault(id));
    if (!updated?.id)
      return toast({
        status: NotificationStatus.Error,
        message: "Resume Template Not Saved As Default!",
      });

    dispatch(getResumes({ where: { isApplied: false, profileId } }));

    setURL({
      path: ROUTER.RESUME_BUILDER_TEMPLATE_DETAIL(),
      params: { id: updated.id },
    });

    dispatch(getResume(updated.id));
  };

  useEffect(() => {
    console.log({ resumeId });

    if (!resumeId) return;

    dispatch(getResume(resumeId));
  }, [resumeId]);

  useEffect(() => {
    console.log({ resume });
  }, [resume]);

  return (
    <Container loading={isLoading}>
      <PageHeader back={false}>
        Resume Builder / {isDefault ? "Default Resume" : resume?.data?.name}
      </PageHeader>

      <div className="flex flex-col gap-y-[20px]">
        {/*  @ts-ignore */}
        <ResumeCoverSection
          loading={resume?.isLoading}
          data={resume?.data?.covers}
        />
        {/*  @ts-ignore */}
        <ResumeExperienceSection
          loading={resume?.isLoading}
          data={resume?.data?.experiences}
        />
        {/*  @ts-ignore */}
        <ResumeEducationSection
          loading={resume?.isLoading}
          data={resume?.data?.educations}
        />
      </div>
      <div className="flex flex-row justify-between items-center mt-[15px]">
        <div className="flex flex-row gap-x-5">
          {!isDefault && (
            <Button
              color="danger"
              variant="outlined"
              onClick={() => handleDelete(resume?.data)}
            >
              Delete
            </Button>
          )}

          {/* {isVerificationAvailable && (
            <Button color="warning" variant="outlined">
              Verify All
            </Button>
          )} */}
        </div>
        <div className="flex flex-row gap-x-5">
          {!isDefault && (
            <Button
              color="secondary"
              variant="text"
              loading={resume?.isLoading}
              onClick={() => handleSaveAsDefault(resumeId)}
            >
              Save as Default
            </Button>
          )}
          <Button color="primary" variant="outlined" onClick={handleEdit}>
            Edit Template
          </Button>
        </div>
      </div>
    </Container>
  );
};
