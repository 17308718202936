import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FiUpload as UploadIcon, FiUser as UserIcon } from "react-icons/fi";

import { Logo, IconButton, Form, Container } from "../../../components";

import { ROUTER } from "../../../routes";
import { checkPassport, scanPassport } from "../../../store";
import { useAppDispatch, useLoading, useToast, useURL } from "../../../hooks";
import { RegisterContext } from "../../../context";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

export const RegisterPassportScan = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const filePickerRef = useRef<HTMLInputElement>(null);

  const handleFilePicker = () => {
    if (!filePickerRef.current) return;

    // @ts-ignore
    filePickerRef.current.value = null;
    filePickerRef.current?.click();
  };

  const handleUploadPassport = async (e) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    setLoading(true);

    const form = new FormData();

    form.append("document", file);

    // Scanning Passport
    const passport = await dispatch(scanPassport(form));
    if (passport.error) {
      setLoading(false);

      const { message } = passport.error;

      return toast({ status: NotificationStatus.Error, message });
    }

    // Checking Passport
    const checkedPassport = await dispatch(checkPassport(passport.data));
    if (checkedPassport.error) {
      setLoading(false);

      const { message } = checkedPassport.error;

      return toast({ status: NotificationStatus.Error, message });
    }

    setLoading(false);
    setRegisterState({ ...registerState, passport: passport.data });
    setURL({ path: ROUTER.REGISTER_PASSPORT_DATA });
  };

  return (
    <Container className="w-full min-h-[250px] py-[50px]">
      <div className="w-full max-w-[640px] text-white">
        <div className="flex flex-col items-center w-full text-center mb-[50px] text-2xl font-semibold">
          <Logo to="/" className="text-5xl" />
          <div className="mt-[35px] text-lg">
            <span>
              Please click the button and scan your ID or upload photo ID
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center gap-x-5">
          <Form hidden>
            <input
              ref={filePickerRef}
              id="upload"
              name="passport"
              type="file"
              accept=".jpg, .jpeg, .png, .heic"
              onChange={handleUploadPassport}
            />
          </Form>
          <IconButton
            icon={UploadIcon}
            iconProps={{ size: 48 }}
            className="w-[155px] h-[155px] text-secondary bg-primaryMid rounded-full hover:bg-primaryBlue hover:text-white"
            disabledClassName="hover:bg-primaryMid hover:text-secondary"
            loading={loading}
            onClick={handleFilePicker}
          />
          <IconButton
            to={ROUTER.LOGIN}
            icon={UserIcon}
            iconProps={{ size: 48 }}
            className="flex flex-row justify-center items-center w-[155px] h-[155px] text-secondary bg-primaryMid rounded-full hover:bg-primaryBlue hover:text-white"
          />
        </div>
      </div>
    </Container>
  );
};
