import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { ticketSlice } from "./ticket.slice";

interface ITicketCreateDto {
  subject: string;
  details: string;
}

export const createTicket =
  (payload: ITicketCreateDto) => async (dispatch: AppDispatch) => {
    try {
      const response = await ApiService.post("tickets", payload);
      const { data } = response.data;

      console.log({ data });

      return data;
    } catch (e) {
      console.log({ e });
    }
  };
