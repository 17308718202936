import React from "react";

import { FiHeart as LikeIcon } from "react-icons/fi";

interface ILikeButtonProps {
  count?: number;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

export const LikeButton: React.FC<ILikeButtonProps> = ({
  count,
  active,
  disabled,
  onClick,
}) => (
  <div
    className="flex flex-row justify-start items-center gap-x-[8px] text-base text-secondary font-medium leading-none select-none"
    onClick={disabled ? () => {} : onClick}
  >
    <LikeIcon
      size={18}
      className={`${
        active ? "text-danger fill-danger" : "text-secondary fill-none"
      } cursor-pointer hover:text-danger`}
    />
    <span>{count}</span>
  </div>
);

LikeButton.defaultProps = {
  count: 0,
  active: false,
  disabled: false,
  onClick: () => {},
};
