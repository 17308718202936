import React, { useContext, useEffect, useRef, useState } from "react";

import { FiUser as UserIcon } from "react-icons/fi";

import AgoraUI, {
  layout,
  RtcPropsInterface,
  CallbacksInterface,
} from "agora-react-uikit";

import { Button, Container, Loader, PageHeader } from "../../components";
import {
  useAppDispatch,
  useLoading,
  useProfile,
  useScreen,
  useToast,
} from "../../hooks";
import { generateVideoCallToken } from "../../store";
import { NotificationStatus } from "../../shared";

interface IVideoCall {
  isActive: boolean;
  rtc: RtcPropsInterface;
  callbacks?: Partial<CallbacksInterface>;
}

export const DemoVideoCallContainer = () => {
  const dispatch = useAppDispatch();

  const { profile } = useProfile();
  const { isMobile } = useScreen();
  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [videoCall, setVideoCall] = useState<IVideoCall>({
    isActive: false,
    rtc: {
      appId: "6777cd39c22742b6a7923568dceb5d94",
      channel: "",
      token: "",
      enableAudio: true,
      enableVideo: true,
      layout: layout.grid,
      CustomVideoPlaceholder: () => (
        <div className="w-full h-full flex flex-col justify-center bg-primaryMid text-secondary">
          <div className="flex flex-col justify-center items-center">
            <UserIcon size={48} />
            <span className="mt-[5px] text-sm font-semibold select-none">
              User
            </span>
          </div>
        </div>
      ),
    },
    callbacks: {
      "user-joined": () => {
        console.log("User Joined");
      },
      "user-published": (user, mediaType) => {
        console.log({ user, mediaType });
      },
      EndCall: () => {
        handleLeave();
      },
    },
  });

  const handleJoin = async () => {
    setLoading(true);

    const { appId, token, channel } = await dispatch(generateVideoCallToken());

    const isValid = !!appId && !!token && !!channel;
    if (!isValid) {
      setVideoCall({
        ...videoCall,
        isActive: false,
      });

      setLoading(false);

      toast({
        status: NotificationStatus.Error,
        message: `You Can't Join To The Meeting.`,
      });

      return;
    }

    setVideoCall({
      ...videoCall,
      isActive: true,
      rtc: {
        ...videoCall.rtc,
        appId,
        token,
        channel,
      },
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleLeave = () => {
    setVideoCall({ ...videoCall, isActive: false });
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <PageHeader back={false}>Meet</PageHeader>
      <Container>
        {videoCall.isActive ? (
          <Container className="relative w-full h-[500px] rounded-lg overflow-hidden">
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              {loading && (
                <div className="absolute flex flex-row justify-center items-center bg-primaryLight w-full h-full z-50">
                  <Loader />
                </div>
              )}

              <AgoraUI
                rtcProps={videoCall.rtc}
                callbacks={videoCall.callbacks}
                styleProps={{
                  UIKitContainer: {
                    background: "#221F28",
                  },

                  gridVideoContainer: {
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridTemplateRows: isMobile ? "1fr 1fr" : "1fr 1fr",
                    gap: "10px",
                    padding: "10px",
                  },
                  gridVideoCells: {
                    height: "200px",
                    borderRadius: "0.5rem",
                    overflow: "hidden",
                  },
                  localBtnContainer: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    height: "100px",
                    background: "transparent",
                  },
                  localBtnStyles: {
                    muteLocalAudio: {
                      width: "40px",
                      height: "40px",
                      background: "#08070C",
                      padding: "10px",
                      border: 0,
                    },
                    muteLocalVideo: {
                      width: "40px",
                      height: "40px",
                      background: "#08070C",
                      padding: "10px",
                      border: 0,
                    },
                    endCall: {
                      width: "40px",
                      height: "40px",
                      background: "#EB5757",
                      padding: "10px",
                      border: 0,
                    },
                  },
                }}
              />
            </div>
          </Container>
        ) : (
          <>
            <Container className="w-full h-[500px] flex flex-row justify-center items-center bg-primaryLight rounded-lg overflow-hidden">
              <div className="flex flex-col justify-center items-center">
                <h2 className="text-white text-lg font-semibold">
                  Meeting #Test
                </h2>
                <span className="text-sm text-white">
                  Do you want to join the meeting?
                </span>
                <div className="mt-[20px]">
                  <Button
                    color="success"
                    className="min-w-[150px]"
                    loading={loading}
                    onClick={handleJoin}
                  >
                    Join
                  </Button>
                </div>
              </div>
            </Container>
          </>
        )}
      </Container>
    </Container>
  );
};
