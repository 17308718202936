import React, { useEffect } from "react";

import { useParams } from "react-router";

import { WebsocketEvents } from "../../shared";

import { Avatar, Container, Link } from "../../components";
import { ChatListItem } from "./../../features";
import { useAppDispatch, useAppSelector, useLoading } from "../../hooks";
import { ROUTER } from "../../routes";
import { EventService } from "../../services";
import { getChatById, getUserChats } from "../../store";
import { textformat } from "../../utils";

export const ChatList = () => {
  const dispatch = useAppDispatch();

  const { chatId } = useParams();
  const [loading, setLoading] = useLoading(true);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    chats: { data: chats, results: chatCount, isLoading: chatsLoading },
  } = useAppSelector((state) => state.chat);

  const profileId = account.profileId;

  const isActive = (id?: string): boolean => (id ? id === chatId : false);

  // useEffect(() => {
  //   EventService.on("message", (message) => {
  //     const { event, data, results } = message || {};

  //     switch (event) {
  //       case WebsocketEvents.GET_CHAT_LIST:
  //         dispatch(setChats({ data, results }));
  //         setLoading(false);
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   setTimeout(() => {
  //     EventService.send({
  //       event: WebsocketEvents.GET_CHAT_LIST,
  //       data: {
  //         userId: profileId,
  //       },
  //     });
  //   }, 500);

  //   const interval = setInterval(() => {
  //     EventService.send({
  //       event: WebsocketEvents.GET_CHAT_LIST,
  //       data: {
  //         userId: profileId,
  //       },
  //     });
  //   }, 500);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (!profileId) return;

    dispatch(getUserChats());

    EventService.send({ event: WebsocketEvents.SYNC_CHATS_START });

    return () => {
      EventService.send({ event: WebsocketEvents.SYNC_CHATS_STOP });
    };
  }, [profileId]);

  return (
    <Container
      loading={chatsLoading}
      className="w-full h-full flex flex-col gap-y-[15px]"
      loadingClassName="px-[20px] py-[20px]"
    >
      <div className="w-full h-full">
        {chats?.map((chat) => (
          <ChatListItem
            key={chat.id}
            profileId={profileId}
            isActive={isActive(chat?.id)}
            // isNewMessage={true}
            {...chat}
          />
        ))}
      </div>
    </Container>
  );
};

ChatList.defaultProps = { lastMessage: "********" };
