import React, { useEffect } from "react";
import { useParams } from "react-router";

import { Container, PageHeader } from "../../components";
import { BusinessAuditCard } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAuditById } from "../../store";

export const AdminBusinessAuditDetailContainer = () => {
  const dispatch = useAppDispatch();

  const { auditId } = useParams();

  const {
    audit: { data: audit, isLoading: auditLoading },
  } = useAppSelector((state) => state.audit);

  useEffect(() => {
    if (!auditId) return;

    dispatch(getAuditById(auditId));
  }, [auditId]);

  return (
    <Container loading={auditLoading}>
      <PageHeader>Audit</PageHeader>
      <Container>
        <BusinessAuditCard {...audit} />
      </Container>
    </Container>
  );
};
