import { useState } from "react";

export const useLoading = (
  initialState?: boolean
): [boolean, (value: boolean) => void] => {
  const [state, setState] = useState(initialState || false);

  const handleUpdate = (value: boolean) => {
    setState(value);
  };

  return [state, handleUpdate];
};
