import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import {
  MdExpandMore as ExpandMoreIcon,
  MdSettings as SettingsIcon,
} from "react-icons/md";

import { FiLogOut as LogOutIcon } from "react-icons/fi";

import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  IconButton,
  Loader,
} from "../../components";
import { ROUTER } from "../../routes";
import {
  logout,
  switchToPersonalProfile,
  switchToBusinessProfile,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useProfile,
} from "../../hooks";

export const ProfileNavbar = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const {
    account: { data: account, isLoading: accountLoading },
  } = useAppSelector((state) => state.account);

  const { profile, hasNotifications, notificationCount } = useProfile();

  const [loading, setLoading] = useLoading();
  const [logoutLoading, setLogoutLoading] = useLoading();

  const isLoading = loading || accountLoading;

  const { firstName, lastName } = profile || {};

  const accountId = account?.id;
  const profileId = account?.profileId;

  const isBusiness = profile?.isBusiness || false;
  const isOwner = account?.ownership?.id ? true : false;
  const isOwnerVerification = account?.ownershipVerification?.id ? true : false;
  const isEmployee = account?.employment?.id ? true : false;
  const isEmployed = isOwner || isEmployee;

  const handleSwitchToPersonal = async (e) => {
    if (!accountId) return;

    setLoading(true);

    await dispatch(switchToPersonalProfile(accountId));

    setLoading(false);
    redirect(ROUTER.HOME);
  };

  const handleSwitchToBusiness = async (e) => {
    if (!accountId) return;

    setLoading(true);

    await dispatch(switchToBusinessProfile(accountId));

    setLoading(false);
    redirect(ROUTER.HOME);
  };

  const handleLogout = async () => {
    setLogoutLoading(true);

    const loggedOut = await dispatch(logout());
    if (!loggedOut) {
      return setLogoutLoading(false);
    }

    setLogoutLoading(false);
    redirect(ROUTER.LOGIN);
  };

  useEffect(() => {
    console.log({ profile, hasNotifications, notificationCount });
  }, [profile, hasNotifications, notificationCount]);

  return (
    <div className="flex justify-between items-center">
      {isLoading ? (
        <IconButton
          icon={() => (
            <div className="flex flex-row justify-start items-center gap-x-[5px]">
              <Loader size={32} />
              <IconButton icon={ExpandMoreIcon} />
            </div>
          )}
          size={28}
        />
      ) : profileId ? (
        <DropdownMenu
          className="max-w-[200px] px-[20px] py-[20px] mt-[17px] -ml-[5px]"
          button={
            <IconButton
              icon={() => (
                <div className="flex flex-row justify-start items-center gap-x-[5px]">
                  <Avatar
                    size={28}
                    src={profile?.avatar}
                    className={`${
                      isBusiness
                        ? "border-[2px] border-solid border-warning"
                        : ""
                    }`}
                  >
                    {isBusiness
                      ? firstName?.slice(0, 1)
                      : firstName?.slice(0, 1)}
                  </Avatar>

                  <div className="md:flex xxs:hidden">
                    <IconButton icon={ExpandMoreIcon} size={28} />
                  </div>
                </div>
              )}
              size={28}
            />
          }
        >
          <div className="mt-[5px] mb-[5px]">
            <h2 className="font-semibold text-md -mb-[5px]">
              <span className="capitalize">
                {lastName ? `${firstName?.slice(0, 1)}.` : firstName}{" "}
                {lastName || ""}
              </span>
            </h2>

            {isBusiness ? (
              <span className="text-warning text-xs font-medium">Business</span>
            ) : (
              <span className="text-secondary text-xs font-medium">
                Personal
              </span>
            )}
          </div>

          <div>
            <DropdownItem to={ROUTER.USER_DETAIL(profileId)}>
              Profile
            </DropdownItem>
            <DropdownItem>Settings</DropdownItem>

            <div className="border-t border-b border-solid border-primaryLight my-[10px] box-border py-[10px]">
              <DropdownItem>
                {isBusiness ? (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleSwitchToPersonal}
                  >
                    Switch to Personal
                  </Button>
                ) : isEmployed ? (
                  <div className="flex flex-col gap-y-[10px]">
                    <Button
                      color="warning"
                      variant="outlined"
                      onClick={handleSwitchToBusiness}
                    >
                      Switch to Business
                    </Button>
                  </div>
                ) : isOwnerVerification ? (
                  <div className="text-xs text-secondary cursor-default">
                    <span>Company registration pending...</span>
                  </div>
                ) : (
                  <Button
                    color="success"
                    variant="outlined"
                    to={ROUTER.BUSINESS_REGISTRATION}
                  >
                    Register as Business
                  </Button>
                )}
              </DropdownItem>
            </div>

            <div>
              <DropdownItem
                icon={LogOutIcon}
                iconClassName="text-danger"
                className="hover:text-danger"
                onClick={handleLogout}
              >
                Logout
              </DropdownItem>
            </div>
          </div>
        </DropdownMenu>
      ) : (
        <Button color="primary" variant="outlined" to={ROUTER.LOGIN}>
          Login
        </Button>
      )}
    </div>
  );
};

ProfileNavbar.defaultProps = {};
