import React from "react";

import { Container } from "../../Container";

interface ISidebarSectionProps {
  className?: string;
  headerClassName?: string;
  headingClassName?: string;
  loading?: boolean;
  heading?: string | null;
  navbar?: JSX.Element | null;
}

export const SidebarSection: React.FC<ISidebarSectionProps> = ({
  children,
  className,
  headerClassName,
  headingClassName,
  loading,
  heading,
  navbar,
}) => (
  <Container
    loading={loading}
    className={`${className} w-full flex flex-col items-start px-[20px] py-[25px] box-border border-t border-primaryLight`}
    loadingClassName="px-[20px] py-[25px]"
  >
    <div
      className={`${headerClassName || ""} ${
        heading ? "mb-[15px]" : ""
      } w-full flex flex-row justify-between items-center`}
    >
      {heading ? (
        <h3
          className={`${headingClassName} text-secondary text-base font-medium`}
        >
          {heading}
        </h3>
      ) : (
        <div></div>
      )}
      <div className="flex flex-row items-center"></div>
      {navbar && navbar}
    </div>

    <div
      className={`${className} relative w-full text-secondary text-base break-words`}
    >
      {children}
    </div>
  </Container>
);

SidebarSection.defaultProps = {
  className: "",
  loading: false,
};
