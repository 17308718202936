import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  FiPaperclip as FileIcon,
  FiImage as ImageIcon,
  FiLink as LinkIcon,
} from "react-icons/fi";
import { VscChromeClose as CloseIcon } from "react-icons/vsc";

import {
  Button,
  Form,
  FormSection,
  TextInput,
  StarRating,
  Switch,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../../hooks";
import { createPost, createResume } from "../../../store";
import { ROUTER } from "../../../routes";
import { addLineBreaks, toBase64 } from "../../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1500, "Exceeded maximum character length of 1500")
    .required("Post can't be empty!"),
});

interface IPostCreateFormProps {
  open?: boolean;
  isPublic?: boolean;
  onClose?: (e?: React.MouseEvent) => void;
}

export const PostCreateForm: React.FC<IPostCreateFormProps> = ({
  open,
  isPublic,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const [imageAttachmentPreview, setImageAttachmentPreview] = useState<
    string | null
  >(null);
  const imageAttachmentRef = useRef<HTMLInputElement>(null);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: { details: "" },
    validationSchema,
    onSubmit: async (values) => {
      if (!profileId) return;

      const { details, image }: any = values;

      const data = {
        ...values,
        details: addLineBreaks(details),
        image,
        isPublic,
        createdById: profileId,
      };

      const form = new FormData();

      Object.keys(data)?.map((i) => {
        console.log({ [i]: data?.[i] });

        form.append(i, data?.[i]);
      });

      setLoading(true);

      const created = await dispatch(createPost(form));
      if (!created?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Post Not Created!",
        });
      }

      // toast({ status: NotificationStatus.Success, message: "Post Created!" });

      setLoading(false);
      resetForm();
    },
  });

  const { image }: any = values;

  const handleImageAttachmentPick = async () => {
    if (!imageAttachmentRef?.current) return;

    // @ts-ignore
    setFieldValue("image", imageAttachmentRef.current.value, true);

    // @ts-ignore
    imageAttachmentRef.current.value = null;
    imageAttachmentRef.current?.click();
  };

  const handleImageAttachmentChange = async (e) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    setFieldValue("image", file, true);
  };

  const handleImageAttachmentRemove = () => {
    setFieldValue("image", null, false);
    setImageAttachmentPreview(null);
  };

  const handleCancel = () => {
    if (onClose) onClose();
    resetForm();
  };

  useEffect(() => {
    if (!image) return setImageAttachmentPreview(null);

    toBase64(image).then((preview) =>
      setImageAttachmentPreview(preview?.toString() || null)
    );
  }, [image]);

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        id="details"
        type="text"
        variant="standard"
        className="min-h-[55px]"
        controlClassName="basis-full"
        labelClassName="text-secondary"
        placeholder="Create Post"
        multiline
        maxRows={10}
        required
        value={values?.details}
        error={touched?.details ? errors?.details : null}
        onChange={handleChange}
      />

      <div className="flex flex-row justify-between items-center mt-[10px]">
        <div className="flex flex-row justify-start items-center gap-x-[10px]">
          <div
            className="text-secondary cursor-pointer hover:text-white"
            onClick={handleImageAttachmentPick}
          >
            <Form hidden>
              <input
                id="image-attachment"
                name="image"
                type="file"
                accept="image/*"
                ref={imageAttachmentRef}
                onChange={handleImageAttachmentChange}
              />
            </Form>

            <ImageIcon size={20} />
          </div>
          <div className="text-secondary cursor-pointer hover:text-white">
            <FileIcon size={20} />
          </div>
          <div className="text-secondary cursor-pointer hover:text-white">
            <LinkIcon size={20} />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-x-[5px]">
          <Button
            type="submit"
            variant="outlined"
            loading={loading}
            className="min-w-[150px]"
            color="success"
          >
            Post
          </Button>
        </div>
      </div>

      {imageAttachmentPreview && (
        <div className="w-full mt-[10px]">
          <div className="relative w-[48px] h-[48px]">
            <div
              className="transition-all z-20 absolute -top-[5px] -right-[5px] w-[18px] h-[18px] flex flex-row justify-center items-center rounded-md cursor-pointer bg-primaryMid hover:bg-primaryLight"
              onClick={handleImageAttachmentRemove}
            >
              <CloseIcon size={10} />
            </div>

            <div className="z-10 relative w-full h-full rounded-lg overflow-hidden">
              <img
                className="w-full h-full"
                src={imageAttachmentPreview}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

PostCreateForm.defaultProps = {
  isPublic: false,
};
