import React from "react";

import { ROUTER } from "../../routes";

import { Button, PageContainerSwitch } from "../../components";
import { CompanyJobSearch } from "..";

export const BusinessHomeContainer = () => {
  const tabs = [
    {
      label: "Jobs",
      component: CompanyJobSearch,
      onRight: (
        <Button
          to={ROUTER.JOB_CREATE}
          variant="outlined"
          color="success"
          className="md:flex xxs:hidden"
        >
          Post Job
        </Button>
      ),
    },
    {
      label: "Archived Jobs",
      component: () => <></>,
    },
  ];

  return <PageContainerSwitch tabs={tabs} />;
};
