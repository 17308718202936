import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { FriendsContainer } = lazyload(() => import("../../features"));

export const FriendsPage = () => (
  <MainLayout>
    <FriendsContainer />
  </MainLayout>
);
