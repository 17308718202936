import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface IVideoCallState {}

const initialState: IVideoCallState = {};

export const videoCallSlice = createSlice({
  name: "Verification Request",
  initialState,
  reducers: {
    generateToken: (state) => {},
  },
});

export const videoCallReducer = videoCallSlice.reducer;
